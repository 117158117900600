import React, { useState, useContext } from "react";
import "./LoginPage.scss";
import { setJWT, setUserId } from "../components/utils/JWT";
import { UserContext } from "../UserContext";
import { login, forgotPasswordEmail } from "../components/API_calls/users";
import { withRouter } from "react-router-dom";
import InputField from "../components/Profile/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PopUp from "../components/PopUp";
import { invalidPassword } from "../components/utils/fieldValidations";

//pop up for reset pw
import Swal from "sweetalert2";

// import * as detailHelpers from "../components/OrderDetails/orderDetailsHelpers";
import { getLatestOrder } from "../components/API_calls/orders";

// import RegisterPage from './RegisterPage';

import { updateLoggedInStatus } from "../components/utils/helpers";

const LoginPage = ({ lastOrderId, ...props }) => {
  const { userDispatch } = useContext(UserContext);
  const [input, setInput] = useState({ username: "", password: "" });
  const [errorMsg, setErrorMsg] = useState({});
  const [forgotPassPopup, setForgotPassPopup] = useState(false);

  // for popup
  const [defaultError, setDefaultError] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("");

  // const [register, setRegister] = useState(false);

  const inputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const validateFields = () => {
    const validPassword = !invalidPassword(input.password, input.email);
    const passwordError = validPassword ? "" : "Invalid password";
    // const emailError = validEmail ? "" : "Invalid email";
    if (!validPassword) {
      setErrorMsg({ password: passwordError });
      return false;
    }
    return true; 
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    setErrorMsg({ username: "", password: "" });
    const validFields = validateFields();
    if (!validFields) {
      return false;
    }

    try {
      console.log('attemp login')
      const res = await login(input.username, input.password);
      console.log(res)
      const { token, userID } = res;
      if (!token) {
        throw new Error();
      }
      setJWT(token);
      setUserId(userID);
      const userInfo = await updateLoggedInStatus(userID);
      userDispatch({ type: "updateUserInfo", payload: userInfo });
      if (!userInfo.isAdmin) {
        props.history.push("/");
      } else {
        console.log('good login')

        const getOrderList = await getLatestOrder();
        let selfHref =
          getOrderList &&
          getOrderList._links &&
          getOrderList._links.self.href.split("/");
        const id = selfHref && selfHref[selfHref.length - 1];
        props.history.push(id ? `/orders/T${id}` : "/orders/new");
        // props.history.push(`/orders`);

      }
    } catch (err) {
      console.log('bad login')
      setDefaultError(`Error: ${err.status} - ${err.message}`);
      setPopUpType("error");
      setPopUpStatus(new Date());
    }
  };


  const callForgotPassAPI = async (email) =>{
    
    try {
      const status = await forgotPasswordEmail(email);

      setDefaultError(`Email Sent`);
      setPopUpType("success");
      setPopUpStatus(new Date());
    } catch (err) {
      setDefaultError(`Error: ${err.status} - ${err.message}`);
      setPopUpType("error");
      setPopUpStatus(new Date());
    }
  }

  const forgotPass = async () => {
    setForgotPassPopup(true);

    const { value: email } = await Swal.fire({
      icon: "info",
      title: "Enter Your Email",
      input: "text",
      showCancelButton: true,
    });

    if(email){
      callForgotPassAPI(email)
    }
   
  };

  

  return (
    <div id="login-register-container">
      <form onSubmit={submitHandler}>
        {popUpStatus ? (
          <PopUp type={popUpType} message={defaultError} status={popUpStatus} />
        ) : null}
        <div className="main-login-container">
          <Grid container justify="center">
            <Grid item xs={12}>
              <span className="header">
                <Typography variant="h2">LOGIN</Typography>
              </span>
            </Grid>
            <Grid item className="field" lg={12} xs={12}>
              <InputField 
                type="text"
                name="username"
                label="Username"
                placeholder="Enter Username"
                value={input.username.toLowerCase()}
                changeHandler={inputHandler}
                errorMsg={errorMsg.username}
                fullWidth
              />
            </Grid>
            <Grid item className="field" lg={12} xs={12}>
              <InputField
                type="password"
                name="password"
                label="Password"
                placeholder="Enter Password"
                value={input.password}
                changeHandler={inputHandler}
                errorMsg={errorMsg.password}
              />
            </Grid>
            <Grid item className="button" xs={3}>
              <Button data-cy="login-btn" onClick={props.submitHandler} type="submit" variant="contained" color="primary">
                Login
              </Button>
              <Button
                data-cy="pw-reset-btn" 
                onClick={forgotPass}
                style={{ marginLeft: "2em" }}
                variant="contained"
                color="primary"
              >
                Reset Password
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
      {/* <Button variant='contained' color='secondary' onClick={() => setRegister(prev => !prev)} >
        {register ? 'Login' : 'Register'}
      </Button> */}
    </div>
  );
};

export default withRouter(LoginPage);
