import React, { useState, useEffect, useContext } from 'react';
import * as billingHelpers from "./BillingHelpers";
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import InvoiceHeader from './InvoiceHeader';
import DefaultTable from '../DefaultTable';
import { invoiceDetailColumns } from '../data/constants';
import ConfirmationDialog from './ConfirmationDialog';
import AddOrderDialog from './AddOrderDialog';
import Dialog from '@material-ui/core/Dialog';
import { createInvoice, removeOrderFromInvoice, addOrderToInvoice, getInvoiceSummary } from '../API_calls/invoicePayslip';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TextField from '@material-ui/core/TextField';
import AddBox from '@material-ui/icons/AddBox';
import { InvoicePayslipDataContext } from '../../Context';
import BillingDrawer from './BillingDrawer';
import ReportWrapper from '../ReportWrapper';
import Invoice1 from './pdfTemplates/Invoice1';
import Invoice2 from './pdfTemplates/Invoice2';
import { roundTo } from '../utils/helpers';
// import Invoice3 from './pdfTemplates/Invoice3';


const TabPanel = ({ children, value, index }) => {

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}>
      <Box
        style={{
          padding: "10px",
          overflow: "auto",
          margin: "5px",
          backgroundColor: "#dee0df"
        }}
      >
        {children}
      </Box>
    </Typography>
  );
};


const InvoiceDetails = ({ details, setDetails, created, upToDate, setMode, invoicePayslipId, history, ...props }) => {

  const { setInvoicePayslipData } = useContext(InvoicePayslipDataContext);

  const classes = billingHelpers.useStylesInvoice();

  const [orderList, setOrderList] = useState([]);

  const [beforeCreate, setBeforeCreate] = useState(!created);
  const [pageSize, setPageSize] = useState(50);

  const [open, setOpen] = useState(true);
  const [tabVal, setTabVal] = useState(0);

  // PDF states
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [addOrderDialogOpen, setAddOrderDialogOpen] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);
  const [pdfType, setPdfType] = useState('');
  const [summaryDetails, setSummaryDetails] = useState({});

  useEffect(() => {
    console.log('details:', details) 
  }, [details]);
  
  //loop through orders, each order is now it's own object. 
  const getServiceItemOrders = (orders) => {
    let serviceItemOrders = [];
    orders.forEach(order => {
      order.serviceItems.forEach(serviceItem => {
        let tempOrder = {...order}
        tempOrder.serviceItems = [serviceItem];
        serviceItemOrders.push(tempOrder)
      })
    })
    return serviceItemOrders;
  }

  useEffect(() => {
    if (beforeCreate) {
      let orderDetails = details.orders.filter(order => !order.invoiced && order.completionTime && moment(order.completionTime).isSameOrBefore(upToDate, 'day'))
      setOrderList(getServiceItemOrders(orderDetails))
    } else {
      setOrderList(getServiceItemOrders(details.orders));
    }
  }, [beforeCreate, details.orders, upToDate]);

  console.log("orderList", orderList)


  // TABLE FUNCTIONS
  const delFunction = async oldData => {
    try {
      const invoiceUrl = details._links ? details._links.self.href : undefined;
      const orderId = oldData.orderID ? oldData.orderID : undefined;
      await removeOrderFromInvoice(invoiceUrl, orderId);

      const updatedOrders = details.orders.filter(order => order.orderID !== oldData.orderID);

      setDetails(prev => ({ ...prev, orders: updatedOrders }));
      setInvoicePayslipData(prev => prev.map(invoice => invoice.invoiceID === details.invoiceID ? { ...details, orders: updatedOrders } : invoice));
    } catch (e) {
      console.log(e);
    }
  };

  const addFunction = async order => {
    try {
      const currOrders = orderList.map(order => order._links.self.href);
      await addOrderToInvoice(details._links.self.href, [...currOrders, order._links.self.href]);

      const updatedOrders = [...orderList, order];

      setDetails(prev => ({ ...prev, orders: updatedOrders }));
      setInvoicePayslipData(prev => prev.map(invoice => invoice.invoiceID === details.invoiceID ? { ...details, orders: updatedOrders } : invoice));

      setAddOrderDialogOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const actions = [
    {
      icon: () => <AddBox />,
      tooltip: "Add",
      isFreeAction: true,
      onClick: (event) => {
        setAddOrderDialogOpen(true);
      }
    },
  ];

  // API FUNCTIONS
  const confirmInvoice = async notes => {
    try {
      let newInvoice = {
        ...details,
        notes: notes,
        invoiceDate: moment(),
        upToPeriod: moment(upToDate),
        insuranceCompany: details._links.self.href,
        orders: orderList.map(order => order._links.self.href)
      };
      const res = await createInvoice(newInvoice);
      setDetails(prev => ({
        ...prev, 
        invoiceID: res.invoiceID, 
        invoiceDate: res.invoiceDate, 
        upToPeriod: res.upToPeriod, 
        insuranceCompany: res._embedded.insuranceCompany, 
        orders: orderList,
        notes: res.notes,
        _links: res._links
      }));
      setBeforeCreate(prev => !prev);
      setOpenConfirmationDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  const openPdfViewer = async type => {
    try {
      const res = await getInvoiceSummary(details._links.self.href.split('{')[0]);
      console.log('res:', res);
      
      // map data into consistent table data format
      const serviceItemsList = res.serviceItems.map(item => ({
        qty: item.summaryReportTally,
        code: item.shortCode,
        description: item.name,
        unitCost: roundTo(item.summaryReportUnitPrice, 2),
        total: roundTo(item.summaryReportTotal, 2)
      }));
      const bloodKit = res.bloodKitTotal.quantity > 0 ? [{
        code: 'Blood Kit',
        qty: res.bloodKitTotal.quantity,
        unitCost: roundTo(res.bloodKitTotal.unitCost, 2),
        total: roundTo(res.bloodKitTotal.totalCost, 2)
      }] : [];
      const urineKit = res.urineKitTotal.quantity > 0 ? [{
        code: 'Urine Kit',
        qty: res.urineKitTotal.quantity,
        unitCost: roundTo(res.urineKitTotal.unitCost, 2),
        total: roundTo(res.bloodKitTotal.totalCost, 2)
      }] : [];
      const scanFax = res.scanFax.quantity > 0 ? [{
        code: 'Scan/Fax',
        qty: res.scanFax.quantity,
        total: roundTo(res.scanFax.totalCost, 2)
      }] : [];
      const mileage = res.mileage.quantity > 0 ? [{
        code: 'Mileage',
        qty: res.mileage.quantity,
        unitCost: roundTo(res.mileage.unitCost, 2),
        total: roundTo(res.mileage.totalCost, 2)
      }] : [];
      const summary = {
        hstTotal: roundTo(res.hsttotal, 2),
        grandTotal: roundTo(res.grandTotal, 2),
        serviceChargeTotal: roundTo(res.serviceChargeTotal, 2),
        tableData: [...serviceItemsList, ...bloodKit, ...urineKit, ...mileage, ...scanFax]
      };
      
      console.log('summary:', summary)
      setSummaryDetails(summary);
    } catch (err) {
      console.log(err);
      setSummaryDetails({ tableData: [] });
    } finally {
      setPdfViewerOpen(true);
      setPdfType(type);
    }
  };

  const invoiceButtonCheck = () => {
    let invoiceCheck = false;
     for (const fee of orderList) {
       console.log("fee", fee.serviceItems)
        for (const expense of fee.serviceItems) {
          if (expense.serviceAmount === null) {
            invoiceCheck = true;
          } else if (expense.insuranceMileage && expense.mileageAmount === null) {
            invoiceCheck = true; 
           } 
          }
        }
     return invoiceCheck;
 }

console.log("invoiceButtonCheck", invoiceButtonCheck())


  // BUTTON COMPONENTS
  const InvoiceButtons = () => {
    return (
      beforeCreate ? 
        <div style={{ padding: "10px 0px 0px 0px", width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setOpenConfirmationDialog(true)}
                color="primary"
                disabled = {invoiceButtonCheck()}
              >
                Create Invoice
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setMode('SUMMARY')}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div> :
        <div style={{ padding: "10px 0px 0px 0px", width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={() => openPdfViewer('PDF 1')} color="primary">
                PDF 1
              </Button>
            </Grid>
            <Grid item>
              <Button variant="contained" onClick={() => openPdfViewer('PDF 2')} color="primary">
                PDF 2
              </Button>
            </Grid>
            {/* <Grid item>
              <Button variant="contained" onClick={() => openPdfViewer('PDF 3')} color="primary">
                PDF 3
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setMode('SUMMARY')}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        color="default"
      >
        <Grid container>
          <Grid item xs={11}>
            <Tabs
              value={tabVal}
              onChange={(e, newVal) => setTabVal(newVal)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={beforeCreate ? "Create Invoice" : 'Invoice Details'} />
            </Tabs>
          </Grid>
          <Grid item container xs={1} justify="flex-end">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge={false}
              onClick={() => setOpen(true)}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
        style={{ marginRight: '77px'}}
      >
        <TabPanel value={tabVal} index={0}>
          <InvoiceHeader beforeCreate={beforeCreate} details={details} setDetails={setDetails} upToDate={upToDate} />

          <div style={{ marginBottom: "15px" }}>
            <Divider style={{ margin: "15px 0px 10px 0px" }} />
            <DefaultTable
              onRowClick={!beforeCreate ? (e, rowData) => history.push(`/orders/${rowData.orderID}`) : null}
              title={""}
              inputColumns={invoiceDetailColumns}
              inputData={orderList}
              pageSize={pageSize}
              setPageSize={setPageSize}
              deleteFunction={!beforeCreate ? delFunction : null}
              // addFunction={!beforeCreate ? addFunction : null}
              actions={!beforeCreate ? actions : []}
            />
            <Divider style={{ margin: "15px 0px 10px 0px" }} />
            {!beforeCreate && <TextField 
              readOnly
              value={details.notes ? details.notes : ''}
              fullWidth
              label='Notes:'
            />}
          </div>

          <InvoiceButtons />
        </TabPanel>
      </main>

      <BillingDrawer 
        open={open} 
        setOpen={setOpen} 
        setDetails={setDetails} 
        currentId={beforeCreate ? details.name : details.invoiceID} 
        idSource={beforeCreate ? 'name' : 'invoiceID'}
      />

      <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} maxWidth="sm" fullWidth>
        <ConfirmationDialog
          confirmationDialogClose={() => setOpenConfirmationDialog(false)}
          confirmCreate={confirmInvoice}
          details={details}
          upToDate={upToDate}
          title={'Create Invoice'}
          source="invoice"
        />
      </Dialog>

      <Dialog open={addOrderDialogOpen} onClose={() => setAddOrderDialogOpen(false)} maxWidth="lg" fullWidth>
        <AddOrderDialog
          // invoicePayslipId={invoicePayslipId}
          details={details}
          setDetails={setDetails}
          setAddOrderDialogOpen={setAddOrderDialogOpen}
          addFunction={addFunction}
          source='invoice'
        />
      </Dialog>
      
      <Dialog open={pdfViewerOpen} onClose={() => setPdfViewerOpen(false)} fullScreen>
        <ReportWrapper title={pdfType} closeDialog={() => setPdfViewerOpen(false)} orientation='landscape'>
          {pdfType === 'PDF 1' && <Invoice1 details={details} summaryDetails={summaryDetails} />}
          {pdfType === 'PDF 2' && <Invoice2 details={details} summaryDetails={summaryDetails} />}
          {/* {pdfType === 'PDF 3' && <Invoice3 details={details} />} */}
        </ReportWrapper>
      </Dialog>
    </div>
  );
};

export default InvoiceDetails;