import React from "react";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
// import Grid from "@material-ui/core/Grid";
// import TextField from "@material-ui/core/TextField";
// import Typography from "@material-ui/core/Typography";
// import moment from "moment";

const CopyOrderDialog = ({ copyState, setCopyState, handleOk, ...props}) => {

  const handleChange = (name, val) => {
    setCopyState(prev => ({ ...prev, [name]: val }));
  };

  return (
    <div>
      <DialogTitle id="form-dialog-title">Copy Order</DialogTitle>
      <DialogContent>
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={copyState.companyInfo}
                onChange={() => handleChange("companyInfo", !copyState.companyInfo)}
                value="companyInfo"
                color="primary"
              />
            }
            label="Copy Company Info?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={copyState.agencyInfo}
                onChange={() => handleChange("agencyInfo", !copyState.agencyInfo)}
                value="agencyInfo"
                color="primary"
              />
            }
            label="Copy Agency Info?"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={copyState.agentInfo}
                onChange={() => handleChange("agentInfo", !copyState.agentInfo)}
                value="agentInfo"
                color="primary"
              />
            }
            label="Copy Agent Info?"
          />
        </FormGroup>
        <RadioGroup value={copyState.clientInfo} onChange={e => handleChange('clientInfo', e.target.value)} row>
          <FormControlLabel
            value="clientNoFirstName"
            control={<Radio color="primary" />}
            labelPlacement="end"
            label="Copy Client Info. Except First Name."
          />
          <FormControlLabel
            value="allClientInfo"
            control={<Radio color="primary" />}
            labelPlacement="end"
            label="Copy Client Info."
          />
          <FormControlLabel
            value="noClientInfo"
            control={<Radio color="primary" />}
            labelPlacement="end"
            label="Do Not Copy Client Info."
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button data-cy="copy-order-ok-btn" onClick={handleOk} color="primary">
          Ok
        </Button>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </div>
  );
};

export default CopyOrderDialog;
