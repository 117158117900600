import instance from "../utils/API";
import instance_base from "../utils/API_base";

const createOrderAttachment = async (info) => {
  try {
    const res = await instance.post("/orderAttachments", info).then((res) => res.data);
    let newData = { ...res, uploadedBy: res._embedded.uploadedBy };
    return newData;
  } catch (error) {
    throw error;
  }
};

const updateOrderAttachment_base = async (url, updatedInfo) => {
  try {
    const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const deleteOrderAttachment_base = async (url) => {
  try {
    await instance_base.delete(url);
  } catch (error) {
    throw error;
  }
};

const getOrderAttachment_base = async (url) => {
  try {
    const res = await instance_base
      .get(url, {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.orderAttachments;
  } catch (error) {
    throw error;
  }
};

const downloadAttachment_base = async (url) => {
  try {
    const res = await instance_base({
      method: "get",
      url: url,
      responseType: "blob"
    }).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const downloadNewAttachments = async companyUrl => {
  try {
    const res = await instance('/orderAttachments/downloadZip', {
      params: { insuranceCompany: companyUrl },
      responseType: "blob"
    });
    return res.data;
  } catch (error) {
    throw error;
  } 
};

export {
  createOrderAttachment,
  updateOrderAttachment_base,
  deleteOrderAttachment_base,
  getOrderAttachment_base,
  downloadAttachment_base,
  downloadNewAttachments
};
