import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "./DefaultTable";
import InsuranceAgencyDetails from "./table-details/InsuranceAgencyDetails";
import Dialog from "@material-ui/core/Dialog";
import Edit from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";

import { updateAgency_base, createAgency } from "./API_calls/agencies";
import { agencyInfoColumns } from "./data/constants";

import { RolesContext } from '../UserContext';
import { getSpecificUserTypes } from "./API_calls/users";

const AgencyCrudTable = ({ agencyListData, ...props }) => {

  const { roles } = useContext(RolesContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [currDetails, setCurrDetails] = useState({});
  const [agencyAdminList, setAgencyAdminList] = useState([]);
  const [footerMsg, setFooterMsg] = useState('');

 

  useEffect(() => {
    if (Object.keys(roles).length === 7) {
      const obtainUserData = async () => {
        setIsLoading(true);
        try {
          const agencyAdminData = await getSpecificUserTypes(roles["ROLE_AGENCY_ADMIN"]);
          setAgencyAdminList(agencyAdminData);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      obtainUserData();
    }
  }, [roles]);
  
  useEffect(() => {
    const initializeData = async () => {
      try {
        setIsLoading(true);
        setInfo(agencyListData);
      } catch (e) {
        console.log("error:", e);
      } finally {
        setIsLoading(false);
      }
    };
    initializeData();
  }, [agencyListData]);


  const addFunction = async (newData) => {
    setIsLoading(true);
    try {
      let res = await createAgency(newData);
      setInfo([res, ...info]);
      setOpen(false);
    } catch (e) {
      setFooterMsg('Agency Admin already assigned.');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {
    console.log(newData)

     //triple input error handling
     if(newData.tele !== null && newData.tele !== "--"){
      if (newData.tele.length !== 12 && newData.tele.length !== 0){
        setFooterMsg("Check Tel Number")
        return
      }  
    }

    if(newData.fax !== null && newData.fax !== "--"){
      if (newData.fax.length !== 12 && newData.fax.length !== 0){
        setFooterMsg("Check Fax Number")
        return
      }  
    }
    //end

    setIsLoading(true);
    try {
      await updateAgency_base(oldData._links.self.href, newData);
      const data = info.map((currData) =>
        currData.tableData.id === oldData.tableData.id ? newData : currData
      );
      setInfo(data);
      setOpen(false);
    } catch (e) {
      setFooterMsg('Agency Admin already assigned.');
      console.log(e);
    } finally {
      setIsLoading(false);
      // setFooterMsg("")

    }
  }; 

  const actions = [
    {
      icon: () => <Edit />,
      tooltip: "Edit",
      onClick: (event, rowData, togglePanel) => {
        setCurrDetails(rowData);
        setOpen(true);
      }
    },
    {
      icon: () => <AddBox />,
      tooltip: "Add",
      isFreeAction: true,
      onClick: (event) => {
        setCurrDetails({});
        setOpen(true);
      }
    }
  ];

  const handleClose = () => {
    setOpen(false);
    setFooterMsg('');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} scroll="paper">
        <InsuranceAgencyDetails
          details={currDetails}
          handleClose={handleClose}
          handleSave={updateFunction}
          handleCreate={addFunction}
          type={Object.keys(currDetails).length > 0 ? "update" : "add"}
          agencyAdminList={agencyAdminList}
          footerMsg={footerMsg}
        />
      </Dialog>
      <DefaultTable
        title="Agency Info"
        inputColumns={agencyInfoColumns}
        inputData={info}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        actions={actions}
      />
    </div>
  );
};

export default AgencyCrudTable;
