import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "../components/DefaultTable";

import { contractorRateColumns } from "../components/data/constants";
import { updateUser_base } from "../components/API_calls/users";
import { updateContractor_base } from "../components/API_calls/users";
import { GlobalNameListContext } from "../Context";
import { roundTo } from "../components/utils/helpers";

const ContractorRates = ({ ...props }) => {

  const { globalNameList } = useContext(GlobalNameListContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  
  const setRate = (info) => {
    const newData = info.map(a => Object.assign({},a))
    newData.forEach(index => index.hstRate = roundTo(index.hstRate*100, 2))
    setInfo(newData)
  };

  useEffect(() => {
    if (globalNameList.contractors) {
      const initializeData = async () => {
        try {
          setIsLoading(true);
          setRate(globalNameList.contractors);
        } catch (e) {
          console.log("error:", e);
        } finally {
          setIsLoading(false);
        }
      };
      initializeData();
    }
  }, [globalNameList.contractors]);

  const updateFunction = async (newData, oldData) => {
    setIsLoading(true);
    try {
      const rates = {
        contractorMileageRate: newData.mileageRate,
        contractorReferralFee: newData.referralFee,
        contractorHSTRate: newData.hstRate
      }
      await updateContractor_base(oldData.link, rates);
      newData.hstRate = roundTo(newData.hstRate, 2)
      const data = info.map((currData) =>
        currData.tableData.id === oldData.tableData.id ? newData : currData
      );
      setInfo(data);

    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };


  return (
    <DefaultTable
      title="Contractor Rates"
      inputColumns={contractorRateColumns}
      inputData={info}
      updateFunction={updateFunction}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default ContractorRates;
