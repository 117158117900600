import instance from "../utils/API";
import instance_base from "../utils/API_base";


//get all insurance companies
const getAllInsuranceCompanies = async () => {
  try {
    const res = await instance
      .get("/insuranceCompanies", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.insuranceCompanies;
  } catch (error) {
    throw error;
  }
};

//get specific insurance company
const getInsuranceCompany = async (companyId) => {
  try {
    const res = await instance.get(`/insuranceCompany/${companyId}`).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const getInsuranceCompany_base = async (url) => {
  try {
    const res = await instance_base.get(url).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//create insurance company
const createInsuranceCompany = async (companyInfo) => {
  var data = {...companyInfo}
  data.hstRate = data.hstRate/100
  try {
    const res = await instance.post("/insuranceCompanies", data).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const createInsuranceCompany_base = async (url, companyInfo) => {
  let newInfo = {...companyInfo}
  newInfo.hstRate = newInfo.hstRate/100
  try {
    const res = await instance_base.post(url, newInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//update insurance company
const updateInsuranceCompany = async (companyId, updatedInfo) => {

  console.log(companyId) 
  console.log(updatedInfo)

  try {
    const res = await instance
      .patch(`/insuranceCompany/${companyId}`, updatedInfo)
      .then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const updateInsuranceCompany_base = async (url, updatedInfo) => {
   
  let newInfo = {...updatedInfo}
  newInfo.lastInvoicedDate= new Date()
  newInfo.hstRate = newInfo.hstRate/100
  try {
    const res = await instance_base.patch(url, newInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//delete insurance company
const deleteInsuranceCompany = async url => {
  try {
    const res = await instance_base.delete(url).then((res) => res);
    return res;
  } catch (error) {
    throw error;
  }
};

export {
  getAllInsuranceCompanies,
  getInsuranceCompany,
  getInsuranceCompany_base,
  createInsuranceCompany,
  createInsuranceCompany_base,
  updateInsuranceCompany,
  updateInsuranceCompany_base,
  deleteInsuranceCompany
};
