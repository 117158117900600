import React, { useState, useEffect, useContext } from 'react';
import {
  getUser_base,
  createServiceItem,
  updateServiceItem_base,
  updateOrder_base,
  deleteServiceItem_base,
  createOrder,
} from '../API_calls/orders';
import { GlobalNameListContext } from '../../Context';
import { UserContext } from '../../UserContext';
import { updateUser_base } from '../API_calls/users';
import { searchAgency } from '../API_calls/agencies';
import * as detailHelpers from './orderDetailsHelpers';
import '../OrderTable/OrderTableItem.scss';

import SearchAgentDialog from './SearchAgentDialog';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import moment from 'moment';
import LoadingSpinner from '../LoadingSpinner';
import OrderInfoDetails from './OrderInfoDetails';
import { generateKey } from '../utils/helpers';

const OrderInfo = ({
  id,
  setId,
  orderDetail,
  save,
  setSave,
  saveOrder,
  updateOrder,
  setCurrentContractors,
  copy,
  setCopy,
  history,
  copyState,
  changeFlag,
  setChangeFlag,
  setAllowSaving,
  ...props
}) => {
  const { globalNameList } = useContext(GlobalNameListContext);
  const { userState } = useContext(UserContext);

  const classes = detailHelpers.useStyles();
  //================================================  Form State
  const [orderInfo, setOrderInfo] = useState({}); //Order Specific, ie entry dates and various flags & status'
  const [insuranceInfo, setInsuranceInfo] = useState({}); // Insurance Coy info & Policy Info (type and amount)
  const [serviceDetails, setServiceDetails] = useState([]); //Array of service items, svcItem has contractor, milage, and service
  const [agentInfo, setAgentInfo] = useState({});
  const [clientInfo, setClientInfo] = useState({}); //Client Specific Info, name DOB languges contact info etc.
  const [labInfo, setLabInfo] = useState(''); // Name, lab# and labPuro#

  const [emailLogInfo, setEmailLogInfo] = useState(''); // Should just be a loading state passed from OrderDetail rather than a whole object
  //Only used as a condition to display the order info

  //=============================================== Loading and Error State
  const [loadingServices, setloadingServices] = useState(false);
  const [loadingAgent, setLoadingAgent] = useState(false);
  const [orderError, setOrderError] = useState(false);
  const [duplicateDetails, setDuplicateDetails] = useState({});
  const [orderDuplicate, setOrderDuplicate] = useState(false);
  //===================================================== Other States
  const [agencyList, setAgencyList] = useState([]);
  const [referredByList, setReferredByList] = useState([]);
  const [searchAgent, setSearchAgent] = useState(false);
  const [deletedServiceItems, setDeletedServiceItems] = useState([]);

  const [isFieldFilled, setIsFieldFilled] = useState({
    /* All Fields required  to save*/
    insuranceCompany: false,
    agentName: false,
    cLastName: false,
    cFirstName: false,
    cGender: false,
    cDOB: false,
    cExamLocation: false,
    cPostalCode: false,
    homeMobile: false,
    insAmount: false,
    insType: false,
  });

  useEffect(() => {
    //[]Will these actually change without a refresh?
    const referredByListTemp = [
      ...globalNameList.contractors,
      ...globalNameList.admins,
      ...globalNameList.superAdmins,
    ].sort((a, b) => a.firstName.localeCompare(b.firstName));

    setReferredByList(referredByListTemp);
  }, [globalNameList.admins, globalNameList.contractors, globalNameList.superAdmins]);

  useEffect(() => {
    setCurrentContractors(serviceDetails);
  }, [serviceDetails, setCurrentContractors]);

  const postOrder = async (details) => {
    //[] should add to useCallback
    // Create the order first
    createOrder({ ...details, createdBy: userState.userInfo.link })
      .then((res) => {
        // Then create service items and link them to the new order
        const filteredServices = serviceDetails.filter((x) => x.serviceType); //This will make the form more robust allowing accidental lines
        const callsToMake = filteredServices.map((item) => {
          return {
            createDateTime: moment().format(),
            employeeMileage: parseInt(item.employeeMileage),
            insuranceMileage: parseInt(item.insuranceMileage),
            order: res._links.self.href.split('{')[0],
            serviceType: item.serviceType.link
              ? item.serviceType.link
              : item.serviceType._links.self.href.split('{')[0],
            updateDateTime: moment(),
            user: item.contractor.name.link,
          };
        });

        if (callsToMake.length > 0) {
          Promise.all(callsToMake.map((call) => createServiceItem(call)))
            .then(() => {
              return res.orderID;
            })
            .then((id) => {
              setChangeFlag((prev) => {
                Object.keys(prev).forEach((key) => (prev[key] = false));
                return prev;
              });
              setSave(false);
              setId(id);
              history.push(`/orders/${id}`);
            })
            .catch((err) => {
              setOrderError(true);
              setSave(false);
              console.log('service item error', err.response.data);
            });
        } else {
          setSave(false);
          setId(res.orderID);
          history.push(`/orders/${res.orderID}`);
        }
      })
      .catch((err) => {
        let duplicate;
        for (let error of err.response.data.message) {
          if (error.ErrorCode === 'DUP') {
            duplicate = true;
          }
        }

        if (duplicate) {
          setDuplicateDetails(details);
          setOrderDuplicate(true);
          setSave(false);
        } else {
          setOrderError(true);
          setSave(false);
        }
      });
  };

  // =========================================useEffect to SAVE UPDATED ORDER
  useEffect(() => {
    if (save) {
      if (orderDetail.orderID === 'new') {
        const newOrder = {
          adminNote: orderInfo.adminNote,
          agency: orderInfo.agency?.link,
          agent: agentInfo.name.link,
          assignmentTime: orderInfo.assignmentTime,
          clientInfo: clientInfo,
          completionTime: orderInfo.completionTime,
          createDateTime: orderInfo.createDateTime,
          examLocation: orderInfo.examLocation,
          insuranceCompany: insuranceInfo.company.link,
          insuranceFaxed: orderInfo.insuranceFaxed,
          insuranceItems: insuranceInfo.insuranceItems,
          lab: labInfo,
          orderDate: orderInfo.orderDate ? orderInfo.orderDate : null,
          orderLocation: orderDetail.orderLocation,
          orderedBy: orderInfo.orderedBy,
          paidBy: orderInfo.paidBy,
          paraOutTime: orderInfo.paraOutTime,
          paraPuroNum: orderInfo.paraPuroNum,
          prebook: orderInfo.prebook,
          prebookConfirmed: orderInfo.prebookConfirmed,
          refNum: orderInfo.refNum,
          referredBy: orderInfo.referredBy,
          remarks: orderInfo.remarks,
          serviceTime: orderInfo.serviceTime,
          status: orderInfo.status,
          updateDateTime: moment().format(),
        };
        postOrder(newOrder); //post order does several things...
      } else {
        // UPDATE ORDER
        if (changeFlag.order || changeFlag.insurance || changeFlag.agent) {
          const patchOrder = {
            ...orderInfo,
            webRequestServiceTypes: orderInfo.webRequestServiceTypes.map((type) => type._links.self.href.split('{')[0]),
            agency: orderInfo.agency ? orderInfo.agency.link : '',
            lab: labInfo,
            prebook: orderInfo.prebook,
            prebookConfirmed: orderInfo.prebookConfirmed,
            insuranceItems: insuranceInfo.insuranceItems,
            clientInfo: clientInfo,
          };

          if (changeFlag.insurance) {
            patchOrder.insuranceCompany = insuranceInfo.company.link;
          }

          if (changeFlag.agent) {
            patchOrder.agent = agentInfo.name.link;
          }

          updateOrder_base(orderDetail._links.self.href, patchOrder)
            .then((res) => {
              setSave(false);
              setChangeFlag((prev) => ({
                ...prev,
                order: false,
                insurance: false,
                agent: false,
              }));
            })
            .catch((err) => {
              setOrderError(true);
              setSave(false);
              console.log(err);
            });
        }

        // DELETE SERVICE ITEMS
        if (changeFlag.serviceItemDelete) {
          for (let item of deletedServiceItems) {
            if (item) {
              deleteServiceItem_base(item)
                .then(() => {
                  setSave(false);
                  setChangeFlag((prev) => ({
                    ...prev,
                    serviceItemDelete: false,
                  }));
                })
                .catch((err) => {
                  setOrderError(true);
                  setSave(false);
                  console.log(err);
                });
            }
          }
        }

        // UPDATE SERVICE DETAILS
        if (changeFlag.serviceDetails) {
          for (let item of serviceDetails) {
            if (item.link !== undefined) {
              // UPDATE SERVICE ITEM
              let itemDetails = {
                employeeMileage: parseInt(item.employeeMileage),
                insuranceMileage: parseInt(item.insuranceMileage),
                serviceType: item.serviceType.link,
              };

              if (item.serviceType.link) {
                itemDetails.serviceType = item.serviceType.link;
              }

              if (item.contractor.name.link) {
                itemDetails.user = item.contractor.name.link;
              }

              updateServiceItem_base(item.link, itemDetails)
                .then((res) => {
                  setSave(false);
                  setChangeFlag((prev) => ({
                    ...prev,
                    serviceDetails: false,
                  }));
                })
                .catch((err) => {
                  setOrderError(true);
                  setSave(false);
                  console.log(err);
                });
            } else {
              //CREATE NEW SERVICE ITEM
              const itemDetails = {
                createDateTime: item.createDateTime,
                employeeMileage: parseInt(item.employeeMileage),
                insuranceMileage: parseInt(item.insuranceMileage),
                order: orderDetail._links.self.href,
                serviceType: item.serviceType.link,
                user: item.contractor.name.link,
              };

              createServiceItem(itemDetails)
                .then(() => {
                  setSave(false);
                  setChangeFlag((prev) => ({
                    ...prev,
                    serviceDetails: false,
                  }));
                })
                .catch((err) => {
                  setOrderError(true);
                  setSave(false);
                  console.log(err);
                });
            }
          }
        }

        // UPDATE AGENT INFO
        if (changeFlag.agent) {
          const patchAgent = {
            email: agentInfo.email,
            username: agentInfo.username,
            ext: agentInfo.ext,
            mobileTel: agentInfo.mobile,
            agentOrderTel: agentInfo.orderContact,
            businessTel: agentInfo.business,
            fax: agentInfo.fax,
            agentNote: agentInfo.agentNote,
          };
          updateUser_base(agentInfo.name.link, patchAgent)
            .then(() => {
              setSave(false);
              setChangeFlag((prev) => ({
                ...prev,
                agent: false,
              }));
            })
            .catch((err) => {
              setOrderError(true);
              setSave(false);
              console.log(err);
            });
        }
      }
    }
  }, [save]);

  useEffect(() => {
    // ============================================ useEffect to  COPY ORDER
    if (copy === true) {
      let clientTemp;

      if (copyState.clientInfo === 'clientNoFirstName') {
        clientTemp = { ...clientInfo, firstName: '', gender: null, dob: null, age: null };
      } else if (copyState.clientInfo === 'noClientInfo') {
        clientTemp = {
          firstName: '',
          lastName: '',
          gender: null,
          dob: null,
          mobile: '',
          home: '',
          business: '',
          fax: '',
          languages: ['English'],
          email: '',
          age: '',
        };
      } else if (copyState.clientInfo === 'allClientInfo') {
        clientTemp = clientInfo;
      }

      // CREATE A NEW ORDER
      // Some fields are commented out per client's request, though not 100% clear which fields are actually needed and which aren't,
      // so they are commented out instead of deleted just in case we need to bring it back
      const copiedOrder = {
        agent: copyState.agentInfo ? agentInfo.name.link : userState.userInfo.link,
        agency: copyState.agencyInfo ? orderInfo.agency.link : '',
        // assignmentTime: orderInfo.assignmentTime,
        clientInfo: clientTemp,
        // completionTime: orderInfo.completionTime,
        examLocation: orderInfo.examLocation,
        insuranceCompany: copyState.companyInfo ? insuranceInfo.company.link : '',
        insuranceFaxed: orderInfo.insuranceFaxed,
        insuranceItems: insuranceInfo.insuranceItems,
        adminNote: orderInfo.adminNote,
        // lab: labInfo,
        orderDate: orderInfo.orderDate,
        orderLocation: orderDetail.orderLocation,
        orderedBy: orderInfo.orderedBy,
        paidBy: orderInfo.paidBy,
        // paraPuroNum: orderInfo.paraPuroNum,
        // prebook: orderInfo.prebook,
        // prebookConfirmed: orderInfo.prebookConfirmed,
        refNum: orderInfo.refNum,
        referredBy: orderInfo.referredBy,
        remarks: orderInfo.remarks,
        // serviceTime: orderInfo.serviceTime,
        specialTests: orderDetail.specialTests,
        status: 'Pending',
        // webRequestServiceTypes: orderInfo.webRequestServiceTypes.map(type => type._links.self.href.split('{')[0])
        createdBy: userState.userInfo.link,
      };
      createOrder(copiedOrder)
        .then((res) => {
          setCopy(false);
          setId(res.orderID);
          history.push(`/orders/${res.orderID}`);
        })
        .catch((err) => {
          setCopy(false);
          console.log(err.response.data);

          let duplicate = false;
          for (let error of err.response.data.message) {
            if (error.ErrorCode === 'DUP') {
              duplicate = true;
            }
          }

          if (duplicate) {
            setOrderDuplicate(true);
            setDuplicateDetails(copiedOrder);
          } else {
            setOrderError(true);
            setSave(false);
          }
        });
    }
  }, [copy]);

  useEffect(() => {
    // ================================================ useEffect to LOAD ORDER
    if (orderDetail && orderDetail !== {} && orderDetail._embedded) {
      const newOrderInfo = {
        createDateTime: orderDetail.createDateTime,
        orderDate: orderDetail.orderDate ? orderDetail.orderDate : orderDetail.createDateTime,
        updateDateTime: orderDetail.updateDateTime,
        notifyAgent: detailHelpers.notifyAgent(
          orderDetail._embedded.agent ? orderDetail._embedded.agent.username : '',
          orderDetail._embedded.emailLogs ? orderDetail._embedded.emailLogs : []
        ),
        serviceTime: orderDetail.serviceTime ? orderDetail.serviceTime : null,
        completionTime: orderDetail.completionTime,
        assignmentTime: orderDetail.assignmentTime,
        paraOutTime: orderDetail.paraOutTime,
        paraPuroNum: orderDetail.paraPuroNum,
        adminNote: orderDetail.adminNote,
        insuranceFaxed: orderDetail.insuranceFaxed,
        status: orderDetail.status,
        orderedBy: orderDetail.orderedBy,
        remarks: orderDetail.remarks,
        refNum: {
          ...orderDetail.refNum,
        },
        examLocation: {
          ...orderDetail.examLocation,
        },
        referredBy: orderDetail.referredBy,
        paidBy: orderDetail.paidBy,
        agency: orderDetail._embedded.agency
          ? {
              name: orderDetail._embedded.agency.name,
              link: orderDetail._embedded.agency._links.self.href.split('{')[0],
            }
          : { name: orderDetail.tempAgency, link: '' },
        prebook: orderDetail.prebook,
        prebookConfirmed: orderDetail.prebookConfirmed,
        webRequestServiceTypes: orderDetail._embedded.webRequestServiceTypes
          ? orderDetail._embedded.webRequestServiceTypes
          : [],
        createdBy: orderDetail._embedded.createdBy ? orderDetail._embedded.createdBy : null,
        cancelledOrHoldDate: orderDetail.cancelledOrHoldDate,
        cancelledOrHoldBy: orderDetail._embedded.cancelledOrHoldBy ? orderDetail._embedded.cancelledOrHoldBy : null,
      };
      const newInsuranceInfo = {
        company: {
          name: orderDetail._embedded.insuranceCompany ? orderDetail._embedded.insuranceCompany.name : '',
          link: orderDetail._embedded.insuranceCompany?._links.self.href.replace('{?projection}', ''),
        },
        insuranceItems:
          orderDetail.insuranceItems.length > 0
            ? orderDetail.insuranceItems
            : [{ insuranceType: '', insuranceAmount: '' }],
      };
      setOrderInfo(newOrderInfo);
      setInsuranceInfo(newInsuranceInfo);
      setClientInfo(orderDetail ? orderDetail.clientInfo : null);
      setLabInfo(orderDetail && orderDetail.lab ? orderDetail.lab : { labName: '', labNumber: '', labPuroNum: '' });
      setEmailLogInfo(orderDetail._embedded.emailLogs ? orderDetail._embedded.emailLogs : []); // What does this do? Being used as a render condition

      if (orderDetail.orderID !== 'new') {
        const newReqFlags = {
          insuranceCompany:
            orderDetail._embedded.insuranceCompany !== undefined && orderDetail._embedded.insuranceCompany.name !== ''
              ? true
              : false,
          agentName: orderDetail._embedded.agent.username !== '' ? true : false,
          cLastName: orderDetail.clientInfo.lastName !== '' ? true : false,
          cFirstName: orderDetail.clientInfo.firstName !== '' ? true : false,
          cGender: orderDetail.clientInfo.gender !== '' ? true : false,
          cDOB: orderDetail.clientInfo.dob ? true : false,
          cExamLocation:
            orderDetail.examLocation.clientLocationAddress !== ''
              ? true
              : false || orderDetail.examLocation.clientLocationMediFastOffice,
          cPostalCode:
            orderDetail.examLocation.clientLocationPostalCode !== ''
              ? true
              : false || orderDetail.examLocation.clientLocationMediFastOffice,
          homeMobile: orderDetail.clientInfo.home !== '' || orderDetail.clientInfo.mobile !== '' ? true : false,
          insAmount: orderDetail.insuranceItems[0].insuranceAmount !== '' ? true : false,
          insType: orderDetail.insuranceItems[0].insuranceType !== '' ? true : false,
        };
        setIsFieldFilled(newReqFlags);
      }

      //===========================================GET and Update services
      const serviceList = orderDetail._embedded.serviceItems
        ? orderDetail._embedded.serviceItems.length > 0
          ? orderDetail._embedded.serviceItems.map((item) => {
              return getUser_base(item._links.user.href.split('{')[0])
                .then((res) => {
                  return {
                    contractor: {
                      name: {
                        firstName: res.firstName ? res.firstName : '',
                        lastName: res.lastName ? res.lastName : '',
                        link: res._links.self.href.split('{')[0],
                      },
                      email: res.username ? res.username : '',
                      mobile: res.mobileTel ? res.mobileTel : '',
                      home: res.homeTel ? res.homeTel : '',
                      business: res.businessTel ? res.businessTel : '',
                      fax: res.fax ? res.fax : '',
                    },
                    serviceType: item.serviceType ? item.serviceType : '',
                    insuranceMileage: item.insuranceMileage ? item.insuranceMileage : '',
                    employeeMileage: item.employeeMileage ? item.employeeMileage : '',
                    link: item._links.self.href.split('{')[0],
                  };
                })
                .catch((err) => {
                  return {
                    contractor: {
                      name: { firstName: '', lastName: '', link: '' },
                      email: '',
                      mobile: '',
                      home: '',
                      business: '',
                      fax: '',
                    },
                    serviceType: item.serviceType ? item.serviceType : '',
                    insuranceMileage: item.insuranceMileage ? item.insuranceMileage : '',
                    employeeMileage: item.employeeMileage ? item.employeeMileage : '',
                    link: item._links.self.href.split('{')[0],
                  };
                });
            })
          : []
        : [];

      if (orderDetail._embedded.serviceItems && orderDetail._embedded.serviceItems.length > 0 && serviceList) {
        setloadingServices(true);
        Promise.all(serviceList).then((res) => {
          setServiceDetails(res);
          setloadingServices(false);
        });
      } else {
        setServiceDetails([
          {
            id: generateKey(),
            employeeMileage: '',
            insuranceMileage: '',
            serviceType: '',
            contractor: { name: { firstName: '', lastName: '' } },
          },
        ]);
      }

      //===========================================GET and Update Agent Info
      if (orderDetail._embedded.agent._links) {
        setLoadingAgent(true);
        getUser_base(orderDetail._embedded.agent._links.self.href.split('{')[0])
          .then((res) => {
            const agent = {
              name: { firstName: res.firstName, lastName: res.lastName, link: res._links.self.href },
              username: res.username,
              email: res.email,
              mobile: res.mobileTel,
              business: res.businessTel,
              ext: res.ext,
              fax: res.fax,
              agentNote: res.agentNote,
              orderContact: res.agentOrderTel,
            };
            setAgentInfo(agent);
            setLoadingAgent(false);
            return res;
          })
          .then((res) => {
            searchAgency({ user: res._links.self.href }).then((res) => {
              const agencyListTemp = res._embedded.agencies.map((agency) => ({
                name: agency.name,
                link: agency._links.self.href,
              }));
              setAgencyList([...agencyListTemp, { name: 'Other', link: '' }]);
            });
          })
          .catch((err) => {
            console.log(err);
            setAgentInfo((prev) => {
              Object.keys(prev).forEach((key) => {
                if (key === 'name') {
                  prev.name.firstName = '';
                  prev.name.lastName = '';
                  prev.name.link = '';
                } else {
                  prev[key] = '';
                }
              });
              return prev;
            });
          });
      } else {
        const agent = {
          name: {
            firstName: orderDetail._embedded.agent.firstName,
            lastName: orderDetail._embedded.agent.lastName,
            link: '',
          },
          email: orderDetail._embedded.agent.username,
          mobile: orderDetail._embedded.agent.mobileTel,
          business: orderDetail._embedded.agent.businessTel,
          ext: orderDetail._embedded.agent.ext,
          fax: orderDetail._embedded.agent.fax,
          agentNote: orderDetail._embedded.agent.agentNote,
          orderContact: orderDetail._embedded.agent.agentOrderTel,
        };
        setAgentInfo(agent);
      }
    }
  }, [orderDetail]);

  useEffect(() => {
    //=========================================useEffect to Calculate and Update Age Field
    if (clientInfo.dob?._isValid) {
      //Only runs when full date is present `.dob` is a moment object when changed
      let age = moment().diff(clientInfo.dob, 'years');
      if (isNaN(age) || clientInfo.dob === null) {
        age = '';
      }
      setClientInfo((prev) => ({ ...prev, age }));
    }
  }, [clientInfo.dob]);

  useEffect(() => {
    //===========================================================Update allowSave in OrderDetails
    //==================================================  Watching for changes to specific sections & required fields
    let mounted = true;
    const requiredFieldValues = Object.values(isFieldFilled);
    const allRequiredFieldsPopulated = requiredFieldValues.every((fieldValue) => fieldValue === true);
    const changeHasOccured = Object.values(changeFlag).includes(true);
    if (mounted) {
      if (allRequiredFieldsPopulated && changeHasOccured) {
        setAllowSaving(true);
      } else {
        setAllowSaving(false);
      }
    }
    return () => {
      mounted = false;
    };
  }, [changeFlag, isFieldFilled, orderDetail.orderID]); //OrderID required to reset the changeFlag after new Orders are created

  //=================================================== Change State Functions

  const updateChangeFlag = (sectionKey) => {
    //[] Should update to accout for undone changes. Only sets true doesn't compare original info to current
    if (!changeFlag[sectionKey]) {
      setChangeFlag((prev) => ({ ...prev, [sectionKey]: true }));
    }
  };

  const changeOrderInfo = (name, newVal, key) => {
    let val = null;
    if (
      key === 'clientLocationPostalCode' ||
      key === 'clientLocationAddress' ||
      key === 'clientLocationMediFastOffice'
    ) {
      let reqFlagKey = '';
      const newSetting = newVal === '' ? false : true;
      switch (key) {
        case 'clientLocationPostalCode':
          reqFlagKey = 'cPostalCode';
          break;
        case 'clientLocationAddress':
          reqFlagKey = 'cExamLocation';
          break;
        case 'clientLocationMediFastOffice':
          setIsFieldFilled((prev) => ({ ...prev, cPostalCode: newVal, cExamLocation: newVal }));
          break;
        default:
      }
      if (key !== 'clientLocationMediFastOffice') setIsFieldFilled((prev) => ({ ...prev, [reqFlagKey]: newSetting }));
    }
    if (name === 'examLocation' || name === 'refNum') {
      val = {
        ...orderInfo[name],
        [key]: newVal,
      };
    } else {
      val = newVal;
    }
    const newOrderInfo = {
      ...orderInfo,
      [name]: val,
    };
    if (name === 'status') {
      const timeNow = moment();
      //Add time log
      switch (newVal) {
        //[]TODO: Case Strings should come from a CONSTANT IOT avoid typos
        //Completed Orders will have their date updated manually.
        case 'Cancelled':
        case 'Hold':
          newOrderInfo.cancelledOrHoldDate = timeNow;
          break;
        case 'Assignment':
          newOrderInfo.assignmentTime = timeNow;
          break;
        default:
          break;
      }
    }
    setOrderInfo(newOrderInfo);
    updateChangeFlag('order');
  };

  const changeOrderDate = (val, setCurrentTime, source) => {
    if (source === 'orderDate') {
      setCurrentTime(val);
      setOrderInfo({ ...orderInfo, orderDate: val });
    } else if (source === 'notifyAgent') {
      setCurrentTime(val);
      setOrderInfo({ ...orderInfo, notifyAgent: val });
    }

    updateChangeFlag('order');
  };

  const addServiceItem = () => {
    let newVal = [
      ...serviceDetails,
      {
        id: generateKey(),
        serviceType: '',
        employeeMileage: '',
        insuranceMileage: '',
        contractor: {
          name: '',
          home: '',
          mobile: '',
          business: '',
          fax: '',
          email: '',
        },
      },
    ];
    setServiceDetails(newVal);
    updateChangeFlag('serviceDetails');
  };

  const delServiceItem = (k, url) => {
    let updatedTargetArr = detailHelpers.deleteArrItem(serviceDetails, k);
    setServiceDetails(updatedTargetArr);

    // save the link of the deleted item to delete on save
    setDeletedServiceItems((prev) => [...prev, url]);

    updateChangeFlag('serviceDetails');
  };

  const changeServiceDetails = (name, newVal, i) => {
    if (name === 'serviceType') {
      setIsFieldFilled((prev) => ({ ...prev, service: true }));
    }

    const newObj = { ...serviceDetails[i], [name]: newVal };
    setServiceDetails((prev) => prev.map((val, index) => (index === i ? newObj : val)));
    updateChangeFlag('serviceDetails');
  };

  const changeContractor = (newVal, i) => {
    getUser_base(newVal.link).then((res) => {
      const newContractor = {
        ...serviceDetails[i],
        contractor: {
          name: newVal,
          home: res.homeTel,
          mobile: res.mobileTel,
        },
      };

      setServiceDetails((prev) => prev.map((val, index) => (index === i ? newContractor : val)));
      changeClientDate(moment(), null, 'assignmentTime');

      updateChangeFlag('serviceDetails');
    });
  };

  const changeInsuranceInfo = (name, newVal, key, i) => {
    //causing cursor error
    //Update isFilledField Flags
    let fieldKey = '';
    const newSetting = newVal ? true : false;
    switch (key) {
      case 'insuranceAmount':
        fieldKey = 'insAmount';
        break;
      case 'insuranceType':
        fieldKey = 'insType';
        break;
      default:
        break;
    }
    if (name === 'company') {
      fieldKey = 'insuranceCompany';
    }
    setIsFieldFilled((prev) => ({ ...prev, [fieldKey]: newSetting }));

    let val;
    if (name === 'company') {
      if (newVal === null) {
        val = { name: '', link: '', defaultLab: '' };
      } else {
        val = newVal;
      }
    } else if (name === 'insuranceItems') {
      let updatedVal = {
        ...insuranceInfo.insuranceItems[i],
        [key]: newVal,
      };
      val = detailHelpers.replaceArrItem(insuranceInfo.insuranceItems, i, updatedVal);
      updateChangeFlag('order');
    }

    setInsuranceInfo({
      ...insuranceInfo,
      [name]: val,
    });
    setLabInfo((prev) => ({ ...prev, labName: val.defaultLab }));
    updateChangeFlag('insurance');
  };

  const addInsuranceType = () => {
    let val = [...insuranceInfo.insuranceItems, { insuranceType: '', insuranceAmount: '' }];
    setInsuranceInfo({
      ...insuranceInfo,
      insuranceItems: val,
    });
    updateChangeFlag('insurance');
  };

  const deleteInsuranceType = (i) => {
    let updatedTargetArr = detailHelpers.deleteArrItem(insuranceInfo.insuranceItems, i);
    setInsuranceInfo({
      ...insuranceInfo,
      insuranceItems: updatedTargetArr,
    });
    updateChangeFlag('insurance');
  };

  const changeLabInfo = (name, newVal) => {
    setLabInfo({
      ...labInfo,
      [name]: newVal,
    });
    updateChangeFlag('order');
  };

  const changeAgentInfo = (name, newVal) => {
    if (name === 'agent') {
      setIsFieldFilled((prev) => ({ ...prev, agentName: true }));

      if (newVal) {
        getUser_base(newVal.link)
          .then((res) => {
            setAgentInfo({
              name: { firstName: res.firstName, lastName: res.lastName, link: res._links.self.href },
              email: res.username ? res.username : '',
              mobile: res.mobileTel ? res.mobileTel : '',
              business: res.businessTel ? res.businessTel : '',
              ext: res.ext ? res.ext : '',
              fax: res.fax ? res.fax : '',
              orderContact: res.agentOrderTel ? res.agentOrderTel : '',
              agentNote: res.agentNote,
            });
            return res;
          })
          .then((res) => {
            searchAgency({ user: res._links.self.href }).then((res) => {
              const agencyListTemp = res._embedded.agencies.map((agency) => ({
                name: agency.name,
                link: agency._links.self.href,
              }));
              setAgencyList([...agencyListTemp, { name: 'Other', link: '' }]);
            });
          })
          .catch((err) => console.log(err));
      } else {
        setAgentInfo((prev) => ({ ...prev, name: { firstName: '', lastName: '', link: '' } }));
      }
    } else {
      setAgentInfo({
        ...agentInfo,
        [name]: newVal,
      });
    }
    updateChangeFlag('agent');
  };

  const okSearchAgent = (agentInfo) => {
    const newVal = { firstName: agentInfo.firstName, link: agentInfo._links.self.href };
    changeAgentInfo('agent', newVal);
    setSearchAgent(false);
  };

  const changeClientInfo = (field, newVal) => {
    setClientInfo({
      ...clientInfo,
      [field]: newVal,
    });

    let reqFlagKey = '';
    const reqFlagValue = newVal ? true : false;
    switch (field) {
      case 'lastName':
        reqFlagKey = 'cLastName';
        break;
      case 'firstName':
        reqFlagKey = 'cFirstName';
        break;
      case 'gender':
        reqFlagKey = 'cGender';
        break;
      case 'mobile':
      case 'home':
        reqFlagKey = 'homeMobile';
        break;
      default:
        break;
    }
    setIsFieldFilled((prev) => ({ ...prev, [reqFlagKey]: reqFlagValue }));
    updateChangeFlag('order');
  };

  const changeClientDate = (val, setCurrentTime, source) => {
    if (source === 'dob') {
      const newReqFlag = val._d ? true : false;
      setIsFieldFilled((prev) => ({ ...prev, cDOB: newReqFlag }));

      setCurrentTime(val); //[] What is this used for? why not time now()?
      setClientInfo({ ...clientInfo, dob: val });
      if (!changeFlag.order) {
        setChangeFlag((prev) => ({ ...prev, order: true }));
      }
    } else {
      if (setCurrentTime) {
        setCurrentTime(val);
      }
      setOrderInfo({ ...orderInfo, [source]: val });
      updateChangeFlag('order');
    }
  };

  // HANDLE LANGUAGES
  const delLanguage = (i) => {
    let deletedLanguageArr = detailHelpers.deleteArrItem(clientInfo.languages, i);
    setClientInfo({ ...clientInfo, languages: deletedLanguageArr });
    updateChangeFlag('order');
  };

  const changeLanguage = (i, newVal) => {
    setClientInfo((prev) => ({ ...prev, languages: prev.languages.map((val, index) => (index === i ? newVal : val)) }));
    updateChangeFlag('order');
  };

  const addLanguage = () => {
    setClientInfo({ ...clientInfo, languages: [...clientInfo.languages, null] });
    updateChangeFlag('order');
  };

  /*********************************** SEARCH AGENT DIALOG **********************************/
  const openSearchAgent = () => {
    setSearchAgent(true);
  };

  const confirmDuplicate = () => {
    postOrder({ ...duplicateDetails, overrideDuplicate: true });
  };

  /*********************************** EXPORT COMPONENT **********************************/
  return save || copy ? (
    <LoadingSpinner />
  ) : orderDetail &&
    orderInfo &&
    insuranceInfo &&
    serviceDetails &&
    agentInfo &&
    clientInfo &&
    labInfo &&
    emailLogInfo ? (
    <div className={classes.root}>
      <OrderInfoDetails
        globalNameList={globalNameList}
        orderDetail={orderDetail}
        orderInfo={orderInfo}
        insuranceInfo={insuranceInfo}
        serviceDetails={serviceDetails}
        serviceItems={props.serviceItems}
        labInfo={labInfo}
        emailLogInfo={emailLogInfo}
        id={id}
        changeOrderId={props.changeOrderId}
        submitOrderId={props.submitOrderId}
        changeOrderInfo={changeOrderInfo}
        changeOrderDate={changeOrderDate}
        changeInsuranceInfo={changeInsuranceInfo}
        addServiceItem={addServiceItem}
        delServiceItem={delServiceItem}
        changeLabInfo={changeLabInfo}
        changeContractor={changeContractor}
        changeServiceDetails={changeServiceDetails}
        addInsuranceType={addInsuranceType}
        deleteInsuranceType={deleteInsuranceType}
        agentInfo={agentInfo}
        changeAgentInfo={changeAgentInfo}
        openSearchAgent={openSearchAgent}
        agencyList={agencyList}
        referredByList={referredByList}
        clientInfo={clientInfo}
        delLanguage={delLanguage}
        changeLanguage={changeLanguage}
        addLanguage={addLanguage}
        changeClientInfo={changeClientInfo}
        changeClientDate={changeClientDate}
        isFieldFilled={isFieldFilled}
        setIsFieldFilled={setIsFieldFilled}
        loadingAgent={loadingAgent}
        loadingServices={loadingServices}
      />

      <Dialog open={searchAgent} onClose={() => setSearchAgent(false)} maxWidth="xl" fullWidth>
        <SearchAgentDialog handleClose={() => setSearchAgent(false)} okSearchAgent={okSearchAgent} history={history} />
      </Dialog>

      <Dialog open={orderError} onClose={() => setOrderError(false)} maxWidth="md">
        <DialogTitle>Oops, something went wrong!</DialogTitle>
        <DialogContent>
          <DialogContentText>Error: could not save Service Details, Please select a Service Type</DialogContentText>
        </DialogContent>
      </Dialog>

      <Dialog open={orderDuplicate} onClose={() => setOrderDuplicate(false)} maxWidth="md">
        <DialogTitle>This order may be a duplicate.</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Order with the same first name, last name and DOB was created in the last 90 days. Would you like to
            proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOrderDuplicate(false)} color="primary">
            Cancel
          </Button>
          <Button data-cy="dupe-order-create-btn" onClick={confirmDuplicate} color="primary">
            Create Order
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <LoadingSpinner />
  );
};

export default OrderInfo;
