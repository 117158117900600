import instance from "../utils/API";
import instance_base from "../utils/API_base";
import Swal from "sweetalert2";
import React from "react";
import Input from '@material-ui/core/Input';





//get all users
const getAllUsers = async () => {
  try {
    const res = await instance
      .get("/users", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.users;
  } catch (error) {
    throw error.response.data;
  }
};

//get specific users
const getSpecificUserTypes = async (roleLink) => {
  try {
    const res = await instance
      .get("/users/search/multiParam", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc",
          role: roleLink
        }
      })
      .then((res) => res.data);
    return res._embedded.users;
  } catch (error) {
    throw error;
  }
};

const getSpecificUserTypes_params = async ({
  username = null,
  role = null,
  enabled = null,
  contactNumber = null,
  firstName = null,
  lastName = null,
  agency = null,
  company = null
}) => {
  try {
    // console.log("THIS IS THE ROLE", role);
    const res = await instance
      .get("/users/search/multiParam", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc",
          role: role,
          username: username,
          enabled: enabled,
          contactNumber: contactNumber,
          firstName: firstName,
          lastName: lastName,
          agency: agency,
          company: company
        }
      })
      .then((res) => res.data);
    return res._embedded.users;
  } catch (error) {
    throw error.response.data;
  }
};

//get specific user
const getUser = async (userId) => {
  try {
    const res = await instance.get(`/users/${userId}`).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

const getUser_base = async (url) => {
  try {
    const res = await instance_base.get(url).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

//login
const login = async (username, password) => {

  try {
    const res = await instance
      .post("/authenticate", {
        //username is always lower case 
        username:username.toLowerCase().trim(),
        password
      })
      .then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//logout
const logout = async () => {
  try {
    const res = await instance.post("/logout").then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

//register
const register = async ({ firstName, lastName, email, password, roleLink }) => {
  try {
    const res = await instance
      .post("/users", {
        enabled: true,
        firstName: firstName,
        lastName: lastName,
        username: email,
        password: password,
        role: roleLink
      })
      .then((res) => res.data);
    return res;
  } catch (error) {
        console.log(error)
    throw error.response.data;
  }
};

//create user
const createUser = async (newData, roleLink) => {
  var data = {...newData}
  data.contractorHSTRate = data.contractorHSTRate/100
  try {
    const res = await instance
      .post("/users", { ...data, email: data.email.toLowerCase().trim(), username: data.username.toLowerCase().trim(), password: "password", role: roleLink })
      .then((res) => res.data);
    return res;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

//update user
const updateUser = async (userId, updatedInfo) => {
  try {
    const res = await instance.patch(`/users/${userId}`, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
        console.log(error)

    throw error;
  }
};

const updateUser_base = async (url, updatedInfo) => {

  updatedInfo.contractorHSTRate = updatedInfo.contractorHSTRate/100
  console.log("users", updatedInfo)
  try {
    const res = await instance_base.patch(url, {...updatedInfo, email: updatedInfo.email.toLowerCase().trim(), username: updatedInfo.username.toLowerCase().trim()}).then((res) => {
      console.log('res:', res)
      return res.data
    });
    return res;
  } catch (error) {
    console.log(error)
    throw error;
  }
};

const updateContractor_base = async (url, updatedInfo) => {

  updatedInfo.contractorHSTRate = updatedInfo.contractorHSTRate/100
  console.log("users", updatedInfo)
  try {
    const res = await instance_base.patch(url, updatedInfo)
      .then(
        (res) => {
      console.log('res:', res)
      return res.data
    });
    return res;
  } catch (error) {
    console.log(error)
    throw error;
  }
};



const resetPassword_base = async (url, newPassword) => {

  //regex for 8 char 1lower 1 upper 1 number 1 symbol
  const passCheck = /^(?=.{8,50})(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%*^&+=]).*$/g;
  const isValidPW =passCheck.test(newPassword)

    if (isValidPW){    
      try {
      const res = await instance_base.patch(url, { password: newPassword })
      return res
      } catch (error) {
        console.log(error)
      throw error;
      }
    } else {
      return false
    }
  
};

const resetPasswordSwal = async (rowData) => {
  const { value } = await Swal.fire({
    icon: "info",
    title: "Enter new password",
    input: "text"
  });

  if (value !== undefined){

    try {
      const reset = await resetPassword_base(rowData._links.self.href, value);
       if (reset){
         return 'success'
       } else {
         return 'fail'
       }
   
     } catch (error) {
       console.log('fail')
       throw error
     }

  }

  
};


//reset pw from login
const forgotPasswordEmail = async (data) => {
    try{
        const getToken = await instance
        .post("/users/requestResetEmail", {
          email: data
        })
        .then((res) => {
          console.log(res)

          if(res.status === 201){
            console.log('success')
            return res
          }
        })
        } catch (error) {
          throw error.response.data;
          }
};

//actully changing the pw
const actullyChgPW = async (pass, token) => { 
  
  // console.log(data.value)  
  const chgPW = await instance
        .post("/users/changePassword", {
          password: pass,
          token: token
        })
        .then((res) => {
          console.log(res)
        });
};


//delete user
const deleteUser = async (userId) => {
  try {
    const res = await instance.delete(`/users/${userId}`).then((res) => res);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export {
  updateContractor_base,
  getAllUsers,
  createUser,
  updateUser_base,
  getUser,
  getUser_base,
  getSpecificUserTypes,
  getSpecificUserTypes_params,
  login,
  logout,
  register,
  updateUser,
  deleteUser,
  resetPassword_base,
  resetPasswordSwal,
  forgotPasswordEmail,
  actullyChgPW
};
