import React, { useState } from "react";
import * as billingHelpers from "./BillingHelpers";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import moment from "moment";

const ConfirmationDialog = ({ confirmationDialogClose, confirmCreate, details, title, source, upToDate, ...props }) => {
  const classes = billingHelpers.useStyles();

  const [notes, setNotes] = useState('');

  return (
    <>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <Grid item container spacing={1}>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              label={source === "invoice" ? `COMPANY:` : "CONTRACTOR"}
              fullWidth
              variant="filled"
              value={source === "invoice" ? details.name : `${details.lastName}, ${details.firstName}`}
              // error={error.error}
              // helperText={error.msg}
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              label={source === "invoice" ? `CUT OFF COMPLETION DATE:` : "UP TO PERIOD:"}
              fullWidth
              variant="filled"
              value={upToDate}
              // error={error.error}
              // helperText={error.msg}
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              label={source === "invoice" ? "INVOICE DATE:" : "PAYSLIP DATE:"}
              fullWidth
              variant="filled"
              value={moment().format('MM-DD-YYYY')}
              // error={error.error}
              // helperText={error.msg}
              readOnly
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="normal"
              label="NOTES:"
              multiline
              variant="outlined"
              value={notes}
              // error={error.error}
              // helperText={error.msg}
              style={{ width: "100%", paddingTop: "0px", margin: "0px" }}
              onChange={e => setNotes(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button data-cy="confirm"  onClick={() => confirmCreate(notes)} color="primary">
          Confirm
        </Button>
        <Button data-cy="cancel" onClick={confirmationDialogClose} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;
