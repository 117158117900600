import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "../components/DefaultTable";

import { companyRateColumns } from "../components/data/constants";
import { updateInsuranceCompany_base } from "../components/API_calls/insuranceCompanies";
import { GlobalNameListContext } from "../Context";
import { forEach } from "async";
import { roundTo } from "../components/utils/helpers";
const CompanyRates = ({ ...props }) => {

  const { globalNameList } = useContext(GlobalNameListContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  
  const setRate = (info) => {
    const newData = info.map(a => Object.assign({},a))
    newData.forEach(index => index.hstRate = roundTo(index.hstRate*100, 2))
    setInfo(newData)
  };

  useEffect(() => {
    if (globalNameList.insuranceCompanies) {
      const initializeData = async () => {
        try {
          setIsLoading(true);
          setRate(globalNameList.insuranceCompanies);
        } catch (e) {
          console.log("error:", e);
        } finally {
          setIsLoading(false);
        }
      };
      initializeData();
    }
  }, [globalNameList.insuranceCompanies]);

  const updateFunction = async (newData, oldData) => {
    setIsLoading(true);
    try {
      await updateInsuranceCompany_base(oldData.link, newData);
      let newInfo = {...newData}
      newInfo.hstRate = roundTo(newInfo.hstRate, 2);
      const data = info.map((currData) =>
        currData.tableData.id === oldData.tableData.id ? newInfo : currData
      );
      setInfo(data);
      console.log("newData", newInfo)
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <DefaultTable
        title="Company Rates"
        inputColumns={companyRateColumns}
        inputData={info}
        updateFunction={updateFunction}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
    </>
  );
};

export default CompanyRates;
