import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const LoadingSpinner = () => {
  const classes = {
    root: { display: "flex", justifyContent: "center", padding: "20px" },
    btn: { width: "10em", height: "10em" }
  };

  return (
    <div style={classes.root}>
      <CircularProgress style={classes.btn} />
    </div>
  );
};

export default LoadingSpinner;
