import React, { useRef } from "react";

import "./ReportWrapper.scss";

import ListItem from "@material-ui/core/ListItem";
import List from "@material-ui/core/List";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Button from '@material-ui/core/Button';
import CreatePDF from "./CreatePDF";
import PrintPDF from "./PrintPDF";

const ReportWrapper = ({ closeDialog, title = "TITLE", orientation = 'landscape', ...props }) => {
  const ref = useRef(null);

  // TODO - implement once API is complete
  const handleEmail = () => {

  };

  return (
    <div className="report-wrapper">
      <div className="report-wrapper-header">
        <span style={{ display: "flex", alignItems: "center" }}>
          <IconButton edge="start" color="inherit" onClick={closeDialog} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className="report-header-title">
            {title}
          </Typography>
        </span>
        <div className="action-section" style={{ justifyContent: "flex-end" }}>
          <List style={{ display: "flex" }} dense disablePadding>
            <ListItem style={{ margin: "0px 2px", padding: "0" }}>
              <Button
                style={{ width: "100%", margin: "0", padding: "0" }}
                variant="contained"
                onClick={handleEmail}
              >
                EMAIL
              </Button>            
            </ListItem>
            <ListItem style={{ margin: "0px 2px", padding: "0" }}>
              <CreatePDF refProp={ref} orientation={orientation} />
            </ListItem>
            <ListItem style={{ margin: "0px 2px", padding: "0" }}>
              <PrintPDF refProp={ref} />
            </ListItem>
          </List>
        </div>
      </div>
      <div ref={ref} className="report-section">
        <span>{props.children}</span>
      </div>
    </div>
  );
};

export default ReportWrapper;
