import React from "react";
import ProcessedReportTable from "../components/ProcessedReportTable";
import PendingReportTable from "../components/PendingReportTable";

const OrderRequestsPage = () => {
  return (
    <div style={{ padding: "5px" }}>
      <PendingReportTable />
      <ProcessedReportTable />
    </div>
  );
};

export default OrderRequestsPage;
