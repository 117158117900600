import instance from "../utils/API";
import instance_base from "../utils/API_base";

const getAllMobileExpenseTypes = async () => {
  try {
    const res = await instance
      .get("/mobileExpenseTypes", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.mobileExpenseTypes;
  } catch (error) {
    throw error.response.data;
  }
};

const createMobileExpenseType = async (info) => {
  try {
    const res = await instance.post("/mobileExpenseTypes", info).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

const updateMobileExpenseType_base = async (url, updatedInfo) => {
  try {
    const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

const deleteMobileExpenseType_base = async url => {
  try {
    return instance_base.delete(url).then(res => console.log('data deleted'));
  } catch (err) {
    throw err.response.data;
  }
};

export { getAllMobileExpenseTypes, createMobileExpenseType, updateMobileExpenseType_base, deleteMobileExpenseType_base };
