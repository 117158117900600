import React, { useState, useContext, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import { formatPhoneNumber } from '../utils/helpers';
//import { valueContainerCSS } from "react-select/src/components/containers";

const normalizeInput = (value, previousValue) => {
  if (!value) return '';
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  // return value
};

const removeLetters = (value) => {
  return value.replace(/a/g, '');
};

export default function OrderDetailsPhoneBoxTest(props) {
  //const formattedValue = normalizeInput(props.initialValue)
  const { onChange, initialValue, name, error, helperText } = props;
  // const { value: propsValue, ...etcProps } = props;
  const [value, setValue] = React.useState(props.initialValue);
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [selectionEnd, setSelectionEnd] = React.useState(0);
  const input = React.useRef(null);

  const handleChange = (e) => {
    const { target } = e;

    if (
      normalizeInput(target.value).indexOf(')') !== -1 &&
      normalizeInput(target.value).indexOf(')') === normalizeInput(target.value).length - 3
    ) {
      setSelectionStart(target.selectionStart + 3);
      setSelectionEnd(target.selectionEnd + 3);
      setValue(target.value);
      onChange(normalizeInput(target.value), name);
    } else if (
      normalizeInput(target.value).indexOf('-') !== -1 &&
      normalizeInput(target.value).indexOf('-') === normalizeInput(target.value).length - 2
    ) {
      setSelectionStart(target.selectionStart + 2);
      setSelectionEnd(target.selectionEnd + 2);
      setValue(target.value);
      onChange(normalizeInput(target.value), name);
    } else if (
      normalizeInput(target.value).indexOf(')') === 4 &&
      normalizeInput(target.value).length === 14 &&
      target.selectionStart === 5
    ) {
      setSelectionStart(target.selectionStart + 2);
      setSelectionEnd(target.selectionEnd + 2);
      setValue(target.value);
      onChange(normalizeInput(target.value), name);
    } else if (
      normalizeInput(target.value).indexOf('-') === 9 &&
      normalizeInput(target.value).length === 14 &&
      target.selectionStart === 10
    ) {
      setSelectionStart(target.selectionStart + 1);
      setSelectionEnd(target.selectionEnd + 1);
      setValue(target.value);
      onChange(normalizeInput(target.value), name);
    } else {
      setSelectionStart(target.selectionStart);
      setSelectionEnd(target.selectionEnd);
      setValue(target.value);
      onChange(normalizeInput(target.value), name);
    }
  };

  //const formattedValue = normalizeInput(props.initialValue)

  useEffect(() => {
    input.current.value = normalizeInput(value);

    // make it happen
    input.current.selectionStart = selectionStart;
    input.current.selectionEnd = selectionEnd;
  }, [value]);

  const formatValue = normalizeInput(props.initialValue);

  return (
    <div className="input-container" style={{ width: '9em' }}>
      <TextField
        // ref={input}
        inputProps={{ ref: input }}
        className="input"
        type="text"
        name={name}
        placeholder="(xxx) xxx-xxxx"
        value={formatValue}
        //value={normalizeInput(value)}
        onChange={handleChange}
        error={error}
        helperText={helperText}
      />
    </div>
  );
}
