import React, { useEffect, useState } from 'react';

import { KeyboardDateTimePicker, KeyboardDatePicker } from "@material-ui/pickers";
import IconButton from '@material-ui/core/IconButton';
import moment from 'moment';
import ClearIcon from '@material-ui/icons/Clear';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

import './DateTimePicker.scss';

const DateTimePicker = ({source, clientInfo, orderInfo, className, label, time = false, changeDate, clearable, error = false, helperText, ...props}) => {
  const initialTime = source ? props[source] : null;

  const [currentTime, setCurrentTime] = useState(initialTime);

  useEffect(() => {
    setCurrentTime(initialTime);
  }, [clientInfo, orderInfo, initialTime]);

  return (
    <Grid>
      <div className='date-time-picker'>
        {time ? 
          <KeyboardDateTimePicker
            className={`picker ${className ? className : ''}`}
            autoOk
            fullWidth
            label={label}
            placeholder={'MM-DD-YYYY HH:MM'}
            variant="inline"
            format={'MM-DD-YYYY hh:mm A'}
            value={currentTime}
            onChange={date => {changeDate(moment(date), setCurrentTime, source)}}
          /> :
          <KeyboardDatePicker 
            className={`picker ${className ? className : ''}`}
            autoOk
            fullWidth
            label={label}
            placeholder={'MM-DD-YYYY'}
            variant="inline"
            format={'MM-DD-YYYY'}
            value={currentTime}
            onChange={date => {changeDate(moment(date), setCurrentTime, source)}}
          />
        }
        {clearable && <IconButton 
          className='clear-button'
          size='small' 
          color='default' 
          onClick={() => changeDate(null, setCurrentTime, source)}
        >
          <ClearIcon />
        </IconButton>}
        {source === 'completionTime' && <IconButton
          size='small'
          color='default'
          onClick={() => changeDate(moment(props.serviceTime), setCurrentTime, source)}
          >
            A
          </IconButton>
        }
        {(source === 'assignmentTime' || source === 'paraOutTime') && <IconButton
          size='small'
          color='default'
          onClick={() => changeDate(moment(), setCurrentTime, source)}
          >
            T
          </IconButton>
        }
      </div>
      {error && <FormHelperText error={true}>{helperText}</FormHelperText>} 
    </Grid>
  );
};

export default DateTimePicker;