import React, { useContext } from 'react';

import * as billingHelpers from "./BillingHelpers";
import clsx from 'clsx';

import AutoCompleteDropDown from '../OrderDetails/AutoCompletes/AutoCompleteDropDown';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import { InvoicePayslipDataContext } from '../../Context';
import { roundTo } from '../utils/helpers';

const InvoiceHeader = ({details, setDetails, beforeCreate, upToDate, ...props}) => {

  const classes = billingHelpers.useStyles();

  const { invoicePayslipData } = useContext(InvoicePayslipDataContext);

  console.log("details", details)

  return (
    <>
      {details && beforeCreate ? 
        <>
          <Grid item container spacing={1}>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Up To Period:"
                fullWidth
                variant="filled"
                type="date"
                value={upToDate}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <AutoCompleteDropDown
                onChange={(e, newVal) => setDetails(invoicePayslipData.find(data => data._links.self.href === newVal._links.self.href))}
                dropDownData={invoicePayslipData}
                dropDownVal='name'
                renderItems={[{ name: details.name }]}
                enableDelete={false}
                label='Insurance Company:'
              />
            </Grid>
          </Grid>
        </> : 
        <>
          <Grid item container spacing={1}>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Invoice No.:"
                fullWidth
                variant="filled"
                value={details.invoiceID}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Invoice Date:"
                fullWidth
                variant="filled"
                value={details.invoiceDate ? moment(details.invoiceDate).format('MM-DD-YYYY') : undefined}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Up To Period:"
                fullWidth
                variant="filled"
                value={details.upToPeriod ? moment(details.upToPeriod).format('MM-DD-YYYY') : undefined}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                fullWidth
                value={details.insuranceCompany ? details.insuranceCompany.name : undefined}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
          </Grid>
        </>
      }
      <Grid container spacing={1}>
        <div style={{ padding: "10px 0px 0px 0px", width: "100%" }}>
          <Grid item container xs={12} spacing={1}>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label={`Toronto ($):`}
                fullWidth
                variant="filled"
                value={details.ordersTotal ? roundTo(details.ordersTotal, 2) : details.ordersTotal}
                readOnly
                // error={error.error}
                // helperText={error.msg}
              />
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label={`Kit ($):`}
                fullWidth
                variant="filled"
                value={details.kitsTotal ? roundTo(details.kitsTotal, 2) : details.kitsTotal}
                readOnly
                // error={error.error}
                // helperText={error.msg}
              />
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label={`Scan/Fax ($):`}
                fullWidth
                variant="filled"
                value={details.scanFaxTotal ? roundTo(details.scanFaxTotal, 2) : details.scanFaxTotal}
                readOnly
                // error={error.error}
                // helperText={error.msg}
              />
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label='HST (%):'
                fullWidth
                variant="filled"
                value={(details.hstRate*100)}
                readOnly
                // error={error.error}
                // helperText={error.msg}
              />
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label={`HST Amount ($):`}
                fullWidth
                variant="filled"
                value={details.hstTotal ? roundTo(details.hstTotal, 2) : details.hstTotal}
                readOnly
                // error={error.error}
                // helperText={error.msg}
              />
            </Grid>
            <Grid item lg={2} sm={6} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label={`Grand Total ($):`}
                fullWidth
                variant="filled"
                value={details.grandTotal ? roundTo(details.grandTotal, 2) : details.grandTotal}
                readOnly
                // error={error.error}
                // helperText={error.msg}
              />
            </Grid>
          </Grid>
        </div>
      </Grid>
    </>
  );
};

export default InvoiceHeader;