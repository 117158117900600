import axios from "axios";
import { getJWT } from "./JWT";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

instance.interceptors.request.use((config) => {
  const token = getJWT();
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

export default instance;
