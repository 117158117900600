import React from "react";

const ErrorPage = (props) => {
  return (
    <div>
      <h1>ERROR PAGE</h1>
      <h2>Path {props.location.pathname} does not exist</h2>
    </div>
  );
};

export default ErrorPage;
