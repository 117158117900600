import instance_base from '../utils/API_base';

const updateLog = async (url, data) => {
  try {
    const res = await instance_base.patch(url, data);
    return res;
  } catch (error) {
    throw error;
  }
};

export { updateLog };
