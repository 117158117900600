import React, { useEffect, useState, useContext } from "react";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import DefaultTable from "./DefaultTable";

import { sysAdminInfoColumns } from "./data/constants";
import {
  createUser,
  updateUser_base,
  resetPasswordSwal,
} from "./API_calls/users";
import { GlobalNameListContext } from "../Context";

import PopUp from "./PopUp";

const SysAdminCrudTable = ({ roleLink, userData, ...props }) => {
  const { setGlobalNameList } = useContext(GlobalNameListContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  // for popup
  const [errorMsg, setErrorMsg] = useState("");
  const [popUpStatus, setPopUpStatus] = useState(false);
  const [popUpType, setPopUpType] = useState("");

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        setInfo(userData);
      } catch (e) {
        console.log("ERROR:", e);
      } finally {
        setIsLoading(false);
      }
    };
    initializeData();
  }, [userData]);

  const addFunction = async (newData) => {
    console.log(newData)
    if (
      newData.firstName !== undefined &&
      newData.username !== undefined &&
      newData.lastName !== undefined
    ) {
      setIsLoading(true);
      try {
        const res = await createUser(newData, roleLink);
        setInfo([res, ...info]);
        setGlobalNameList((prev) => ({
          ...prev,
          superAdmins: [
            ...prev.superAdmins,
            { firstName: res.firstName, link: res._links.self.href },
          ],
        }));
      } catch (e) {
        console.log(e);
        setErrorMsg("Error: Check Email Format");
        setPopUpType('error')
        setPopUpStatus(new Date());
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMsg("Error: Please fillout all fields");
      setPopUpType('error')
      setPopUpStatus(new Date());
    }
  };

  const updateFunction = async (newData, oldData) => {
    console.log(newData);
    if (
      newData.firstName !== "" &&
      newData.username !== "" &&
      newData.lastName !== ""
    ) {
      setIsLoading(true);
      try {
        await updateUser_base(oldData._links.self.href, newData);
        const data = info.map((currData) =>
          currData.tableData.id === oldData.tableData.id ? newData : currData
        );
        setInfo(data); 
      } catch (e) {
        console.log(e);
        setErrorMsg("Error: Check Email Format Or Duplicate Username");
        setPopUpType('error')
        setPopUpStatus(new Date());
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMsg("Error: Please fillout all fields");
      setPopUpType('error')
      setPopUpStatus(new Date());
    }
  };

  const actions = [
    {
      icon: () => <LockOpenIcon />,
      tooltip: "Reset Password",
      onClick: async (event, rowData, togglePanel) => {
        try {
          const status = await resetPasswordSwal(rowData); 

          if (status === "success") {
            setErrorMsg(`Password Changed`);
            setPopUpType("success");
            setPopUpStatus(new Date());
          }

          if (status === "fail") {
            setErrorMsg(`Password requirements: Min length 8, 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Char (!@#$%^*...)`);
            setPopUpType("error");
            setPopUpStatus(new Date());
          }
        } catch (err) {
          setErrorMsg(`Enter a new password`);
          setPopUpType("error");
          setPopUpStatus(new Date());
        }
      },
    },
  ];

  return (
    <div>
      <DefaultTable 
        title="System Admin Info"
        cyData='sysAdmin'
        actions={actions}
        inputColumns={sysAdminInfoColumns}
        inputData={info}
        updateFunction={updateFunction}
        addFunction={addFunction}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      {popUpStatus ? (
        <PopUp type={popUpType} message={errorMsg} status={popUpStatus} />
      ) : null}
    </div>
  );
};

export default SysAdminCrudTable;
