/*
NOTE: for a quick optimization, debounced textfields were used. However, this may not be the best optimization technique.
See TODO doc for other potential ideas that I had but didn't have time to try.
Because the debounces were really meant to be a temporary fix, and are really rudimentary at best, the original text fields
have only been commented out, not deleted.
*/

import React, { useState, useContext, useEffect } from 'react';
import { AppDataContext } from '../../Context';
import * as detailHelpers from './orderDetailsHelpers';
import { generateKey } from '../utils/helpers';
import '../OrderTable/OrderTableItem.scss';
import SimpleButton from './SimpleButton';
import DateTimePicker from './DateTimePicker';
import ServiceItem from './ServiceItem';
import AutoCompleteDropDown from './AutoCompletes/AutoCompleteDropDown';
import InsuranceItem from './InsuranceItem';

import { withRouter } from 'react-router-dom';
// import validator from "validator";
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import AgentInfo from './Details-AgentInfo';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';

import ConversionDialog from '../OrderTable/ConversionDialog';
import { statusTypes } from '../data/constants';
import DebouncedTextField from '../basic-components/DebouncedTextField';
import OrderDetailsPhoneBox from '../basic-components/OrderDetailsPhoneBox';
import OrderDetailsPhoneBoxTest from '../basic-components/OrderDetailsPhoneBoxTest';

import moment from 'moment';
import { CircularProgress } from '@material-ui/core';

const OrderInfoDetails = ({
  appData,
  globalNameList,
  orderDetail,
  orderInfo,
  insuranceInfo,
  serviceDetails,
  agentInfo,
  labInfo,
  emailLogInfo,
  id,
  changeOrderId,
  submitOrderId,
  changeInsuranceInfo,
  changeOrderInfo,
  changeOrderDate,
  addServiceItem,
  delServiceItem,
  changeLabInfo,
  changeContractor,
  changeServiceDetails,
  addInsuranceType,
  deleteInsuranceType,
  changeAgentInfo,
  openSearchAgent,
  agencyList,
  clientInfo,
  delLanguage,
  changeLanguage,
  addLanguage,
  changeClientInfo,
  changeClientDate,
  referredByList,
  isFieldFilled,
  setIsFieldFilled,
  loadingAgent,
  loadingServices,
  ...props
}) => {
  // STYLES
  const classes = detailHelpers.useStyles();

  // CONTEXT
  const { appDataState } = useContext(AppDataContext);

  // COMPONENT STATE
  // const [error, setError] = useState({ error: false, msg: "" });
  const [examLocation, setExamLocation] = useState('');
  const [serviceItemsList, setServiceItemsList] = useState([]);
  const [open, setOpen] = useState(false);
  const [clientLanguages, setClientLanguages] = useState([]);

  // GLOBAL VARIABLES
  const insuranceTypeList = appDataState ? Object.values(appDataState.insuranceTypes) : [];
  const labNameList = appDataState ? appDataState.lab : [];
  const languageList = appDataState ? appDataState.languages : [];

  useEffect(() => {
    /* ==============SET Client Languages */
    const currLanguages = clientInfo.languages.length > 0 ? clientInfo.languages : ['English'];
    const clientLanguagesTemp = currLanguages.map((language, i) => {
      return (
        <Grid item xs={12} key={generateKey()}>
          <Grid item xs={12} className={classes.overflowHidden}>
            <AutoCompleteDropDown
              dropDownData={languageList}
              renderItems={[language]}
              enableDelete={clientInfo.languages.length > 1 ? true : false}
              value={language}
              deleteHandler={() => delLanguage(i)}
              onChange={(e, newVal) => changeLanguage(i, newVal)}
            />
          </Grid>
        </Grid>
      );
    });
    setClientLanguages(clientLanguagesTemp);
  }, [clientInfo.languages, languageList]);

  useEffect(() => {
    /* ==============SET Client Location */
    if (orderInfo.examLocation.clientLocationMediFastOffice) {
      setExamLocation('MediFast');
    } else {
      setExamLocation(
        `${orderInfo.examLocation.clientLocationAddress}+${orderInfo.examLocation.clientLocationPostalCode}`
      );
    }
  }, [orderInfo.examLocation]);

  useEffect(() => {
    /* ==============SET Service List*/
    const serviceItemsListTemp = serviceDetails.map((serviceDetail, index) => {
      return (
        <ServiceItem
          key={serviceDetail.id ? serviceDetail.id : serviceDetail.link}
          index={index}
          globalNameList={globalNameList}
          showDelete={serviceDetails.length > 1}
          serviceDetail={serviceDetail}
          changeContractor={changeContractor}
          changeServiceDetails={changeServiceDetails}
          delServiceItem={delServiceItem}
        />
      );
    });

    setServiceItemsList(serviceItemsListTemp);
  }, [serviceDetails, globalNameList, changeContractor, changeServiceDetails, delServiceItem]);

  // HANDLE DEBOUNCE FUNCTIONS
  const handleDebouncedClient = (val, key) => {
    //[] Doesn't actaully do anything...
    changeClientInfo(key, val);
  };

  const insuranceItemsList = insuranceInfo.insuranceItems.map((type, i) => {
    return (
      <InsuranceItem
        key={i}
        type={type}
        changeInsuranceInfo={changeInsuranceInfo}
        i={i}
        insuranceTypeList={insuranceTypeList}
        deleteInsuranceType={deleteInsuranceType}
        insuranceInfo={insuranceInfo}
      />
    );
  });

  return (
    orderDetail &&
    orderInfo &&
    insuranceInfo &&
    serviceDetails &&
    agentInfo &&
    labInfo &&
    emailLogInfo && (
      <>
        <Grid container>
          <Grid item container xs={12} spacing={1}>
            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Order ID:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  data-cy="order-id-textfield"
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  hiddenLabel
                  fullWidth
                  readOnly
                  value={orderDetail.medifastID}
                />
              </Grid>
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Order Date:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <DateTimePicker
                  id={id}
                  orderInfo={orderInfo}
                  source="orderDate"
                  orderDate={orderInfo.orderDate}
                  changeDate={changeOrderDate}
                  time={false}
                />
              </Grid>
            </Grid>

            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Status:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Select
                  style={{ width: '100%' }}
                  onChange={(e) => changeOrderInfo('status', e.target.value, null)}
                  value={orderInfo.status}
                >
                  {appDataState.orderStatusTypes.map((status) => (
                    <MenuItem key={generateKey()} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Ordered By:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="ordered-by"
                  select
                  value={orderInfo.orderedBy}
                  onChange={(e) => changeOrderInfo('orderedBy', e.target.value)}
                  margin="none"
                  style={{ width: '100%' }}
                  error={!orderInfo.orderedBy}
                  helperText={!orderInfo.orderedBy && 'This field is required.'}
                >
                  {appDataState.orderByTypes.map((option, index) => (
                    <MenuItem key={generateKey()} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>

            <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Notify Agent:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  id={id}
                  orderInfo={orderInfo}
                  source="notifyAgent"
                  notifyAgent={orderInfo.notifyAgent}
                  changeDate={changeOrderDate}
                />
              </Grid>
            </Grid>

            <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Insurance Company:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <AutoCompleteDropDown
                  onChange={(e, newVal) => changeInsuranceInfo('company', newVal)}
                  dropDownData={globalNameList.insuranceCompanies}
                  dropDownVal="name"
                  renderItems={
                    insuranceInfo.company ? [insuranceInfo.company] : [{ name: '', link: '', defaultLab: '' }]
                  }
                  enableDelete={false}
                  error={!insuranceInfo.company.name}
                  helperText="This field is required."
                />
              </Grid>
            </Grid>

            <Grid item lg sm={3} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Lab:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <AutoCompleteDropDown
                  dropDownData={labNameList}
                  renderItems={[labInfo.labName]}
                  enableDelete={false}
                  onChange={(e, newVal) => changeLabInfo('labName', newVal)}
                />
              </Grid>
            </Grid>
          </Grid>

          <div style={{ padding: '10px 0px 0px 0px', width: '100%' }}>
            <AgentInfo
              nameList={globalNameList.agents}
              agentInfo={agentInfo}
              changeAgentInfo={changeAgentInfo}
              changeOrderInfo={changeOrderInfo}
              openSearchAgent={openSearchAgent}
              agencyList={agencyList}
              agency={orderInfo.agency}
              orderInfo={orderInfo}
              reqFlag={isFieldFilled} //[]Update drilled names
              setReqFlag={setIsFieldFilled} //[]Update drilled names
              loadingAgent={loadingAgent}
            />
          </div>

          <div style={{ padding: '10px 0px 0px 0px', width: '100%' }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Client Last Name:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField, classes.dense)}
                    margin="none"
                    hiddenLabel
                    value={clientInfo.lastName}
                    style={{ width: '100%' }}
                    onChange={(e) => changeClientInfo('lastName', e.target.value)}
                    error={!clientInfo.lastName}
                    helperText={!clientInfo.lastName ? 'This field is required.' : null}
                  />
                </Grid>
              </Grid>

              <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Client First Name:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField, classes.dense)}
                    margin="none"
                    hiddenLabel
                    value={clientInfo.firstName}
                    style={{ width: '100%' }}
                    onChange={(e) => changeClientInfo('firstName', e.target.value)}
                    error={!clientInfo.firstName}
                    helperText={!clientInfo.firstName ? 'This field is required.' : null}
                  />
                </Grid>
              </Grid>

              <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Gender:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <AutoCompleteDropDown
                    dropDownData={['Male', 'Female', 'Unknown']}
                    deleteHandler={() => console.log('delete clicked')}
                    renderItems={[clientInfo.gender]}
                    enableDelete={false}
                    onChange={(e, newVal) => changeClientInfo('gender', newVal)}
                    error={!clientInfo.gender}
                    helperText="This field is required."
                  />
                </Grid>
              </Grid>

              <Grid item lg={2} sm={2} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Date of Birth:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <DateTimePicker
                      source="dob"
                      dob={clientInfo.dob}
                      changeDate={changeClientDate}
                      clientInfo={clientInfo}
                      orderInfo={orderInfo}
                      time={false}
                      error={!clientInfo.dob}
                      helperText="This field is required."
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Grid item xs={12}>
                    <Typography>
                      <strong>Age:</strong>
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      className={clsx(classes.textField, classes.dense)}
                      margin="none"
                      hiddenLabel
                      inputProps={{ readOnly: true }}
                      value={clientInfo.age}
                      style={{ width: '100%' }}
                      onChange={(e) => changeClientInfo('age', e.target.value)}
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Exam Location:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField, classes.dense)}
                    margin="none"
                    hiddenLabel
                    fullWidth
                    disabled={orderInfo.examLocation.clientLocationMediFastOffice}
                    value={
                      orderInfo.examLocation.clientLocationMediFastOffice
                        ? ''
                        : orderInfo.examLocation.clientLocationAddress
                    }
                    onChange={(e) => changeOrderInfo('examLocation', e.target.value, 'clientLocationAddress')}
                    error={
                      !orderInfo.examLocation.clientLocationMediFastOffice &&
                      !orderInfo.examLocation.clientLocationAddress
                    }
                    helperText={
                      !orderInfo.examLocation.clientLocationMediFastOffice &&
                      !orderInfo.examLocation.clientLocationAddress
                        ? 'This field is required.'
                        : null
                    }
                  />
                </Grid>
              </Grid>

              <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Postal Code:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    className={clsx(classes.textField, classes.dense)}
                    margin="none"
                    hiddenLabel
                    disabled={orderInfo.examLocation.clientLocationMediFastOffice}
                    value={
                      orderInfo.examLocation.clientLocationMediFastOffice
                        ? ''
                        : orderInfo.examLocation.clientLocationPostalCode
                    }
                    onChange={(e) => changeOrderInfo('examLocation', e.target.value, 'clientLocationPostalCode')}
                    error={
                      !orderInfo.examLocation.clientLocationMediFastOffice &&
                      !orderInfo.examLocation.clientLocationPostalCode
                    }
                    helperText={
                      !orderInfo.examLocation.clientLocationMediFastOffice &&
                      !orderInfo.examLocation.clientLocationPostalCode
                        ? 'This field is required.'
                        : null
                    }
                  />
                </Grid>
              </Grid>

              <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
                <Grid item xs={6}>
                  <Button
                    variant="contained"
                    color="default"
                    size="small"
                    onClick={() =>
                      window.open(`https://www.google.com/maps/search/?api=1&query=${examLocation}`, '_blank')
                    }
                  >
                    Map
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={orderInfo.examLocation.clientLocationMediFastOffice}
                        onChange={() =>
                          changeOrderInfo(
                            'examLocation',
                            !orderInfo.examLocation.clientLocationMediFastOffice,
                            'clientLocationMediFastOffice'
                          )
                        }
                        color="primary"
                      />
                    }
                    label="MediFast?"
                  />
                </Grid>
              </Grid>
            </Grid>
          </div>

          <div style={{ padding: '10px 0px 0px 0px', width: '100%' }}>
            <Grid item container xs={12} spacing={1}>
              <Grid item className={classes.phoneNumber}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Home #:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <OrderDetailsPhoneBoxTest
                    className={clsx(classes.textField, classes.dense)}
                    initialValue={clientInfo.home}
                    onChange={handleDebouncedClient}
                    name="home"
                    error={!clientInfo.mobile && !clientInfo.home}
                    helperText={!clientInfo.mobile && !clientInfo.home ? 'Please provide either home or mobile.' : null}
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid item className={classes.phoneNumber}>
                <Grid item xs={12}>
                  <Typography>
                    <strong>Mobile #:</strong>
                  </Typography>
                </Grid>

                <Grid item xs={12}>
                  <OrderDetailsPhoneBoxTest
                    className={clsx(classes.textField, classes.dense)}
                    initialValue={clientInfo.mobile}
                    onChange={handleDebouncedClient}
                    name="mobile"
                    error={!clientInfo.mobile && !clientInfo.home}
                    helperText={!clientInfo.mobile && !clientInfo.home ? 'Please provide either home or mobile.' : null}
                    type="number"
                  />
                </Grid>
              </Grid>

              <Grid item container lg={3} sm={6} xs={12} className={classes.overflowHidden}>
                {insuranceItemsList}
              </Grid>

              <Grid item container lg={1} sm={2} xs={12} alignItems="center" justify="center">
                <SimpleButton
                  onClick={addInsuranceType}
                  text={'ADD'}
                  icon={<AddIcon data-cy="add-insurance-type-btn" />}
                />
              </Grid>

              <Grid item container xs={3}>
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={6} className={classes.overflowHidden}>
                    <Typography>
                      <strong>Ref Type:</strong>
                    </Typography>
                    <AutoCompleteDropDown
                      onChange={(e, newVal) => changeOrderInfo('refNum', newVal, 'refNumType')}
                      dropDownData={appDataState.refNoTypes}
                      renderItems={[orderInfo.refNum.refNumType]}
                      enableDelete={false}
                      disableClearable={true}
                    />
                  </Grid>
                  <Grid item xs={6} className={classes.overflowHidden}>
                    <Typography>
                      <strong>Ref No.:</strong>
                    </Typography>
                    <TextField
                      style={{ width: '100%' }}
                      className={clsx(classes.textField, classes.dense)}
                      hiddenLabel
                      value={orderInfo.refNum.refNumNumber}
                      onChange={(e) => changeOrderInfo('refNum', e.target.value, 'refNumNumber')}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Grid>
        {/* END of Grid Container */}

        <div style={{ padding: '10px 0px 10px 0px', width: '100%' }}>
          <Divider />
          <Grid item container justify="center" xs={12}>
            <Typography variant="h6">Service Details:</Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item container xs={10}>
              {!loadingServices && serviceItemsList}
              {loadingServices && <CircularProgress />}
            </Grid>
            <Grid item container xs={2} justify="center" alignItems="center">
              <SimpleButton onClick={addServiceItem} text={'ADD'} icon={<AddIcon />} />
              <SimpleButton onClick={() => setOpen(true)} text={'VIEW WEB REQUEST'} style={{ marginLeft: '20px' }} />
              <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)} scroll="paper">
                <ConversionDialog details={orderDetail} handleClose={() => setOpen(false)} type="view" />
              </Dialog>
            </Grid>
          </Grid>
        </div>

        <Divider />

        <div style={{ padding: '10px 0px 0px 0px', width: '100%' }}>
          <Grid item container xs={12} spacing={1}>
            <Grid item lg={3} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Remarks:</strong>
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  hiddenLabel
                  multiline
                  fullWidth
                  value={orderInfo.remarks}
                  onChange={(e) => changeOrderInfo('remarks', e.target.value, null)}
                />
                {/*<DebouncedTextField
                className={clsx(classes.textField, classes.dense)}
                multiline
                initialValue={orderInfo.remarks}
                onChange={handleDebouncedOrderInfo}
                name='remarks'
              /> */}
              </Grid>
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12} className={classes.overflowHidden}>
                <Typography>
                  <strong>Languages:</strong>
                </Typography>
              </Grid>
              {clientLanguages}
            </Grid>

            <Grid item container lg={1} sm={2} xs={12} justify="flex-start" alignItems="center">
              <SimpleButton text={'ADD'} icon={<AddIcon />} onClick={addLanguage} />
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Assignment:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  source="assignmentTime"
                  assignmentTime={orderInfo.assignmentTime}
                  changeDate={changeClientDate}
                  clientInfo={clientInfo}
                  orderInfo={orderInfo}
                  time={false}
                />
              </Grid>
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Appointment:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  source="serviceTime"
                  serviceTime={orderInfo.serviceTime}
                  changeDate={changeClientDate}
                  clientInfo={clientInfo}
                  orderInfo={orderInfo}
                  time={true}
                />
              </Grid>
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Completion:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  source="completionTime"
                  serviceTime={orderInfo.serviceTime}
                  completionTime={orderInfo.completionTime}
                  changeDate={changeClientDate}
                  clientInfo={clientInfo}
                  orderInfo={orderInfo}
                  time={false}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div style={{ padding: '10px 0px 0px 0px', width: '100%' }}>
          <Grid item container xs={12} spacing={1}>
            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Lab. No.:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  hiddenLabel
                  value={labInfo.labNumber}
                  style={{ width: '100%' }}
                  onChange={(e) => changeLabInfo('labNumber', e.target.value)}
                />
                {/*<DebouncedTextField
                className={clsx(classes.textField, classes.dense)}
                initialValue={labInfo.labNumber}
                onChange={handleDebouncedLabInfo}
                name='labNumber'
              /> */}
              </Grid>
            </Grid>

            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Lab Puro No.:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  hiddenLabel
                  value={labInfo.labPuroNum}
                  style={{ width: '100%' }}
                  onChange={(e) => changeLabInfo('labPuroNum', e.target.value)}
                />
                {/*<DebouncedTextField
                className={clsx(classes.textField, classes.dense)}
                initialValue={labInfo.labPuroNum}
                onChange={handleDebouncedLabInfo}
                name='labPuroNum'
              /> */}
              </Grid>
            </Grid>

            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Para Puro No.:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  hiddenLabel
                  value={orderInfo.paraPuroNum}
                  fullWidth
                  onChange={(e) => changeOrderInfo('paraPuroNum', e.target.value)}
                />
                {/*<DebouncedTextField
                className={clsx(classes.textField, classes.dense)}
                initialValue={orderInfo.paraPuroNum}
                onChange={handleDebouncedOrderInfo}
                name='paraPuroNum'
              /> */}
              </Grid>
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Referred By:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <AutoCompleteDropDown
                  onChange={(e, newVal) => changeOrderInfo('referredBy', newVal, null)}
                  dropDownData={referredByList.map((item) => item.firstName)}
                  renderItems={[orderInfo.referredBy]}
                  enableDelete={false}
                />
              </Grid>
            </Grid>

            <Grid item lg={2} sm={2} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Paid By:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <AutoCompleteDropDown
                  onChange={(e, newVal) => changeOrderInfo('paidBy', newVal, null)}
                  dropDownData={['COMPANY']}
                  renderItems={[orderInfo.paidBy]}
                  enableDelete={false}
                />
              </Grid>
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Para.Out:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <DateTimePicker
                  source="paraOutTime"
                  paraOutTime={orderInfo.paraOutTime}
                  changeDate={changeClientDate}
                  clientInfo={clientInfo}
                  orderInfo={orderInfo}
                  time={false}
                />
              </Grid>
            </Grid>

            <Grid item lg={3} sm={6} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Admin Note:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  hiddenLabel
                  multiline
                  value={orderInfo.adminNote}
                  style={{ width: '100%', paddingTop: '0px', margin: '0px' }}
                  onChange={(e) => changeOrderInfo('adminNote', e.target.value)}
                />
                {/*<DebouncedTextField
                className={clsx(classes.textField, classes.dense)}
                multiline
                initialValue={orderInfo.adminNote}
                onChange={handleDebouncedOrderInfo}
                name='adminNote'
            /> */}
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div style={{ paddingTop: '10px', width: '100%' }}>
          <Grid item container xs={12} spacing={1}>
            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderInfo.prebook}
                    onChange={() => changeOrderInfo('prebook', !orderInfo.prebook, null)}
                    color="primary"
                  />
                }
                label="Prebook"
              />
            </Grid>

            <Grid item lg={2} sm={2} xs={12} className={classes.overflowHidden}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                hiddenLabel
                readOnly
                name="prebookMsg"
                value={orderInfo.prebook ? 'Please call back to see if OK or not?' : ''}
                style={{ width: '310px' }}
              />
            </Grid>

            <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderInfo.prebookConfirmed}
                    onChange={() => changeOrderInfo('prebookConfirmed', !orderInfo.prebookConfirmed, null)}
                    color="primary"
                  />
                }
                label="Confirmed"
              />
            </Grid>

            <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={orderInfo.insuranceFaxed}
                    onChange={() => changeOrderInfo('insuranceFaxed', !orderInfo.insuranceFaxed)}
                    color="primary"
                  />
                }
                label="Insurance Faxed"
              />
            </Grid>

            <Grid item lg={3} sm={6} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Created:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  hiddenLabel
                  fullWidth
                  readOnly
                  value={`${moment(orderInfo.createDateTime).format('MM-DD-YYYY, hh:mm A')}${
                    orderInfo.createdBy ? `; ${orderInfo.createdBy.lastName}, ${orderInfo.createdBy.firstName}` : ''
                  }`}
                />
              </Grid>
            </Grid>

            <Grid item lg={3} sm={6} xs={12} className={classes.overflowHidden}>
              <Grid item xs={12}>
                <Typography>
                  <strong>Cancelled/Hold:</strong>
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  hiddenLabel
                  fullWidth
                  readOnly
                  value={`${
                    orderInfo.cancelledOrHoldDate
                      ? moment(orderInfo.cancelledOrHoldDate).format('MM-DD-YYYY, hh:mm A')
                      : ''
                  }${
                    orderInfo.cancelledOrHoldBy
                      ? `; ${orderInfo.cancelledOrHoldBy.lastName}, ${orderInfo.cancelledOrHoldBy.firstName}`
                      : ''
                  }`}
                />
              </Grid>
            </Grid>
          </Grid>
        </div>
      </>
    )
  );
};

export default withRouter(OrderInfoDetails);
