import validator from "validator";

const invalidName = (name) => {
  return /^[a-zA-Z -]+$/.test(name) ? false : true;
};

const invalidPhoneNumber = (number) => {
  // return number === null
  //   ? false
  //   : validator.isEmpty(number)
  //   ? false
  //   : true;
  return false;
};

const invalidEmail = (email) => {
  return email === null ? false : validator.isEmail(email) ? false : true;
};

const invalidAddress = (address) => {
  // if(address === null){
  //   return true;
  // }
  // const newAddress = validator.ltrim(validator.rtrim(address));
  // return newAddress.length > 0 && newAddress !== "" ? false : true;
  return false;
};

const invalidPassword = (password, email) => {
  let countCriteria = 0;
  let lengthCriteria = password.length >= 8 && password.length <= 50;
  let whiteSpaceCriteria = !/[\s]+/.test(password);
  let usernameCriteria = email !== password;
  let upperCaseCriteria = /[A-Z]+/.test(password);
  let lowerCaseCriteria = /[a-z]+/.test(password);
  let digitCriteria = /[0-9]+/.test(password);
  let specialCharacterCriteria = /[\W|_]+/.test(password);
  countCriteria += upperCaseCriteria ? 1 : 0;
  countCriteria += lowerCaseCriteria ? 1 : 0;
  countCriteria += digitCriteria ? 1 : 0;
  countCriteria += specialCharacterCriteria ? 1 : 0;
  return false;
};

export { invalidName, invalidPhoneNumber, invalidEmail, invalidAddress, invalidPassword };
