import * as orderHelpers from "../OrderTable/orderTableHelpers";
import { makeStyles } from "@material-ui/core/styles";

// returns the specific order summary data:
export const getOrder = id => {
  let orders = orderHelpers.getOrderList;
  return orders.filter(order => order.id === Number(id));
};

// order details json:
export const getOrderDetailById = id => {
  switch (Number(id)) {
    case 86:
      return {
        order: {
          id: 86,
          submissionTime: "2019-10-23T19:32:22.815112",
          serviceTime: "2019-10-23T19:32:22.815112",
          completionTime: "2019-10-23T19:32:22.815112",
          assignmentTime: "2019-10-23T19:32:22.815112",
          paraOutTime: "2019-10-23T19:32:22.815112",
          faxed: false,
          location: {
            address: "location 1",
            postalCode: "N0A, 1T0",
            mediFastOffice: false
          },
          status: "pending",
          orderedBy: "Others",
          refNum: {
            type: "APP.NO",
            number: 12345
          },
          paraPuroNum: 57893 - 86,
          internalMsg: "Internal msg - 86"
        },

        prebook: {
          type: "YES",
          msg: "PLEASE CALL BACK TO SEE IF OK OR NOT?",
          confirmed: false
        },

        lab: {
          name: "Lab name - 86",
          labNumber: 5678986,
          labPuroNum: 5678986
        },

        insurance: {
          company: "Industrial Alliance Insurance and Financial",
          type: [{ name: "Critical Illness", amount: 30.0 }]
        },

        serviceDetails: [
          {
            id: 1,
            contractor: {
              id: 1,
              name: "Contractor One",
              email: "contractor_1@email.com",
              mobile: "(211)-211-2111",
              home: "(211)-tel-2111",
              business: "(211)-bbb-2111",
              fax: "(211)-fax-2111"
            },
            serviceType: "Urine Specimen",
            IM: "",
            EM: ""
          }
        ],

        agent: {
          id: 1,
          name: "Agent One",
          email: "agent_1@email.com",
          mobile: "(111)-111-1111",
          business: { phone: "(111)-bbb-1111", ext: "353" },
          fax: "(111)-fax-1111",
          agency: "Liland Insurance Inc.",
          internalNote: {
            notes: "internal note order-86",
            orderContact: ""
          },
          remarks: "Something here...86"
        },

        client: {
          id: 1,
          firstName: "Client",
          lastName: "One",
          DOB: "06/17/1950",
          age: "70",
          gender: "Male",
          languages: [""],
          email: "client_1@email.com",
          home: "(311)-311-3111",
          mobile: "(311)-mob-3111",
          business: "",
          fax: ""
        },

        emailLogs: [
          {
            id: 1,
            sentAt: "2019-10-23T19:32:22.815112",
            subject: "",
            type: "status update",
            from: "",
            to: "agent_1@email.com"
          },
          {
            id: 2,
            sentAt: "2019-10-24T18:45:08.493002",
            subject: "",
            type: "status update",
            from: "",
            to: "agent_1@email.com"
          },
          {
            id: 3,
            sentAt: "2019-10-23T19:32:24.980348",
            subject: "",
            type: "status update",
            from: "",
            to: "client_1@email.com"
          },
          {
            id: 3,
            sentAt: "2019-10-23T19:32:24.980348",
            subject: "",
            type: "status update",
            from: "",
            to: "contractor_1@email.com"
          }
        ],

        expenses: [
          {
            id: 1,
            name: "",
            cost: "",
            comment: ""
          }
        ]
      };
    case 92:
      return {
        order: {
          id: 92,
          submissionTime: "2019-10-23T19:32:22.815112",
          serviceTime: "2019-10-23T19:32:22.815112",
          completionTime: "2019-10-23T19:32:22.815112",
          assignmentTime: "2019-10-23T19:32:22.815112",
          paraOutTime: "2019-10-23T19:32:22.815112",
          faxed: false,
          location: {
            address: "location 2",
            postalCode: "N0A, 1T0",
            mediFastOffice: false
          },
          status: "available",
          orderedBy: "Agent",
          refNum: {
            type: "APP.NO",
            number: 12345
          },
          paraPuroNum: 57893 - 92,
          internalMsg: "Internal msg - 92"
        },

        prebook: {
          type: "",
          msg: "",
          confirmed: false
        },

        lab: {
          name: "Lab name - 92",
          labNumber: 67890 - 92,
          labPuroNum: 67890 - 92
        },

        insurance: {
          company: "Industrial Alliance Insurance and Financial",
          type: [
            {
              name: "Life",
              amount: 30.0
            }
          ]
        },

        serviceDetails: [
          {
            id: 2,
            contractor: {
              id: 2,
              name: "Contractor Two",
              email: "contractor_2@email.com",
              mobile: "(222)-222-2222",
              home: "(222)-tel-2222",
              buisiness: "(222)-bbb-2222",
              fax: ""
            },
            serviceType: "Full Blood Profile",
            IM: "",
            EM: ""
          },
          {
            id: 3,
            contractor: {
              id: 3,
              name: "Contractor Three",
              email: "contractor_3@email.com",
              mobile: "(233)-233-2333",
              home: "(233)-tel-2333",
              business: "(233)-bbb-2333",
              fax: ""
            },
            serviceType: "Urine Specimen",
            IM: "",
            EM: ""
          }
        ],

        agent: {
          id: 2,
          name: "Agent Two",
          email: "agent_2@email.com",
          mobile: "(122)-122-1222",
          business: { phone: "(122)-bbb-1222", ext: "252" },
          fax: "(122)-fax-1222",
          agency: "Liland Insurance Inc.",
          internalNote: {
            notes: "internal note order-92",
            orderContact: ""
          },
          remarks: "Something here...92"
        },

        client: {
          id: 2,
          firstName: "Client",
          lastName: "Two",
          DOB: "06/17/1955",
          age: "65",
          gender: "Female",
          languages: ["English", "Spanish"],
          email: "client_2@email.com",
          home: "(322)-322-3222",
          mobile: "(322)-mob-3222",
          business: "",
          fax: ""
        },

        emailLogs: [
          {
            id: 1,
            sentAt: "2019-10-23T19:32:22.815112",
            subject: "",
            type: "status update",
            from: "",
            to: "agent_2@email.com"
          },
          {
            id: 2,
            sentAt: "2019-10-24T18:45:08.493002",
            subject: "",
            type: "status update",
            from: "",
            to: "agent_2@email.com"
          },
          {
            id: 3,
            sentAt: "2019-10-23T19:32:24.980348",
            subject: "",
            type: "status update",
            from: "",
            to: "client_2@email.com"
          },
          {
            id: 3,
            sentAt: "2019-10-23T19:32:24.980348",
            subject: "",
            type: "status update",
            from: "",
            to: "contractor_2@email.com"
          },
          {
            id: 4,
            sentAt: "2019-10-23T19:32:24.980348",
            subject: "",
            type: "status update",
            from: "",
            to: "contractor_3@email.com"
          }
        ],

        expenses: [
          {
            id: 1,
            name: "",
            cost: "",
            comment: ""
          }
        ]
      };
    default:
      return null;
  }
};

export const getPrebookOptions = {
  prebook: [
    {
      type: "YES",
      msg: "PLEASE CALL BACK TO SEE IF OK OR NOT?"
    },
    {
      type: "PREBOOKED BY AGENT",
      msg: ""
    },
    {
      type: "MSG.(APPT...)",
      msg: ""
    }
  ]
};

export const getLabList = {
  lab: [
    {
      name: "EXAMONE"
    },
    {
      name: "EXAM-Two"
    }
  ]
};

export const getInsuranceLists = {
  insuranceType: ["Life", "Critical Illness", "Disability", "Group", "Other"],
  serviceItem: ["Full Blood Profile", "Urine Specimen", "service 1", "service 2", "service3"],
  insuranceCompany: ["Industrial Alliance Insurance and Financial", "Canada Life Insurance", "Company 3"],
  lab: ["EXAMONE", "EXAM-Two"],
  agency: ["AGENCY-1", "AGENCY-2", "AGENCY-3"],
  languages: ["English", "Spanish"],
  prebook: [
    {
      type: "YES",
      msg: "PLEASE CALL BACK TO SEE IF OK OR NOT?"
    },
    {
      type: "PREBOOKED BY AGENT",
      msg: ""
    },
    {
      type: "MSG.(APPT...)",
      msg: ""
    }
  ]
};

// list of contractors:
export const getContractorList = [
  {
    id: 1,
    name: "Contractor One",
    email: "contractor_1@email.com",
    mobile: "(211)-211-2111",
    business: "(211)-bbb-2111",
    fax: ""
  },
  {
    id: 2,
    name: "Contractor Two",
    email: "contractor_2@email.com",
    mobile: "(222)-222-2222",
    business: "(222)-bbb-2222",
    fax: ""
  },
  {
    id: 3,
    name: "Contractor Three",
    email: "contractor_3@email.com",
    mobile: "(233)-233-2333",
    business: "(233)-bbb-2333",
    fax: ""
  }
];

// list of agnets:
export const getAgentList = [
  {
    id: 1,
    name: "Agent One",
    email: "agent_1@email.com",
    mobile: "(211)-211-2111",
    business: "(211)-bbb-2111",
    fax: ""
  },
  {
    id: 2,
    name: "Agent Two",
    email: "agent_2@email.com",
    mobile: "(222)-222-2222",
    business: "(222)-bbb-2222",
    fax: ""
  },
  {
    id: 3,
    name: "Agent Three",
    email: "agent_3@email.com",
    mobile: "(233)-233-2333",
    business: "(233)-bbb-2333",
    fax: ""
  }
];

// list of clients:
export const getClientList = [
  {
    id: 1,
    firstName: "Client One",
    lastName: "Last Name Client One",
    email: "client_1@email.com",
    mobile: "(211)-211-2111",
    business: "(211)-bbb-2111",
    fax: ""
  },
  {
    id: 2,
    firstName: "Client Two",
    lastName: "Last Name Client Two",
    email: "client_2@email.com",
    mobile: "(222)-222-2222",
    business: "(222)-bbb-2222",
    fax: ""
  },
  {
    id: 3,
    firstName: "Client Three",
    lastName: "Last Name Client Three",
    email: "client_3@email.com",
    mobile: "(233)-233-2333",
    business: "(233)-bbb-2333",
    fax: ""
  }
];

// // Insurance package and service types mock json:
// export const getServicePackageList = [
//   {
//     id: 55,
//     packageName: "packageOne",
//     services: ["Full Blood Profile", "Urine Specimen", "service 1"]
//   },
//   {
//     id: 66,
//     packageName: "packageTwo",
//     services: ["Full Blood Profile", "Urine Specimen", "service 1"]
//   },
//   {
//     id: 77,
//     packageName: "Life",
//     services: ["Full Blood Profile", "Urine Specimen", "service 3"]
//   }
// ];

// returns array of values for the inputed key:
export const getValOfKey = (arr, key) => {
  return arr.map(item => {
    for (let k in item) {
      if (k === key) {
        return item[k];
      }
    }
  });
};

// loop through array of objects, outputs value of inputed key as an array:
export const getValsOfKey = (arr, targetKey, name) => {
  return arr.map(item => {
    let keys = Object.keys(item);
    return keys
      .filter(key => key === targetKey)
      .map(target => {
        if (typeof item[target] !== "object") {
          return item[target];
        } else {
          let objKeys = Object.keys(item[target]);
          return objKeys.filter(objKey => objKey === name).map(name => item[target][name])[0];
        }
      })[0];
  });
};

// returns array of the matched value, where arr = array, field = key of obj in arr, val = value of obj[key], newField = target key of obj
export const getMatchVal = (arr, field, newVal, newField) => {
  let matchedArr = arr.map(item => {
    for (let key in item) {
      if (key === field && item[key] === newVal) {
        return item[newField];
      }
    }
  });

  return matchedArr.filter(val => val !== undefined);
};

// return value of key in arrary of objects:
export const getContractorName = (arr, val) => {
  return arr.map(item => {
    for (let key in item) {
      if (key === val) {
        return item[key];
      }
    }
  });
};

// return arr of obj values:
export const objToArr = obj => {
  let arr = [];
  for (let key in obj) {
    arr.push(obj[key]);
  }
  return arr;
};

// return matched list of service types:
export const getServicesByPackage = (list, name) => {
  let target = list.filter(insurancePackage => {
    return name === insurancePackage.packageName ? insurancePackage.services : null;
  });

  return target[0].services;
};

// return array without deleted item:
export const deleteArrItem = (arr, index) => {
  return arr.filter((item, i) => i !== index);
};

// replace array item at inputed index:
export const replaceArrItem = (arr, index, newVal) => {
  let output = arr;
  output.splice(index, 1, newVal);
  return output;
};

// material-ui component useStyles for order details:
export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  heading2: {
    fontSize: theme.typography.pxToRem(17),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "white"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  blueBackground: {
    backgroundColor: "#3f51b5"
  },
  unitLabel: {
    fontSize: "15px",
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    padding: "10px 5px 0px 5px"
  },
  overflowHidden: {
    overflow: "hidden"
  },
  hover: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  error: {
    borderBottom: '2px solid #f44336 !important'
  }
}));

// helper to sort through email and get latest email log for agent:
export const notifyAgent = (email, emailLogs = []) => {
  const agentEmails = emailLogs.filter(log => email === log.emailTo)
    .sort((a, b) => {
      let keyA = new Date(a.sentAt);
      let keyB = new Date(b.sentAt);
      if (keyA > keyB) {
        return -1;
      } else {
        return 1;
      }
    });

  return agentEmails.length > 0 ? agentEmails[0].sentAt : null;
};

// display array items as a string separated with comma:
export const listItemWithComma = arr => {
  let output = "";
  arr.forEach((item, index) => {
    if (index === arr.length - 1) {
      output += item;
    } else {
      output += item + ", ";
    }
  });
  return output;
};

// returns array of data for the specific inputed column name of the inputed id:
export const getColumnDataById = (datalist, id, name) => {
  let columnData = [];

  for (let data of datalist) {
    if (data.id === Number(id)) {
      for (let colName of Object.keys(data)) {
        let lettersArr = [];
        name.split("").forEach((letter, index) => {
          const arr =
            index === 0 ? lettersArr.push(letter.toLowerCase()) : letter !== " " ? lettersArr.push(letter) : null;
          return arr;
        });
        if (colName === lettersArr.join("")) {
          columnData.push(data[colName]);
        }
      }
    }
  }

  return columnData;
};

export const getListWithLinks = (req, list) => {
  return list.map(company => {
    // console.log('company:', company)
    let keys = Object.keys(company);
    let target = keys.filter(key => key === req);
    let output = {
      [req]: company[target],
      link: company._links.self.href
    };

    if (req === 'firstName') {
      output.lastName = company.lastName;
      if (company.contractorShortCode !== undefined) {
        output.shortCode = company.contractorShortCode;
        output.hstRate = company.contractorHSTRate;
        output.mileageRate = company.contractorMileageRate;
        output.referralFee = company.contractorReferralFee;
      }
    }

    if (req === 'shortCode') {
      output.name = company.name;
    }

    return output;
  });
};

export const getInsuranceCompaniesList = list => {
  return list.map(company => ({ 
    name: company.name, 
    defaultLab: company.defaultLab, 
    link: company._links.self.href,
    urineKit: company.urineKit,
    bloodKit: company.bloodKit,
    hstRate: company.hstRate,
    mileageRate: company.mileageRate,
    shortCode: company.shortCode
  }));
};

export const getTargetRole = (rolesArr, target) => {
  return rolesArr && rolesArr._embedded && rolesArr._embedded.roles.find(role => role.name === target);
};

