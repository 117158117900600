import React, { useState, useEffect, useContext } from 'react';
import * as billingHelpers from "./BillingHelpers";
import clsx from 'clsx';

import Divider from '@material-ui/core/Divider';
import PayslipHeader from './PayslipHeader';
import DefaultTable from '../DefaultTable';
import { payslipDetailColumns } from '../data/constants';
import ConfirmationDialog from './ConfirmationDialog';
import AddOrderDialog from './AddOrderDialog';
import Dialog from '@material-ui/core/Dialog';
import { updateServiceItemToPayslip, createPayslip } from '../API_calls/invoicePayslip';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import AppBar from '@material-ui/core/AppBar';
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import TextField from '@material-ui/core/TextField';
import AddBox from '@material-ui/icons/AddBox';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import { InvoicePayslipDataContext } from '../../Context';
import BillingDrawer from './BillingDrawer';
import ReportWrapper from '../ReportWrapper';
import PayslipPdf from './pdfTemplates/PayslipPdf';


const TabPanel = ({ children, value, index }) => {

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}>
      <Box
        style={{
          padding: "10px",
          overflow: "auto",
          margin: "5px",
          backgroundColor: "#dee0df"
        }}
      >
        {children}
      </Box>
    </Typography>
  );
};


const PayslipDetails = ({ details, setDetails, created, upToDate, setMode, invoicePayslipId, history, ...props }) => {

  const { setInvoicePayslipData } = useContext(InvoicePayslipDataContext);

  const classes = billingHelpers.useStylesInvoice();

  const [itemsList, setItemsList] = useState([]);

  const [beforeCreate, setBeforeCreate] = useState(!created);
  const [pageSize, setPageSize] = useState(50);

  const [open, setOpen] = useState(true);
  const [tabVal, setTabVal] = useState(0);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [addOrderDialogOpen, setAddOrderDialogOpen] = useState(false);
  const [pdfViewerOpen, setPdfViewerOpen] = useState(false);

  // DEBUGGING
  useEffect(() => {
    console.log('details:', details)
  
  }, [details]);

  useEffect(() => {
    console.log('itemsList:', itemsList)
  
  }, [itemsList]);

  useEffect(() => {

    const services = details.serviceItems.map(item => ({
      order: item.order,
      serviceExpenseName: item.serviceType.name,
      serviceExpenseCharge: item.contractorAmount,
      link: item._links.self.href.split('{')[0]
    }));

    const mileage = details.serviceItems.map(item => ({
      order: item.order,
      serviceExpenseName: 'Mileage',
      serviceExpenseCharge: item.mileageAmount,
      serviceItemLink: item._links.self.href.split('{')[0]
    }));

    const expenses = details.mobileExpenses.map(expense => ({
      order: expense.order,
      serviceExpenseName: expense.mobileExpenseType.name,
      serviceExpenseCharge: expense.amount,
    }));

    setItemsList([...services, ...mileage, ...expenses]);
  
  }, [beforeCreate, details.serviceItems, details.mobileExpenses, upToDate]);

  // TABLE FUNCTIONS
  const delFunction = async oldData => {
    console.log('oldData:', oldData)
    try {
      const itemsArray = details.serviceItems
        .map(item => item._links.self.href.split('{')[0])
        .filter(itemLink => itemLink !== oldData.link);
      console.log('itemsArray:', itemsArray);
      const res = await updateServiceItemToPayslip(details._links.self.href.split('{')[0], itemsArray);

      const updatedItems = details.serviceItems.filter(item => item._links.self.href.split('{')[0] !== oldData.link);

      setDetails(prev => ({ ...prev, ...res, serviceItems: updatedItems }));
      setInvoicePayslipData(prev => prev.map(payslip => payslip.payslipID === details.payslipID ? { ...details, ...res, serviceItems: updatedItems } : payslip));
    } catch (e) {
      console.log(e);
    }
  };

  const addFunction = async order => {
    console.log('order:', order)
    try {
      const currItems = details.serviceItems.map(item => item._links.self.href.split('{')[0]);
      const newItems = order.serviceItems.filter(item => item.user.username === details.serviceItems[0].user.username)
      const res = await updateServiceItemToPayslip(details._links.self.href.split('{')[0], [...currItems, ...newItems.map(item => item._links.self.href.split('{')[0])]);
      console.log('res:', res)

      const updatedItems = [...details.serviceItems, ...newItems.map(item => ({...item, order: order }))];

      setDetails(prev => ({ ...prev, ...res, serviceItems: updatedItems }));
      setInvoicePayslipData(prev => prev.map(payslip => payslip.payslipID === details.payslipId ? { ...details, ...res, serviceItems: updatedItems } : payslip));

      setAddOrderDialogOpen(false);
    } catch (err) {
      console.log(err);
    }
  };

  const actions = [
    {
      icon: () => <AddBox />,
      tooltip: "Add",
      isFreeAction: true,
      onClick: (event) => {
        setAddOrderDialogOpen(true);
      }
    },
    rowData => ({
      icon: () => <DeleteOutline />,
      tooltip: "Delete",
      onClick: (event, rowData) => delFunction(rowData),
      hidden: beforeCreate || details.serviceItems.length <=1 || !rowData.link
    }),
  ];

  // API FUNCTIONS
  const confirmPayslip = async notes => {
    try {
      let newPayslip = {
        notes: notes,
        payslipDate: moment(),
        upToPeriod: moment(upToDate),
        contractor: details._links.self.href,
        serviceItems: itemsList.map(item => item.link).filter(link => link)
      };
      const res = await createPayslip(newPayslip);
      console.log('res:', res)
      setDetails(prev => ({
        ...prev, 
        notes: res.notes, 
        payslipID: res.payslipID, 
        upToPeriod: res.upToPeriod,
        payslipDate: res.payslipDate,
        _links: res._links
      }));
      setBeforeCreate(prev => !prev);
      setOpenConfirmationDialog(false);
    } catch (err) {
      console.log(err);
    }
  };

  // BUTTON COMPONENTS
  const payslipButtonCheck = () => {
     let payslipCheck = false;
      for (const fee of itemsList) {
       if (fee.serviceExpenseName !== "Mileage" && fee.serviceExpenseCharge === null) {
         payslipCheck = true;
         break
      } 
      }
      return payslipCheck;
  }

  const PayslipButtons = () => {
    return (
      beforeCreate ? 
        <div style={{ padding: "10px 0px 0px 0px", width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setOpenConfirmationDialog(true)}
                color="primary"
                disabled={payslipButtonCheck()}
              >
                Create Payslip
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setMode('SUMMARY')}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div> :
        <div style={{ padding: "10px 0px 0px 0px", width: "100%" }}>
          <Grid container spacing={1}>
            <Grid item>
              <Button variant="contained" onClick={() => setPdfViewerOpen(true)} color="primary">
                Open PDF
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                onClick={() => setMode('SUMMARY')}
                color="primary"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
    );
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        color="default"
      >
        <Grid container>
          <Grid item xs={11}>
            <Tabs
              value={tabVal}
              onChange={(e, newVal) => setTabVal(newVal)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label={beforeCreate ? "Create Payslip" : 'Payslip Details'} />
            </Tabs>
          </Grid>
          <Grid item container xs={1} justify="flex-end">
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge={false}
              onClick={() => setOpen(true)}
              className={clsx(open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
        </Grid>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
        style={{ marginRight: '77px'}}
      >
        <TabPanel value={tabVal} index={0}>
          <PayslipHeader beforeCreate={beforeCreate} details={details} setDetails={setDetails} upToDate={upToDate} />

          <div style={{ marginBottom: "15px" }}>
            <Divider style={{ margin: "15px 0px 10px 0px" }} />
            <DefaultTable
              onRowClick={!beforeCreate ? (e, rowData) => history.push(`/orders/${rowData.order.orderID}`) : null}
              title={""}
              inputColumns={payslipDetailColumns}
              inputData={itemsList}
              pageSize={pageSize}
              setPageSize={setPageSize}
              // deleteFunction={!beforeCreate ? delFunction : null}
              // addFunction={!beforeCreate ? addFunction : null}
              actions={!beforeCreate ? actions : []}
            />
            <Divider style={{ margin: "15px 0px 10px 0px" }} />
            {!beforeCreate && <TextField 
              readOnly
              value={details.notes ? details.notes : ''}
              fullWidth
              label='Notes:'
            />}
          </div>

          <PayslipButtons />
        </TabPanel>
      </main>

      <BillingDrawer 
        open={open} 
        setOpen={setOpen} 
        setDetails={setDetails} 
        currentId={beforeCreate ? details.username : details.payslipID}
        idSource={beforeCreate ? 'username' : 'payslipID'} 
      />

      <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)} maxWidth="sm" fullWidth>
        <ConfirmationDialog
          confirmationDialogClose={() => setOpenConfirmationDialog(false)}
          confirmCreate={confirmPayslip}
          details={details}
          upToDate={upToDate}
          title={'Create Payslip'}
          source="payslip"
        />
      </Dialog>

      <Dialog open={addOrderDialogOpen} onClose={() => setAddOrderDialogOpen(false)} maxWidth="lg" fullWidth>
        <AddOrderDialog
          // invoicePayslipId={invoicePayslipId}
          details={details}
          setDetails={setDetails}
          setAddOrderDialogOpen={setAddOrderDialogOpen}
          addFunction={addFunction}
        />
      </Dialog>

      <Dialog open={pdfViewerOpen} onClose={() => setPdfViewerOpen(false)} fullScreen>
        <ReportWrapper title='Payslip PDF' closeDialog={() => setPdfViewerOpen(false)} orientation='landscape'>
          <PayslipPdf details={details} itemsList={itemsList} />
        </ReportWrapper>
      </Dialog>
    </div>
  );
};

export default PayslipDetails;