import React from "react";
import Input from "@material-ui/core/Input";
import classNames from "classnames";

import "./InputField.scss";

const InputField = ({
  error = false,
  required = false,
  disabled = false,
  fullWidth = true,
  multiline = false,
  name,
  onChange,
  placeholder,
  readOnly = false,
  type = "text",
  label,
  value,
  numRows,
  accept = "",
  files,
  id,
  ...props
}) => {
  const classname = classNames({
    input: true
  });
  return (
    <Input
      data-cy={name}
      label={label}
      error={error}
      required={required}
      disabled={disabled}
      fullWidth={fullWidth}
      multiline={multiline}
      name={name}
      readOnly={readOnly}
      type={type}
      value={value}
      className={classname}
      onChange={onChange}
      placeholder={placeholder}
      color="primary"
      disableUnderline={true}
      rows={numRows}
      // onClick={e => console.log(e)}
      // inputLabelProps={{
      //   shrink: true
      // }}
      inputProps={{ accept: accept, multiple: true, files: files }}
    />
  );
};

export default InputField;
