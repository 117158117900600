import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "../components/DefaultTable";
import FeeScheduleHeader from "../components/FeeScheduleHeader";

import { contractorFeeColumns } from "../components/data/constants";
import {
  createContractorFee,
  updateContractorFee_base,
  searchContractorFee,
  deleteContractorFee_base
} from "../components/API_calls/contractorFees";
import { GlobalNameListContext } from "../Context";

import PopUp from "../components/PopUp";


const ContractorFeeSchedule = ({ ...props }) => {

  const { globalNameList } = useContext(GlobalNameListContext);

  // const [contractorList, setContractorList] = useState([]);
  const [selectedContractor, setSelectedContractor] = useState(null);

  // TABLE STATES
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  // for popup
  const [errorMsg, setErrorMsg] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("");

  //filtering through shortCodes of mobileServiceType, adding to options only if it doesn't exist
  useEffect(() => {
    if (globalNameList) {    
          let newMobileServiceList = {};
          let newMobileService = {}
          for (const service of info) {
            newMobileService[service.mobileServiceType.shortCode] = true
          }
          for (const serviceType of globalNameList.mobileServiceTypes) {
            if (!newMobileService[serviceType.shortCode]) {
              newMobileServiceList[serviceType.link] = `${serviceType.shortCode} - ${serviceType.name}`;
            }
          }
          contractorFeeColumns[1].lookup = newMobileServiceList;
    }
  }, [globalNameList, info]);

  const changeContractor = val => {
    if (val) {
      setSelectedContractor(prev => ({ ...prev, ...val }));
      searchContractorFee(val.link)
      .then((res) => {

        if (globalNameList) {

          const initializeData = async () => {
            let newMobileServiceList = {};
            let newMobileService = {};
            for (const service of res) {
              newMobileService[service.mobileServiceType.shortCode] = true
            }
            for (const serviceType of globalNameList.mobileServiceTypes) {
              if (!newMobileService[serviceType.shortCode]) {
                newMobileServiceList[serviceType.link] = `${serviceType.shortCode} - ${serviceType.name}`;
              }
            }
            contractorFeeColumns[1].lookup = newMobileServiceList;
            setInfo(res)
          };
          initializeData();
        }
        })
      .catch((e) => setInfo([]));
  } else {
    setSelectedContractor(null);
  }  };

  const addFunction = async (newData) => {

    if (newData.mobileServiceLink && newData.charge>0){
      setIsLoading(true);

      try {
        const res = await createContractorFee({
          contractor: selectedContractor.link,
          mobileServiceType: newData.mobileServiceLink,
          charge: newData.charge
        });
        setInfo([res, ...info]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }

    } else {
      setErrorMsg(`Error: Fill out all fields`);
      setPopUpType("error");
      setPopUpStatus(new Date());
      setIsLoading(false);
    }

    
  };

  const deleteFunction = async (oldData) => {
    
    setIsLoading(true);
    try {
      await deleteContractorFee_base(oldData._links.self.href);
      const data = info.filter((currData) => currData.tableData.id !== oldData.tableData.id);
      setInfo(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {


    if (newData.mobileServiceLink && newData.charge>0){

        setIsLoading(true);
        try {
          await updateContractorFee_base(oldData._links.self.href, {
            mobileServiceType: newData.mobileServiceLink,
            charge: newData.charge
          });
          const data = info.map((currData) =>
            currData.tableData.id === oldData.tableData.id ? newData : currData
          );
          setInfo(data);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
    } else {
      setErrorMsg(`Error: Fill out all fields`);
      setPopUpType("error");
      setPopUpStatus(new Date());
      setIsLoading(false);
    }
  };

  return (
    <>
      <FeeScheduleHeader 
        title="Required: Select A Contractor"
        value={selectedContractor}
        onChange={changeContractor}
        name="contractor"
        options={globalNameList.contractors}
        labelField="firstName"
        idField="id"
      />
      <DefaultTable
        title="Contractor Fee Schedule"
        inputColumns={contractorFeeColumns}
        inputData={info}
        addFunction={selectedContractor ? addFunction : false}
        updateFunction={updateFunction}
        deleteFunction={deleteFunction}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      {popUpStatus ? (
        <PopUp type={popUpType} message={errorMsg} status={popUpStatus} />
      ) : null}
    </>
  );
};

export default ContractorFeeSchedule;
