import React from 'react';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generateKey } from '../../utils/helpers';

const InvoiceSummary = ({ summaryDetails, ...props}) => {

  const columnHeaders = ['Qty', 'Code', 'Description', 'Unit Cost', 'Total'];

  const tableColumns = columnHeaders.map((header, index) => {
    return (
      <TableCell key={generateKey()} align="center">
        {header}
      </TableCell>
    );
  });

  const tableRows = summaryDetails.tableData.map((item, index) => {
    return (
      <TableRow key={generateKey()}>
        <TableCell align="center">
          {item.qty}
        </TableCell>
        <TableCell align="center">
          {item.code}
        </TableCell>
        <TableCell align="center">
          {item.description}
        </TableCell>
        <TableCell align="center">
          {item.unitCost ? `$${item.unitCost}` : ''}
        </TableCell>
        <TableCell align="center">
          ${item.total}
        </TableCell>
      </TableRow>
    );
  });
  
  return (
    <div className='invoice-table-container'>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {tableColumns}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows}
          <TableRow>
            <TableCell colSpan={4} align='right' variant='head'>Total Service Charge</TableCell>
            <TableCell colSpan={1} align='right'>${summaryDetails.serviceChargeTotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} align='right' variant='head'>HST</TableCell>
            <TableCell colSpan={1} align='right'>${summaryDetails.hstTotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} align='right' variant='head'>Grand Total</TableCell>
            <TableCell colSpan={1} align='right'>${summaryDetails.grandTotal}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell colSpan={4} align='right'/>
            <TableCell colSpan={1} align='right'>HST Reg. No. 891896649 RT</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default InvoiceSummary;