import React from "react";
import TextField from "@material-ui/core/TextField";

import "./SelectField.scss";

const SelectField = ({
  type = "text",
  name,
  label,
  placeholder,
  value,
  changeHandler,
  errorMsg,
  disabled = false,
  ...props
}) => {
  return (
    <div className="container">
      <TextField
        InputProps={{
          readOnly: disabled
        }}
        type="select"
        select
        name={name}
        error={errorMsg ? true : false}
        className="textField"
        value={value}
        onChange={changeHandler}
        id={name}
        label={label}
        placeholder={placeholder}
        helperText={errorMsg}
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
      >
        {props.children}
      </TextField>
    </div>
  );
};

export default SelectField;
