import instance from '../utils/API';
import instance_base from '../utils/API_base';

//get latest order
const getLatestOrder = async () => {
  try {
    const res = await instance
      .get('/orders', {
        params: {
          size: 1,
          sort: 'createDateTime,desc',
        },
      })
      .then((res) => res.data);
    return res._embedded.orders[0];
  } catch (error) {
    throw error;
  }
};

//get all orders
const getAllOrders = async (page = 0, fetchSize = 50) => {
  try {
    const res = await instance
      .get('/orders', {
        params: {
          page,
          size: fetchSize,
          sort: 'createDateTime,desc',
        },
      })
      .then((res) => res.data);
    return res._embedded.orders;
  } catch (error) {
    throw error;
  }
};

const getOrderIDsByPage = async (page = 0, fetchSize = 50) => {
  try {
    const res = await instance
      .get('/orders', {
        params: {
          page,
          size: fetchSize,
          sort: 'createDateTime,desc',
          projection: 'orderid',
        },
      })
      .then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const getAllOrdersObj = async () => {
  try {
    const res = await instance.get('/orders').then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//get specific order
const getOrder = async (orderId) => {
  try {
    // TODO - when orderID is changed on API, remove the slice
    const res = await instance.get(`/orders/${orderId.slice(1)}`).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const getOrder_base = async (orderLink) => {
  try {
    const res = await instance_base.get(orderLink);
    return res.data;
  } catch (error) {
    throw error;
  }
};

//create order
const createOrder = async (orderInfo) => {
  try {
    const res = await instance.post('/orders', orderInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const updateOrder_base = async (url, orderInfo) => {
  try {
    const res = await instance_base.patch(url, orderInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const updateInsuranceCompany_base = async (companyInfo, id) => {
  try {
    const res = await instance_base.patch(id, companyInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const getOrders_base = async (id) => {
  try {
    const res = await instance_base.get(id).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//update order
const updateOrder = async (orderId, orderInfo) => {
  try {
    const res = await instance.put(`/orders/${orderId}`, orderInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//delete order
const deleteOrder = async (orderId) => {
  try {
    const res = await instance.delete(`/orders/${orderId}`).then((res) => res);
    return res;
  } catch (error) {
    throw error;
  }
};

const getUser_base = async (url) => {
  try {
    const res = await instance_base.get(url).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const addOrderInsuranceCompanyRelation_base = async (url, link) => {
  try {
    const res = instance_base.put(url, link, {
      headers: {
        'content-type': 'text/uri-list',
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

const addOrderUser = async (orderId, link, relation) => {
  try {
    const res = await instance.put(`/orders/${orderId}/${relation}`, link, {
      headers: {
        'content-type': 'text/uri-list',
      },
    });
    return res;
  } catch (error) {
    throw error;
  }
};

const addOrderAgent_base = async (url, link) => {
  try {
    const res = await instance_base.put(url, link, {
      headers: {
        'content-type': 'text/uri-list',
      },
    });
    return res;
  } catch (err) {
    throw err;
  }
};

const getInsuranceAgencyPair = async (url) => {
  const res = await instance.get(url).then((res) => res.data);
  return res;
};

const getRoles = async () => {
  try {
    const res = await instance.get('/roles').then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const getSearchedUsers = async (data) => {
  try {
    const res = await instance
      .get('/users/search/multiParam', {
        params: { ...data },
      })
      .then((res) => res.data);

    return res;
  } catch (error) {
    throw error;
  }
};

const getMobileServiceTypes = async () => {
  try {
    const res = await instance.get('/mobileServiceTypes').then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const createServiceItem = async (data) => {
  try {
    const res = await instance.post('/serviceItems', data).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const updateServiceItem_base = async (url, data) => {
  try {
    const res = await instance_base.patch(url, data).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const deleteServiceItem_base = async (url) => {
  try {
    const res = await instance_base.delete(url).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const searchOrders = async (params, page = 0) => {
  try {
    const res = await instance.get('/orders/search/multiParam', {
      params: {
        ...params,
        sort: 'createDateTime,desc',
      },
    });
    return res.data._embedded.orders;
  } catch (error) {
    throw error;
  }
};

export {
  getLatestOrder,
  getAllOrders,
  getOrderIDsByPage,
  getOrder,
  getOrder_base,
  createOrder,
  updateOrder,
  deleteOrder,
  getUser_base,
  updateOrder_base,
  updateInsuranceCompany_base,
  getOrders_base,
  getAllOrdersObj,
  addOrderInsuranceCompanyRelation_base,
  addOrderUser,
  addOrderAgent_base,
  getSearchedUsers,
  getRoles,
  getInsuranceAgencyPair,
  getMobileServiceTypes,
  createServiceItem,
  updateServiceItem_base,
  deleteServiceItem_base,
  searchOrders,
};
