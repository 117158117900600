import {
  // lightBlue,
  indigo,
  red,
  purple,
  pink,
  orange,
  blue,
  green,
  // yellow,
  grey,
  // lime
} from "@material-ui/core/colors";

const themeTemplates = {
  Default: { main: indigo, secondary: red },
  omega: { main: red, secondary: purple },
  alpha: { main: pink, secondary: orange },
  delta: { main: blue, secondary: green },
  shadow: { main: grey, secondary: grey }
};

export { themeTemplates };
