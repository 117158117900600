import React, { useState, useEffect, useContext } from 'react';
import * as billingHelpers from './BillingHelpers';
import clsx from 'clsx';

import DateTimePicker from '../OrderDetails/DateTimePicker';
import SimpleButton from '../OrderDetails/SimpleButton';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import DefaultTable from '../DefaultTable';
import { orderColumns } from '../data/constants';
import { searchOrders } from '../API_calls/orders';
import AutoCompleteDropDown from '../OrderDetails/AutoCompletes/AutoCompleteDropDown';
import { GlobalNameListContext } from '../../Context';

const AddOrderDialog = ({ source, addFunction, setAddOrderDialogOpen, details, setDetails, ...props }) => {
  const { globalNameList } = useContext(GlobalNameListContext);

  const classes = billingHelpers.useStyles();

  const [searchField, setSearchField] = useState({});
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [pageSize, setPageSize] = useState(10);

  const getOrders = async (params) => {
    try {
      params =
        source === 'invoice'
          ? { ...params, insuranceCompany: details.insuranceCompany._links.self.href.split('{')[0] }
          : { ...params, contractor: details.serviceItems[0].user._links.self.href.split('{')[0] };
      const res = await searchOrders(params);
      setOrders(res);
    } catch (err) {
      console.log(err);
    }
  };

  const changeDate = (val, setCurrentTime, source) => {
    setCurrentTime(val);
    setSearchField((prev) => ({ ...prev, [source]: val.format() }));
  };

  const onRowClick = (event, rowData) => {
    const data = orders.map((currData) => {
      if (currData.tableData.id === rowData.tableData.id) {
        const newRow = currData;
        newRow.tableData.clicked = true;
        return newRow;
      } else {
        const oldRow = currData;
        oldRow.tableData.clicked = false;
        return oldRow;
      }
    });
    setSelectedOrder(rowData);
    setOrders(data);
  };

  return (
    <>
      <DialogTitle id="alert-dialog-title">{source === 'invoice' ? 'Add Order' : 'Add Service Item'}</DialogTitle>

      <DialogContent>
        <Grid container spacing={1} style={{ margin: '10px 0' }}>
          <Grid item container lg={11} sm={12} xs={12} spacing={1}>
            <Grid item lg={3} sm={6} xs={12} style={{ padding: '0 10px' }}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Order ID:"
                fullWidth
                value={searchField.medifastID ? searchField.medifastID : ''}
                // error={error.error}
                // helperText={error.msg}
                onChange={(e) => setSearchField((prev) => ({ ...prev, medifastID: e.target.value }))}
              />
            </Grid>
            {source !== 'invoice' && (
              <Grid item lg={3} sm={6} xs={12} style={{ padding: '0 10px' }}>
                <AutoCompleteDropDown
                  onChange={(e, newVal) => setSearchField((prev) => ({ ...prev, insuranceCompany: newVal }))}
                  dropDownData={globalNameList.insuranceCompanies}
                  dropDownVal="name"
                  renderItems={
                    searchField.insuranceCompany
                      ? [searchField.insuranceCompany]
                      : [{ name: '', link: '', defaultLab: '' }]
                  }
                  enableDelete={false}
                  label="Insurance Company:"
                />
              </Grid>
            )}
            <Grid item lg={3} sm={6} xs={12} style={{ padding: '0 10px' }}>
              <DateTimePicker
                source="fromDate"
                fromDate={searchField.fromDate ? searchField.fromDate : null}
                label="Completed Date From:"
                changeDate={changeDate}
              />
            </Grid>
            <Grid item lg={3} sm={6} xs={12} style={{ padding: '0 10px' }}>
              <DateTimePicker
                source="toDate"
                toDate={searchField.toDate ? searchField.toDate : null}
                label="Completed Date To:"
                changeDate={changeDate}
              />
            </Grid>
          </Grid>

          <Grid item container lg={1} sm={12} xs={12} style={{ marginTop: '20px', textAlign: 'center' }}>
            <Grid item xs={12}>
              <SimpleButton onClick={() => getOrders(searchField)} text={'SEARCH'} icon={<SearchIcon />} />
            </Grid>
          </Grid>
        </Grid>
        <Divider />

        <DefaultTable
          title="Order List"
          inputColumns={orderColumns}
          inputData={orders}
          pageSize={pageSize}
          setPageSize={setPageSize}
          onRowClick={onRowClick}
          rowStyle={(data, index) => {
            if (data.tableData.clicked) {
              return { backgroundColor: 'darkgrey', color: 'white' };
            }
          }}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={() => addFunction(selectedOrder)} color="primary">
          Add Order
        </Button>
        <Button onClick={() => setAddOrderDialogOpen(false)} color="primary" autoFocus>
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default AddOrderDialog;
