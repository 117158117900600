import React from "react";
import doStuff from "../components/utils/seed";
import instance_base from "../components/utils/API_base";
import moment from 'moment';
import { generateKey } from '../components/utils/helpers';

import {
  getAllUsers,
  register,
  getUser,
  updateUser,
  deleteUser
} from "../components/API_calls/users";
import {
  getLatestOrder,
  getAllOrders,
  getOrder,
  createOrder,
  deleteOrder,
  createServiceItem
} from "../components/API_calls/orders";
import { createInsuranceCompany } from "../components/API_calls/insuranceCompanies";

const Footer = () => {
  const id = 29;
  const orderId = 268;

  const tryDeleteOrder = async (orderId) => {
    try {
      const res = await deleteOrder(orderId);
      // console.log("in here:", res);
    } catch (err) {
      console.log("this is the custom thrown error", err);
    }
  };

  const addLink = async () => {
    instance_base.put(
      "https://qa.api.gigeco.net:8080/api/v1/orders/550/insuranceCompany",
      "https://qa.api.gigeco.net:8080/api/v1/insuranceCompanies/183",
      {
        headers: {
          "content-type": "text/uri-list"
        }
      }
    );
  };

  return (
    <div>
      <div>
        <button onClick={getAllUsers}>get all users</button>
        <button
          onClick={() =>
            register({
              firstName: generateKey(),
              lastName: generateKey(),
              email: `${generateKey()}@fakeemail.com`,
              username: `${generateKey()}@fakeemail.com`,
              password: "P@ssword",
              role: "https://dev.api.gigeco.net:8080/api/v1/roles/755"
            })
          }
        >
          register
        </button>
        <button onClick={() => getUser(id)}>get user</button>
        <button
          onClick={() =>
            updateUser(id, {
              firstName: "tyler123",
              lastName: "caceres123",
              password: "password123",
              email: "tyler123@gmail.com"
            })
          }
        >
          update user
        </button>
        <button onClick={() => deleteUser(id)}>delete user</button>
      </div>
      <div>
        <button onClick={getAllOrders}>get all orders</button>
        <button onClick={() => getOrder(orderId)}>get order</button>
        <button onClick={getLatestOrder}>get latest order</button>
        <button
          onClick={() =>
            createOrder({
              agent: 'https://dev.api.gigeco.net:8080/api/v1/users/762',
              agency: null,
              tempAgency: 'temp',
              assignmentTime: moment().format(),
              clientInfo:	{
                firstName: 'Bob',
                lastName: 'Smith',
                gender: null,
                dob: null,
                mobile: '123',
                home: '123',
                business: '123',
                fax: '',
                languages: [],
                email: '12345@gmail.com',
                age: '',
              },
              completionTime: null,
              createDateTime: moment().format(),
              examLocation: {
                clientLocationAddress: '',
                clientLocationPostalCode: '',
                clientLocationMediFastOffice: true
              },
              insuranceCompany: '',
              insuranceFaxed: false,
              insuranceItems: [],
              internalMsg: 'asdf',
              lab: {
                labName: "abc",
                labNumber: "dsf",
                labPuroNum: "sdfds"
              },
              orderLocation: 'Toronto',
              orderedBy: '',
              paidBy: 'COMPANY',
              paraOutTime: '',
              paraPuroNum: '',
              prebook: false,
              prebookConfirmed: false,
              refNum: '',
              referredBy: '',
              serviceTime: null,
              specialTests: [],
              status: "Assignment",
              updateDateTime: moment().format()
          })}
        >
          create order
        </button>
        <button onClick={() => tryDeleteOrder(orderId)}>delete order</button>
        <button onClick={() => doStuff()}>dostuff</button>
        <button
          onClick={() =>
            createInsuranceCompany({
              address: `fake address ${generateKey()}`,
              name: `fake name ${generateKey()}`,
            })
          }
        >
          create company
        </button>
        <button
          onClick={() =>
            createServiceItem({
              serviceType: `fake serviceType ${generateKey()}`
            })
          }
        >
          create service item
        </button>
        <button onClick={addLink}>add link to insurance company</button>
      </div>
    </div>
  );
};

export default Footer;
