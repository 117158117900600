import instance from "../utils/API";
import instance_base from "../utils/API_base";
import transform from "mass-data-transform";

const createContractorFee = async (info) => {
  try {
    const res = await instance.post("/contractorFees", info).then((res) => res.data);
    return {
      charge: res.charge,
      mobileServiceLink: res._embedded.mobileServiceType._links.self.href.replace(
        "{?projection}",
        ""
      ),
      mobileServiceType: { ...res._embedded.mobileServiceType },
      _links: { self: { href: res._links.self.href } }
    };
  } catch (error) {
    throw error;
  }
};

const updateContractorFee_base = async (url, updatedInfo) => {
  try {
    const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const deleteContractorFee_base = async (url) => {
  try {
    await instance_base.delete(url);
  } catch (error) {
    throw error;
  }
};

const searchContractorFee = async (contractorLink) => {
  try {
    const res = await instance
      .get("/contractorFees/search/findByContractor", {
        params: { contractor: contractorLink, page: 0, size: 1000, sort: "createDateTime,desc" }
      })
      .then((res) => res.data);
    const transformedData = transform(res._embedded.contractorFees, {
      addFields: {
        mobileServiceLink: (r) => r.mobileServiceType._links.self.href.replace("{?projection}", "")
      }
    });
    return transformedData;
  } catch (error) {
    throw error;
  }
};

export {
  createContractorFee,
  updateContractorFee_base,
  searchContractorFee,
  deleteContractorFee_base
};
