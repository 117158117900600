import React, { useState, useEffect } from "react";

import DetailHeader from "./DetailHeader";
import DetailFooter from "./DetailFooter";
import Required from "../basic-components/Required";

import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ReportLabel from "../ReportLabel";
import BasicInput from "../basic-components/BasicInput";
import BasicTextarea from "../basic-components/BasicTextarea";
import BasicCheckbox from "../basic-components/BasicCheckbox";
import TripleInputTele from "../basic-components/TripleInputTele";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { roundTo } from "../utils/helpers";

import { getInsuranceCompany_base } from "../API_calls/insuranceCompanies";
import { generateKey, normalizePhone2 } from "../utils/helpers";
import validator from "validator";

const InsuranceCompanyDetails = ({
  handleClose,
  handleSave = null,
  handleCreate = null,
  details,
  labList,
  type,
  insuranceCompanyUsers,
  footerMsg,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [phone3Box, setPhone3Box] = useState({});
  const [formatPhone, setFormatPhone] = useState("");

  let validMobileTel = values.tel
  ? values.tel.length === 12
  : false; 

  // useEffect(() => {
  //   console.log('values:', values)
  //   console.log('insuranceCompanyUsers:', insuranceCompanyUsers);

  // }, [values]);

  useEffect(() => {
    // setValues(details);
    if (details) {
      if (type === "add") {
        setValues((prev) => ({ ...prev, enabled: true }));
      } else {
        getInsuranceCompany_base(details._links.self.href).then((res) => {
          res.hstRate = roundTo(res.hstRate * 100, 2);
          console.log(res)
          let telSplit;
          let faxSplit;

          if (res.tel){
            res.tel=normalizePhone2(res.tel)
            telSplit=res.tel.split("-")
          } else telSplit = ""

          if (res.fax){
            res.fax=normalizePhone2(res.fax)
            faxSplit = res.fax.split("-")
          } else faxSplit = ""

          
          setValues((prev) => ({
            ...res,
            adminUser:
              res._embedded && res._embedded.adminUser
                ? res._embedded.adminUser._links.self.href.split("{")[0]
                : "",
          }));

          // const telSplit = res.tel.split("-");

          // let faxSplit;

          // if (res.fax) {
          //   faxSplit = res.fax.split("-");
          // } else faxSplit = "";

          setPhone3Box({
            tel1: telSplit[0],
            tel2: telSplit[1],
            tel3: telSplit[2],
            fax1: faxSplit[0],
            fax2: faxSplit[1],
            fax3: faxSplit[2],
          });
        });
      }
    }
  }, [details, type]);

  const changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const selectHandler = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const removeWords = (arr) => {
    const finalOP = [];
    if (arr.length === 0) {
      return arr;
    }
    if (!arr[0][1] && !arr[1][1] && !arr[2][1]) {
      return "";
    }

    if (arr.length >= 1) {
      finalOP.push(arr[0][1]);
    }
    if (arr.length >= 2) {
      finalOP.push(arr[1][1]);
    }
    if (arr.length === 3) {
      finalOP.push(arr[2][1]);
    }
    return finalOP.join("-");
  };

  useEffect(() => {
    const formatPhoneNumber = Object.entries(phone3Box).filter((element) => {
      if (element[0].startsWith(phone3Box.type)) {
        return element;
      }
    });
    const numbersOnly = removeWords(formatPhoneNumber);
    setFormatPhone({ type: phone3Box.type, formattedNum: numbersOnly });
  }, [phone3Box]);

  useEffect(() => {
    setValues({ ...values, [formatPhone.type]: formatPhone.formattedNum });
  }, [formatPhone]);

  const changeHandlerPhone = (e) => {
    setPhone3Box({
      ...phone3Box,
      type: e.target.name.slice(0, -1),
      [e.target.name]: e.target.value.replace(/\D/g, ""),
    });
  };

  return (
    <>
      <DetailHeader title="Insurance Company details" />
      <DialogContent dividers={true}>
        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                ShortCode:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="shortCode"
                value={values.shortCode}
                error={errors.shortCode}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Billing Attention:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="billingAttention"
                value={values.billingAttention}
                error={errors.billingAttention}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="name"
                value={values.name}
                error={errors.name}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Billing Email:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="billingEmail"
                value={values.billingEmail}
                error={
                  values.billingEmail
                    ? !validator.isEmail(values.billingEmail)
                    : false
                }
                errorMsg="Not a valid email address"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Mileage Rate:
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item">
              <BasicInput
                type="number"
                name="mileageRate"
                value={values.mileageRate}
                error={errors.mileageRate}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                type="checkbox"
                name="isLabCode"
                checked={values.isLabCode}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">
                Allow to provide lab code# to agency or agents?
              </ReportLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                HST Rate (%):
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item">
              <BasicInput
                type="number"
                name="hstRate"
                value={values.hstRate}
                error={errors.hstRate}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              {""}
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                type="checkbox"
                name="requireAppNo"
                checked={values.requireAppNo}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">Require App. No.</ReportLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Default Lab:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <Select
                style={{ width: "100%" }}
                onChange={(e) => selectHandler("defaultLab", e)}
                value={values.defaultLab ? values.defaultLab : ""}
              >
                {labList.map((lab) => (
                  <MenuItem key={generateKey()} value={lab}>
                    {lab}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              {""}
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                type="checkbox"
                name="requireInsAmt"
                checked={values.requireInsAmt}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">Require Ins. Amt.</ReportLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Enabled:
              </ReportLabel>
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                name="enabled"
                checked={values.enabled}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Tel:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="tel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Fax:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="fax"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Remarks:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="remarks"
                value={values.remarks}
                error={errors.remarks}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Address:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="address"
                value={values.address}
                error={errors.address}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                City:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="city"
                rows={1}
                value={values.city}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Province:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="province"
                rows={1}
                value={values.province}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Postal Code:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="postalCode"
                rows={1}
                value={values.postalCode}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Insurance Company User:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <Select
                style={{ width: "100%" }}
                onChange={(e) => selectHandler("adminUser", e)}
                value={values.adminUser ? values.adminUser : ""}
              >
                {insuranceCompanyUsers.map((user) => (
                  <MenuItem key={generateKey()} value={user._links.self.href}>
                    {user.lastName}, {user.firstName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item"></Grid>
            <Grid item xs={9} className="row-section-item"></Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DetailFooter
        type={type}
        handleClose={handleClose}
        handleSave={() => handleSave(values, details)}
        handleCreate={() => handleCreate(values)}
        footerMsg={footerMsg}
        disabled={
          !(
            values.name &&
            values.address &&
            values.postalCode &&
            values.city &&
            values.province &&
            validMobileTel
          )
        }
      />
    </>
  );
};

export default InsuranceCompanyDetails;
