import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../UserContext";

import "./ProfilePage.scss";
import InputField from "../components/Profile/InputField";
import SaveButton from "../components/Profile/SaveButton";
import ProfileSectionHeader from "../components/Profile/ProfileSectionHeader";
import Grid from "@material-ui/core/Grid";
import PopUp from "../components/PopUp";

import { getUser, updateUser } from "../components/API_calls/users";
import { normalizePhone2 } from "../components/utils/helpers";

import {
  invalidName,
  invalidPhoneNumber,
  invalidEmail,
  invalidAddress,
  invalidPassword
} from "../components/utils/fieldValidations";

const ProfilePage = ({ ...props }) => {
  const { userState } = useContext(UserContext);

  const [errors, setErrors] = useState({});
  const [errorsPassword, setErrorsPassword] = useState({});

  const [defaultError, setDefaultError] = useState("");
  const [popUpType, setPopUpType] = useState("error");
  const [popUpStatus, setPopUpStatus] = useState("");

  const [values, setValues] = useState({});
  const [canEdit, setCanEdit] = useState(false);

  useEffect(() => {
    console.log('values:', values)
  
  }, [values]);

  useEffect(() => {
    if (userState.userInfo.id === props.match.params.id || userState.userInfo.isAdmin) {
      setCanEdit(true);
    }
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const res = await getUser(props.match.params.id);
      setValues({ ...res, password: "" });
    } catch (error) {
    }
  };

  const onChangeHandler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const submitHandler = async () => {
    setErrors({});
    const emailErrorMsg = invalidEmail(values.email) ? "Invalid Email" : null;
    const firstNameErrorMsg = invalidName(values.firstName) ? "Invalid First Name" : null;
    const lastNameErrorMsg = invalidName(values.lastName) ? "Invalid Last Name" : null;
    const homeTelErrorMsg = invalidPhoneNumber(values.homeTel)
      ? "Please ensure valid number"
      : null;
    const mobileTelErrorMsg = invalidPhoneNumber(values.mobileTel)
      ? "Please ensure valid number"
      : null;
    const businessTelErrorMsg = invalidPhoneNumber(values.businessTel)
      ? "Please ensure valid number"
      : null;
    const otherTelErrorMsg = invalidPhoneNumber(values.otherTel)
      ? "Please ensure valid number"
      : null;
    const faxErrorMsg = invalidPhoneNumber(values.fax) ? "Please ensure valid number" : null;
    const addressErrorMsg = invalidAddress(values.address) ? "Invalid Address" : null;
    const address2ErrorMsg = invalidAddress(values.address2) ? "Invalid Address" : null;
    if (
      emailErrorMsg ||
      firstNameErrorMsg ||
      lastNameErrorMsg ||
      homeTelErrorMsg ||
      mobileTelErrorMsg ||
      businessTelErrorMsg ||
      otherTelErrorMsg ||
      faxErrorMsg ||
      addressErrorMsg ||
      address2ErrorMsg
    ) {
      setErrors({
        ...errors,
        email: emailErrorMsg,
        firstName: firstNameErrorMsg,
        lastName: lastNameErrorMsg,
        homeTel: homeTelErrorMsg,
        mobileTel: mobileTelErrorMsg,
        businessTel: businessTelErrorMsg,
        otherTel: otherTelErrorMsg,
        fax: faxErrorMsg,
        address: addressErrorMsg,
        address2: address2ErrorMsg
      });
    } else {
      try {
        const response = await updateUser(props.match.params.id, {
          firstName: values.firstName,
          lastName: values.lastName,
          homeTel: normalizePhone2(values.homeTel),
          mobileTel: normalizePhone2(values.mobileTel),
          businessTel: normalizePhone2(values.businessTel),
          otherTel: normalizePhone2(values.otherTel),
          fax: normalizePhone2(values.fax),
          address: values.address,
          city: values.city,
          province: values.province,
          postalCode: values.postalCode,
          email: values.email
        });
        const { firstName, lastName, homeTel, address, username: email } = response;
        setValues({ ...values, firstName, lastName, homeTel, address, email });
        setDefaultError(`Success: Info saved.`);
        setPopUpType("success");
        setPopUpStatus(new Date());
      } catch (err) {
        setDefaultError(`Error: ${err.status} - ${err.message}`);
        setPopUpType("error");
        setPopUpStatus(new Date());
      }
    }
  };

  const invalidConfirmPasswords = (pass1, pass2) => {
    if (pass1 !== pass2) {
      return "Passwords do not match";
    } else if (invalidPassword(pass1, values.email)) {
      return "Invalid password.";
    }
    return false;
  };

  const submitHandlerPassword = async () => {
    setErrorsPassword({});
    const passwordErrorMsg = invalidConfirmPasswords(values.password, values.confirmPassword);
    if (passwordErrorMsg) {
      setErrorsPassword({ ...errorsPassword, password: passwordErrorMsg });
    } else {
      try {
        const response = await updateUser(props.match.params.id, { password: values.password });
        setValues({ ...values, password: "", confirmPassword: "" });
        setDefaultError(`Success: Info saved.`);
        setPopUpType("success");
        setPopUpStatus(new Date());
      } catch (err) {
        setDefaultError(`Error: ${err.status} - ${err.message}`);
        setPopUpType("error");
        setPopUpStatus(new Date());
      }
    }
  };

  return (
    <>
      {popUpStatus ? <PopUp type={popUpType} message={defaultError} status={popUpStatus} /> : null}
      <div className="section-container">
        <Grid container>
          <Grid item xs={12}>
            <ProfileSectionHeader title="User Information"></ProfileSectionHeader>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              label="First Name"
              placeholder=""
              value={values.firstName}
              errorMsg={errors.firstName}
              changeHandler={onChangeHandler}
              name="firstName"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              label="Last Name"
              placeholder=""
              value={values.lastName}
              errorMsg={errors.lastName}
              changeHandler={onChangeHandler}
              name="lastName"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              label="Address"
              placeholder=""
              value={values.address}
              errorMsg={errors.address}
              changeHandler={onChangeHandler}
              name="address"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div className='address-2'>
              <InputField
                disabled={!canEdit}
                label="City"
                placeholder=""
                value={values.city}
                // errorMsg={errors.address2}
                changeHandler={onChangeHandler}
                name="city"
              />
              <InputField
                disabled={!canEdit}
                label="Province"
                placeholder=""
                value={values.province}
                // errorMsg={errors.address2}
                changeHandler={onChangeHandler}
                name="province"
              />
              <InputField
                disabled={!canEdit}
                label="Postal Code"
                placeholder=""
                value={values.postalCode}
                // errorMsg={errors.address2}
                changeHandler={onChangeHandler}
                name="postalCode"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              type="tel"
              label="Home Tel."
              placeholder=""
              value={values.homeTel}
              errorMsg={errors.homeTel}
              changeHandler={onChangeHandler}
              name="homeTel"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              type="tel"
              label="Mobile Tel."
              placeholder=""
              value={values.mobileTel}
              errorMsg={errors.mobileTel}
              changeHandler={onChangeHandler}
              name="mobileTel"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              type="tel"
              label="Business Tel."
              placeholder=""
              value={values.businessTel}
              errorMsg={errors.businessTel}
              changeHandler={onChangeHandler}
              name="businessTel"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              type="tel"
              label="Other Tel."
              placeholder=""
              value={values.otherTel}
              errorMsg={errors.otherTel}
              changeHandler={onChangeHandler}
              name="otherTel"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              type="tel"
              label="Fax"
              placeholder=""
              value={values.fax}
              errorMsg={errors.fax}
              changeHandler={onChangeHandler}
              name="fax"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              disabled={!canEdit}
              label="Email"
              placeholder=""
              value={values.email}
              errorMsg={errors.email}
              changeHandler={onChangeHandler}
              name="email"
              noCase={true}
            />
          </Grid>
        </Grid>
        {canEdit && (
          <div className="save-button">
            <Grid container justify="center">
              <Grid item>
                <SaveButton submitHandler={submitHandler}></SaveButton>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      {canEdit && (
        <div className="section-container">
          <Grid container justify="center">
            <Grid item xs={12}>
              <ProfileSectionHeader title="Password"></ProfileSectionHeader>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                disabled={!canEdit}
                type="password"
                label="Password"
                placeholder=""
                value={values.password}
                errorMsg={errorsPassword.password}
                changeHandler={onChangeHandler}
                name="password"
                noCase={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputField
                disabled={!canEdit}
                type="password"
                label="Confirm Password"
                placeholder=""
                value={values.confirmPassword}
                errorMsg={errorsPassword.password}
                changeHandler={onChangeHandler}
                name="confirmPassword"
                noCase={true}
              />
            </Grid>
          </Grid>

          <div className="save-button">
            <Grid container justify="center">
              <Grid item>
                <SaveButton submitHandler={submitHandlerPassword}></SaveButton>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfilePage;
