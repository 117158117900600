import React, { useState, useEffect, useContext, Fragment } from 'react';
// import { withRouter } from "react-router-dom";

import DetailHeader from '../table-details/DetailHeader';
import DetailFooter from '../table-details/DetailFooter';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import ReportLabel from '../ReportLabel';
import BasicInput from '../basic-components/BasicInput';
import BasicTextarea from '../basic-components/BasicTextarea';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import GenderOptions from '../basic-components/GenderOptions';
import { getUser_base } from '../API_calls/users';
import { generateKey } from '../utils/helpers';
import { GlobalNameListContext, WebRequestsContext } from '../../Context';

import SimpleButton from '../OrderDetails/SimpleButton';
import AddIcon from '@material-ui/icons/Add';

import moment from 'moment';
import { createServiceItem, updateOrder_base } from '../API_calls/orders';
// import { TextField } from "@material-ui/core";
// import AutoCompleteDropDown from "../OrderDetails/AutoCompletes/AutoCompleteDropDown";
// import AutoCompleteDropDownControlled from "../OrderDetails/AutoCompletes/AutoCompleteDropDownControlled";
import DeleteButton from '../OrderDetails/DeleteButton';

const ConversionDialog = ({
  handleClose,
  handleSave = null,
  handleCreate = null,
  details,
  type,
  history,
  ...props
}) => {
  const { globalNameList } = useContext(GlobalNameListContext);
  const { setWebRequests } = useContext(WebRequestsContext);

  const [values, setValues] = useState({});
  // const [errors, setErrors] = useState({});

  const [serviceItems, setServiceItems] = useState([]);

  useEffect(() => {
    const initializeData = async () => {
      if (type === 'convert') {
        const res = await getUser_base(details._links.self.href);
        setValues((prev) => ({ ...prev, ...res }));
      } else if (type === 'view') {
        setValues((prev) => ({ ...prev, ...details }));
        const serviceItemsTemp = details._embedded.serviceItems
          ? details._embedded.serviceItems.map((item) => item.serviceType._links.self.href.split('{')[0])
          : [];
        setServiceItems(serviceItemsTemp);
      }
    };
    initializeData();
  }, [details, type]);

  // useEffect(() => {
  //   console.log('values:', values)
  //   console.log('details:', details)
  //   console.log('serviceItems:', serviceItems)

  // }, [values, details, serviceItems]);

  const handleServiceItems = (value, index) => {
    setServiceItems((prev) => prev.map((item, i) => (i === index ? value : item)));
  };

  const handleConvert = async () => {
    try {
      for (let item of serviceItems) {
        const newItem = await createServiceItem({
          serviceType: item,
          order: values._links.self.href,
        });
        console.log('newItem:', newItem);
      }
      const res = await updateOrder_base(values._links.self.href, { status: 'Assignment' });
      console.log('res:', res);
    } catch (err) {
      console.log(err);
    } finally {
      setWebRequests((prev) => prev - 1);
      history.push(`/orders/${values.orderID}`);
    }
  };

  const addServiceItem = () => {
    setServiceItems((prev) => [...prev, '']);
  };

  const deleteServiceItem = (index) => {
    setServiceItems((prev) => prev.filter((item, i) => i !== index));
  };

  const genderOptions = () => {
    return (
      <GenderOptions
        name="gender"
        readOnly
        value={values.clientInfo && values.clientInfo.gender !== '' ? values.clientInfo.gender : 'Unknown'}
      />
    );
  };

  const serviceItemsList = serviceItems.map((item, index) => {
    return (
      <Fragment key={generateKey()}>
        <Grid item xs={3} className="row-section-item">
          <ReportLabel variant="subtitle1" flexEnd>
            Service {index + 1}:
          </ReportLabel>
        </Grid>
        <Grid container justify="space-around" item xs={9} className="row-section-item">
          <Select
            style={{ width: '85%' }}
            onChange={type !== 'view' ? (e) => handleServiceItems(e.target.value, index) : null}
            value={serviceItems[index]}
          >
            {globalNameList.mobileServiceTypes.map((type) => (
              <MenuItem key={generateKey()} value={type.link}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
          {serviceItems.length > 1 && type !== 'view' && (
            <DeleteButton deleteHandler={() => deleteServiceItem(index)} />
          )}
        </Grid>
      </Fragment>
    );
  });

  const insuranceItemsList =
    values.insuranceItems &&
    values.insuranceItems.map((item) => {
      return (
        <Fragment key={generateKey()}>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Insurance Amount ($):
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput name="insuranceAmount" value={item.insuranceAmount} readOnly={true} />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Insurance Type:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput name="insuranceType" value={item.insuranceType} readOnly={true} />
            </Grid>
          </Grid>
        </Fragment>
      );
    });

  const webRequestServicesList =
    values._embedded &&
    values._embedded.webRequestServiceTypes &&
    values._embedded.webRequestServiceTypes.map((item) => `${item.shortCode} - ${item.name}`).join('\n');

  return (
    <>
      <DetailHeader title={type === 'convert' ? 'Convert Web Request' : 'Web Request Details'} />
      <DialogContent dividers={true}>
        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Order ID:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput name="orderID" value={values.medifastID} readOnly={true} />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Status:
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item" alignItems="center">
              <BasicInput name="status" value={values.status} readOnly={true} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Insurance Company:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="insuranceCompany"
                value={
                  values._embedded && values._embedded.insuranceCompany ? values._embedded.insuranceCompany.name : ''
                }
                readOnly={true}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Agent:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="agent"
                value={
                  values._embedded && values._embedded.agent
                    ? `${values._embedded.agent.lastName}, ${values._embedded.agent.firstName}`
                    : ''
                }
                readOnly={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Agency:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="agency"
                value={values._embedded && values._embedded.agency ? values._embedded.agency.name : ''}
                readOnly={true}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Remarks:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea name="remarks" rows={3} value={values.remarks} readOnly={true} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Client:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="client"
                value={values.clientInfo ? `${values.clientInfo.lastName}, ${values.clientInfo.firstName}` : ''}
                readOnly={true}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Gender:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              {genderOptions()}
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                DOB:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput name="DOB" value={values.clientInfo ? values.clientInfo.dob : ''} readOnly={true} />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Age:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="age"
                value={
                  values.clientInfo && moment().diff(values.clientInfo.dob, 'years')
                    ? moment().diff(values.clientInfo.dob, 'years')
                    : ''
                }
                readOnly={true}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          {insuranceItemsList}
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            {serviceItemsList}
            <Grid item container justify="center" xs={12} className="row-section-item">
              {type !== 'view' && <SimpleButton onClick={addServiceItem} text={'ADD'} icon={<AddIcon />} />}
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Request Service:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea name="webRequestServiceTypes" value={webRequestServicesList} readOnly={true} />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      {type !== 'view' && <DetailFooter type={type} handleClose={handleClose} handleCreate={handleConvert} />}
    </>
  );
};

export default ConversionDialog;
