import React, { useState, useEffect, useReducer, createContext } from "react";
import { getAllRoles } from "./components/API_calls/roles";

// user context

const initialUserState = { isLoggedIn: false, userInfo: {} };

let userReducer = (state, { type, payload }) => {
  switch (type) {
    case "login":
      return { ...state, isLoggedIn: true, userInfo: { id: payload } };
    case "updateUserInfo":
      return { ...state, isLoggedIn: true, userInfo: payload };
    case "logout":
      return { ...state, isLoggedIn: false, userInfo: {} };
    default:
      throw new Error(`unsupported action type: ${type}`);
  }
};

const UserContext = createContext(initialUserState);

const UserProvider = (props) => {
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  return (
    <UserContext.Provider value={{ userState, userDispatch }}>
      {props.children}
    </UserContext.Provider>
  );
};

//roles context
const RolesContext = createContext({});

const RolesProvider = (props) => {
  const [roles, setRoles] = useState({});

  const getRolesData = async () => {
    try {
      const roles = await getAllRoles();
      let roleMap = {};
      for (const role of roles) {
        roleMap[role.name] = role._links.self.href;
      }
      setRoles(roleMap);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getRolesData();
  }, []);

  return <RolesContext.Provider value={{ roles }}>{props.children}</RolesContext.Provider>;
};

export { UserContext, UserProvider, RolesContext, RolesProvider };
