import React, { useState, useContext, useEffect } from 'react';
import * as billingHelpers from "./BillingHelpers";
import { InvoicePayslipDataContext } from '../../Context';
import DateTimePicker from '../OrderDetails/DateTimePicker';
import { getUnbilledCompaniesInvoices } from '../API_calls/invoicePayslip';

import Grid from '@material-ui/core/Grid';
import SimpleButton from '../OrderDetails/SimpleButton';
import SearchIcon from '@material-ui/icons/Search';
import LoadingSpinner from '../LoadingSpinner';
import Divider from '@material-ui/core/Divider';
import { createInvoiceColumns } from '../data/constants';
import DefaultTable from '../DefaultTable';
import InvoiceDetails from './InvoiceDetails';
import moment from 'moment';
import { roundTo } from '../utils/helpers';

const CreateInvoice = ({ invoicePayslipId, setError, history, ...props }) => {

  const { invoicePayslipData, setInvoicePayslipData } = useContext(InvoicePayslipDataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [upToDate, setUpToDate] = useState(billingHelpers.lastDateOfPrevMonth());
  const [pageSize, setPageSize] = useState(50);
  const [mode, setMode] = useState('SUMMARY');
  const [details, setDetails] = useState({});

  // FUNCTION CALL FOR GETTING DATA LIST
  const getData = async (date) => {
    setIsLoading(true);
    try {
      const res = await getUnbilledCompaniesInvoices(date)
      setInvoicePayslipData(res);
      setIsLoading(false);
    } catch (err) {
      setError(prev => ({...prev, status: 'Error', msg: 'Search returned no results. Please try again.'}));
    }
  };

  // ON MOUNT AND UNMOUNT
  useEffect(() => {
    getData(moment(upToDate).format('YYYY-MM-DD'));

    return () => setInvoicePayslipData([]);
  }, []);

  const changeDate = (val, setCurrentTime, source) => {
    setCurrentTime(val);
    setUpToDate(val.format('YYYY-MM-DD'));
    //search date as soon as you chg
    // getData(moment(val.format('YYYY-MM-DD')).format('YYYY-MM-DD'));

  };

  const showDetails = data => {
    setDetails(prev => ({ ...prev, ...data }));
    setMode('DETAILS');
  };

  const totalAmountFooter = activeData => {
      return (
        <tfoot style={{ display: "flex", alignItems: "center" }}>
          <tr style={{ display: "flex", padding: "10px" }}>
            <th>TOTAL:</th>
            <td style={{ display: "flex", paddingLeft: '20px' }}>
              <strong>${roundTo(activeData.reduce((acc, curr) => acc + curr.grandTotal, 0), 2)}</strong>
            </td>
          </tr>
        </tfoot>
      );
  };


  return (
      <>
        {mode === 'DETAILS' && details && 
          <InvoiceDetails
            details={details}
            setDetails={setDetails}
            created={false}
            upToDate={upToDate}
            setMode={setMode}
            invoicePayslipId={invoicePayslipId}
            history={history}
          />}
        {mode === 'SUMMARY' && 
          <>
            <Grid item container spacing={1} style={{ margin: "10px" }}>
              <Grid item lg={9} xs={12}>
                <DateTimePicker 
                  source='upToDate'
                  upToDate={upToDate}
                  label='Up To Period:'
                  changeDate={changeDate}
                />
              </Grid>
              <Grid item lg={3} xs={12} style={{ marginTop: "20px", textAlign: "center" }}>
                <SimpleButton onClick={() => getData(upToDate)} text={"SEARCH"} icon={<SearchIcon />} />
              </Grid>
            </Grid>
            <Divider />
            {isLoading ? <LoadingSpinner /> :
              <DefaultTable
                onRowClick={(e, rowData) => showDetails(rowData)}
                title={""}
                inputColumns={createInvoiceColumns}
                inputData={invoicePayslipData}
                pageSize={pageSize}
                setPageSize={setPageSize}
                summaryFooter={totalAmountFooter}
              />}
          </>}
      </>  
  );
};

export default CreateInvoice;