import React, { useState, useContext } from "react";
import "./LoginPage.scss";
import { setJWT, setUserId } from "../components/utils/JWT";
import { UserContext } from "../UserContext";
import { actullyChgPW } from "../components/API_calls/users";
import { withRouter } from "react-router-dom";
import InputField from "../components/Profile/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PopUp from "../components/PopUp";
import {
  invalidEmail,
  invalidPassword,
} from "../components/utils/fieldValidations";
import Swal from "sweetalert2";

const PasswordReset = (props) => {
  const { userDispatch } = useContext(UserContext);
  const [input, setInput] = useState({ newPass: "", confirmPass: "" });
  const [errorMsg, setErrorMsg] = useState({});

  //for popup
  const [defaultError, setDefaultError] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("");

  const token = props.location.search.replace("?token=", "");

  // const [register, setRegister] = useState(false);

  const inputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const passCheck = /^(?=.{8,50})(?=.*[a-z])(?=.*[A-Z])(?=.*[@!#$%*^&+=]).*$/g;

    console.log(input.newPass);
    const isValidPW = passCheck.test(input.newPass);
    console.log(isValidPW);

    //regex password requirements put here
    if (input.newPass === input.confirmPass) {
      if (isValidPW) {
        try {
          const status = await actullyChgPW(input.newPass, token);
          setDefaultError(`Password Changed`);
          setPopUpType("success");
          setPopUpStatus(new Date());

          setTimeout(function () {
            props.history.push("/login");
          }, 1000);
        } catch (err) {
          setDefaultError(`Error: Token expired, request a new email`);
          setPopUpType("error");
          setPopUpStatus(new Date());
        }
      } else {
        setDefaultError(`Check password requirements`);
        setPopUpType("error");
        setPopUpStatus(new Date());
      }
    } else {
      setDefaultError(`Error: Check Your Password Match`);
      setPopUpType("error");
      setPopUpStatus(new Date());
    }
  };

  return (
    <div id="login-register-container">
      <form onSubmit={submitHandler}>
        {popUpStatus ? (
          <PopUp type={popUpType} message={defaultError} status={popUpStatus} />
        ) : null}
        <div className="main-login-container">
          <Grid container justify="center">
            <Grid item xs={12}>
              <span className="header">
                <Typography variant="h2">Password Reset</Typography>
              </span>
            </Grid>
            <Grid item className="field" lg={12} xs={12}>
              <InputField
                autoComplete="off"
                type="password"
                name="newPass"
                label="New Password"
                placeholder="Enter Password"
                changeHandler={inputHandler}
                errorMsg={errorMsg.username}
                fullWidth
              />
            </Grid>
            <Grid item className="field" lg={12} xs={12}>
              <InputField
                autoComplete="off"
                type="password"
                name="confirmPass"
                label="Confirm Password"
                placeholder="Confirm Password"
                changeHandler={inputHandler}
                errorMsg={errorMsg.password}
              />
            </Grid>
            <Grid container direction="row" justify="center">
              <p>
                Password requirements: Min length 8, 1 Uppercase, 1 Lowercase, 1
                Number, 1 Special Char (!@#$%^*...)
              </p>
            </Grid>
            <Grid item className="button" xs={3}>
              <Button type="submit" variant="contained" color="primary">
                Confirm Change
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </div>
  );
};

export default withRouter(PasswordReset);
