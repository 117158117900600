import React, { useEffect, useState } from "react";

import DefaultTable from "./DefaultTable";

import { emailLogsInfoColumns } from "./data/constants";

const EmailLogsTable = ({ emailLogs = [], ...props }) => {
  useEffect(() => {
    setIsLoading(true);
    // setInfo(emailLogs);
    setIsLoading(false);
  }, [emailLogs]);

  // const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  return (
    <DefaultTable
      title="Email Logs"
      inputColumns={emailLogsInfoColumns}
      inputData={emailLogs}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default EmailLogsTable;
