import React from "react";
import "./ReportTable.scss";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import ReportLabel from "./ReportLabel";

/**
 * dataList = array of data (one level deep object)
 * dataHeaders = array of header names (strings)
 * boldHeaders = name of headers that should have bold rows (strings)
 */
const ReportTable = ({ dataList, dataHeaders, boldHeaders, ...props }) => {
  
  return (
    <div className='table-container'>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {dataHeaders.map((header, index) => {
              return (
                <TableCell key={index} align="center">
                  {header.title}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <TableBody>
          {dataList.map((data, index) => (
            <TableRow key={index}>
              {dataHeaders.map((header, index2) => (
                <TableCell key={index2} align="center">
                  <ReportLabel bold={boldHeaders.includes(header)}>{data[header.name]}</ReportLabel>
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  );
};

export default ReportTable;
