import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import CreateInvoice from './CreateInvoice';
import CreatePayslip from './CreatePayslip';
import SearchInvoice from './SearchInvoice';
import SearchPayslip from './SearchPayslip';
import PopUp from "../PopUp";

const BillingPage = ({ match, history, ...props }) => {

  const { params: { invoicePayslipId } } = match;

  const [error, setError] = useState({ status: '', msg: '' });

  return (
    <>
      {error.status === 'Error' && <PopUp type="error" message={error.msg} status={error.status} />}
      {invoicePayslipId === 'createInvoice' && <CreateInvoice invoicePayslipId={invoicePayslipId} setError={setError} history={history} />}
      {invoicePayslipId === 'createPayslip' && <CreatePayslip invoicePayslipId={invoicePayslipId} setError={setError} history={history} />}
      {invoicePayslipId === 'searchInvoice' && <SearchInvoice invoicePayslipId={invoicePayslipId} setError={setError} history={history} />}
      {invoicePayslipId === 'searchPayslip' && <SearchPayslip invoicePayslipId={invoicePayslipId} setError={setError} history={history} />}
    </>
  );
};

export default withRouter(BillingPage);