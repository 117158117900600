import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "../components/DefaultTable";
import FeeScheduleHeader from "../components/FeeScheduleHeader";

import { companyFeeColumns } from "../components/data/constants";
import {
  searchCompanyFee,
  createCompanyFee,
  updateCompanyFee_base,
  deleteCompanyFee_base
} from "../components/API_calls/companyFees";
import { GlobalNameListContext } from "../Context";
import PopUp from "../components/PopUp";


const CompanyFeeSchedule = ({ ...props }) => {

  const { globalNameList } = useContext(GlobalNameListContext);

  // const [companyList, setCompanyList] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);

  // TABLE STATES
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  // for popup
  const [errorMsg, setErrorMsg] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("");

  //filtering through shortCodes of mobileServiceType, adding to options only if it doesn't exist
  useEffect(() => {
    if (globalNameList) {
      const initializeData = async () => {
        let newMobileServiceList = {};
        let newMobileService = {};
        for (const service of info) {
          newMobileService[service.mobileServiceType.shortCode] = true
        }
        for (const serviceType of globalNameList.mobileServiceTypes) {
          if (!newMobileService[serviceType.shortCode]) {
            newMobileServiceList[serviceType.link] = `${serviceType.shortCode} - ${serviceType.name}`;
          }
        }
        companyFeeColumns[1].lookup = newMobileServiceList;
      };
      initializeData();
    }
  }, [globalNameList, info]);


  const changeCompany = val => {
    if (val) {
      setSelectedCompany(prev => ({ ...prev, ...val }));
      searchCompanyFee(val.link)
        .then((res) => {

          if (globalNameList) {

            const initializeData = async () => {
              let newMobileServiceList = {};
              let newMobileService = {};
              for (const service of res) {
                newMobileService[service.mobileServiceType.shortCode] = true
              }
              for (const serviceType of globalNameList.mobileServiceTypes) {
                if (!newMobileService[serviceType.shortCode]) {
                  newMobileServiceList[serviceType.link] = `${serviceType.shortCode} - ${serviceType.name}`;
                }
              }
              companyFeeColumns[1].lookup = newMobileServiceList;
              setInfo(res)
            };
            initializeData();
          }
          })
        .catch((e) => setInfo([]));
    } else {
      setSelectedCompany(null);
    }  };

  const addFunction = async (newData) => {

    if (newData.mobileServiceLink && newData.price>0){
      setIsLoading(true);
      try {
        const res = await createCompanyFee({
          insuranceCompany: selectedCompany.link,
          mobileServiceType: newData.mobileServiceLink,
          price: newData.price ? newData.price : 0,
          scanFaxPrice: newData.scanFaxPrice ? newData.scanFaxPrice : 0
        });
        setInfo([res, ...info]);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }

    } else {
      setErrorMsg(`Error: Fill out all fields`);
      setPopUpType("error");
      setPopUpStatus(new Date());
      setIsLoading(false);
    }
  };

  const deleteFunction = async (oldData) => {
    setIsLoading(true);
    try {
      await deleteCompanyFee_base(oldData._links.self.href);
      const data = info.filter((currData) => currData.tableData.id !== oldData.tableData.id);
      setInfo(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {

    if (newData.mobileServiceLink && newData.price>0){
      setIsLoading(true);
      try {
        await updateCompanyFee_base(oldData._links.self.href, {
          mobileServiceType: newData.mobileServiceLink,
          price: newData.price,
          scanFaxPrice: newData.scanFaxPrice
        });
        const data = info.map((currData) =>
          currData.tableData.id === oldData.tableData.id ? newData : currData
        );

        setInfo(data);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }

    }else {
      setErrorMsg(`Error: Fill out all fields`);
      setPopUpType("error");
      setPopUpStatus(new Date());
      setIsLoading(false);
    }
  };

  return (
    <>
      <FeeScheduleHeader
        title="Required: Select A Company"
        value={selectedCompany}
        onChange={changeCompany}
        name="contractor"
        options={globalNameList.insuranceCompanies}
        labelField="name"
        idField="id"
      />
      <DefaultTable
        title="Company Rates"
        inputColumns={companyFeeColumns}
        inputData={info}
        addFunction={selectedCompany ? addFunction : false}
        updateFunction={updateFunction}
        deleteFunction={deleteFunction}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />
      {popUpStatus ? (
        <PopUp type={popUpType} message={errorMsg} status={popUpStatus} />
      ) : null}
    </>
  );
};

export default CompanyFeeSchedule;
