import React, { useState, useEffect } from 'react';
import './ContractorReport.scss';
import moment from 'moment';
import ReportLabel from '../components/ReportLabel';
import ReportTable from '../components/ReportTable';
// import ReportWrapper from "../components/ReportWrapper";
// import CreatePDF from "../components/CreatePDF";
import LoadingSpinner from '../components/LoadingSpinner';

import logo from '../components/utils/medifast_logo.png';

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { getUser_base, getOrder } from '../components/API_calls/orders';

const ContractorReport = ({ id, ...props }) => {
  const [serviceDetails, setServiceDetails] = useState([]);
  const [details, setDetails] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  const serviceHeaders = [
    { title: 'Service', name: 'serviceType' },
    { title: 'Contractor', name: 'contractor' },
    { title: 'Business', name: 'business' },
    { title: 'Fax', name: 'fax' },
    { title: 'Mobile', name: 'mobile' },
  ];

  // useEffect(() => {
  //   console.log('details:', details)
  // }, [details]);

  // useEffect(() => {
  //   console.log('serviceDetails:', serviceDetails);
  // }, [serviceDetails]);

  useEffect(() => {
    if (id) {
      getOrder(id).then((res) => {
        setDetails((prev) => ({
          ...prev,
          ...res,
          examLocation: res.examLocation.clientLocationMediFastOffice
            ? {
                ...res.examLocation,
                clientLocationAddress: '15 Wertheim Ct Unit 204\nRichmond Hill, ON',
                clientLocationPostalCode: 'L4B 3H7',
              }
            : res.examLocation,
        }));
        const serviceList =
          res._embedded.serviceItems && res._embedded.serviceItems.length > 0
            ? res._embedded.serviceItems.map((item) => {
                // console.log('item', item);
                if (item._links.user) {
                  return getUser_base(item._links.user.href.split('{')[0]).then((res) => {
                    // console.log('res', res);
                    return {
                      serviceType: item.serviceType ? item.serviceType.name : '',
                      contractor: `${res.lastName}, ${res.firstName}`,
                      business: res.businessTel ? res.businessTel : '',
                      fax: res.fax ? res.fax : '',
                      mobile: res.mobileTel ? res.mobileTel : '',
                    };
                  });
                } else {
                  return {
                    serviceType: '',
                    contractor: '',
                    business: '',
                    fax: '',
                    mobile: '',
                  };
                }
              })
            : [];

        if (serviceList.length > 0) {
          Promise.all(serviceList).then((res) => {
            setServiceDetails(res);
            setIsLoaded(true);
          });
        } else {
          setServiceDetails([]);
          setIsLoaded(true);
        }
      });
    }
  }, [id]);

  return isLoaded ? (
    <>
      <Box className="section main-section">
        <Box className="section header-section">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <img src={logo} alt="Medifast logo" />
            </Grid>
            <Grid item xs={3}>
              <ReportLabel>
                Order No.:{' '}
                <ReportLabel variant="subtitle1" bold={true}>
                  {details.medifastID}
                </ReportLabel>
              </ReportLabel>
            </Grid>
            <Grid item xs={3}>
              <ReportLabel>
                Order Date:
                <ReportLabel variant="subtitle1" bold={true}>
                  {details.orderDate ? moment(details.orderDate).format('MM-DD-YYYY') : ''}
                </ReportLabel>
              </ReportLabel>
            </Grid>
            <Grid item xs={3}>
              <ReportLabel>
                Lab:
                <ReportLabel variant="subtitle1" bold={true}>
                  {details.lab.labName}
                </ReportLabel>
              </ReportLabel>
            </Grid>
          </Grid>
        </Box>
        <Box className="section agent-section">
          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Company:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel bold={true}>
                  {details._embedded.insuranceCompany ? details._embedded.insuranceCompany.name : ''}
                </ReportLabel>
              </Grid>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Agency:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>{details._embedded.agency ? details._embedded.agency.name : ''}</ReportLabel>
              </Grid>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Agent:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>
                  {details._embedded.agent
                    ? `${details._embedded.agent.lastName}, ${details._embedded.agent.firstName}`
                    : ''}
                </ReportLabel>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item container xs={6}>
                <Grid item xs={2}>
                  <ReportLabel flexEnd>Email:</ReportLabel>
                </Grid>
                <Grid item xs={10}>
                  <ReportLabel>{details._embedded.agent ? details._embedded.agent.username : ''}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={2}>
                  <ReportLabel flexEnd>Mobile:</ReportLabel>
                </Grid>
                <Grid item xs={10}>
                  <ReportLabel>{details._embedded.agent ? details._embedded.agent.mobileTel : ''}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={2}>
                  <ReportLabel flexEnd>Business:</ReportLabel>
                </Grid>
                <Grid item xs={10}>
                  <ReportLabel>{details._embedded.agent ? details._embedded.agent.businessTel : ''}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={2}>
                  <ReportLabel flexEnd>Fax:</ReportLabel>
                </Grid>
                <Grid item xs={10}>
                  <ReportLabel>{details._embedded.agent ? details._embedded.agent.fax : ''}</ReportLabel>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="section client-section">
          <Grid container spacing={2}>
            <Grid item container xs={4}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Client:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel bold={true}>
                  {details.clientInfo.lastName}, {details.clientInfo.firstName}
                </ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={8}>
              {''}
            </Grid>
            <Grid item container xs={4}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>D.O.B:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>
                  {moment(details.clientInfo.dob).format('MM-DD-YYYY')} (Age: {details.clientInfo.age})
                </ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <Grid item xs={3}>
                <ReportLabel flexEnd>Gender:</ReportLabel>
              </Grid>
              <Grid item xs={9}>
                <ReportLabel>{details.clientInfo.gender}</ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              {''}
            </Grid>
            <Grid item container xs={4}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Address:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>{details.examLocation.clientLocationAddress}</ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <Grid item xs={3}>
                <ReportLabel flexEnd>Postal Code:</ReportLabel>
              </Grid>
              <Grid item xs={9}>
                <ReportLabel>{details.examLocation.clientLocationPostalCode}</ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={4}>
              <Grid item container xs={6}>
                <Grid item xs={3}>
                  <ReportLabel flexEnd>Email:</ReportLabel>
                </Grid>
                <Grid item xs={9}>
                  <ReportLabel>{details.clientInfo.email}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={3}>
                  <ReportLabel flexEnd>Home:</ReportLabel>
                </Grid>
                <Grid item xs={9}>
                  <ReportLabel>{details.clientInfo.home}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={3}>
                  <ReportLabel flexEnd>Mobile:</ReportLabel>
                </Grid>
                <Grid item xs={9}>
                  <ReportLabel>{details.clientInfo.mobile}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={3}>
                  <ReportLabel flexEnd>Business:</ReportLabel>
                </Grid>
                <Grid item xs={9}>
                  <ReportLabel>{details.clientInfo.business}</ReportLabel>
                </Grid>
              </Grid>
              <Grid item container xs={6}>
                <Grid item xs={3}>
                  <ReportLabel flexEnd>Fax:</ReportLabel>
                </Grid>
                <Grid item xs={9}>
                  <ReportLabel>{details.clientInfo.fax}</ReportLabel>
                </Grid>
              </Grid>
            </Grid>

            {details.insuranceItems.map((item, index) => {
              return (
                <>
                  <Grid item container xs={4}>
                    <Grid item xs={2}>
                      <ReportLabel flexEnd>{index === 0 && details.refNum.refNumType}</ReportLabel>
                    </Grid>
                    <Grid item xs={10}>
                      <ReportLabel>{index === 0 && details.refNum.refNumNumber}</ReportLabel>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4}>
                    <Grid item xs={2}>
                      <ReportLabel flexEnd>Amount:</ReportLabel>
                    </Grid>
                    <Grid item xs={10}>
                      <ReportLabel>$ {item.insuranceAmount} K</ReportLabel>
                    </Grid>
                  </Grid>
                  <Grid item container xs={4}>
                    <Grid item xs={2}>
                      <ReportLabel flexEnd>Type:</ReportLabel>
                    </Grid>
                    <Grid item xs={10}>
                      <ReportLabel>{item.insuranceType}</ReportLabel>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Box>
        <Box className="section service-section">
          <ReportTable dataList={serviceDetails} dataHeaders={serviceHeaders} boldHeaders={['service']} />
        </Box>
        <Box className="section other-section">
          <Grid container spacing={2}>
            <Grid item container xs={6}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Assignment:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>
                  {details.assignmentTime ? moment(details.assignmentTime).format('MM-DD-YYYY') : ''}
                </ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Lab No.:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>{details.lab.labNumber}</ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Appointment:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>{details.serviceTime ? moment(details.serviceTime).format('MM-DD-YYYY') : ''}</ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              {''}
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Completion:</ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>
                  {details.completionTime ? moment(details.completionTime).format('MM-DD-YYYY') : ''}
                </ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={6}>
              <Grid item xs={2}>
                <ReportLabel flexEnd>Language: </ReportLabel>
              </Grid>
              <Grid item xs={10}>
                <ReportLabel>{details.clientInfo.languages.join(', ')}</ReportLabel>
              </Grid>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={1}>
                <ReportLabel flexEnd>Remarks: </ReportLabel>
              </Grid>
              <Grid item xs={11}>
                <ReportLabel>{details.remarks}</ReportLabel>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box className="section header-section">
          <Grid container>
            <Grid item xs={12}>
              For inquiries, please call the following - Phone: (905) 882-0190
              <br />
              Visit us at: http://www.medifast.ca
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default ContractorReport;
