import React, { useEffect, useState } from "react"; 

import DefaultTable from "./DefaultTable";
import InsuranceAgencyUserDetails from "./table-details/InsuranceAgencyUserDetails";
import Dialog from "@material-ui/core/Dialog";
import Edit from "@material-ui/icons/Edit";
import AddBox from "@material-ui/icons/AddBox";
import LockOpenIcon from "@material-ui/icons/LockOpen";

import { insuranceAgencyUserInfoColumns } from "./data/constants";
import { createUser, updateUser_base, resetPasswordSwal } from "./API_calls/users";
import { updateAgency_base } from './API_calls/agencies';

import PopUp from "./PopUp";


const InsuranceAgencyUserCrudTable = ({ roleLink, userData, agencyListData, ...props }) => {
  
  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        setInfo(userData);
        const resAgencyList = agencyListData.map((agency) => {
          return { id: agency._links.self.href, name: agency.name };
        });
        setAgencyList(resAgencyList);
      } catch (e) {
        console.log("ERROR:", e);
      } finally {
        setIsLoading(false);
      }
    };
    initializeData();
  }, [userData, agencyListData]);

  const [agencyList, setAgencyList] = useState([]);
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [currDetails, setCurrDetails] = useState({});
  const [footerMsg, setFooterMsg] = useState('');

  // for popup
  const [errorMsg, setErrorMsg] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("");


  const addFunction = async (newData) => {
    setIsLoading(true);
    try {
      const res = await createUser(newData, roleLink);
      setInfo(prev => [res, ...prev]);
      setOpen(false);
    } catch (e) {
      setFooterMsg('Could not submit form. Check duplicate Username.');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData, agencyLink = null) => {

      //triple input error handling
      if(newData.businessTel !== null && newData.businessTel !== "--"){
        if (newData.businessTel.length !== 12 && newData.businessTel.length !== 0){
          setFooterMsg("Check Business Number")
          return
        }  
      }
  
      if(newData.homeTel !== null && newData.homeTel !== "--"){
        if (newData.homeTel.length !== 12 && newData.homeTel.length !== 0){
          setFooterMsg("Check Home Number")
          return
        }  
      }
  
      if(newData.fax !== null && newData.fax !== "--"){
        if (newData.fax.length !== 12 && newData.fax.length !== 0){
          setFooterMsg("Check Fax Number")
          return
        }  
      }
  
      if(newData.otherTel !== null && newData.otherTel !== "--"){
        if (newData.otherTel.length !== 12 && newData.otherTel.length !== 0){
          setFooterMsg("Check Other Number")
          return
        }  
      }
      //end

    
    setIsLoading(true);
    try {
      await updateUser_base(oldData._links.self.href, newData);
      if (agencyLink) {
        await updateAgency_base(agencyLink, { admin: oldData._links.self.href });
      }
      setInfo(prev => prev.map((currData) =>
        currData.tableData.id === oldData.tableData.id ? newData : currData
      ));
      setOpen(false);
    } catch (e) {
      setFooterMsg('Could not submit form. Check duplicate Username.');
      console.log(e);
    } finally {
      setIsLoading(false);
      // setFooterMsg('');

    }
  };

  const actions = [
    {
      icon: () => <Edit />,
      tooltip: "Edit",
      onClick: (event, rowData, togglePanel) => {
        setCurrDetails(rowData);
        setOpen(true);
      }
    },
    {
      icon: () => <AddBox />,
      tooltip: "Add",
      isFreeAction: true,
      onClick: (event) => {
        setCurrDetails({});
        setOpen(true);
      }
    },
    {
      icon: () => <LockOpenIcon />,
      tooltip: "Reset Password",
      onClick: async (event, rowData, togglePanel) => {
        
        try {
          const status = await resetPasswordSwal(rowData);
          console.log(status)

          if (status === 'success'){
            setErrorMsg(`Password Changed`);
            setPopUpType('success')
            setPopUpStatus(new Date());  
          }

          if (status === 'fail'){
            setErrorMsg(`Password requirements: Min length 8, 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Char (!@#$%^*...)`);
            setPopUpType('error')
            setPopUpStatus(new Date());  
          }


        } catch (err) {
          setErrorMsg(`Enter a new password`);
          setPopUpType('error')
          setPopUpStatus(new Date());
        }
      }
    }
  ];

  const handleClose = () => {
    setOpen(false);
    setFooterMsg('');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} scroll="paper">
        <InsuranceAgencyUserDetails   
          agencyList={agencyList}
          details={currDetails}
          handleClose={handleClose}
          handleSave={updateFunction}
          handleCreate={addFunction}
          type={Object.keys(currDetails).length > 0 ? "update" : "add"}
          footerMsg={footerMsg}
        />
      </Dialog>
      <DefaultTable
        title="Agency Admin Info"
        inputColumns={insuranceAgencyUserInfoColumns}
        inputData={info}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        actions={actions}
      />
      {popUpStatus ? <PopUp type={popUpType} message={errorMsg} status={popUpStatus} /> : null}

    </div>
  );
};

export default InsuranceAgencyUserCrudTable;
