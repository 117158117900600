import React from "react";
import Button from "@material-ui/core/Button";
import EmailIcon from "@material-ui/icons/Email";

const SendEmailButton = ({ onClick, color = "primary", size = "small", ...props }) => {
  return (
    <Button
      onClick={onClick}
      variant="contained"
      color={color}
      size={size}
      startIcon={<EmailIcon />}
    >
      {props.children}
    </Button>
  );
};

export default SendEmailButton;
