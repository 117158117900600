import React, { useEffect, useState } from "react";
import DefaultTable from "./DefaultTable";

import { expensesInfoColumns } from "./data/constants";
import { createMobileExpense, deleteMobileExpense_base } from './API_calls/mobileExpenses';

import PopUp from "./PopUp";


const ExpensesCrudTable = ({
  mobileExpenses,
  mobileExpenseTypeList,
  contractorDetails = [],
  order,
  ...props
}) => {
  useEffect(() => {
    setInfo(mobileExpenses);
    const mobileExpenseTypes = {};
    for (const type of mobileExpenseTypeList) {
      mobileExpenseTypes[type._links.self.href] = `${type.shortCode} - ${type.name}`;
    }
    expensesInfoColumns[0].lookup = mobileExpenseTypes;
  }, [mobileExpenses, mobileExpenseTypeList]);

  useEffect(() => {
    const updateListOfContractors = () => {
      const contractorList = {};
      for (const i in contractorDetails) {
        contractorList[contractorDetails[i].contractor.name.link] =
          contractorDetails[i].contractor.name.firstName;
      }
      expensesInfoColumns[2].lookup = contractorList;
    };
    updateListOfContractors();
  }, [contractorDetails]);


  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  //for popup error msg
  const [popUpStatus, setPopUpStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [popUpType, setPopUpType] = useState("");



  const addFunction = async (newData) => {
    // console.log('newData:', newData);
    // console.log('order:', order)
    setIsLoading(true);
    try {
      const res = await createMobileExpense({...newData, order });
      setInfo(prev => [
        {
          contractorFirstName: res._embedded.user.firstName,
          contractorLastName: res._embedded.user.lastName,
          mobileExpenseTypeShortCode: res._embedded.mobileExpenseType.shortCode,
          mobileExpenseTypeName: res._embedded.mobileExpenseType.name,
          amount: res.amount,
          description: res.description
        }, 
        ...prev
      ]);
    } catch (e) {
      console.log(e);
      setErrorMsg('Error: Please fillout all fields')
      setPopUpType('error')
      setPopUpStatus(new Date())
      // setTimeout(setPopUpStatus(false), 3000)
    } finally {
      setIsLoading(false);
    }
  };

  // const updateFunction = async (newData, oldData) => {
  //   setIsLoading(true);
  //   try {
  //     await updateMobileExpense_base(oldData._links.self.href, newData);
  //     setInfo(prev => prev.map(currData => currData.tableData.id === oldData.tableData.id ? newData : currData));
  //   } catch (e) {
  //     console.log(e)
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const deleteFunction = async (oldData) => {
    setIsLoading(true);
    try {
      await deleteMobileExpense_base(oldData._links.self.href);
      setInfo(prev => prev.filter(currData => currData.tableData.id !== oldData.tableData.id));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <DefaultTable
      title="Expenses"
      inputColumns={expensesInfoColumns}
      inputData={info}
      // updateFunction={updateFunction}
      addFunction={addFunction}
      deleteFunction={deleteFunction}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />

    {popUpStatus ? <PopUp type="error" message={errorMsg} status={popUpStatus} /> : null}
    </>
  );
};

export default ExpensesCrudTable;
