import React, { useEffect, useState, useContext } from 'react';

import DefaultTable from './DefaultTable';
import ContractorDetails from './table-details/ContractorDetails';
import Dialog from '@material-ui/core/Dialog';
import Edit from '@material-ui/icons/Edit';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import AddBox from '@material-ui/icons/AddBox';
import { roundTo } from '../components/utils/helpers';

import { contractorInfoColumns } from './data/constants';
import {
  // getSpecificUserTypes,
  updateUser_base,
  createUser,
  // resetPassword_base,
  resetPasswordSwal,
} from './API_calls/users';
import { GlobalNameListContext } from '../Context';
import PopUp from './PopUp';

const ContractorCrudTable = ({ roleLink, userData, ...props }) => {
  const { setGlobalNameList } = useContext(GlobalNameListContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [currDetails, setCurrDetails] = useState({});
  const [footerMsg, setFooterMsg] = useState('');

  // for popup
  const [errorMsg, setErrorMsg] = useState('');
  const [popUpStatus, setPopUpStatus] = useState('');
  const [popUpType, setPopUpType] = useState('');

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        const newData = userData.map((a) => Object.assign({}, a));
        newData.forEach((index) => (index.contractorHSTRate = roundTo(index.contractorHSTRate, 2)));
        setInfo(newData);
      } catch (e) {
        console.log('ERROR:', e);
      } finally {
        setIsLoading(false);
      }
    };
    initializeData();
  }, [userData]);

  const addFunction = async (newData) => {
    setIsLoading(true);
    newData.contractorHSTRate = roundTo(newData.contractorHSTRate, 2);
    try {
      const res = await createUser(newData, roleLink);
      setInfo([res, ...info]);
      setOpen(false);
      setGlobalNameList((prev) => ({
        ...prev,
        contractors: [...prev.contractors, { firstName: res.firstName, link: res._links.self.href }],
      }));
    } catch (e) {
      setFooterMsg('Could not submit form. Check duplicate Username');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {
    //triple input error handling
    if (newData.homeTel !== null && newData.homeTel !== '--') {
      if (newData.homeTel.length !== 12 && newData.homeTel.length !== 0) {
        setFooterMsg('Check Home Number');
        return;
      }
    }

    if (newData.fax !== null && newData.fax !== '--') {
      if (newData.fax.length !== 12 && newData.fax.length !== 0) {
        setFooterMsg('Check Fax Number');
        return;
      }
    }
    //end

    setIsLoading(true);
    try {
      await updateUser_base(oldData._links.self.href, newData);
      newData.contractorHSTRate = roundTo(newData.contractorHSTRate, 2);
      const data = info.map((currData) => (currData.tableData.id === oldData.tableData.id ? newData : currData));
      setInfo(data);
      setOpen(false);
    } catch (e) {
      setFooterMsg('Could not submit form. Check duplicate Username/Email');
      console.log(e);
    } finally {
      setIsLoading(false);
      // setFooterMsg('');
    }
  };

  const actions = [
    {
      icon: () => <Edit />,
      tooltip: 'Edit',
      onClick: (event, rowData, togglePanel) => {
        let newData = { ...rowData };
        newData.contractorHSTRate = roundTo(newData.contractorHSTRate, 2);
        setCurrDetails(newData);
        setOpen(true);
      },
    },
    {
      icon: () => <AddBox />,
      tooltip: 'Add',
      isFreeAction: true,
      onClick: (event) => {
        setCurrDetails({});
        setOpen(true);
      },
    },
    {
      icon: () => <LockOpenIcon />,
      tooltip: 'Reset Password',
      onClick: async (event, rowData, togglePanel) => {
        try {
          const status = await resetPasswordSwal(rowData);
          console.log(status);

          if (status === 'success') {
            setErrorMsg(`Password Changed`);
            setPopUpType('success');
            setPopUpStatus(new Date());
          }

          if (status === 'fail') {
            setErrorMsg(
              `Password requirements: Min length 8, 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Char (!@#$%^*...)`
            );
            setPopUpType('error');
            setPopUpStatus(new Date());
          }
        } catch (err) {
          setErrorMsg(`Enter a new password`);
          setPopUpType('error');
          setPopUpStatus(new Date());
        }
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setFooterMsg('');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} scroll="paper">
        <ContractorDetails
          details={currDetails}
          handleClose={handleClose}
          handleSave={updateFunction}
          handleCreate={addFunction}
          type={Object.keys(currDetails).length > 0 ? 'update' : 'add'}
          footerMsg={footerMsg}
        />
      </Dialog>
      <DefaultTable
        title="Contractor Info"
        inputColumns={contractorInfoColumns}
        inputData={info}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        actions={actions}
      />

      {popUpStatus ? <PopUp type={popUpType} message={errorMsg} status={popUpStatus} /> : null}
    </div>
  );
};

export default ContractorCrudTable;
