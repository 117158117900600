import React, { useState, useEffect } from "react";

import DetailHeader from "./DetailHeader";
import DetailFooter from "./DetailFooter";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ReportLabel from "../ReportLabel";
import BasicInput from "../basic-components/BasicInput";
import BasicInputLowerCase from "../basic-components/BasicInputLowerCase";
import BasicTelInput from "../basic-components/BasicTelInput";
import TextField from "@material-ui/core/TextField";
import TripleInputTele from "../basic-components/TripleInputTele";

import BasicTextarea from "../basic-components/BasicTextarea";
import BasicCheckbox from "../basic-components/BasicCheckbox";
import GenderOptions from "../basic-components/GenderOptions";
import Required from "../basic-components/Required";
import "./DetailsGridCSS.scss";
import {
  generateKey,
  formatPhoneNumber,
  normalizePhone2,
} from "../utils/helpers";

import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import { getUser_base } from "../API_calls/users";
import validator from "validator";

const InsuranceAgencyUserDetails = ({
  agencyList,
  handleClose,
  handleSave = null,
  handleCreate = null,
  details,
  type,
  footerMsg,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [phone3Box, setPhone3Box] = useState({});
  const [formatPhone, setFormatPhone] = useState("");

  let validMobileTel = values.mobileTel
  ? values.mobileTel.length === 12
  : false; 

  let validEmail;
  if (!values.email){
    validEmail =  validator.isEmail("")
  } else {
    validEmail = validator.isEmail(values.email)
  }

  useEffect(() => {
    if (details) {
      const initializeData = async () => {
        if (type === "add") {
          setValues({
            enabled: true,
            gender: "Unknown",
            agency: { id: "", name: "" },
          });
        } else {
          const res = await getUser_base(details._links.self.href);

          setValues({
            ...res,
            agency: res._embedded.managedAgency
              ? {
                  name: res._embedded.managedAgency.name,
                  id: res._embedded.managedAgency._links.self.href.split(
                    "{"
                  )[0],
                }
              : { name: "", id: "" },
          });

          let mobileSplit;
          let businessSplit;
          let homeSplit;
          let faxSplit;
          let otherSplit;

          if (res.mobileTel) {
            mobileSplit = res.mobileTel.split("-");
          } else mobileSplit = "";

          if (res.businessTel) {
            res.businessTel = normalizePhone2(res.businessTel);
            businessSplit = res.businessTel.split("-");
          } else businessSplit = "";

          if (res.homeTel) {
            res.homeTel = normalizePhone2(res.homeTel);
            homeSplit = res.homeTel.split("-");
          } else homeSplit = "";

          if (res.fax) {
            res.fax = normalizePhone2(res.fax);
            faxSplit = res.fax.split("-");
          } else faxSplit = "";

          if (res.otherTel) {
            res.otherTel = normalizePhone2(res.otherTel);
            otherSplit = res.otherTel.split("-");
          } else otherSplit = "";

          setPhone3Box({
            mobileTel1: mobileSplit[0],
            mobileTel2: mobileSplit[1],
            mobileTel3: mobileSplit[2],
            businessTel1: businessSplit[0],
            businessTel2: businessSplit[1],
            businessTel3: businessSplit[2],
            homeTel1: homeSplit[0],
            homeTel2: homeSplit[1],
            homeTel3: homeSplit[2],
            fax1: faxSplit[0],
            fax2: faxSplit[1],
            fax3: faxSplit[2],
            otherTel1: otherSplit[0],
            otherTel2: otherSplit[1],
            otherTel3: otherSplit[2],
          });
        }
      };
      initializeData();
    }
  }, [details, type]);

  useEffect(() => {
    console.log("values:", values);
    if (values.username) {
      console.log(validator.isEmail(values.username));
    }
  }, [values]);

  const changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const selectHandler = (selected) => {
    // console.log("SELECTED IS THIS", selected);
    setValues({ ...values, agency: { id: selected } });
  };

  const genderOptions = () => {
    return (
      <GenderOptions
        name="gender"
        value={values.gender}
        onChange={changeHandler}
      />
    );
  };

  const removeWords = (arr) => {
    const finalOP = [];
    if (arr.length === 0) {
      return arr;
    }
    if (!arr[0][1] && !arr[1][1] && !arr[2][1]) {
      return "";
    }

    if (arr.length >= 1) {
      finalOP.push(arr[0][1]);
    }
    if (arr.length >= 2) {
      finalOP.push(arr[1][1]);
    }
    if (arr.length === 3) {
      finalOP.push(arr[2][1]);
    }
    return finalOP.join("-");
  };

  useEffect(() => {
    const formatPhoneNumber = Object.entries(phone3Box).filter((element) => {
      if (element[0].startsWith(phone3Box.type)) {
        return element;
      }
    });
    const numbersOnly = removeWords(formatPhoneNumber);
    setFormatPhone({ type: phone3Box.type, formattedNum: numbersOnly });
  }, [phone3Box]);

  useEffect(() => {
    setValues({ ...values, [formatPhone.type]: formatPhone.formattedNum });
  }, [formatPhone]);

  const changeHandlerPhone = (e) => {
    setPhone3Box({ ...phone3Box, type: e.target.name.slice(0, -1), [e.target.name]: e.target.value.replace(/\D/g, "") });

  };

  return (
    <>
      <DetailHeader title="Agency Admin details" />
      <DialogContent dividers={true}>
        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Username:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                name="username"
                value={values.username}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Enabled:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                name="enabled"
                checked={values.enabled}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Insurance Agency:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <Select
                style={{ width: "100%" }}
                onChange={(e) => selectHandler(e.target.value)}
                value={values.agency ? values.agency.id : ""} // fix once confirmed with Gary
              >
                {agencyList.map((agency) => (
                  <MenuItem key={generateKey()} value={agency.id}>
                    {agency.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              {""}
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Last Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="lastName"
                value={values.lastName}
                error={errors.lastName}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                First Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="firstName"
                value={values.firstName}
                error={errors.firstName}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Gender:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              {genderOptions()}
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Agent Note:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="internalNote"
                value={values.internalNote}
                error={errors.internalNote}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Home:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="homeTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                type="email"
                name="email"
                value={values.email}
                error={values.email ? !validator.isEmail(values.email) : false}
                onChange={changeHandler}
                errorMsg="Not a valid email address"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Mobile:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="mobileTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Fax:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="fax"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Business:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="businessTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Other:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="otherTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Address:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="address"
                value={values.address}
                error={errors.address}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                City:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="city"
                rows={1}
                value={values.city}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Province:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="province"
                rows={1}
                value={values.province}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Postal Code:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="postalCode"
                rows={1}
                value={values.postalCode}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DetailFooter
        // type={Object.keys(details).length > 0 ? "update" : "add"}
        type={type}
        handleClose={handleClose}
        handleSave={() => handleSave(values, details, values.agency.id)}
        handleCreate={() => handleCreate(values)}
        disabled={
          !(
            values.username &&
            validEmail &&
            values.firstName &&
            values.lastName &&
            values.enabled &&
            validMobileTel &&
            values.city &&
            values.province &&
            values.postalCode
          )
        }
        footerMsg={footerMsg}
      />
    </>
  );
};

export default InsuranceAgencyUserDetails;
