import React, { useState, useContext, useEffect } from 'react';
import * as billingHelpers from './BillingHelpers';
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import SimpleButton from '../OrderDetails/SimpleButton';
import { InvoicePayslipDataContext, GlobalNameListContext } from '../../Context';
import LoadingSpinner from '../LoadingSpinner';
import DefaultTable from '../DefaultTable';
import Divider from '@material-ui/core/Divider';
import InvoiceDetails from '../Billing2/InvoiceDetails';
import { searchInvoiceColumns } from '../data/constants';
import DateTimePicker from '../OrderDetails/DateTimePicker';
import AutoCompleteDropDown from '../OrderDetails/AutoCompletes/AutoCompleteDropDown';
import { getPastInvoices } from '../API_calls/invoicePayslip';


const SearchInvoice = ({ invoicePayslipId, setError, history, ...props}) => {

  const classes = billingHelpers.useStyles();

  const { invoicePayslipData, setInvoicePayslipData } = useContext(InvoicePayslipDataContext);
  const { globalNameList } = useContext(GlobalNameListContext);

  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('SUMMARY');
  const [searchField, setSearchField] = useState({ invoiceID: '', insuranceCompany: {}, completionFromDate: null, completionToDate: null });

  // TABLE STATES
  const [pageSize, setPageSize] = useState(50);
  const [details, setDetails] = useState({});
  const [listPage, setListPage] = useState(0);

  // FUNCTION CALL FOR GETTING DATA LIST
  const getData = async (data, page = 0, search = true) => {
    setIsLoading(true);
    try {
      const params = {
        invoiceID: data.invoiceID ? data.invoiceID : null,
        insuranceCompany: data.insuranceCompany !== {} ? data.insuranceCompany.link : null,
        completionFromDate: data.completionFromDate ? data.completionFromDate : null,
        completionToDate: data.completionToDate ? data.completionToDate : null
      }
      const res = await getPastInvoices(params, page);
      search ? setInvoicePayslipData(res) : setInvoicePayslipData(prev => [...prev, ...res]);
      setIsLoading(false);
    } catch (err) {
      console.log('err:', err);
      setError(prev => ({...prev, status: 'Error', msg: 'Search returned no results. Please try again.'}));
    }
  };

  // ON UNMOUNT
  useEffect(() => {
    return () => setInvoicePayslipData([]);
  }, []);

  const changeDate = (val, setCurrentTime, source) => {
    setCurrentTime(val);
    setSearchField(prev => ({ ...prev, [source]: val ? val.format('MM-DD-YYYY') : val }));
  };

  const showDetails = data => {
    setDetails(prev => ({ ...prev, ...data }));
    setMode('DETAILS');
  };

  const onMoreOrdersClick = () => {
    setListPage(prev => prev + 1);
    getData(searchField, listPage + 1, false);
  };

  return (
    <>
      {mode === 'DETAILS' && 
        <InvoiceDetails
          details={details}
          setDetails={setDetails}
          created={true}
          // upcompletionToDate={searchField.completionToDate}
          setMode={setMode}
          invoicePayslipId={invoicePayslipId}
          history={history}
        />
      }
      {mode === 'SUMMARY' && 
        <>
          <Grid container spacing={1} style={{ margin: "10px 0"}}>
            <Grid item container lg={11} sm={12} xs={12} spacing={1}>
              <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
                <TextField
                  className={clsx(classes.textField, classes.dense)}
                  margin="none"
                  label='Invoice No.:'
                  fullWidth
                  value={searchField.invoiceID}
                  // error={error.error}
                  // helperText={error.msg}
                  onChange={e => setSearchField(prev => ({ ...prev, invoiceID: e.target.value }))}
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
                <AutoCompleteDropDown
                  onChange={(e, newVal) => setSearchField(prev => ({ ...prev, insuranceCompany: newVal }))}
                  dropDownData={globalNameList.insuranceCompanies}
                  dropDownVal='name'
                  renderItems={searchField.insuranceCompany ? [searchField.insuranceCompany] : [{name: '', link: '', defaultLab: ''}]}
                  enableDelete={false}
                  label='Insurance Company:'
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
                <DateTimePicker
                  data-cy="search-invoice-from"
                  source='completionFromDate'
                  completionFromDate={searchField.completionFromDate}
                  label='From:'
                  changeDate={changeDate}
                  clearable
                />
              </Grid>
              <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
                <DateTimePicker
                  data-cy="search-invoice-to"
                  source='completionToDate'
                  completionToDate={searchField.completionToDate}
                  label='To:'
                  changeDate={changeDate}
                  clearable
                />
              </Grid>
            </Grid>

            <Grid item container lg={1} sm={12} xs={12} style={{ marginTop: "20px", textAlign: "center" }}>
              <Grid item xs={12}>
                <SimpleButton onClick={() => getData(searchField)} text={"SEARCH"} icon={<SearchIcon />} />
              </Grid>
            </Grid>
          </Grid>
          <Divider />
          {isLoading ? <LoadingSpinner /> :
            <DefaultTable
              onRowClick={(e, rowData) => showDetails(rowData)}
              title={""}
              inputColumns={searchInvoiceColumns}
              inputData={invoicePayslipData}
              pageSize={pageSize}
              setPageSize={setPageSize}
              loadMoreOrders={onMoreOrdersClick}
            />}
        </>
      }
    </>
  );
};

export default SearchInvoice;