import React, { useContext } from "react";
import { Redirect, Route } from "react-router-dom";
// import { getJWT, removeJWT, getUserId, removeUserId } from "./utils/JWT";
// import { getUser } from "./API_calls/users";
import { UserContext } from "../UserContext";
// import LoadingSpinner from "./LoadingSpinner";

const SysAdminRoute = ({ children, ...rest }) => {
  const { userState } = useContext(UserContext);
  return (
    userState.userInfo.hasOwnProperty("isAdmin") && (
      <Route
        {...rest}
        render={({ location }) =>
          userState.userInfo.isAdmin ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login"
                // state: { from: location }
              }}
            />
          )
        }
      />
    )
  );
};

export default SysAdminRoute;
