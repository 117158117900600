import React, { useContext, useEffect, useState } from "react";
import { RolesContext } from "../UserContext";

import { getSpecificUserTypes } from "../components/API_calls/users";
import { getAllInsuranceCompanies } from "../components/API_calls/insuranceCompanies";
import { getAllAgencies } from "../components/API_calls/agencies";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import LoadingSpinner from "../components/LoadingSpinner";

import AgentCrudTable from "../components/AgentCrudTable";
import ContractorCrudTable from "../components/ContractorCrudTable";
import SysAdminCrudTable from "../components/SysAdminCrudTable";
import InsuranceAgencyUserCrudTable from "../components/InsuranceAgencyUserCrudTable";
import InsuranceCompanyUserCrudTable from "../components/InsuranceCompanyUserCrudTable";
// import { makeStyles } from "@material-ui/core";


// const useStyles = makeStyles(theme => ({
//   content: {
//     flexGrow: 1,
//     transition: theme.transitions.create("margin", {
//       easing: theme.transitions.easing.sharp,
//       duration: theme.transitions.duration.leavingScreen
//     }),
//     marginRight: 0,
//     width: '100%'
//   }
// }));

const UsersPage = () => {

  // const classes = useStyles();

  const { roles } = useContext(RolesContext);
    
  useEffect(() => {
    if (Object.keys(roles).length === 7) {
      const obtainUserData = async () => {
        setIsLoading(true); 
        try {
          const sysAdminData = await getSpecificUserTypes(roles["ROLE_SUPER_ADMIN"]);
          const agentData = await getSpecificUserTypes(roles['ROLE_AGENCY_USER']);
          const contractorData = await getSpecificUserTypes(roles["ROLE_CONTRACTOR"]);
          const insuranceAgencyUserData = await getSpecificUserTypes(roles["ROLE_AGENCY_ADMIN"]);
          const insuranceCompanyUserData = await getSpecificUserTypes(roles["ROLE_INSURANCE_USER"]);
          const companyListData = await getAllInsuranceCompanies();
          const agencyListData = await getAllAgencies();
          setUserData({
            sysAdminData,
            agentData,
            contractorData,
            insuranceAgencyUserData,
            insuranceCompanyUserData
          });
          setCompanyList(companyListData);
          setAgencyList(agencyListData);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      obtainUserData();
    }
  }, [roles]);

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({});
  const [agencyList, setAgencyList] = useState([]);
  const [companyList, setCompanyList] = useState([]);

  const TabPanel = ({ children, value, index }) => {
    return (
      <Typography component="div" role="tabpanel" hidden={value !== index}>
        <Box
          style={{
            padding: "10px",
            overflow: "auto",
            margin: "5px",
          }}
        >
          {children}
        </Box>
      </Typography>
    );
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ width: "100%", margin: "0", padding: "0" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab data-cy='sysAdminTab' label="System Admins" />
          <Tab data-cy='agentsTab' label="Agents" />
          <Tab data-cy='contractorsTab' label="Contractors" />
          <Tab data-cy='agencyAdminTab' label="Agency Admins" />
          <Tab data-cy='InsuranceUsersTab' label="Insurance Company Users" />
        </Tabs>
      </AppBar>
        <TabPanel value={value} index={0}>
          <SysAdminCrudTable roleLink={roles["ROLE_SUPER_ADMIN"]} userData={userData.sysAdminData} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <AgentCrudTable
            roleLink={roles["ROLE_AGENCY_USER"]}
            userData={userData.agentData}
            companyListData={companyList}
            agencyListData={agencyList}
          />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <ContractorCrudTable
            roleLink={roles["ROLE_CONTRACTOR"]}
            userData={userData.contractorData}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <InsuranceAgencyUserCrudTable
            roleLink={roles["ROLE_AGENCY_ADMIN"]}
            userData={userData.insuranceAgencyUserData}
            agencyListData={agencyList}
          />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <InsuranceCompanyUserCrudTable
            roleLink={roles["ROLE_INSURANCE_USER"]}
            userData={userData.insuranceCompanyUserData}
            companyListData={companyList}
          />
        </TabPanel>
    </>
  );
};

export default UsersPage;
