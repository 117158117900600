import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { getGlobalData } from "./API_calls/global";
import { Helmet } from "react-helmet";

import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { themeTemplates } from "./utils/material-theme";

import {
  OrdersProvider,
  OrderTabProvider,
  GlobalNameListProvider,
  InvoicePayslipDataProvider,
  WebRequestsProvider
} from "../Context";
import { RolesProvider } from "../UserContext";

import "./App.scss";

import AuthenticateComponent from "./AuthenticateComponent";
// import AdminAuthorizeComponent from "./AdminAuthorizeComponent";
import DrawerMenu from "./Menu/DrawerMenu";
import Footer from "../pages/Footer";
import RegisterPage from "../pages/RegisterPage";
import LoginPage from "../pages/LoginPage";
import PasswordReset from "../pages/PasswordReset";

import ProfilePage from "../pages/ProfilePage"; 
//import OrderTable from "./OrderTable/OrderTable";
import OrderTableNew from "./OrderTable/OrderTableNew";
import OrderDetail from "./OrderDetails/OrderDetail";
import ErrorPage from "../pages/ErrorPage";
import RatesAndSchedulesPage from "../pages/RatesAndSchedulesPage";
import UsersPage from "../pages/UsersPage";
import CompaniesPage from "../pages/CompaniesPage";
import ServiceAndExpensePage from "../pages/ServiceAndExpensePage";
import NewOrderPage from "../pages/NewOrderPage";
import OrderListPage from "../pages/OrderListPage";
import OrderRequestsPage from "../pages/OrderRequestsPage";
import BillingPage from "./Billing2/BillingPage";
// import InvoicePayslipDetails from "./Billing/InvoicePayslipDetails";

import SysAdminRoute from "./SysAdminRoute";
import ViewOrderPage from "../pages/ViewOrderPage";
import InsurancePortalListPage from "../pages/InsurancePortalListPage";
import InsurancePortalDetailPage from "../pages/InsurancePortalDetailPage";
// import AgentRoute from "./AgentRoute";

const App = () => {
  const [globalData, setGlobalData] = useState({
    appColorTheme: "",
    appDescription: "",
    appName: "",
    companyName: ""
  });

  let themeType = globalData.appColorTheme !== "" ? globalData.appColorTheme : "Default";

  const theme = createMuiTheme({
    palette: {
      primary: themeTemplates[themeType].main,
      secondary: themeTemplates[themeType].secondary
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(","),
      fontSize: 12,
      overline: {
        fontWeight: 400,
        fontSize: "0.6rem",
        lineHeight: '0.6rem',
        paddingLeft: '0.4rem',
        textTransform: "uppercase"
      }
    },
    overrides: {
      MuiSelect: {
        select: {
          paddingLeft: '0px !important'
        },
        root: {
          textTransform: 'uppercase'
        }
      },
    }
  });

  
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const res = await getGlobalData();
        setGlobalData(res);
      } catch (err) {
        console.log("ERR:", err);
      }
    };
    fetchInitialData();
  }, []);

  return (
    <>
      <MuiThemeProvider theme={theme}>
        <Helmet>
          <title>{globalData.appName}</title>
          <meta name="description" content={globalData.appDescription} />
        </Helmet>
        <div className="main-container">
          <Router>
            <Switch>
              {/* <Redirect exact path="/" to="/orders" /> */}
              <Route exact path="/login" component={LoginPage} />
              <Route exact path="/users/changePassword" component={PasswordReset} />

              <WebRequestsProvider>
                <DrawerMenu globalData={globalData}>
                  <AuthenticateComponent>
                    <Switch>
                      <Route exact path="/footer" component={Footer} />
                      {/* ***** SYSADMIN ROUTES */}
                      <GlobalNameListProvider>
                        <OrdersProvider>
                          <RolesProvider>
                            <SysAdminRoute data-cy="orderlist" exact path="/orders"> 
                              <OrderTableNew />
                            </SysAdminRoute>
                            <OrderTabProvider>
                              <SysAdminRoute exact path="/orders/:orderId">
                                <OrderDetail />
                              </SysAdminRoute>
                            </OrderTabProvider>
                            <SysAdminRoute exact path="/fee-schedule">
                              <RatesAndSchedulesPage />
                            </SysAdminRoute>
                            <SysAdminRoute exact path="/users">
                              <UsersPage />
                            </SysAdminRoute>
                            <SysAdminRoute exact path="/companies">
                              <CompaniesPage />
                            </SysAdminRoute>
                            <SysAdminRoute exact path="/service-expense">
                              <ServiceAndExpensePage />
                            </SysAdminRoute>
                            <InvoicePayslipDataProvider>
                              <SysAdminRoute exact path="/invoice-payslip/:invoicePayslipId">
                                <BillingPage />
                              </SysAdminRoute>
                            </InvoicePayslipDataProvider>
                            {/* ***** ADMIN ROUTES ***** AGENT ROUTES */}
                            {/* <AgentRoute exact path="/new-order">
                              <NewOrderPage />
                            </AgentRoute>
                            <AgentRoute exact path="/order-list">
                              <OrderListPage />
                            </AgentRoute>
                            <AgentRoute exact path="/order-requests">
                              <OrderRequestsPage />
                            </AgentRoute> */}
                            <Route exact path="/new-order/:orderType">
                              <NewOrderPage />
                            </Route>
                            <Route exact path="/order-list">
                              <OrderListPage />
                            </Route>
                            <Route exact path="/order-requests">
                              <OrderRequestsPage />
                            </Route>
                            <Route exact path="/view-order/:orderID">
                              <ViewOrderPage />
                            </Route>
                            {/* **** INSURANCE COMPANY USER PORTAL ***** */}
                            <Route exact path='/insurance-user/orders'>
                              <InsurancePortalListPage />
                            </Route>
                            <Route exact path='/insurance-user/orders/:orderID'>
                              <InsurancePortalDetailPage />
                            </Route>
                          </RolesProvider>
                        </OrdersProvider>
                        {/* ***** PUBLIC ROUTES */}
                        <Route exact path="/profile/:id" component={ProfilePage} />
                        <Route exact path="/register" component={RegisterPage} />

                      </GlobalNameListProvider>
                      <Route component={ErrorPage} />
                    </Switch>
                  </AuthenticateComponent>


                </DrawerMenu>
              </WebRequestsProvider>
            </Switch>
          </Router>
        </div>
      </MuiThemeProvider>
    </>
  );
};

export default App;
