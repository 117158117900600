import React, { useContext, useEffect, useState } from "react";
import DefaultTable from "./DefaultTable";
import { webAccessLogsInfoColumns } from "./data/constants";
import { getWebAccessLogs } from "./API_calls/webAccessLogs";
import { RolesContext } from "../UserContext"; 
import { getSpecificUserTypes } from "../components/API_calls/users";


const WebAccessLogsCrudTable = ({ details, ...props }) => {

  const [webAccessLogs, setWebAccessLogs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const { roles } = useContext(RolesContext);
  
  //calling api for getWebAccessLogs and getSpecificUserTypes, filtering through to only show role of insurance user
  useEffect(() => {
      const initialize = async () => {
        setIsLoading(true);
        try {
          if (roles["ROLE_INSURANCE_USER"]) {
            const webAccessLogsData = await getWebAccessLogs(); 
            const insuranceCompanyUserData = await getSpecificUserTypes(roles["ROLE_INSURANCE_USER"]); //filters through users
            const filteredInsuranceUsers = insuranceCompanyUserData.map(user => user.username)
            let filteredAccessLogs = webAccessLogsData.filter(function(log){
              return filteredInsuranceUsers.includes(log.by.username)
            })
            setWebAccessLogs(filteredAccessLogs.map((log, index) => ({...log, id: index, by: `${log.by.lastName}, ${log.by.firstName}`})));
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      initialize();
   }, [details]);

  return (
    <DefaultTable
      title="Web Access Logs"
      inputColumns={webAccessLogsInfoColumns}
      inputData={webAccessLogs}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default WebAccessLogsCrudTable;
