import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom';

import DefaultTable from "./DefaultTable";

import { pendingReportInfoColumns } from "./data/constants";
import { UserContext } from "../UserContext";
import { searchOrders, updateOrder_base } from "./API_calls/orders";

import FileCopyIcon from '@material-ui/icons/FileCopy';

const PendingReportTable = ({ ...props }) => {

  const history = useHistory();

  const { userState } = useContext(UserContext);
  
  const [orderList, setOrderList] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (userState) {
      const getData = async () => {
        const list = await searchOrders({
          agent: userState.userInfo.link,
          statusList: 'Pending,Assignment'
        });
        setOrderList(list);
      }
      getData();
    }
  }, [userState]);


  const deleteFunction = async (oldData) => {
    try {
      await updateOrder_base(oldData._links.self.href, { status: 'Cancelled' });
      const updatedList = orderList.filter((currData) => currData.tableData.id !== oldData.tableData.id);
      setOrderList(updatedList);
    } catch (err) {
      console.log(err);
    }
  };

  const onRowClick = (event, rowData) => {
    console.log('rowData:', rowData)
    history.push({
      pathname: `/view-order/${rowData.orderID}`,
      state: { orderLink: rowData._links.self.href }
    });
  };

  const actions = [
    rowData => 
    ({
      icon: () => <FileCopyIcon />,
      tooltip: "Copy Order",
      onClick: (event, rowData, togglePanel) => {
        history.push({
          pathname: '/new-order/copy',
          state: { orderLink: rowData._links.self.href }
        });
      },
    }),
  ];

  return (
    <DefaultTable
      title="Pending Requests"
      inputColumns={pendingReportInfoColumns}
      inputData={orderList}
      deleteFunction={deleteFunction}
      pageSize={pageSize}
      setPageSize={setPageSize}
      headerStyle={{ backgroundColor: "#34b7eb", textTransform: 'capitalize' }}
      toolbarStyle={{ backgroundColor: "lightgreen", textTransform: 'capitalize' }}
      actions={actions}
      onRowClick={onRowClick}
    />
  );
};

export default PendingReportTable;
