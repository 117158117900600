import React from "react";
import AgentReport from "./AgentReport";
import ReportWrapper from "../components/ReportWrapper";

const AgentReportViewer = ({ closeDialog, id }) => {
  return (
    <div>
      <ReportWrapper closeDialog={closeDialog} title="Agent Report">
        <AgentReport id={id} />
      </ReportWrapper>
    </div>
  );
};

export default AgentReportViewer;
