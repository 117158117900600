import React, { useRef } from "react";
import AddBox from "@material-ui/icons/AddBox";
import "./NewAddBox.scss";

const NewAddBox = ({ onChange, ...props }) => {
  const ref = useRef(null);
  return (
    // <span className="newAddBox">
    //   <input
    //     type="file"
    //     id="imgupload"
    //     style={{ display: "none", padding: "0px", margin: "0px" }}
    // onChange={onChange}
    //   />
    //   <label
    //     className="newAddBox"
    //     for="imgupload"
    //     style={{ padding: "0px", margin: "0px", display: "flex" }}
    //   >
    //     <AddBox />
    //   </label>
    // </span>
    <span className="newAddBox" style={{ padding: "0px", margin: "0px", display: "flex" }}>
      <input ref={ref} type="file" id="file" style={{ display: "none" }} onChange={onChange} />
      <AddBox onClick={() => ref.current.click()} />
    </span>
  );
};

export default NewAddBox;
