import React, { useRef, useState, forwardRef } from "react";
import MaterialTable, { MTableToolbar, MTableBody } from "material-table";
import "./DefaultTable.scss";

import AddBox from "@material-ui/icons/AddBox";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
// import { TableBody } from "@material-ui/core";
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import zIndex from "@material-ui/core/styles/zIndex";

const DefaultTable = ({
  inputColumns,
  inputData,
  detailPanel = null,
  title,
  density = "dense",
  addFunction = false,
  updateFunction = false,
  deleteFunction = false,
  customAdd = true,
  actions = [],
  isLoading = false,
  headerStyle,
  rowStyle,
  toolbarStyle,
  pageSize = 5,
  setPageSize,
  filtering = false,
  onRowClick = null,
  selection = false,
  onSelectionChange = null,
  selectionProps = {},
  bodyExtra = null,
  summaryFooter = null,
  defaultFilter = false,
  paging = true,
  toolbar = true,
  className = 'table-container',
  cyData,
  // openExtra = null,
  // openDetails = null,
  loadMoreOrders, // this is a function to call onClick
  ...props
}) => {
  const ref = useRef(null);
  // console.log("reference is this:", ref);
  const [filter, setFilter] = useState(defaultFilter);

  // useEffect(()=>{
  //   openExtra(ref);
  // },[openDetails])

  const addID=`add${cyData}`
  const editID=`edit${cyData}`


  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} data-cy={addID} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    Edit: forwardRef((props, ref) => <Edit data-cy={editID} {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
    ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
  };

  const editableSettings = {
    onRowAdd: addFunction ? addFunction : null,
    onRowUpdate: updateFunction ? updateFunction : null,
    onRowDelete: deleteFunction ? deleteFunction : null
  };

  const styles = {
    header: {
      backgroundColor: "#5c5d5e",
      color: "#FFF",
      textTransform: 'capitalize',
      zIndex: 0
    },
    toolbar: {
      backgroundColor: "white",
      textTransform: 'capitalize'
    },
    row: null
  };

  const filterFeature = {
    icon: () => {
      return <FilterList />;
    },
    tooltip: "Toggle Filter",
    isFreeAction: true,
    onClick: (event, rowData) => {
      setFilter(!filter);
    }
  };
  // console.log(inputData);
  // console.log("THE REFERENCE IS THIS", ref.current);

  return (
    <div className={className}>
      <MaterialTable
        tableRef={ref}
        isLoading={isLoading}
        onRowClick={onRowClick}
        icons={tableIcons}
        title={title}
        columns={inputColumns}
        data={inputData}
        editable={editableSettings}
        style={{ textTransform: 'uppercase' }}
        options={{
          exportButton: true,
          filtering: filter,
          padding: density,
          debounceInterval: 500,
          headerStyle: headerStyle || styles.header,
          pageSize: pageSize,
          pageSizeOptions: [5, 10, 25, 50],
          addRowPosition: "first",
          emptyRowsWhenPaging: false,
          selection: selection,
          showSelectAllCheckbox: false,
          showTextRowsSelected: false,
          rowStyle: rowStyle || styles.row,
          columnsButton: true,
          toolbar: toolbar,
          paging: paging
          // selectionProps: selectionProps
        }}
        actions={[...actions, filterFeature]}
        // actions={actions}
        components={{
          Toolbar: (props) => (
            <div style={toolbarStyle || styles.toolbar}>
              <MTableToolbar {...props} />
            </div>
          ),
          Body: (props) => {
            if (ref.current && summaryFooter) {
              return (
                <>
                  <MTableBody {...props} />
                  {summaryFooter(ref.current.state.data)}
                </>
              );
            } else {
              return <MTableBody {...props} />;
            }
          },
          Pagination: (props) => {
            return (
              <>
                <TablePagination {...props} />
                {loadMoreOrders && (
                  <td style={{ float: 'right', marginRight: '1em' }}>
                    <Button size='small' onClick={loadMoreOrders}>Load More Orders</Button>
                  </td>
                )}
              </>
                ) 
          } 
        }}  
        onChangeRowsPerPage={(value) => setPageSize(value)}
        onSelectionChange={onSelectionChange}
        localization={{
          toolbar: {
            searchPlaceholder: "Filter"
          },
          header: {
            actions: ""
          }
        }}
        detailPanel={detailPanel}
      />
    </div>
  );
};

export default DefaultTable;
