import React, { useState, useEffect, useContext } from "react";
import { getRoles } from "../API_calls/orders";
import { getTargetRole } from "./orderDetailsHelpers";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { getSpecificUserTypes, createUser } from "../API_calls/users";
import DefaultTable from "../DefaultTable";
import { searchAgentOrderInfoColumns } from "../../components/data/constants";
import AgentDetails from "../table-details/AgentDetails";
import Dialog from '@material-ui/core/Dialog';
import { getAllInsuranceCompanies } from "../API_calls/insuranceCompanies";
import { getAllAgencies } from "../API_calls/agencies";
import AddBox from '@material-ui/icons/AddBox';
import { GlobalNameListContext } from "../../Context";

const SearchAgentDialog = ({ okSearchAgent, history, ...props }) => {

  const { setGlobalNameList } = useContext(GlobalNameListContext);
  
  const [info, setInfo] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState({});
  const [targetRole, setTargetRole] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);

  // ADD AGENT DIALOG STATE
  const [open, setOpen] = useState(false);
  const [footerMsg, setFooterMsg] = useState('');
  const [companyList, setCompanyList] = useState([]);
  const [agencyList, setAgencyList] = useState([]);

  useEffect(() => {
    getRoles().then(res => setTargetRole(getTargetRole(res, "ROLE_AGENCY_USER"))); //use this role to fetch all agents
  }, []);

  // INITIALIZE ADD AGENT DIALOG
  useEffect(() => {
    const initializeData = async () => {
      try {
        const resCompanyList = await getAllInsuranceCompanies();
        resCompanyList.map((company) => {
          return { id: company._links.self.href, name: company.name };
        });
        setCompanyList(resCompanyList);
        const resAgencyList = await getAllAgencies()
        resAgencyList.map((agency) => {
          return { id: agency._links.self.href, name: agency.name };
        });
        setAgencyList(resAgencyList);
      } catch (e) {
        console.log("ERROR:", e);
      }
    };
    initializeData();
  }, []);

  useEffect(() => {
    if (targetRole) {
      console.log('targetRole:', targetRole)
      const getAgentList = async () => {
        const list = await getSpecificUserTypes(targetRole._links.self.href);
        setInfo(list);
        setIsLoading(false);
      }
  
      getAgentList();
    }
  
  }, [targetRole]);

  const onRowClick = (event, rowData) => {
    const data = info.map(currData => {
      if (currData.tableData.id === rowData.tableData.id) {
        const newRow = currData;
        newRow.tableData.clicked = true;
        return newRow;
      } else {
        const oldRow = currData;
        oldRow.tableData.clicked = false;
        return oldRow;
      }
    });
    setSelectedAgent(rowData);
    setInfo(data);
  };

  const actions = [
    {
      icon: () => <AddBox />,
      tooltip: "Add",
      isFreeAction: true,
      onClick: () => setOpen(true),
    },
  ];

  // ADD AGENT DIALOG FUNCTIONS
  const handleClose = () => {
    setOpen(false);
    setFooterMsg('');
  };

  const addFunction = async (newData) => {
    setIsLoading(true);
    try {
      const res = await createUser(newData, targetRole._links.self.href);
      setInfo(prev => [res, ...prev]);
      setOpen(false);
      setGlobalNameList(prev => ({ ...prev, agents: [...prev.agents, { firstName: res.firstName, lastName: res.lastName, link: res._links.self.href }]}));
    } catch (e) {
      setFooterMsg('Could not submit form. Please check all required fields again.');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  ///////////////////////////////////////////////////////////////////////////

  return (
    <>
      <DialogTitle id="form-dialog-title">Search Agent For Order</DialogTitle>
      <DialogContent>
        <div style={{ marginTop: "10px" }}>
          <DefaultTable
            title="Select Agent"
            inputColumns={searchAgentOrderInfoColumns}
            inputData={info}
            isLoading={isLoading}
            pageSize={pageSize}
            setPageSize={setPageSize}
            onRowClick={onRowClick}
            rowStyle={(data, index) => {
              if (data.tableData.clicked) {
                return { backgroundColor: "darkgrey", color: "white" };
              }
            }}
            actions={actions}
          />
        </div>
        <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} scroll="paper">
          <AgentDetails
            details={{}}
            companyList={companyList}
            agencyList={agencyList}
            handleClose={handleClose}
            handleCreate={addFunction}
            type='add'
            footerMsg={footerMsg}
          />
        </Dialog>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => okSearchAgent(selectedAgent)} color="primary">
          Ok
        </Button>
        <Button onClick={props.handleClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </>
  );
};

export default SearchAgentDialog;
