import React from 'react';
import InvoicePdfHeader from './InvoicePdfHeader';

import './Invoice.scss';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generateKey, roundTo } from '../../utils/helpers';
import moment from 'moment';
import InvoiceSummary from './InvoiceSummary';

const Invoice1 = ({ details, summaryDetails, ...props }) => {
  const columnHeaders = [
    'Order ID',
    'Compl.',
    'Applicant Name',
    'Appl. #',
    'Agent Name',
    'Service(s)',
    'Amount',
    'Mileage',
    'Total',
  ];

  const tableColumns = columnHeaders.map((header, index) => {
    return (
      <TableCell key={generateKey()} align="center">
        {header}
      </TableCell>
    );
  });

  const tableRows = details.orders.map((order, index) => {
    return (
      <TableRow key={generateKey()}>
        <TableCell align="center">{order.medifastID}</TableCell>
        <TableCell align="center">{moment(order.completionTime).format('MM-DD-YYYY')}</TableCell>
        <TableCell align="center">
          {order.clientInfo.lastName}, {order.clientInfo.firstName}
        </TableCell>
        <TableCell align="center">{/* WHAT IS THIS NUMBER */}</TableCell>
        <TableCell align="center">
          {order.agentLastName}, {order.agentFirstName}
        </TableCell>
        <TableCell align="center">
          {order.serviceItems.length > 0
            ? order.serviceItems.reduce((acc, curr) => acc + curr.serviceType.shortCode + ' ', '')
            : ''}
        </TableCell>
        <TableCell align="right">
          $
          {roundTo(
            order.serviceItems.reduce((acc, curr) => acc + curr.serviceAmount, 0),
            2
          )}
        </TableCell>
        <TableCell align="right">{order.serviceItems.reduce((acc, curr) => acc + curr.em, 0)}</TableCell>
        <TableCell align="right">
          $
          {roundTo(
            order.serviceItems.reduce((acc, curr) => acc + curr.mileageAmount, 0),
            2
          )}
        </TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <div id="invoice-container">
        <InvoicePdfHeader
          title="Invoice"
          insuranceCompany={details.insuranceCompany}
          invoiceDate={details.invoiceDate}
          invoiceID={details.invoiceID}
          upToPeriod={details.upToPeriod}
        />
        <div className="invoice-table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>{tableColumns}</TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </div>
      </div>

      <div id="invoice-container" className="invoice-summary">
        <InvoicePdfHeader
          title="Summary"
          insuranceCompany={details.insuranceCompany}
          invoiceDate={details.invoiceDate}
          invoiceID={details.invoiceID}
          upToPeriod={details.upToPeriod}
        />
        <InvoiceSummary summaryDetails={summaryDetails} />
      </div>
    </>
  );
};

export default Invoice1;
