import instance from "../utils/API";
import instance_base from "../utils/API_base";

const createInvoice = async details => {
  try {
    const res = await instance.post('/insuranceCompanyInvoices', details);
    return res.data;
  } catch (err) {
    throw err;
  }
};

const createPayslip = async details => {
  try {
    const res = await instance.post('/contractorPayslips', details);
    return res.data;
  } catch (err) {
    throw err;
  }
};

const getUnbilledCompaniesInvoices = async date => {
  try {
    const res = await instance.get(`/insuranceCompanies/search/outstandingOrders?cutOffDate=${date}&projection=invoice`);
    return res.data._embedded.insuranceCompanies;
  } catch (err) {
    throw err;
  }
};

const getPastInvoices = async (searchParams, page = 0) => {
  try {
    const res = await instance.get(`/insuranceCompanyInvoices/search/invoices`, {
      params: { 
        ...searchParams, 
        projection: 'invoice', 
        page,
        size: 25,
        sort: 'createDateTime,desc' 
      },
    });
    return res.data._embedded.insuranceCompanyInvoices;
  } catch (err) {
    throw err;
  }
};

const getInvoiceSummary = async (invoiceUrl) => {
  try {
    const res = await instance_base.get(`${invoiceUrl}?projection=reportSummary`);
    return res.data;
  } catch (err) {
    throw err;
  }};

const removeOrderFromInvoice = async (invoiceUrl, orderId) => {
  try {
    const res = await instance_base.delete(`${invoiceUrl}/orders/${orderId}`);
    return res;
  } catch (err) {
    throw err;
  }
};

const getUnpaidContractorsPayslips = async date => {
  try {
    const res = await instance.get(`/users/search/outstandingOrders?cutOffDate=${date}&projection=payslip`);
    return res.data._embedded.users;
  } catch (err) {
    throw err;
  }
};

const addOrderToInvoice = async (invoiceUrl, ordersArray) => {
  try {
    const res = await instance_base.patch(invoiceUrl, { orders: ordersArray });
    return res.data;
  } catch (err) {
    throw err;
  }
};

const getPastPayslips = async (searchParams, page = 0) => {
  try {
    const res = await instance.get(`/contractorPayslips/search/payslips`, {
      params: {
        ...searchParams, 
        projection: 'payslipSearch', 
        page,
        size: 25,
        sort: 'createDateTime,desc' 
      }
    });
    return res.data._embedded.contractorPayslips;
  } catch (err) {
    throw err;
  }
};

const updateServiceItemToPayslip = async (payslipUrl, itemsArray) => {
  try {
    const res = await instance_base.patch(payslipUrl, { serviceItems: itemsArray });
    return res.data;
  } catch (err) {
    throw err;
  }
};

export { 
  createInvoice, 
  createPayslip, 
  getUnbilledCompaniesInvoices, 
  removeOrderFromInvoice, 
  getPastInvoices, 
  addOrderToInvoice, 
  getUnpaidContractorsPayslips, 
  getPastPayslips, 
  updateServiceItemToPayslip,
  getInvoiceSummary
};
