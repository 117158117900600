import instance from "../utils/API";

const getAllEmailLogs = async () => {
  try {
    const res = await instance
      .get("/emailLogs", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.emailLogs;
  } catch (error) {
    throw error;
  }
};

const createEmailLog = async (info) => {
  try {
    const res = await instance.post("/emailLogs", info).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

export { getAllEmailLogs, createEmailLog};
