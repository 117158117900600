import async from "async";
import { updateOrder } from "../API_calls/orders";

const idArray = [
  58,
  59,
  60,
  61,
  62,
  63,
  64,
  65,
  66,
  67,
  68,
  69,
  70,
  71,
  72,
  73,
  74,
  75,
  76,
  77,
  78,
  79,
  80,
  81,
  82,
  83,
  84,
  85,
  86,
  87,
  88,
  89,
  90,
  91,
  92,
  93,
  94,
  95
];

const statuses = ["available", "taken", "pending", "cancelled"];
const agents = ["Peter Parker", "Clark Kent", "Bruce Wayne", "Charles Xavier"];

const doStuff = () => {
  async.each(idArray, (id) => {
    updateOrder(id, {
      assignee: agents[Math.floor(Math.random() * agents.length)],
      location: "Toronto 123",
      serviceTime: "2019-10-24T18:38:10.242008",
      serviceType: "test typeca",
      status: statuses[Math.floor(Math.random() * statuses.length)]
    });
  });
};

export default doStuff;
