import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

export const invoiceCompanyList = () => {
  return [
    {
      id: 1,
      company: "BMO-BMO ASSURANCE COMPANY (TORONTO)",
      unbilledOrderAmount: 571.45,
      lastInvoiceDate: "2019-10-24T18:45:08.493002"
    },
    {
      id: 2,
      company: "CA-CANADA LIFE (TORONTO) LAB CODE 5862",
      unbilledOrderAmount: 2835.55,
      lastInvoiceDate: "2019-10-24T18:45:08.493002"
    },
    {
      id: 3,
      company: "CA(CI)-CANADA LIFE (CI & DI) LAB CODE 5874",
      unbilledOrderAmount: 164.2,
      lastInvoiceDate: "2019-10-24T18:45:08.493002"
    },
    {
      id: 4,
      company: "DFS-DESJARDINS FINANCIAL SECURITY (TORONTO)",
      unbilledOrderAmount: 2126.25,
      lastInvoiceDate: "2019-10-24T18:45:08.493002"
    },
    {
      id: 5,
      company: "EM-THE EMPIRE LIFE INSURANCE CO. (KINGSTON) LAB CODE 5707",
      unbilledOrderAmount: 537.4,
      lastInvoiceDate: "2019-10-24T18:45:08.493002"
    },
    {
      id: 6,
      company: "IA INS-INDUSTRIAL ALLIANCE INSURANCE AND FINANCIAL SERVICES INC.",
      unbilledOrderAmount: 16174.3,
      lastInvoiceDate: "2019-10-24T18:45:08.493002"
    }
  ];
};

export const getCompanyInvoiceDetailByIdBeforeCreate = id => {
  switch (Number(id)) {
    case 1:
      return {
        invoice: {
          invoiceId: "",
          invoiceDate: null,
          created: false,
          upToPeriod: "2019-10-24T18:45:08.493002",
          insuranceCompany: "BMO-BMO ASSURANCE COMPANY (TORONTO)",
          notes: ""
        },

        expenses: {
          location: "Toronto",
          amount: 301.0,
          kit: 17.2,
          scanOrFax: 0.0,
          tax: {
            HST: "13.00%",
            HSTAmount: 41.37
          },
          grandTotal: 359.57
        },

        orders: [
          {
            orderId: 1,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "Mailvagnam, Sasikaran",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 133.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "Sasikaran, Amuthini",
            type: "T01",
            amount: 35.0,
            mileage: 63.0,
            total: 168.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "Sasikaran, Amuthini",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 168.0
          }
        ]
      };
    case 2:
      return {
        invoice: {
          invoiceId: "",
          invoiceDate: null,
          created: false,
          upToPeriod: "2019-10-24T18:45:08.493002",
          insuranceCompany: "BMO-BMO ASSURANCE COMPANY (TORONTO)",
          notes: ""
        },

        expenses: {
          location: "Toronto",
          amount: 301.0,
          kit: 17.2,
          scanOrFax: 0.0,
          tax: {
            HST: "13.00%",
            HSTAmount: 41.37
          },
          grandTotal: 359.57
        },

        orders: [
          {
            orderId: 3,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "CLIENT - 3",
            type: "T01",
            amount: 35.0,
            mileage: 63.0,
            total: 168.0
          },
          {
            orderId: 3,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "CLIENT - 3",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 168.0
          },
          {
            orderId: 4,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "CLIENT - 4 ",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 133.0
          }
        ]
      };
    default:
      return null;
  }
};

export const getCompanyInvoiceDetailByIdAfterCreate = id => {
  switch (Number(id)) {
    case 1:
      return {
        invoice: {
          invoiceId: "TIV11",
          invoiceDate: null,
          created: true,
          upToPeriod: "2019-10-24T18:45:08.493002",
          insuranceCompany: "BMO-BMO ASSURANCE COMPANY (TORONTO)",
          notes: ""
        },

        expenses: {
          location: "Toronto",
          amount: 301.0,
          kit: 17.2,
          scanOrFax: 0.0,
          tax: {
            HST: "13.00%",
            HSTAmount: 41.37
          },
          grandTotal: 359.57
        },

        orders: [
          {
            orderId: 1,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "Mailvagnam, Sasikaran",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 133.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "Sasikaran, Amuthini",
            type: "T01",
            amount: 35.0,
            mileage: 63.0,
            total: 168.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "Sasikaran, Amuthini",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 168.0
          }
        ]
      };
    case 2:
      return {
        invoice: {
          invoiceId: "TIV22",
          invoiceDate: null,
          created: true,
          upToPeriod: "2019-10-24T18:45:08.493002",
          insuranceCompany: "BMO-BMO ASSURANCE COMPANY (TORONTO)",
          notes: ""
        },

        expenses: {
              location: "Toronto",
              amount: 301.0,
              kit: 17.2,
              scanOrFax: 0.0,
              tax: {
                HST: "13.00%",
                HSTAmount: 41.37
              },
              grandTotal: 359.57
            },

        orders: [
          {
            orderId: 3,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "CLIENT - 3",
            type: "T01",
            amount: 35.0,
            mileage: 63.0,
            total: 168.0
          },
          {
            orderId: 3,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "CLIENT - 3",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 168.0
          },
          {
            orderId: 4,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            clientName: "CLIENT - 4 ",
            type: "P04",
            amount: 70.0,
            mileage: 63.0,
            total: 133.0
          }
        ]
      };
    default: 
      return null;
  }
};

export const searchInvoiceList = () => {
  return {
    complanyName: "CA(CI)-CANADA LIFE (CI & DI) LAB CODE 5874",
    invoiceDateFrom: "",
    invoiceDateTo: "",
    invoiceList: [
      {
        id: 1,
        amount: 571.45,
        invoiceDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 2,
        amount: 2835.55,
        invoiceDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 3,
        amount: 164.2,
        invoiceDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 4,
        amount: 2126.25,
        invoiceDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 5,
        amount: 537.4,
        invoiceDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 6,
        amount: 16174.3,
        invoiceDate: "2019-10-24T18:45:08.493002"
      }
    ]
  };
};

export const searchOrderList = () => {
  return [
    {
      id: "T42B653",
      orderDate: "10/30/2019",
      service: "P04",
      client: "MAJLVAGANAM, SASIKARAN",
      assignment: "10/30/2019",
      appointment: "11/02/2019",
      completion: "11/02/2019",
      labNo: "02337895",
      status: "Para. Out",
      paraOut: "11/06/2019"
    }
  ];
};

export const newInvoiceOrders = () => {
  return [
    {
      orderId: "T42B653",
      orderDate: "10/30/2019",
      completionTime: "11/02/2019",
      clientName: "MAJLVAGANAM, SASIKARAN",
      type: "P04",
      amount: 70.0,
      mileage: 63.0,
      total: 133.0
    }
  ];
};

/////////////////////////////////////////////////////////////////////

export const payslipContractorList = () => {
  return [
    {
      id: 1,
      contractor: "ABHI - NAIR, ABHIMANUE BALACHANDRAN",
      unpaidOrderAmount: 16,
      lastPayslipDate: "2019-07-03"
    },
    {
      id: 2,
      contractor: "YL1 - LAU, YVONNE SIO CHAN",
      unpaidOrderAmount: 65.6,
      lastPayslipDate: "2019-12-03"
    }
  ];
};

export const getPayslipDetailByIdBeforeCreate = id => {
  switch (Number(id)) {
    case 1:
      return {
        payslip: {
          payslipId: "",
          payslipDate: null,
          created: false,
          upToPeriod: "2019-10-24T18:45:08.493002",
          contractor: "Armovit, Joquin",
          notes: ""
        },

        expenses: {
          amount: 1127.15,
          HSTRate: "13.00%",
          HST: 146.53,
          grandTotal: 1273.68
        },

        orders: [
          {
            orderId: 1,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "IA INS",
            clientName: "Mailvagnam, Sasikaran",
            service: "P04",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Wang, Chun-Wen",
            service: "P01",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Sasikaran, Amuthini",
            type: "P04",
            amount: 70.0,
            expenseType: "Referral Fee",
            expenseCharge: 63.0
          }
        ]
      };
    case 2:
      return {
        payslip: {
          payslipId: "",
          payslipDate: null,
          created: false,
          upToPeriod: "2019-10-24T18:45:08.493002",
          contractor: "Armovit, Joquin - 2",
          notes: ""
        },

        expenses: {
          amount: 1127.15,
          HSTRate: "13.00%",
          HST: 146.53,
          grandTotal: 1273.68
        },

        orders: [
          {
            orderId: 1,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "IA INS",
            clientName: "Mailvagnam, Sasikaran - 2",
            service: "P04",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Wang, Chun-Wen - 2",
            service: "P01",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Sasikaran, Amuthini - 2",
            type: "P04",
            amount: 70.0,
            expenseType: "Referral Fee",
            expenseCharge: 63.0
          }
        ]
      };
    default:
      return null;
  }
};

export const getPayslipDetailByIdAfterCreate = id => {
  switch (Number(id)) {
    case 1:
      return {
        payslip: {
          payslipId: "TP1911-020",
          payslipDate: "12/03/2019",
          created: false,
          upToPeriod: "2019-10-24T18:45:08.493002",
          contractor: "Armovit, Joquin",
          notes: ""
        },

        expenses: {
          amount: 1127.15,
          HSTRate: "13.00%",
          HST: 146.53,
          grandTotal: 1273.68
        },

        orders: [
          {
            orderId: 1,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "IA INS",
            clientName: "Mailvagnam, Sasikaran",
            service: "P04",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Wang, Chun-Wen",
            service: "P01",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Sasikaran, Amuthini",
            type: "P04",
            amount: 70.0,
            expenseType: "Referral Fee",
            expenseCharge: 63.0
          }
        ]
      };
    case 2:
      return {
        payslip: {
          payslipId: "TP1911-020",
          payslipDate: "12/03/2019",
          created: false,
          upToPeriod: "2019-10-24T18:45:08.493002",
          contractor: "Armovit, Joquin - 2",
          notes: ""
        },

        expenses: {
          amount: 1127.15,
          HSTRate: "13.00%",
          HST: 146.53,
          grandTotal: 1273.68
        },

        orders: [
          {
            orderId: 1,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "IA INS",
            clientName: "Mailvagnam, Sasikaran - 2",
            service: "P04",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Wang, Chun-Wen - 2",
            service: "P01",
            serviceCharge: 70.0,
            expenseType: "Mileage",
            expenseCharge: 63.0
          },
          {
            orderId: 2,
            orderDate: "2019-10-24T18:45:08.493002",
            completionTime: "2019-10-24T18:45:08.493002",
            co: "BMO",
            clientName: "Sasikaran, Amuthini - 2",
            type: "P04",
            amount: 70.0,
            expenseType: "Referral Fee",
            expenseCharge: 63.0
          }
        ]
      };
    default: 
      return null;
  }
};

export const searchPayslipList = () => {
  return {
    contractorName: "Armovit, Joquin",
    payslipDateFrom: "",
    payslipDateTo: "",
    payslipList: [
      {
        id: 1,
        amount: 571.45,
        payslipDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 2,
        amount: 2835.55,
        payslipDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 3,
        amount: 164.2,
        payslipDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 4,
        amount: 2126.25,
        payslipDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 5,
        amount: 537.4,
        payslipDate: "2019-10-24T18:45:08.493002"
      },
      {
        id: 6,
        amount: 16174.3,
        payslipDate: "2019-10-24T18:45:08.493002"
      }
    ]
  };
};

export const newPayslipOrders = () => {
  return [
    {
      orderId: "T42B653",
      orderDate: "10/30/2019",
      completionTime: "11/02/2019",
      co: "IA INS",
      clientName: "MAJLVAGANAM, SASIKARAN",
      service: "P04",
      serviceCharge: 70.0,
      expenseType: "Mileage",
      expenseCharge: 133.0
    }
  ];
};

export const useStyles = makeStyles(theme => ({
  root: {
    width: "100%"
  },
  collapseRoot: {
    width: "100%",
    backgroundColor: "lightgrey"
  },
  nested: {
    width: "100%",
    paddingLeft: theme.spacing(4)
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "33.33%",
    flexShrink: 0
  },
  heading2: {
    fontSize: theme.typography.pxToRem(17),
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "white"
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },
  blueBackground: {
    backgroundColor: "#3f51b5"
  },
  unitLabel: {
    fontSize: "15px",
    display: "flex",
    flexDirection: "row",
    alignSelf: "flex-end",
    padding: "10px 5px 0px 5px"
  },
  overflowHidden: {
    overflow: "hidden"
  },
  phoneNumber: {
    width: "8em",
  }
}));

const drawerWidth = 80;
export const useStylesInvoice = makeStyles(theme => ({
  root: {
    display: "flex",
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper
  },
  tabDirection: {
    float: "right"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    top: "63px",
    width: "100%"
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: drawerWidth
  },
  title: {
    flexGrow: 1
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "white"
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    marginTop: theme.spacing(7.3),
    ...theme.mixins.toolbar,
    justifyContent: "flex-start"
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    marginRight: -drawerWidth,
    marginTop: "50px"
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    marginRight: 0,
    marginTop: "50px"
  }
}));

export const lastDateOfPrevMonth = () => {
  const date = moment()
    .subtract(1, "months")
    .endOf("month")
    .format("YYYY-MM-DD");

  return date;
};

export const getColData = (arr, colName) => {
  let output = [];
  arr.map(obj => {
    return Object.keys(obj).map(key => {
      if (key === colName) {
        output.push(obj[key]);
      }
    });
  });
  return output;
};
