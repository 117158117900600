////////////////////// TODO //////////////////////////////////

/* Fix reuseability bug */

////////////////////// TODO //////////////////////////////////

import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DeleteButton from "../DeleteButton";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from '@material-ui/core/FormHelperText';

const AutoCompleteDropDownControlled = ({
  multiple = false,
  width = "100%",
  label = "",
  variant,
  margin = "none",
  error = false,
  helperText = '',
  ...props
}) => {

  const getOptionLabel = option => {
    if (props.dropDownVal) {
      if (props.source === 'contractor') {
        return `${option.shortCode ? option.shortCode + ' - ' : ''}${option.lastName}, ${option.firstName}`;
      } else if (props.dropDownVal === 'firstName') {
        return `${option.lastName}, ${option.firstName}`;
      } else if (props.dropDownVal === 'shortCode') {
        return `${option.shortCode} - ${option.name}`;
      } else {
        return option[props.dropDownVal];
      }
    } else {
      return option;
    }
  };

  return (
    <Grid item>
      <div style={{ display: "flex", flexDirection: "rows" }}>
        <Autocomplete
          style={{ width: width }}
          options={props.dropDownData}
          disableClearable={true}
          multiple={multiple}
          renderInput={params => <TextField {...params} label={label} margin={margin} fullWidth variant={variant} />}
          defaultValue={props.renderItem}
          autoSelect
          getOptionLabel={option => getOptionLabel(option)}
          // getOptionSelected={(option, { value }) => option.link === value.link}
          onChange={props.onChange}
          classes={{ inputRoot: error ? 'error' : '' }}  
        />
        {props.enableDelete ? (
          <DeleteButton
            style={{ cursor: "pointer", display: "flex", justifySelf: "flex-end" }}
            deleteHandler={props.deleteHandler}
          />
        ) : null}
        {props.enableSearch ? (
          <IconButton
            onClick={props.onClickSearch}
            size="small"
            color="secondary"
            style={{ display: "flex", justifySelf: "flex-end" }}
          >
            <SearchIcon />
          </IconButton>
        ) : null}
      </div>
      {error && <FormHelperText error={true}>{helperText}</FormHelperText>} 
    </Grid>
  );
};

export default AutoCompleteDropDownControlled;
