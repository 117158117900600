import React, { useState, useEffect } from "react";
import "./BasicInput.scss";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";
import "./TripleInputTele.scss"; 

const removeLetters = (value) =>{

  if (value == undefined){
    return ""
  }

  return value.replace(/\D/g, "")
}

const TripleInputTele = ({
  value,
  setValues,
  phone3Box,
  setPhone3Box,
  onChange,
  type = "text",
  name,
  error = false,
  readOnly = false,
  disabled = false,
  errorMsg,
  ...props
}) => {

  const [selectionStart, setSelectionStart] = useState(0);
  const [selectionEnd, setSelectionEnd] = useState(0);
  const input1 = React.useRef(null);
  const input2 = React.useRef(null);
  const input3 = React.useRef(null);

  useEffect(
    () => {
      // make it happen
      input1.current.selectionStart = selectionStart;
      input1.current.selectionEnd = selectionEnd;

      input2.current.selectionStart = selectionStart;
      input2.current.selectionEnd = selectionEnd;


      input3.current.selectionStart = selectionStart;
      input3.current.selectionEnd = selectionEnd;

    },
    [value]
  );


  const basicTextInputClass = classnames({ "basic-input": true, "error-input": error });
  const mobileSplit= value[name] && value[name].split("-")

  const clearFields = () =>{
    setPhone3Box({...phone3Box, [`${name}1`]:"", [`${name}2`]:"", [`${name}3`]:""})
    setValues({...value, [name]: ""})
  }

  const localChg= (e) =>{

    const { target } = e;

    console.log(mobileSplit)

    // save cursor position
    setSelectionStart(target.selectionStart);
    setSelectionEnd(target.selectionEnd);

    onChange(e)

  }
  
  return (

    <div className="tripleInputTele">
      <span className="tripleInputTeleRow">
      (
      <input
        ref={input1}
        data-cy={name}
        className={basicTextInputClass}
        value={mobileSplit && removeLetters(mobileSplit[0])}
        onChange={e =>{
          if (removeLetters(e.target.value).length===3){
            input2.current.focus() 
          }
          localChg(e)
        }}
        type="tel"
        maxlength="3"
        name={`${name}1`}
        readOnly={readOnly}
        disabled={disabled}
        step={type === "number" ? "0.01" : undefined}
        pattern="\d*"
        title="Only numbers allowed"
        className="areaCode"
      /> 
      )
      </span>
      <span className="firstThreeRow">

      <input
        ref={input2}
        data-cy={name}
        className={basicTextInputClass}
        value={mobileSplit && removeLetters(mobileSplit[1])}
        onChange={e =>{
          if (removeLetters(e.target.value).length===3){
            input3.current.focus() 
          }
          localChg(e)
        }}        
        type="tel"
        name={`${name}2`}
        readOnly={readOnly}
        disabled={disabled}
        maxlength="3"
        step={type === "number" ? "0.01" : undefined}
        pattern="\d*"
        title="Only numbers allowed"

        className="firstThree"
      />
        </span>
      <span className="lastFourRow">
        -
      <input
        ref={input3}
        data-cy={name}
        className={basicTextInputClass}
        value={mobileSplit && removeLetters(mobileSplit[2])}
        onChange={localChg}
        type="tel"
        maxlength="4"
        name={`${name}3`}
        readOnly={readOnly}
        disabled={disabled}
        step={type === "number" ? "0.01" : undefined}
        pattern="\d*"
        title="Only numbers allowed"
        className="lastFour"
      />
      </span>

      <button onClick={clearFields}>Clear Field</button>
      {error && <Typography variant='overline' color='secondary'>{errorMsg}</Typography>}
    </div>
  );
};

export default TripleInputTele;
