import React from "react";

import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";

const DetailFooter = ({ type, handleClose, handleSave, handleCreate, disabled, footerMsg, ...props }) => {
  return (
    <DialogActions>
      <Typography variant='button' color='secondary'>{footerMsg}</Typography>
      <Button onClick={handleClose} color="primary">
        Cancel
      </Button>
      {type === "update" ? (
        <Button onClick={handleSave} color="primary" disabled={disabled}>
          Save
        </Button>
      ) : (
        <Button onClick={handleCreate} color="primary" disabled={disabled}>
          {type === 'convert' ? 'Convert' : 'Create'}
        </Button>
      )}
    </DialogActions>
  );
};

export default DetailFooter;
