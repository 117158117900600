import React from 'react';

import { useDebouncedCallback } from 'use-debounce';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

const DebouncedTextField = ({
  className, 
  initialValue, 
  onChange, 
  error, 
  helperText, 
  label, 
  name, 
  disabled, 
  InputProps, 
  type = "text", 
  multiline, 
  ...props
}) => {

  // const [debouncedCallback] = useDebouncedCallback(value => {
  //   onChange(value, name);
  // }, 5000);

  const debouncedCallback = (value) =>{
    onChange(value, name)
  }

  return (
    name === 'insuranceAmount' ? 
      <Grid container direction='column'>
        <div 
          className={`${error ? 'Mui-error' : ''} MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-fullWidth MuiInput-fullWidth MuiInputBase-formControl MuiInput-formControl`}
          style={{ paddingTop: '2px'}}
        >
          <NumberFormat
            className='MuiInputBase-input MuiInput-input'
            thousandSeparator={true}
            value={initialValue}
            prefix='$'
            onValueChange={values => debouncedCallback(values.value)}
          />
        </div> 
        {error && <FormHelperText error={true}>{helperText}</FormHelperText>} 
      </Grid>
      : 
      <TextField
        data-cy={name}
        autoFocus
        key={`${name}-${initialValue}`} // use key to force rerender when initialValue changes
        disabled={disabled}
        className={className + type === 'email' ? 'noCase' : null}
        margin="none"
        type={type}
        label={label}
        defaultValue={initialValue}
        fullWidth
        onChange={e => debouncedCallback(e.target.value)}
        error={error}
        helperText={helperText}
        InputProps={InputProps}
      />
  );
};

export default DebouncedTextField;