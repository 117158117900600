import React, { useState, useEffect } from "react";

// import { makeStyles } from "@material-ui/core/styles";
// import ExpansionPanel from "@material-ui/core/ExpansionPanel";
// import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
// import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
// import Typography from "@material-ui/core/Typography";
// import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import DetailHeader from "./DetailHeader";
import DetailFooter from "./DetailFooter";
import DialogContent from "@material-ui/core/DialogContent";
// import moment from "moment";
import Grid from "@material-ui/core/Grid";
import ReportLabel from "../ReportLabel";
import BasicInput from "../basic-components/BasicInput";
import BasicInputLowerCase from "../basic-components/BasicInputLowerCase";

import BasicTextarea from "../basic-components/BasicTextarea";
import BasicCheckbox from "../basic-components/BasicCheckbox";
import { getAgency_base } from "../API_calls/agencies";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Required from "../basic-components/Required";

import { generateKey, normalizePhone2 } from "../utils/helpers";
import validator from "validator";
import TripleInputTele from "../basic-components/TripleInputTele";

// import DetailsGridCSS from "./DetailsGridCSS.scss";

const InsuranceAgencyDetails = ({
  handleClose,
  handleSave = null,
  handleCreate = null,
  details,
  companyList,
  agencyList,
  type,
  agencyAdminList,
  footerMsg,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [phone3Box, setPhone3Box] = useState({});
  const [formatPhone, setFormatPhone] = useState("");

  console.log(values)

  let validMobileTel = values.tele
  ? values.tele.length === 12
  : false; 

  let validEmail;
  if (!values.email){
    validEmail =  validator.isEmail("")
  } else {
    validEmail = validator.isEmail(values.email)
  }


  console.log(validEmail)

  useEffect(() => {
    if (details) {
      const initializeData = async () => {
        if (type === "add") {
          setValues({ enabled: true });
        } else {
          const res = await getAgency_base(details._links.self.href);

          let teleSplit;
          let faxSplit;

          if (res.tele) {
            res.tele = normalizePhone2(res.tele);
            teleSplit = res.tele.split("-");
          } else teleSplit = "";

          if (res.fax) {
            res.fax = normalizePhone2(res.fax);
            faxSplit = res.fax.split("-");
          } else faxSplit = "";

          setValues({
            ...res,
            admin:
              res._embedded && res._embedded.admin
                ? res._embedded.admin._links.self.href.split("{")[0]
                : "",
          });

          setPhone3Box({
            tele1: teleSplit[0],
            tele2: teleSplit[1],
            tele3: teleSplit[2],
            fax1: faxSplit[0],
            fax2: faxSplit[1],
            fax3: faxSplit[2],
          });
        }
      };
      initializeData();
      console.log(values);
    }
  }, [details, type]);

  const changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const selectHandler = (name, e) => {
    setValues({ ...values, [name]: e.target.value });
  };

  const removeWords = (arr) => {
    const finalOP = [];
    if (arr.length === 0) {
      return arr;
    }
    if (!arr[0][1] && !arr[1][1] && !arr[2][1]) {
      return "";
    }

    if (arr.length >= 1) {
      finalOP.push(arr[0][1]);
    }
    if (arr.length >= 2) {
      finalOP.push(arr[1][1]);
    }
    if (arr.length === 3) {
      finalOP.push(arr[2][1]);
    }
    return finalOP.join("-");
  };

  useEffect(() => {
    const formatPhoneNumber = Object.entries(phone3Box).filter((element) => {
      if (element[0].startsWith(phone3Box.type)) {
        return element;
      }
    });
    const numbersOnly = removeWords(formatPhoneNumber);
    setFormatPhone({ type: phone3Box.type, formattedNum: numbersOnly });
  }, [phone3Box]);

  useEffect(() => {
    setValues({ ...values, [formatPhone.type]: formatPhone.formattedNum });
  }, [formatPhone]);

  const changeHandlerPhone = (e) => {
    setPhone3Box({ ...phone3Box, type: e.target.name.slice(0, -1), [e.target.name]: e.target.value.replace(/\D/g, "") });

  };

  return (
    <>
      <DetailHeader title="Insurance Agency details" />
      <DialogContent dividers={true}>
        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                ShortCode:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="shortCode"
                value={values.shortCode}
                error={errors.shortCode}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Enabled:
              </ReportLabel>
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                name="enabled"
                checked={values.enabled}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="name"
                value={values.name}
                error={values.name ? values.name.length > 50 : null}
                onChange={changeHandler}
                errorMsg={
                  values.name
                    ? `Too many characters: ${50 - values.name.length}`
                    : null
                }
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Billing Attention:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="billingAttention"
                value={values.billingAttention}
                error={errors.billingAttention}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Remarks:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="remarks"
                // rows={15}
                value={values.remarks}
                error={errors.remarks}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Subscribe:
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item" alignItems="center">
              <BasicCheckbox
                type="checkbox"
                name="newOrderEmail"
                checked={values.newOrderEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">
                New Order Email
              </ReportLabel>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item" alignItems="center">
              <BasicCheckbox
                type="checkbox"
                name="updateStatusEmail"
                checked={values.updateStatusEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">
                Update Status Email
              </ReportLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item" alignItems="center">
              <BasicCheckbox
                type="checkbox"
                name="appointmentEmail"
                checked={values.appointmentEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">
                Appointment Email
              </ReportLabel>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item" alignItems="center">
              <BasicCheckbox
                type="checkbox"
                name="completionEmail"
                checked={values.completionEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">
                Completion Email
              </ReportLabel>
            </Grid>
          </Grid>
        </Grid> */}

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Tel:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="tele"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email 1:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                type="email"
                name="email"
                value={values.email}
                error={values.email ? !validator.isEmail(values.email) : false}
                errorMsg="Not a valid email address"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Fax:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="fax"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email 2:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                type="email"
                name="email2"
                value={values.email2}
                error={
                  values.email2 ? !validator.isEmail(values.email2) : false
                }
                errorMsg="Not a valid email address"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Address:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="address"
                value={values.address}
                error={values.address ? values.address.length > 100 : null}
                onChange={changeHandler}
                errorMsg={
                  values.address
                    ? `Too many characters: ${100 - values.address.length}`
                    : null
                }
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                City:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="city"
                rows={1}
                value={values.city}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Province:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="province"
                rows={1}
                value={values.province}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Postal Code:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="postalCode"
                rows={1}
                value={values.postalCode}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Agency Admin:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <Select
                style={{ width: "100%" }}
                onChange={(e) => selectHandler("admin", e)}
                value={values.admin ? values.admin : ""}
              >
                {agencyAdminList.map((user) => (
                  <MenuItem key={generateKey()} value={user._links.self.href}>
                    {user.lastName}, {user.firstName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item"></Grid>
            <Grid item xs={9} className="row-section-item"></Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DetailFooter
        type={type}
        handleClose={handleClose}
        handleSave={() => handleSave(values, details)}
        handleCreate={() => handleCreate(values)}
        disabled={
          !(
            values.name &&
            values.address &&
            values.postalCode &&
            values.city &&
            values.province &&
            validEmail &&
            validMobileTel
          )
        }
        footerMsg={footerMsg}
      />
    </>
  );
};

export default InsuranceAgencyDetails;
