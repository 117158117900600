import React, { useEffect, useState, useContext } from "react";
import { useHistory } from 'react-router-dom';

import DefaultTable from "./DefaultTable";

import { processedReportInfoColumns } from "./data/constants";
import { UserContext } from "../UserContext";
import { searchOrders } from "./API_calls/orders";

const ProcessedReportTable = ({ ...props }) => {

  const history = useHistory();

  const { userState } = useContext(UserContext);

  const [orderList, setOrderList] = useState([]);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (userState) {
      const getData = async () => {
        const list = await searchOrders({
          agent: userState.userInfo.link,
          statusList: 'Complete'
        });
        setOrderList(list);
      }
      getData();
    }

  }, [userState]);

  const onRowClick = (event, rowData) => {
    console.log('rowData:', rowData)
    history.push({
      pathname: `/view-order/${rowData.orderID}`,
      state: { orderLink: rowData._links.self.href }
    });
  };


  return (
    <DefaultTable
      title="Processed Requests (Last 60 days)"
      inputColumns={processedReportInfoColumns}
      inputData={orderList}
      pageSize={pageSize}
      setPageSize={setPageSize}
      headerStyle={{ backgroundColor: "#34b7eb", textTransform: 'Capitalize' }}
      onRowClick={onRowClick}
    />
  );
};

export default ProcessedReportTable;
