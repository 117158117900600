import Cookies from "js-cookie";
const cookieName = "gigeco-jwt";
const cookieNameUser = "gigeco-user";

const getJWT = () => {
  return Cookies.get(cookieName);
};

const setJWT = (val) => {
  Cookies.set(cookieName, val, { expires: 30 });
};

const removeJWT = () => {
  Cookies.remove(cookieName);
};

const getUserId = () => {
  return Cookies.get(cookieNameUser);
};

const setUserId = (val) => {
  Cookies.set(cookieNameUser, val, { expires: 30 });
};

const removeUserId = () => {
  Cookies.remove(cookieNameUser);
};

export { getJWT, setJWT, removeJWT, getUserId, setUserId, removeUserId };
