import React, { useEffect, useState } from "react";

import DefaultTable from "./DefaultTable";

import { webRequestServiceInfoColumns } from "./data/constants";
import {
  createWebRequestServiceType,
  updateWebRequestServiceType_base
} from "./API_calls/webRequestServiceTypes";

import PopUp from "./PopUp";


const WebRequestServiceCrudTable = ({ data, ...props }) => {
  
  useEffect(() => {
    if (data) {
      const initializeData = async () => {
        try {
          setIsLoading(true);
          setInfo(data);
        } catch (e) {
          console.log("error:", e);
        } finally {
          setIsLoading(false);
        }
      };
      initializeData();
    }
  }, [data]);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

   //for popup error msg
   const [popUpStatus, setPopUpStatus] = useState(false);
   const [errorMsg, setErrorMsg] = useState('')
   const [popUpType, setPopUpType] = useState("");


  const addFunction = async (newData) => {
    setIsLoading(true);
    try {
      let res = await createWebRequestServiceType(newData);
      setInfo([res, ...info]);
    } catch (e) {
      setErrorMsg('Error: Please fillout all fields')
      setPopUpType("error");

      setPopUpStatus(new Date())

      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {
    setIsLoading(true);
    try {
      await updateWebRequestServiceType_base(oldData._links.self.href, newData);
      const data = info.map((currData) =>
        currData.tableData.id === oldData.tableData.id ? newData : currData
      );
      setInfo(data);
    } catch (e) {
      setErrorMsg('Error: Please fillout all fields')
      setPopUpType("error");

      setPopUpStatus(new Date())
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <DefaultTable
        title="Web Request Service Info"
        inputColumns={webRequestServiceInfoColumns}
        inputData={info}
        updateFunction={updateFunction}
        addFunction={addFunction}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      {popUpStatus ? <PopUp type="error" message={errorMsg} status={popUpStatus} /> : null}

    </div>
  );
};

export default WebRequestServiceCrudTable;
