/* [] TODO Add error message > If fetch failst there is no message to the user */
import React, { useState, useEffect, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import { searchOrders } from '../API_calls/orders';
import * as detailHelpers from '../OrderDetails/orderDetailsHelpers';
import DefaultTable from '../DefaultTable';
import SimpleButton from '../OrderDetails/SimpleButton';
import LoadingSpinner from '../LoadingSpinner';

// import FilterListIcon from "@material-ui/icons/FilterList";
// import TextField from "@material-ui/core/TextField";
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import Dialog from '@material-ui/core/Dialog';
import ConversionDialog from './ConversionDialog';
// import Edit from '@material-ui/icons/Edit';
// import Button from '@material-ui/core/Button';

import { orderColumns, statusTypes } from '../data/constants';
import Typography from '@material-ui/core/Typography';
import AutoCompleteDropDown from '../OrderDetails/AutoCompletes/AutoCompleteDropDown';
import { GlobalNameListContext } from '../../Context';
import DateTimePicker from '../OrderDetails/DateTimePicker';
import DebouncedTextField from '../basic-components/DebouncedTextField';
//import useOrderPagination from '../utils/hooks/useOrderPagination';

const OrderTableNew = ({ history, ...props }) => {
  const { globalNameList } = useContext(GlobalNameListContext);

  // Use order pagination hook - disabled and needs work, but ideally would be implemented
  //const { orders, onMoreOrdersClick, setOrders, getOrderList } = useOrderPagination();

  const classes = detailHelpers.useStyles();

  const [orders, setOrders] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [currDetails, setCurrDetails] = useState({});
  const [orderPage, setOrderPage] = useState(0);
  const [loading, setLoading] = useState(false);

  const [val, setVal] = useState({});

  useEffect(() => {
    setLoading(true);
    getData();
  }, []);

  const getData = async (page = 0, search = true) => {
    const searchParams = {
      ...val,
      medifastID: val.medifastID ? val.medifastID : null,
      clientLastName: val.clientLastName ? val.clientLastName : null,
      clientFirstName: val.clientFirstName ? val.clientFirstName : null,
      agent: val.agent ? val.agent.link : null,
      contractor: val.contractor ? val.contractor.link : null,
    };
    try {
      const orders = await searchOrders(searchParams, page);
      search ? setOrders(orders) : setOrders((prev) => [...prev, ...orders]);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const changeDate = (val, setCurrentTime, source) => {
    setCurrentTime(val);
    setVal((prev) => ({ ...prev, [source]: val ? val.format('YYYY-MM-DD') : val }));
  };

  const handleChange = (val, key) => {
    setVal((prev) => ({ ...prev, [key]: val }));
  };

  const actions = [
    (rowData) => ({
      icon: () => (
        <Typography variant="button" color="secondary" size="small">
          Match
        </Typography>
      ),
      tooltip: 'Match Order',
      onClick: (event, rowData, togglePanel) => {
        setCurrDetails(rowData);
        setOpen(true);
      },
      hidden: rowData.status !== 'Pending',
    }),
  ];

  const onMoreOrdersClick = () => {
    setOrderPage((prev) => prev + 1);
    getData(orderPage + 1, false);
    console.log('getData', getData(orderPage + 1, false));
    console.log('orders in onMoreOrdersClick', orders);
  };

  return (
    <>
      {/* =============================Search Field======================= */}
      <div style={{ padding: '20px' }}>
        <Grid container spacing={1}>
          <Grid item lg={2} xs={4}>
            <DebouncedTextField
              className={clsx(classes.textField, classes.dense)}
              initialValue={val.medifastID}
              onChange={handleChange}
              name="medifastID"
              label="Order ID:" // On the users end they still refer to it as the Order ID
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <DebouncedTextField
              className={clsx(classes.textField, classes.dense)}
              initialValue={val.clientLastName}
              onChange={handleChange}
              name="clientLastName"
              label="Client Last Name:"
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <DebouncedTextField
              className={clsx(classes.textField, classes.dense)}
              initialValue={val.clientFirstName}
              onChange={handleChange}
              name="clientFirstName"
              label="Client First Name:"
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <DateTimePicker
              source="clientdob"
              clientdob={val.clientdob ? val.clientdob : null}
              label="Client DOB:"
              changeDate={changeDate}
              clearable
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setVal((prev) => ({ ...prev, agent: newVal }))}
              dropDownData={globalNameList.agents}
              dropDownVal="firstName"
              renderItems={val.agent ? [val.agent] : [null]}
              label="Agent:"
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setVal((prev) => ({ ...prev, contractor: newVal }))}
              dropDownData={globalNameList.contractors}
              dropDownVal="firstName"
              renderItems={val.contractor ? [val.contractor] : [null]}
              label="Contractor:"
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <DateTimePicker
              source="createDate"
              createDate={val.createDate ? val.createDate : null}
              label="Creation Date:"
              changeDate={changeDate}
              clearable
            />
          </Grid>
          <Grid item lg={2} xs={4}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setVal((prev) => ({ ...prev, statusList: newVal }))}
              dropDownData={statusTypes}
              renderItems={val.statusList ? [val.statusList] : ['']}
              label="Status:"
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: '20px', textAlign: 'center' }}>
          <Grid item xs={12}>
            <SimpleButton onClick={getData} text={'SEARCH'} icon={<SearchIcon />} />
          </Grid>
        </Grid>
      </div>
      {/* ===========================End of Search ======================================= */}
      <Divider />
      <Dialog fullWidth maxWidth="lg" open={open} onClose={() => setOpen(false)} scroll="paper">
        <ConversionDialog
          details={currDetails}
          handleClose={() => setOpen(false)}
          // handleSave={updateFunction}
          // handleCreate={addFunction}
          type="convert"
          history={history}
        />
      </Dialog>
      {loading && <LoadingSpinner />}
      {!loading && (
        <DefaultTable
          onRowClick={(e, rowData) => history.push(`/orders/${rowData.orderID}`)}
          title="Order List"
          inputColumns={orderColumns}
          inputData={orders}
          pageSize={pageSize}
          setPageSize={setPageSize}
          actions={actions}
          loadMoreOrders={onMoreOrdersClick}
        />
      )}
    </>
  );
};

export default withRouter(OrderTableNew);
