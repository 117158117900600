import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "./DefaultTable";
import AgentDetails from "./table-details/AgentDetails";
import Dialog from "@material-ui/core/Dialog";
import Edit from "@material-ui/icons/Edit";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import AddBox from "@material-ui/icons/AddBox";
// import Grid from "@material-ui/core/Grid";
// import ReportLabel from "./ReportLabel";

// import SearchIcon from "@material-ui/icons/Search";
// import SimpleButton from "./OrderDetails/SimpleButton";
// import BasicSelect from "./basic-components/BasicSelect";
// import BasicInput from "./basic-components/BasicInput";

import { agentInfoColumns } from "./data/constants";
import {
  updateUser_base,
  createUser,
  resetPasswordSwal
} from "./API_calls/users";
import { GlobalNameListContext } from "../Context";

import PopUp from "./PopUp";


const AgentCrudTable = ({ roleLink, companyListData, agencyListData, userData, ...props }) => {


  const { setGlobalNameList } = useContext(GlobalNameListContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [currDetails, setCurrDetails] = useState({});

  const [values, setValues] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [agencyList, setAgencyList] = useState([]);

  const [footerMsg, setFooterMsg] = useState('');


  // for popup
  const [errorMsg, setErrorMsg] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("");

  useEffect(() => {
    const initializeData = async () => {
      try {
        const resCompanyList = companyListData.map((company) => {
          return { id: company._links.self.href, name: company.name };
        });
        setCompanyList(resCompanyList);
        const resAgencyList = agencyListData.map((agency) => {
          return { id: agency._links.self.href, name: agency.name };
        });
        setAgencyList(resAgencyList);
        setInfo(userData);
      } catch (e) {
        console.log("ERROR:", e);
      }
    };
    initializeData();
  }, [agencyListData, companyListData, userData]);

  const addFunction = async (newData) => {
    console.log('newData:', newData)
    setIsLoading(true);
    try {
      const res = await createUser(newData, roleLink);
      setInfo(prev => [res, ...prev]);
      setOpen(false);
      setGlobalNameList(prev => ({ ...prev, agents: [...prev.agents, { firstName: res.firstName, lastName: res.lastName, link: res._links.self.href }]}));
    } catch (e) {
      setFooterMsg('Could not submit form. Check duplicate Username.');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => { 
    console.log(newData, "new saving data")

    if(newData.businessTel !== null && newData.businessTel !== "--"){
      if (newData.businessTel.length !== 12 && newData.businessTel.length !== 0){
        setFooterMsg("Check Business Number")
        return
      }  
    }

    if(newData.homeTel !== null && newData.homeTel !== "--"){
      if (newData.homeTel.length !== 12 && newData.homeTel.length !== 0){
        setFooterMsg("Check Home Number")
        return
      }  
    }

    if(newData.fax !== null && newData.fax !== "--"){
      if (newData.fax.length !== 12 && newData.fax.length !== 0){
        setFooterMsg("Check Fax Number")
        return
      }  
    }

    if(newData.otherTel !== null && newData.otherTel !== "--"){
      if (newData.otherTel.length !== 12 && newData.otherTel.length !== 0){
        setFooterMsg("Check Other Number")
        return
      }  
    }

    
    setIsLoading(true);
    try {
      await updateUser_base(oldData._links.self.href, newData);
      setInfo(prev => prev.map((currData) => currData.tableData.id === oldData.tableData.id ? newData : currData));
      setOpen(false);
    } catch (e) {
      setFooterMsg('Could not submit form. Check duplicate Username/Email');
      console.log(e);
    } finally {
      setIsLoading(false);
      // setFooterMsg('');

    }
  };

  // const changeHandler = (e) => {
  //   setValues({ ...values, [e.target.name]: e.target.value });
  // };

  const actions = [
    {
      icon: () => <Edit data-cy='editAgentBtn' />,
      tooltip: "Edit",
      onClick: (event, rowData, togglePanel) => {
        setCurrDetails(rowData);
        setOpen(true);
      }
    },
    {
      icon: () => <AddBox data-cy='addAgentBtn'/>,
      tooltip: "Add",
      isFreeAction: true,
      onClick: (event) => {
        setCurrDetails({});
        setOpen(true);
      }
    },
    {
      icon: () => <LockOpenIcon />,
      tooltip: "Reset Password",
      onClick: async (event, rowData, togglePanel) => {
        
        try {
          const status = await resetPasswordSwal(rowData);
          console.log(status)

          if (status === 'success'){
            setErrorMsg(`Password Changed`);
            setPopUpType('success')
            setPopUpStatus(new Date());  
          }

          if (status === 'fail'){
            setErrorMsg(`Password requirements: Min length 8, 1 Uppercase, 1 Lowercase, 1 Number, 1 Special Char (!@#$%^*...)`);
            setPopUpType('error')
            setPopUpStatus(new Date());  
          }


        } catch (err) {
          setErrorMsg(`Enter a new password`);
          setPopUpType('error')
          setPopUpStatus(new Date());
        }
      }
    }
  ];

  const handleClose = () => {
    setOpen(false);
    setFooterMsg('');
  };

  return (
    <>
      {/* <Grid container direction="row" justify="center" alignItems="baseline">
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              Username:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicInput name="username" value={values.username} onChange={changeHandler} />
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              First Name:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicInput name="firstName" value={values.firstName} onChange={changeHandler} />
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              Last Name:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicInput name="lastName" value={values.lastName} onChange={changeHandler} />
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              Contact No:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicInput name="contactNo" value={values.contactNo} onChange={changeHandler} />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="center">
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              Enabled:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicSelect
              value={values.enabled}
              isClearable={true}
              isSearchable={false}
              onChange={selectHandler3}
              labelField="name"
              idField="id"
              options={[
                { id: true, name: "true" },
                { id: false, name: "false" }
              ]}
            />
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              CO:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicSelect
              value={values.companyId}
              isClearable={true}
              onChange={selectHandler}
              labelField="name"
              idField="id"
              options={companyList}
            />
          </Grid>
        </Grid>
        <Grid item container xs={3}>
          <Grid item xs={3}>
            <ReportLabel variant="subtitle1" flexEnd>
              AG:
            </ReportLabel>
          </Grid>
          <Grid item xs={9}>
            <BasicSelect
              value={values.agencyId}
              isClearable={true}
              onChange={selectHandler2}
              labelField="name"
              idField="id"
              options={agencyList}
            />
          </Grid>
        </Grid>
        <Grid item container xs={3} justify="center">
          <SimpleButton onClick={searchForAgent} text={"SEARCH"} icon={<SearchIcon />} />
        </Grid>
      </Grid> */}

      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} scroll="paper">
        <AgentDetails
          details={currDetails}
          companyList={companyList}
          agencyList={agencyList}
          handleClose={handleClose}
          handleSave={updateFunction}
          handleCreate={addFunction}
          type={Object.keys(currDetails).length > 0 ? "update" : "add"}
          footerMsg={footerMsg} 
          setFooterMsg={setFooterMsg}
        />
      </Dialog>
      <DefaultTable
        title="Agent Infos"
        cyData='agentInfo'
        inputColumns={agentInfoColumns}
        inputData={info}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        actions={actions}
      />

      {popUpStatus ? <PopUp type={popUpType} message={errorMsg} status={popUpStatus} /> : null}

    </>
  );
};

export default AgentCrudTable;
