import React, { useEffect, useState, useContext } from 'react';
import { UserContext } from '../UserContext';

import DefaultTable from './DefaultTable';
import { withRouter } from 'react-router-dom';

import { documentInfoColumns } from './data/constants';

import {
  createOrderAttachment,
  updateOrderAttachment_base,
  deleteOrderAttachment_base,
  getOrderAttachment_base,
} from './API_calls/orderAttachments';

import NewAddBox from './NewAddBox';

const DocumentCrudTable = ({ match, orderLink, orderAttachmentLink, updateParaTime, ...props }) => {
  const { userState } = useContext(UserContext);
  let userLink = userState.userInfo.link;

  // TABLE STATES
  const [documentInfoData, setDocumentInfoData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  useEffect(() => {
    if (orderAttachmentLink) {
      const initialize = async () => {
        setIsLoading(true);
        try {
          await getOrderAttachment_base(orderAttachmentLink.replace('{?projection}', '')).then((res) =>
            setDocumentInfoData(res)
          );
        } catch (error) {
          console.log(error);
        } finally {
          setIsLoading(false);
        }
      };
      initialize();
    }
  }, [orderAttachmentLink]);

  const addDocument = async (e) => {
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append('file', e.target.files[0]);
      formData.append('uploadedBy', userLink);
      formData.append('order', orderLink);
      const res = await createOrderAttachment(formData);
      const newParaTime = res.createDateTime;
      updateParaTime(newParaTime);
      setDocumentInfoData([res, ...documentInfoData]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteDocument = async (oldData) => {
    setIsLoading(true);
    try {
      await deleteOrderAttachment_base(oldData._links.self.href);
      const newData = documentInfoData.filter((currData) => currData.tableData.id !== oldData.tableData.id);
      setDocumentInfoData(newData);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateDocument = async (newData, oldData) => {
    try {
      setIsLoading(true);
      await updateOrderAttachment_base(oldData._links.self.href, { fileName: newData.fileName });
      const data = documentInfoData.map((currData) =>
        currData.tableData.id === oldData.tableData.id ? newData : currData
      );
      setDocumentInfoData(data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const NewAddIcon = () => <NewAddBox onChange={addDocument} />;

  const actions = [
    {
      icon: NewAddIcon,
      tooltip: 'Add Document',
      isFreeAction: true,
    },
  ];

  return (
    <DefaultTable
      title="Documents"
      inputColumns={documentInfoColumns}
      inputData={documentInfoData}
      updateFunction={updateDocument}
      deleteFunction={deleteDocument}
      actions={actions}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default withRouter(DocumentCrudTable);
