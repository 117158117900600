import React from 'react';

import logo from '../../utils/medifast_logo.png';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

const InvoicePdfHeader = ({ invoiceDate, invoiceID, upToPeriod, title, insuranceCompany, ...props }) => {
  return (
    <div className='invoice-header-container'>
      <div className='invoice-header-item'>
        <img src={logo} alt='Medifast logo' />
        <div>
          <Typography variant='button'>Address:</Typography>
          <Typography>
            15 Wertheim Court, Ste 204,
            <br/> 
            Richmond Hill Ont. L4B 3H7
          </Typography>
        </div>
        <div>
          <Typography variant='button'>Tel:</Typography>
          <Typography>905-882-0190</Typography>
        </div>
      </div>

      <div className='invoice-header-item'>
        <div>
          <Typography variant='button'>To:</Typography>
          <Typography>
            {insuranceCompany.name}
            <br/>
            {insuranceCompany.address}
          </Typography>
        </div>
        <div>
          <Typography variant='button'>Attention:</Typography>
          <Typography>{/* WHOSE NAME GOES HERE? */}</Typography>
        </div>
      </div>

      <Typography variant='h4' className='invoice-header-title'>{title}</Typography>

      <div className='invoice-header-item'>
        <div>
          <Typography variant='button'>Date:</Typography>
          <Typography>{moment(invoiceDate).format('MM-DD-YYYY')}</Typography>
        </div>
        <div>
          <Typography variant='button'>Invoice ID:</Typography>
          <Typography>{invoiceID}</Typography>
        </div>
      </div>

      <div className='invoice-header-item'>
        <div>
          <Typography variant='button'>Up To Period:</Typography>
          <Typography>{moment(upToPeriod).format('MM-DD-YYYY')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default InvoicePdfHeader;