import React, { Fragment } from 'react';
import InvoicePdfHeader from './InvoicePdfHeader';

import './Invoice.scss';

import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generateKey, roundTo } from '../../utils/helpers';
import moment from 'moment';
import InvoiceSummary from './InvoiceSummary';

const Invoice2 = ({ details, summaryDetails, ...props }) => {
  const tableRows = details.orders.map((order) => {
    return (
      <Fragment key={generateKey()}>
        <TableRow>
          <TableCell>{order.medifastID}</TableCell>
          <TableCell>{moment(order.completionTime).format('MM-DD-YYYY')}</TableCell>
          <TableCell variant="head">
            {moment(order.orderDate ? order.orderDate : order.createDateTime).format('MM-DD-YYYY')}
          </TableCell>
          <TableCell>
            {order.clientInfo.lastName}, {order.clientInfo.firstName}
          </TableCell>
          <TableCell>{moment(order.clientInfo.dob).format('MM-DD-YYYY')}</TableCell>
          <TableCell colSpan={2} />
        </TableRow>
        {order.serviceItems.map((item) => (
          <Fragment key={generateKey()}>
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell>{item.serviceType.name}</TableCell>
              <TableCell>{item.serviceType.shortCode}</TableCell>
              <TableCell />
              <TableCell align="right">${roundTo(item.serviceAmount, 2)}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={3} />
              <TableCell colSpan={2}>Mileage</TableCell>
              <TableCell />
              <TableCell align="right">${roundTo(item.mileageAmount, 2)}</TableCell>
            </TableRow>
          </Fragment>
        ))}
        <TableRow>
          <TableCell colSpan={2} />
          <TableCell variant="head">Agent</TableCell>
          <TableCell>
            {order.agentLastName}, {order.agentFirstName}
          </TableCell>
          <TableCell variant="head">Application #</TableCell>
          <TableCell>{/* APPLICATION NO. GOES HERE */}</TableCell>
          <TableCell align="right">
            $
            {roundTo(
              order.serviceItems.reduce((acc, curr) => acc + curr.serviceAmount + curr.mileageAmount, 0),
              2
            )}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  });

  return (
    <>
      <div id="invoice-container">
        <InvoicePdfHeader
          title="Invoice"
          insuranceCompany={details.insuranceCompany}
          invoiceDate={details.invoiceDate}
          invoiceID={details.invoiceID}
          upToPeriod={details.upToPeriod}
        />
        <div className="invoice-table-container">
          <Table size="small" aria-label="a dense table">
            <TableHead>
              <TableRow>
                <TableCell>Order ID</TableCell>
                <TableCell>Completed</TableCell>
                <TableCell>Order Date</TableCell>
                <TableCell>
                  Applicant Name
                  <br />
                  Service
                </TableCell>
                <TableCell>
                  DOB
                  <br />
                  Service Code
                </TableCell>
                <TableCell />
                <TableCell>
                  <br />
                  Amount
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{tableRows}</TableBody>
          </Table>
        </div>
      </div>

      <div id="invoice-container" className="invoice-summary">
        <InvoicePdfHeader
          title="Summary"
          insuranceCompany={details.insuranceCompany}
          invoiceDate={details.invoiceDate}
          invoiceID={details.invoiceID}
          upToPeriod={details.upToPeriod}
        />
        <InvoiceSummary summaryDetails={summaryDetails} />
      </div>
    </>
  );
};

export default Invoice2;
