import { getUser } from "../API_calls/users";
import jwt from "jsonwebtoken";
import { getJWT } from "./JWT";

export const updateLoggedInStatus = async (userId) => {
  const res = await getUser(userId); 
  const { firstName, lastName, username, email } = res
  let arrHref = res._links.self.href.split("/");
  const id = arrHref[arrHref.length - 1];
  const link = res._links.self.href;
  if (username !== jwt.decode(getJWT()).sub) {
    throw new Error("tampered JSON WEB TOKEN");
  }
  const accountType = res._embedded.role.name;
  let isAdmin = false;
  if (
    accountType === "ROLE_SUPER_ADMIN" ||
    accountType === "ROLE_ADMIN" ||
    accountType === "ROLE_AGENCY_ADMIN"
  ) {
    isAdmin = true;
  }

  const adminType = isAdmin ? accountType : null;
  const insuranceAgencyPairs = res._embedded.insuranceAgencyPairs;
  const managedInsuranceCompany = res._embedded.managedInsuranceCompany;
  const userInfo = {
    username,
    email,
    firstName,
    lastName,
    id,
    accountType,
    isAdmin,
    adminType,
    link,
    insuranceAgencyPairs,
    managedInsuranceCompany: managedInsuranceCompany ? managedInsuranceCompany._links.self.href.split('{')[0] : null
  };
  return userInfo;
};

export const generateKey = () => {
  return Math.random().toString(36).substr(2, 6);
};

// rounding function (mainly used for invoicing/billing amounts)
// source: https://stackoverflow.com/a/43998255
// may not be a perfect solution due to JavaScript's weird behaviour with floats
export const roundTo = (n, digits) => {
  const x = Math.pow(10, Number(digits) + 1);
  return (Number(n) + (1 / x)).toFixed(digits);
};


export const formatPhoneNumber = (phoneNumberString) => {
  let cleaned = ('' + phoneNumberString).replace(/\D/g, '')
  let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return  + match[1] + '-' + match[2] + '-' + match[3]
  }
  return null
};

export const normalizePhone = (phone) => {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, "");

  //check if number length equals to 10
  if (phone.length == 10) {
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  return phone;
}

export const normalizePhone2 = (phone) => {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, "");

  //check if number length equals to 10
      //reformat and return phone number
      return phone.replace(/(\d{3})(\d{0,3})(\d{0,4})/, "$1-$2-$3");
 
}