import React, { useState, useEffect } from 'react';
import './AgentReport.scss';
import moment from 'moment';
import ReportLabel from '../components/ReportLabel';
// import ReportWrapper from "../components/ReportWrapper";
// import CreatePDF from "../components/CreatePDF";
import logo from '../components/utils/medifast_logo.png';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { getOrder } from '../components/API_calls/orders';
import LoadingSpinner from '../components/LoadingSpinner';

const AgentReport = ({ id, ...props }) => {
  const [details, setDetails] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   console.log('details:', details)
  // }, [details]);

  useEffect(() => {
    if (id) {
      getOrder(id).then((res) => {
        setDetails((prev) => ({ ...prev, ...res }));
        setIsLoaded(true);
      });
    }
  }, [id]);

  return isLoaded ? (
    <>
      <Box className="section main-section">
        <Box className="section header-section">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <img src={logo} alt="Medifast logo" />
            </Grid>
            <Grid item xs={8}>
              <ReportLabel variant="h4" bold={true}>
                Status Report
              </ReportLabel>
            </Grid>
          </Grid>
        </Box>

        <Box className="section other-section">
          <Grid container>
            <Grid item container spacing={2} xs={6}>
              <Grid item xs={3}>
                <ReportLabel variant="subtitle1" flexEnd>
                  Attention:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Company:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Branch:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Applicant:
                </ReportLabel>
              </Grid>

              <Grid item xs={9}>
                <ReportLabel variant="subtitle1">
                  {details._embedded.agent ? (
                    `${details._embedded.agent.lastName}, ${details._embedded.agent.firstName}`
                  ) : (
                    <br />
                  )}
                </ReportLabel>
                <ReportLabel variant="subtitle1">
                  {details._embedded.insuranceCompany ? details._embedded.insuranceCompany.name : <br />}
                </ReportLabel>
                <ReportLabel variant="subtitle1">
                  {details._embedded.agency ? details._embedded.agency.name : <br />}
                </ReportLabel>
                <ReportLabel variant="subtitle1">
                  {details.clientInfo.lastName}, {details.clientInfo.firstName}
                </ReportLabel>
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={6}>
              <Grid item xs={4} alignContent="center">
                <ReportLabel variant="subtitle1" flexEnd>
                  Print Date:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Fax:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  D.O.B.:
                </ReportLabel>
              </Grid>

              <Grid item xs={8}>
                <ReportLabel variant="subtitle1">{moment().format('MM-DD-YYYY')}</ReportLabel>

                <ReportLabel variant="subtitle1">
                  {details._embedded.agent && details._embedded.agent.fax ? details._embedded.agent.fax : <br />}
                </ReportLabel>
                <ReportLabel variant="subtitle1">{moment(details.clientInfo.dob).format('MM-DD-YYYY')}</ReportLabel>
              </Grid>
            </Grid>
          </Grid>

          <br />

          <Grid container spacing={1}>
            <Grid item container spacing={2} xs={6}>
              <Grid item xs={3}>
                <ReportLabel variant="subtitle1" flexEnd>
                  Service:
                </ReportLabel>
              </Grid>
            </Grid>
          </Grid>

          <Grid container spacing={1}>
            <Grid item xs={1}></Grid>
            <Grid item xs={11} style={{ border: '2px solid grey', height: '100px' }}>
              {details._embedded.serviceItems && details._embedded.serviceItems.length > 0
                ? details._embedded.serviceItems.map((item) => (
                    <ReportLabel variant="subtitle1">{item.serviceType.name}</ReportLabel>
                  ))
                : null}
            </Grid>
          </Grid>

          <br />

          <Grid container>
            <Grid item container spacing={2} xs={6}>
              <Grid item xs={3} alignContent="center">
                <ReportLabel variant="subtitle1" flexEnd>
                  Order No.:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Order Date:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Remarks:
                </ReportLabel>
              </Grid>

              <Grid item xs={9}>
                <ReportLabel variant="subtitle1">{details.medifastID}</ReportLabel>
                <ReportLabel variant="subtitle1">{moment(details.orderDate).format('MM-DD-YYYY')}</ReportLabel>
                <ReportLabel variant="subtitle1">{details.remarks}</ReportLabel>
              </Grid>
            </Grid>

            <Grid item container spacing={2} xs={6}>
              <Grid item xs={4} alignContent="center">
                <ReportLabel variant="subtitle1" flexEnd>
                  Lab No.:
                </ReportLabel>
                <ReportLabel variant="subtitle1" flexEnd>
                  Completion Date:
                </ReportLabel>
              </Grid>

              <Grid item xs={8}>
                <ReportLabel variant="subtitle1">{details.lab.labNumber ? details.lab.labNumber : <br />}</ReportLabel>
                <ReportLabel variant="subtitle1">{moment(details.completionTime).format('MM-DD-YYYY')}</ReportLabel>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box className="section header-section">
          <Grid container>
            <Grid item xs={12}>
              For inquiries, please call the following - Phone: (905) 882-0190
              <br />
              Visit us at: http://www.medifast.ca
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  ) : (
    <LoadingSpinner />
  );
};

export default AgentReport;
