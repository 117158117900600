import React, { useContext, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { getOrder_base } from '../components/API_calls/orders';
import { getWebAccessLogs } from '../components/API_calls/webAccessLogs';
import { RolesContext } from '../UserContext';
import { getSpecificUserTypes } from '../components/API_calls/users';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import ReportLabel from '../components/ReportLabel';
import LoadingSpinner from '../components/LoadingSpinner';
import moment from 'moment';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import './NewOrderPage.scss';
import './InsurancePortal.scss';
import { generateKey } from '../components/utils/helpers';
import DefaultTable from '../components/DefaultTable';
import { insuranceFilesColumns, securityLogsColumns } from '../components/data/constants';

const TabPanel = ({ children, value, index }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}>
      <Box
        style={{
          padding: '10px',
          overflow: 'auto',
          margin: '5px',
        }}
      >
        {children}
      </Box>
    </Typography>
  );
};

const InsurancePortalDetailPage = ({ match, history, location, ...props }) => {
  const [details, setDetails] = useState({});
  const [insuranceItems, setInsuranceItems] = useState([]);
  const [serviceItems, setServiceItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [pageSize2, setPageSize2] = useState(50);
  const [tabVal, setTabVal] = useState(0);

  const [webAccessLogs, setWebAccessLogs] = useState([]);
  const { roles } = useContext(RolesContext);

  useEffect(() => {
    const getDetails = async (link) => {
      try {
        const order = await getOrder_base(link);
        setDetails(order);
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };

    getDetails(location.state.orderLink);
  }, [location.state]);

  useEffect(() => {
    if (details.insuranceItems && details.insuranceItems.length > 0) {
      const items = details.insuranceItems.map((item) => {
        return (
          <Grid key={generateKey()} item container xs={12}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold minHeight>
                Insurance Type:
              </ReportLabel>
            </Grid>
            <Grid item xs={3} className="sub-section__input">
              <Typography>{item.insuranceType}</Typography>
            </Grid>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold minHeight>
                Insurance Amount:
              </ReportLabel>
            </Grid>
            <Grid item xs={3} className="sub-section__input">
              <Typography>{item.insuranceAmount}</Typography>
            </Grid>
          </Grid>
        );
      });
      setInsuranceItems(items);
    }
  }, [details.insuranceItems]);

  useEffect(() => {
    if (details._embedded) {
      let sourceList = details._embedded.serviceItems ? details._embedded.serviceItems : [];

      const items = sourceList.map((item, index) => {
        return (
          <Grid key={generateKey()} item container xs={12}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold minHeight>
                Item {index + 1}:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <Typography>{item.serviceType.name}</Typography>
            </Grid>
          </Grid>
        );
      });
      setServiceItems(items);
    }
  }, [details._embedded]);

  //calling api for getWebAccessLogs and getSpecificUserTypes, filtering through to only show role of insurance user
  useEffect(() => {
    if (details._embedded) {
      const initialize = async () => {
        setIsLoading(true);
        try {
          if (roles['ROLE_INSURANCE_USER']) {
            const webAccessLogsData = await getWebAccessLogs();
            const insuranceCompanyUserData = await getSpecificUserTypes(roles['ROLE_INSURANCE_USER']);
            const filteredInsuranceUsers = insuranceCompanyUserData.map((user) => user.username);
            let filteredAccessLogs = webAccessLogsData.filter(function (log) {
              return filteredInsuranceUsers.includes(log.by.username);
            });
            setWebAccessLogs(
              filteredAccessLogs.map((log, index) => ({
                ...log,
                id: index,
                by: `${log.by.lastName}, ${log.by.firstName}`,
              }))
            );
          }
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      initialize();
    }
  }, [details._embedded]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div className="portal-detail-container">
      <AppBar color="default" className="portal-appbar">
        <Grid container>
          <Grid item xs={11}>
            <Tabs
              value={tabVal}
              onChange={(e, newVal) => setTabVal(newVal)}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Order Information" />
              <Tab label="Security Logs" />
            </Tabs>
          </Grid>
        </Grid>
      </AppBar>
      <main className="portal-content">
        <TabPanel value={tabVal} index={0}>
          <Grid item container alignItems="flex-start" style={{ marginBottom: '15px' }}>
            <Grid item container xs={6}>
              <Grid item xs={12} className="section-header main-info">
                <Typography variant="h6">Order Details</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Order ID:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.medifastID}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Order Date:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>
                    {details.orderDate
                      ? moment(details.orderDate).format('MM-DD-YYYY')
                      : moment(details.createDateTime).format('YYYY-DD-MM')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Lab No.:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.lab ? `${details.lab.labNum}` : ''}</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={12} className="section-header main-info">
                <Typography variant="h6">Progress</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Status:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.status}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Appointment Date:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{moment(details.serviceTime).format('MM-DD-YYYY hh:mm A')}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Completion Date:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{moment(details.completionTime).format('MM-DD-YYYY')}</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container alignItems="flex-start" style={{ marginBottom: '15px' }}>
            <Grid item container xs={6}>
              <Grid item xs={12} className="section-header client-info">
                <Typography variant="h6">Application</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Insurance Company:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>
                    {details._embedded.insuranceCompany ? details._embedded.insuranceCompany.name : ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Agency:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details._embedded.agency ? details._embedded.agency.name : ''}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Agent:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>
                    {details._embedded.agent
                      ? `${details._embedded.agent.lastName}, ${details._embedded.agent.firstName}`
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Policy No.:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.refNum.refNumNumber}</Typography>
                </Grid>
              </Grid>
              {insuranceItems}
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={12} className="section-header client-info">
                <Typography variant="h6">Client</Typography>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Name:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>
                    {details.clientInfo.lastName}, {details.clientInfo.firstName}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Gender:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.clientInfo.gender}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Date of Birth:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{moment(details.clientInfo.dob).format('MM-DD-YYYY')}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Age:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.clientInfo.age}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Home:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.clientInfo.home}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Mobile:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>{details.clientInfo.mobile}</Typography>
                </Grid>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={3} className="sub-section">
                  <ReportLabel flexEnd bold minHeight>
                    Address:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="sub-section__input">
                  <Typography>
                    {details.examLocation
                      ? `${details.examLocation.clientLocationAddress}, ${details.examLocation.clientLocationPostalCode}`
                      : ''}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item container alignItems="flex-start" style={{ marginBottom: '15px' }}>
            <Grid item container xs={6}>
              <Grid item xs={12} className="section-header service-info">
                <Typography variant="h6">Reports</Typography>
              </Grid>
              <Grid item container xs={12}>
                <DefaultTable
                  toolbarStyle={{ display: 'none' }}
                  headerStyle={{
                    color: 'black',
                    fontWeight: 'bold',
                    textTransform: 'capitalize',
                    backgroundColor: 'lightblue',
                  }}
                  className={'no-margin-table'}
                  inputColumns={insuranceFilesColumns}
                  inputData={
                    details._embedded && details._embedded.orderAttachments ? details._embedded.orderAttachments : []
                  }
                  pageSize={pageSize}
                  setPageSize={setPageSize}
                />
              </Grid>
            </Grid>

            <Grid item container xs={6}>
              <Grid item xs={12} className="section-header service-info">
                <Typography variant="h6">Medical Requirements</Typography>
              </Grid>
              {serviceItems}
            </Grid>
          </Grid>

          <Grid item container>
            <Button variant="contained" color="primary" className="view-order-button">
              Print
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="view-order-button"
              onClick={() => history.push('/insurance-user/orders')}
            >
              Back
            </Button>
          </Grid>
        </TabPanel>
        <TabPanel value={tabVal} index={1}>
          <DefaultTable
            title="Security Logs"
            inputColumns={securityLogsColumns}
            inputData={webAccessLogs}
            pageSize={pageSize2}
            setPageSize={setPageSize2}
          />
        </TabPanel>
      </main>
    </div>
  );
};

export default withRouter(InsurancePortalDetailPage);
