import React, { useState, useContext, useEffect } from "react";
import "./RegisterPage.scss";
import { register } from "../components/API_calls/users";
import { withRouter } from "react-router-dom";
import InputField from "../components/Profile/InputField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import PopUp from "../components/PopUp";
// import InputLabel from "@material-ui/core/InputLabel";
// import { makeStyles } from "@material-ui/core/styles";
// import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SelectField from "../components/Profile/SelectField";
import { UserContext, RolesContext } from "../UserContext";
import { typeOfAccounts } from "../components/data/constants";
import validator from "validator";
import { getAllAgencies } from '../components/API_calls/agencies';

import {
  invalidEmail,
  invalidName,
  invalidPassword,
  // invalidPhoneNumber,
  // invalidAddress
} from "../components/utils/fieldValidations";

const RegisterPage = ({ ...props }) => {

  const { userState, userDispatch } = useContext(UserContext);
  const { roles } = useContext(RolesContext);

  const [input, setInput] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
    accountType: "",
    agency: ""
  });
  const [errorMsg, setErrorMsg] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    confirmPassword: "",
    accountType: "",
    agency: ""
  });
  const [defaultError, setDefaultError] = useState("");
  const [popUpStatus, setPopUpStatus] = useState("");
  const [popUpType, setPopUpType] = useState("error");

  //! change this based on the user's account type
  // --> admin, sysadmin, superadmin
  const [agencies, setAgencies] = useState([]);
  useEffect(() => {
    const getAgencyListData = async () => {
      const agencyListData = await getAllAgencies();
      setAgencies([...agencyListData, {id: '', name: 'Other'}]);
    };
    getAgencyListData();
  }, []);

  const inputHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const invalidConfirmPasswords = (pass1, pass2) => {
    if (pass1 !== pass2) {
      return "Passwords do not match";
    } else if (invalidPassword(pass1, input.email)) {
      return "Invalid Password";
    }
    return "";
  };

  const validateFields = () => {
    const validEmail = !invalidEmail(input.email);
    const validFirstName = !invalidName(input.firstName);
    const validLastName = !invalidName(input.lastName);
    const passwordError = invalidConfirmPasswords(input.password, input.confirmPassword);
    const validPassword = passwordError === "" ? true : false;
    const validAccountType = !validator.isEmpty(input.accountType);
    const validAgency = !validator.isEmpty(input.agency);
    const emailError = validEmail ? "" : "Invalid email";
    const firstNameError = validFirstName ? "" : "Invalid Name";
    const lastNameError = validLastName ? "" : "Invalid Name";
    const accountTypeError = validAccountType ? "" : "Cannot be blank";
    const agencyError = validAgency ? "" : "Cannot be blank";
    if (
      !validEmail ||
      !validPassword ||
      !validFirstName ||
      !validLastName ||
      !validAccountType ||
      !validAgency
    ) {
      setErrorMsg({
        email: emailError,
        password: passwordError,
        firstName: firstNameError,
        lastName: lastNameError,
        confirmPassword: passwordError,
        agency: agencyError,
        accountType: accountTypeError
      });
      return false;
    }
    return true;
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setErrorMsg({
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      confirmPassword: "",
      accountType: "",
      agency: ""
    });
    const validFields = validateFields();
    if (!validFields) {
      return false;
    }
    try {
      const newUser = await register({
        firstName: input.firstName,
        lastName: input.lastName,
        email: input.email,
        password: input.password,
        roleLink: roles[input.accountType]
      });
      if (!newUser) {
        throw new Error();
      }
      setInput({
        email: "",
        password: "",
        firstName: "",
        lastName: "",
        confirmPassword: "",
        accountType: "",
        agency: ""
      });
      setDefaultError(`Success: new user ${newUser.username} created`);
      setPopUpType("success");
      setPopUpStatus(new Date());
    } catch (err) {
      setDefaultError(`Error: ${err.status} - ${err.message}`);
      setPopUpType("error");
      setPopUpStatus(new Date());
    }
  };

  return (
    <form onSubmit={submitHandler}>
      {popUpStatus ? <PopUp type={popUpType} message={defaultError} status={popUpStatus} /> : null}
      <div className="section-container">
        <Grid container>
          <Grid item xs={12}>
            <span className="header">
              <Typography variant="h2">CREATE USER</Typography>
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              label="Email"
              placeholder="Enter Email"
              value={input.email}
              errorMsg={errorMsg.email}
              changeHandler={inputHandler}
              name="email"
              noCase={true}
            />
          </Grid>
          <Grid item xs={6} md={3}>
            <SelectField
              label="Account Type"
              placeholder="Select Account Type"
              value={input.accountType}
              errorMsg={errorMsg.accountType}
              changeHandler={inputHandler}
              name="accountType"
            >
              {Object.keys(typeOfAccounts).map((option) => (
                <MenuItem key={option} value={typeOfAccounts[option]}>
                  {option}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={6} md={3}>
            <SelectField
              label="Agency Name"
              placeholder="Select Agency"
              value={input.agency}
              errorMsg={errorMsg.agency}
              changeHandler={inputHandler}
              name="agency"
            >
              {agencies.map((option) => (
                <MenuItem key={option.id} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </SelectField>
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              label="First Name"
              placeholder="Enter First Name"
              value={input.firstName}
              errorMsg={errorMsg.firstName}
              changeHandler={inputHandler}
              name="firstName"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              label="Last Name"
              placeholder="Enter Last Name"
              value={input.lastName}
              errorMsg={errorMsg.lastName}
              changeHandler={inputHandler}
              name="lastName"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              label="Password"
              placeholder="Enter password"
              type="password"
              value={input.password}
              errorMsg={errorMsg.password}
              changeHandler={inputHandler}
              name="password"
              noCase={true}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputField
              label="Confirm Password"
              placeholder="Enter password again"
              type="password"
              value={input.confirmPassword}
              errorMsg={errorMsg.confirmPassword}
              changeHandler={inputHandler}
              name="confirmPassword"
              noCase={true}
            />
          </Grid>
        </Grid>
        <div className="save-button">
          <Grid container justify="center">
            <Grid item>
              <Button color="primary" variant="contained" onClick={submitHandler}>
                CREATE USER
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </form>
  );
};

export default withRouter(RegisterPage);
