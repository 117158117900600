import React from "react";

const GenderOptions = ({ onChange: changeHandler, value, readOnly = false, ...props }) => {
  return (
    <>
      <label style={{ marginRight: "10px" }}>
        <input
          type="radio"
          name="gender"
          value="Unknown"
          checked={value === "Unknown"}
          onChange={changeHandler}
          readOnly={readOnly}
        />
        Unknown
      </label>
      <label style={{ marginRight: "10px" }}>
        <input
          type="radio"
          name="gender"
          value="Male"
          checked={value === "Male"}
          onChange={changeHandler}
          readOnly={readOnly}
        />
        Male
      </label>
      <label>
        <input
          type="radio"
          name="gender"
          value="Female"
          checked={value === "Female"}
          onChange={changeHandler}
          readOnly={readOnly}
        />
        Female
      </label>
    </>
  );
};

export default GenderOptions;
