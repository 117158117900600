import instance from "../utils/API";
import instance_base from "../utils/API_base";
import transform from "mass-data-transform";

const createCompanyFee = async (info) => {
  try {
    const res = await instance.post("/companyFees", info).then((res) => res.data);
    return {
      price: res.price,
      scanFaxPrice: res.scanFaxPrice,
      mobileServiceLink: res._embedded.mobileServiceType._links.self.href.replace(
        "{?projection}",
        ""
      ),
      mobileServiceType: { ...res._embedded.mobileServiceType },
      _links: { self: { href: res._links.self.href } }
    };
  } catch (error) {
    throw error;
  }
};

const updateCompanyFee_base = async (url, updatedInfo) => {
  try {
    const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

const deleteCompanyFee_base = async (url) => {
  try {
    await instance_base.delete(url);
  } catch (error) {
    throw error;
  }
};

const searchCompanyFee = async (companyLink) => {
  try {
    const res = await instance
      .get("/companyFees/search/findByInsuranceCompany", {
        params: { insuranceCompany: companyLink, page: 0, size: 1000, sort: "createDateTime,desc" }
      })
      .then((res) => res.data);
    const transformedData = transform(res._embedded.companyFees, {
      addFields: {
        mobileServiceLink: (r) => r.mobileServiceType._links.self.href.replace("{?projection}", "")
      }
    });
    return transformedData;
  } catch (error) {
    throw error;
  }
};

export { createCompanyFee, updateCompanyFee_base, searchCompanyFee, deleteCompanyFee_base };
