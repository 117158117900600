import React, { useEffect, useState, useContext } from 'react';
import { AppDataContext, GlobalNameListContext } from '../Context';

import DefaultTable from '../components/DefaultTable';
import InsuranceCompanyDetails from './table-details/InsuranceCompanyDetails';
import Dialog from '@material-ui/core/Dialog';
import Edit from '@material-ui/icons/Edit';
import AddBox from '@material-ui/icons/AddBox';

import { companyInfoColumns } from '../components/data/constants';

import { RolesContext } from '../UserContext';

import {
  // getAllInsuranceCompanies,
  updateInsuranceCompany_base,
  createInsuranceCompany,
  deleteInsuranceCompany,
} from './API_calls/insuranceCompanies';
import { getSpecificUserTypes } from './API_calls/users';
import { roundTo } from '../components/utils/helpers';

const CompanyCrudTable = ({ companyListData, ...props }) => {
  const { appDataState } = useContext(AppDataContext);
  const { roles } = useContext(RolesContext);
  const { setGlobalNameList } = useContext(GlobalNameListContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);
  const [open, setOpen] = useState(false);
  const [currDetails, setCurrDetails] = useState({});

  const [labList, setLabList] = useState([]);
  const [insuranceCompanyUsers, setInsuranceCompanyUsers] = useState([]);
  const [footerMsg, setFooterMsg] = useState('');

  useEffect(() => {
    if (Object.keys(roles).length === 7) {
      const obtainUserData = async () => {
        setIsLoading(true);
        try {
          const insuranceCompanyUserData = await getSpecificUserTypes(roles['ROLE_INSURANCE_USER']);
          setInsuranceCompanyUsers(insuranceCompanyUserData);
        } catch (e) {
          console.log(e);
        } finally {
          setIsLoading(false);
        }
      };
      obtainUserData();
    }
  }, [roles]);

  useEffect(() => {
    if (appDataState) {
      const initializeData = async () => {
        setIsLoading(true);
        try {
          const newData = companyListData.map((a) => Object.assign({}, a));
          newData.forEach((index) => (index.hstRate = roundTo(index.hstRate * 100, 2)));
          setInfo(newData);
          setLabList(appDataState.lab);
        } catch (e) {
          console.log('ERROR:', e);
        } finally {
          setIsLoading(false);
        }
      };
      initializeData();
    }
  }, [companyListData, appDataState]);

  const addFunction = async (newData) => {
    setIsLoading(true);
    try {
      const res = await createInsuranceCompany(newData);
      setInfo([res, ...info]);
      setOpen(false);
      setGlobalNameList((prev) => ({
        ...prev,
        insuranceCompanies: [...prev.insuranceCompanies, { name: res.name, link: res._links.self.href }],
      }));
    } catch (e) {
      setFooterMsg('Insurance Agency User already assigned.');
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {
    if (newData.tel !== null && newData.tel !== '--') {
      if (newData.tel.length !== 12 && newData.tel.length !== 0) {
        setFooterMsg('Check Tel Number');
        return;
      }
    }

    if (newData.fax !== null && newData.fax !== '--') {
      if (newData.fax.length !== 12 && newData.fax.length !== 0) {
        setFooterMsg('Check Fax Number');
        return;
      }
    }

    setIsLoading(true);
    try {
      await updateInsuranceCompany_base(oldData._links.self.href, newData);
      let newInfo = { ...newData };
      newInfo.hstRate = roundTo(newInfo.hstRate, 2);
      const data = info.map((currData) => (currData.tableData.id === oldData.tableData.id ? newInfo : currData));
      setInfo(data);
      setOpen(false);
    } catch (e) {
      setFooterMsg('Insurance Agency User already assigned.');
      console.log(e);
    } finally {
      setIsLoading(false);
      setFooterMsg('');
    }
  };

  const deleteFunction = async (oldData) => {
    setIsLoading(true);
    try {
      await deleteInsuranceCompany(oldData._links.self.href);
      setInfo((prev) => prev.filter((comp) => comp._links.self.href !== oldData._links.self.href));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const actions = [
    {
      icon: () => <Edit />,
      tooltip: 'Edit',
      onClick: (event, rowData, togglePanel) => {
        setCurrDetails(rowData);
        setOpen(true);
      },
    },
    {
      icon: () => <AddBox />,
      tooltip: 'Add',
      isFreeAction: true,
      onClick: (event) => {
        setCurrDetails({});
        setOpen(true);
      },
    },
  ];

  const handleClose = () => {
    setOpen(false);
    setFooterMsg('');
  };

  return (
    <div>
      <Dialog fullWidth maxWidth="lg" open={open} onClose={handleClose} scroll="paper">
        <InsuranceCompanyDetails
          details={currDetails}
          labList={labList}
          handleClose={handleClose}
          handleSave={updateFunction}
          handleCreate={addFunction}
          type={Object.keys(currDetails).length > 0 ? 'update' : 'add'}
          insuranceCompanyUsers={insuranceCompanyUsers}
          footerMsg={footerMsg}
        />
      </Dialog>
      <DefaultTable
        title="Company Info"
        inputColumns={companyInfoColumns}
        inputData={info}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        actions={actions}
        deleteFunction={deleteFunction}
      />
    </div>
  );
};

export default CompanyCrudTable;
