import React from "react";
import "./BasicTextarea.scss";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";

const BasicTextarea = ({ value, onChange, rows = 5, name, error = false, readOnly = false, errorMsg, ...props }) => {
  const basicTextAreaClass = classnames({ "basic-textarea": true, "error-textarea": error });

  return (
    <>
      <textarea
        className={basicTextAreaClass}
        value={value}
        rows={rows}
        onChange={onChange}
        name={name}
        readOnly={readOnly}
      />
      {error && <Typography variant='overline' color='secondary'>{errorMsg}</Typography>}
    </>
  );
};

export default BasicTextarea;
