import React from "react";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import "./DropdownField.scss";

const DropdownField = ({
  disabled = false,
  value,
  onChange,
  options,
  name,
  error,
  labelField,
  ...props
}) => {
  return (
    <FormControl disabled={disabled} fullWidth className="form-control" error={error}>
      <Select
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        autoWidth={false}
        // labelId="wurt"
        disableUnderline
        displayEmpty
      >
        {options.map((option) => {
          return (
            <MenuItem key={option.id ? option.id : option.link} value={option.id ? option.id : option.link}>
              {option[labelField]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default DropdownField;
