import instance from "../utils/API";
import instance_base from "../utils/API_base";

const getAllMobileExpenses = async () => {
  try {
    const res = await instance
      .get("/mobileExpenses", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.mobileExpenses;
  } catch (error) {
    throw error;
  }
};

const getMobileExpenses_base = async (url) => {
  try {
    const res = await instance_base.get(url).then((res) => res.data);
    return res._embedded.mobileExpenses;
  } catch (error) {
    throw error;
  }
};

const createMobileExpense = async (info) => {
  try {
    const res = await instance.post("/mobileExpenses", info).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

// const updateMobileExpense_base = async (url, updatedInfo) => {
//   try {
//     const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
//     return res;
//   } catch (error) {
//     throw error.response.data;
//   }
// };

const deleteMobileExpense_base = async url => {
  try {
    return instance_base.delete(url).then(res => console.log('mobile expense deleted'));
  } catch (err) {
    throw err.response.data;
  }
};

export { createMobileExpense, getMobileExpenses_base, getAllMobileExpenses, deleteMobileExpense_base };
