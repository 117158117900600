import instance from "../utils/API";
import instance_base from "../utils/API_base";

const getAllWebRequestServiceTypes = async () => {
  try {
    const res = await instance
      .get("/webRequestServiceTypes", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.webRequestServiceTypes;
  } catch (error) {
    throw error.response.data;
  }
};

const createWebRequestServiceType = async (info) => {
  try {
    const res = await instance.post("/webRequestServiceTypes", info).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

const updateWebRequestServiceType_base = async (url, updatedInfo) => {
  try {
    const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export {
  getAllWebRequestServiceTypes,
  createWebRequestServiceType,
  updateWebRequestServiceType_base
};
