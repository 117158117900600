import React, { useEffect, useState } from "react";

import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import MobileServiceCrudTable from "../components/MobileServiceCrudTable";
import WebRequestServiceCrudTable from "../components/WebRequestServiceCrudTable";
import MobileExpenseCrudTable from "../components/MobileExpenseCrudTable";

import { getAllWebRequestServiceTypes } from "../components/API_calls/webRequestServiceTypes";
import { getAllMobileServiceTypes } from "../components/API_calls/mobileServiceTypes";
import { getAllMobileExpenseTypes } from "../components/API_calls/mobileExpenseTypes";
import LoadingSpinner from "../components/LoadingSpinner";

const ServiceAndExpensePage = () => {
  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      try {
        const webRequestServiceListData = await getAllWebRequestServiceTypes();
        const mobileServiceListData = await getAllMobileServiceTypes();
        const mobileExpenseListData = await getAllMobileExpenseTypes();
        setWebRequestServiceList(webRequestServiceListData);
        setMobileServiceList(mobileServiceListData);
        setMobileExpenseList(mobileExpenseListData);
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    initialize();
  }, []);

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box
          style={{
            padding: "10px",
            overflow: "auto",
            margin: "5px"
          }}
        >
          {children}
        </Box>
      </Typography>
    );
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [webRequestServiceList, setWebRequestServiceList] = useState([]);
  const [mobileServiceList, setMobileServiceList] = useState([]);
  const [mobileExpenseList, setMobileExpenseList] = useState([]);

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <AppBar
        position="static"
        color="default"
        style={{ width: "100%", margin: "0", padding: "0" }}
      >
        <Tabs
          data-cy="edit"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Mobile Expense" />
          <Tab label="Mobile Service" data-cy='mobile-service'/>
          <Tab label="Web Request Service" data-cy='web-request-service' />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <MobileExpenseCrudTable data={mobileExpenseList} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <MobileServiceCrudTable data={mobileServiceList} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <WebRequestServiceCrudTable data={webRequestServiceList} />
      </TabPanel>
    </div>
  );
};

export default ServiceAndExpensePage;
