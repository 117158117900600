import instance from "../utils/API";

const getWebAccessLogs = async () => {
  try {
    const res = await instance
      .get("/webAccessLogs", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc",
        }
      })
      .then((res) => res.data);
    return res._embedded.webAccessLogs;
  } catch (error) {
    throw error;
  }
};

export { getWebAccessLogs };