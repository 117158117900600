import React from "react";

import "./FeeScheduleHeader.scss";
import AutoCompleteDropDown from "./OrderDetails/AutoCompletes/AutoCompleteDropDown";
import Divider from '@material-ui/core/Divider';

const FeeScheduleHeader = ({
  value,
  onChange,
  name,
  options,
  labelField,
  idField,
  title,
  ...props
}) => {
  return (
    // <div className="drop-down-container">
    //   <ReportLabel bold variant="subtitle1">
    //     {title}
    //   </ReportLabel>
    //   {/* <BasicSelect
    //     value={value}
    //     onChange={onChange}
    //     options={options}
    //     labelField={labelField}
    //     idField={idField}
    //     isSearchable={true}
    //   /> */}
    //   <AutoCompleteDropDownControlled
    //     source={name}
    //     dropDownData={options}
    //     dropDownVal={name === 'contractor' ? 'firstName' : 'name'}
    //     // renderItem={serviceDetail.contractor.name}
    //     enableDelete={false}
    //     multiple={false}
    //     onChange={onChange}
    //   />
    // </div>
    <div className='drop-down-container'>
      <AutoCompleteDropDown
        onChange={(e, val) => onChange(val)}
        dropDownData={options}
        dropDownVal={labelField}
        renderItems={[value]}
        label={`${title}:`}
      />
      <Divider />
    </div>
  );
};

export default FeeScheduleHeader;
