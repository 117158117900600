import React, { useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import { getJWT, removeJWT, getUserId, removeUserId } from "./utils/JWT";
import { UserContext } from "../UserContext";
import { updateLoggedInStatus } from "./utils/helpers";
import LoadingSpinner from "./LoadingSpinner";

const AuthenticateComponent = ({ history, ...props }) => {
  const { userState, userDispatch } = useContext(UserContext);

  useEffect(() => {
    if (userState) {
      const authenticateJWT = async (userId) => {
        try {
          const userInfo = await updateLoggedInStatus(userId);
          userDispatch({ type: "updateUserInfo", payload: userInfo });
        } catch (err) {
          removeJWT();
          removeUserId();
          history.push("/login");
        }
      };
      const jwt = getJWT();
      const userId = getUserId();
      if (!jwt || !userId) {
        history.push("/login");
      } else if (userState.isLoggedIn && userState.userInfo.hasOwnProperty("isAdmin")) {
        // what were they trying to do here?!?! 
      } else {
        authenticateJWT(userId);
      }
    }
  }, []);

  return (
    userState.isLoggedIn ? props.children : <LoadingSpinner />
  );
};

export default withRouter(AuthenticateComponent);
