/////////////////// TODO /////////////////////

/* field validations */
/* agency should be mapping from 'agent - insurance company' relations,
  (not directly from agency entity) */

/////////////////// TODO /////////////////////

import React from 'react';
import * as detailHelpers from './orderDetailsHelpers';
import '../OrderTable/OrderTableItem.scss';
import AutoCompleteDropDown from './AutoCompletes/AutoCompleteDropDown';
import { makeStyles } from '@material-ui/core/styles';

// import validator from "validator";

import clsx from 'clsx';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DebouncedTextField from '../basic-components/DebouncedTextField';
import OrderDetailsPhoneBox from '../basic-components/OrderDetailsPhoneBox';
import OrderDetailsPhoneBoxTest from '../basic-components/OrderDetailsPhoneBoxTest';
import OrderDetailsPhoneBoxStatic from '../basic-components/OrderDetailsPhoneBoxStatic';
import CircularProgress from '@material-ui/core/CircularProgress';

export const useStyles = makeStyles((theme) => ({
  error: {
    borderBottom: '2px solid #f44336 !important',
  },
}));

const AgentInfo = ({
  agentInfo,
  changeAgentInfo,
  openSearchAgent,
  nameList,
  agencyList,
  agency,
  changeOrderInfo,
  orderInfo,
  reqFlag,
  setReqFlag,
  loadingAgent,
  ...props
}) => {
  const classes = detailHelpers.useStyles();

  const handleDebouncedChange = (val, key) => {
    console.log(val, 'val', key, 'key');
    changeAgentInfo(key, val);
  };

  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
          <Grid item xs={12}>
            <Typography>
              <strong>Agent Name:</strong>
            </Typography>
          </Grid>

          {nameList && agentInfo.name && (
            <Grid item xs={12}>
              <AutoCompleteDropDown
                onChange={(e, newVal) => changeAgentInfo('agent', newVal)}
                dropDownData={nameList}
                dropDownVal="firstName"
                renderItems={agentInfo.name ? [agentInfo.name] : [{ firstName: '', lastName: '', link: '' }]}
                enableDelete={false}
                enableSearch={true}
                onClickSearch={openSearchAgent}
                disableClearable={true}
                error={!agentInfo.name.firstName}
                helperText="This field is required"
              />
            </Grid>
          )}

          {loadingAgent && (
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          )}
        </Grid>

        <Grid item lg={2} sm={4} xs={12} className={classes.overflowHidden}>
          <Grid item xs={12}>
            <Typography>
              <strong>Agent Email:</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              hiddenLabel
              defaultValue={agentInfo.email}
              style={{ width: "100%" }}
              // inputProps={{ readOnly: true }}
              onBlur={e => changeAgentInfo("email", e.target.value)}
            /> */}
            <DebouncedTextField
              className={clsx(classes.textField, classes.dense)}
              initialValue={agentInfo.email}
              onChange={handleDebouncedChange}
              name="email"
              type="email"
              error={!agentInfo.email}
              helperText={!agentInfo.email ? 'This field is required.' : null}

              // label="Client First Name:"
            />
          </Grid>
        </Grid>

        <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
          <Grid item xs={12}>
            <Typography>
              <strong>Agent Mobiles:</strong>
            </Typography>
          </Grid>

          <Grid item xs={12}>
            {/* <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              hiddenLabel
              defaultValue={agentInfo.mobile}
              style={{ width: "100%" }}
              // inputProps={{ readOnly: true }}
              onBlur={e => changeAgentInfo("mobile", e.target.value)}
            /> */}
            <OrderDetailsPhoneBoxTest
              className={clsx(classes.textField, classes.dense)}
              initialValue={agentInfo && agentInfo.mobile}
              onChange={handleDebouncedChange}
              name="mobile"
              type="number"
            />
          </Grid>
        </Grid>

        <Grid item lg={2} sm={6} xs={12} className={classes.overflowHidden}>
          <Grid item xs={12}>
            <Typography>
              <strong>Agency:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => changeOrderInfo('agency', newVal, null)}
              dropDownData={agencyList}
              dropDownVal="name"
              renderItems={orderInfo.agency ? [orderInfo.agency] : [{ name: '', link: '' }]}
              enableDelete={false}
              error={!orderInfo.agency}
              helperText="This field is required"
            />
          </Grid>
        </Grid>

        <Grid item className={classes.phoneNumber}>
          <Grid item xs={12}>
            <Typography>
              <strong>Business:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              hiddenLabel
              defaultValue={agentInfo.business}
              style={{ width: "100%" }}
              // inputProps={{ readOnly: true }}
              onBlur={e => changeAgentInfo("business", e.target.value)}
            /> */}
            <OrderDetailsPhoneBoxTest
              className={clsx(classes.textField, classes.dense)}
              initialValue={agentInfo.business}
              onChange={handleDebouncedChange}
              name="business"
              //inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>

        <Grid item style={{ width: '6.2em' }} className={classes.overflowHidden}>
          <Grid item xs={8}>
            <Typography>
              <strong>Ext.:</strong>
            </Typography>
          </Grid>
          <Grid item xs={8}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              hiddenLabel
              defaultValue={agentInfo.ext}
              style={{ width: '100%' }}
              //inputProps={{ readOnly: true }}
              onBlur={(e) => changeAgentInfo('ext', e.target.value)}
            />
            {/*<DebouncedTextField
              className={clsx(classes.textField, classes.dense)}
              initialValue={agentInfo.ext}
              onChange={handleDebouncedChange}
              name='ext'
              type='number'

            /> */}
          </Grid>
        </Grid>

        <Grid item className={classes.phoneNumber}>
          <Grid item xs={12}>
            <Typography>
              <strong>Fax:</strong>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {/* <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              hiddenLabel
              defaultValue={agentInfo.fax}
              style={{ width: "100%" }}
              onBlur={e => changeAgentInfo("fax", e.target.value)}
            /> */}
            <OrderDetailsPhoneBoxTest
              className={clsx(classes.textField, classes.dense)}
              initialValue={agentInfo.fax}
              onChange={handleDebouncedChange}
              name="fax"
              type="number"
              //inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>

        <Grid item style={{ width: '15em' }} className={classes.overflowHidden}>
          <Grid item xs={12} className={classes.overflowHidden}>
            <Typography>
              <strong>Agent Note:</strong>
            </Typography>
          </Grid>

          <Grid item xs={12} className={classes.overflowHidden}>
            <TextField
              className={clsx(classes.textField, classes.dense)}
              hiddenLabel
              multiline
              defaultValue={agentInfo.agentNote}
              style={{ width: '100%', paddingTop: '0px', margin: '0px' }}
              onBlur={(e) => changeAgentInfo('agentNote', e.target.value)}
            />
            {/*<DebouncedTextField
              multiline
              className={clsx(classes.textField, classes.dense)}
              initialValue={agentInfo.agentNote}
              onChange={handleDebouncedChange}
              name='agentNote'
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default AgentInfo;
