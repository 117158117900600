import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import "./RadioField.scss";

const RadioField = ({ value, onChange, options, name, ...props }) => {
  return (
    <FormControl>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value}
        onChange={onChange}
        row
      >
        {options.map(option => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={option.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

export default RadioField;
