import instance from "../utils/API";
import instance_base from "../utils/API_base";

const getAllMobileServiceTypes = async () => {
  try {
    const res = await instance
      .get("/mobileServiceTypes", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.mobileServiceTypes;
  } catch (error) {
    throw error.response.data;
  }
};

const createMobileServiceType = async (info) => {
  try {
    const res = await instance.post("/mobileServiceTypes", info).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

const updateMobileServiceType_base = async (url, updatedInfo) => {
  try {
    const res = await instance_base.patch(url, updatedInfo).then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

export { getAllMobileServiceTypes, createMobileServiceType, updateMobileServiceType_base };
