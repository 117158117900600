import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import { UserContext } from '../UserContext';
import AutoCompleteDropDown from '../components/OrderDetails/AutoCompletes/AutoCompleteDropDown';
import DateTimePicker from '../components/OrderDetails/DateTimePicker';
import SimpleButton from '../components/OrderDetails/SimpleButton';
import DefaultTable from '../components/DefaultTable';
import { dateRangeTypes, insurancePortalColumns } from '../components/data/constants';
import { searchOrders } from '../components/API_calls/orders';
import { downloadNewAttachments } from '../components/API_calls/orderAttachments';
import moment from 'moment';

const InsurancePortalListPage = props => {

  const history = useHistory();

  const { userState } = useContext(UserContext);
  console.log('userState:', userState)

  const [searchField, setSearchField] = useState({});
  const [dateRange, setDateRange] = useState('');

  // TABLE STATES
  const [ordersList, setOrdersList] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [orderPage, setOrderPage] = useState(0);

  // LOAD DATA

  const getData = async (page, search = true) => {
    const dateFields = dateRange ? {
      [dateRange.toDate]: searchField.toDate ? searchField.toDate : null,
      [dateRange.fromDate]: searchField.fromDate ? searchField.fromDate : null
    } : {};

    const list = await searchOrders({ 
      clientFirstName: searchField.clientFirstName ? searchField.clientFirstName : null,
      clientLastName: searchField.clientLastName ? searchField.clientLastName : null,
      insuranceCompany: userState.userInfo.managedInsuranceCompany, // update when confirmed from client
      ...dateFields
    }, page);
    // console.log('list:', list)
    
    // if on search submission, replace entire list, otherwise, append new results to existing list
    search ? setOrdersList(list) : setOrdersList(prev => [...prev, ...list]);
  };

  useEffect(() => {
    if (userState) {
      getData();
    }

  }, [userState]);
  
  // FUNCTION DECLARATIONS
  const changeDate = (val, setCurrentTime, source) => {
    setCurrentTime(val);
    setSearchField(prev => ({ ...prev, [source]: val ? val.format() : val }));
  };

  const onRowClick = (event, rowData) => {
    console.log('rowData:', rowData)
    history.push({
      pathname: `/insurance-user/orders/${rowData.orderID}`,
      state: { orderLink: rowData._links.self.href }
    });
  };

  const actions = [
    {
      icon: () => <CloudDownloadIcon />,
      tooltip: "Download All New PDFs",
      isFreeAction: true,
      onClick: async() => {
        try {
          const res = await downloadNewAttachments(userState.userInfo.managedInsuranceCompany);
          let link = document.createElement("a");
          link.download = `new-files-${moment().format('MM-DD-YYYY')}.zip`;
          const url = window.URL.createObjectURL(new Blob([res]));
          link.href = url;
          link.click();
        } catch (err) {
          alert('No new files to download');
        }
      }
    },
  ];

  const onMoreOrdersClick = () => {
    setOrderPage(prev => prev + 1);
    getData(orderPage + 1, false);
  };
  
  return (
    <>
      <Grid container spacing={1} style={{ margin: "10px 0"}}>
        <Grid item container lg={12} sm={12} xs={12} spacing={1}>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <TextField 
              fullWidth
              onChange={e => setSearchField(prev => ({ ...prev, clientLastName: e.target.value }))}
              value={searchField.clientLastName}
              label='Last Name'
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <TextField 
              fullWidth
              onChange={e => setSearchField(prev => ({ ...prev, clientFirstName: e.target.value }))}
              value={searchField.clientFirstName}
              label='First Name'
            />
          </Grid>
        </Grid>

        <Grid item container lg={11} sm={12} xs={12} spacing={1} style={{ margin: "10px 0"}}>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setDateRange(newVal)}
              dropDownData={dateRangeTypes}
              renderItems={dateRange ? [dateRange] : [null]}
              enableDelete={false}
              dropDownVal='name'
              label='Date Range:'
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <DateTimePicker
              source='fromDate'
              fromDate={searchField.fromDate ? searchField.fromDate : null}
              label='From:'
              changeDate={changeDate}
              clearable
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <DateTimePicker
              source='toDate'
              toDate={searchField.toDate ? searchField.toDate : null}
              label='To:'
              changeDate={changeDate}
              clearable
            />
          </Grid>
        </Grid>

        <Grid item container lg={1} sm={12} xs={12} style={{ marginTop: "20px", textAlign: "center" }}>
          <Grid item xs={6}>
            <SimpleButton onClick={getData} text={"SEARCH"} icon={<SearchIcon />} />
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      <DefaultTable
        title=""
        inputColumns={insurancePortalColumns}
        inputData={ordersList}
        pageSize={pageSize}
        setPageSize={setPageSize}
        // headerStyle={{ backgroundColor: "#34b7eb", textTransform: 'capitalize' }}
        onRowClick={onRowClick}
        actions={actions}
        loadMoreOrders={onMoreOrdersClick}
      />
    </>
  );
};

export default InsurancePortalListPage;