import React from 'react';

import logo from '../../utils/medifast_logo.png';
import moment from 'moment';
import './Invoice.scss';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import { generateKey, roundTo } from '../../utils/helpers';

const PayslipPdf = ({ details, itemsList, ...props }) => {
  console.log('details:', details);

  const contractor = details.serviceItems.length > 0 ? details.serviceItems[0].user : {};

  const tableRows = itemsList.map((item) => {
    return (
      <TableRow key={generateKey()}>
        <TableCell>{item.order.medifastID}</TableCell>
        <TableCell>
          {item.order.orderDate
            ? moment(item.order.orderDate).format('MM-DD-YYYY')
            : moment(item.order.createDateTime).format('MM-DD-YYYY')}
        </TableCell>
        <TableCell>{moment(item.order.completionTime).format('MM-DD-YYYY')}</TableCell>
        <TableCell>
          {item.order.clientInfo.lastName}, {item.order.clientInfo.firstName}
        </TableCell>
        <TableCell>{item.order.insuranceShortCode}</TableCell>
        <TableCell>{item.serviceExpenseName}</TableCell>
        <TableCell align="right">${item.serviceExpenseCharge}</TableCell>
      </TableRow>
    );
  });

  return (
    <>
      <div id="invoice-container">
        <div className="invoice-header-container">
          <div className="invoice-header-item">
            <img src={logo} alt="Medifast logo" />
            <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
              {contractor.lastName}, {contractor.firstName} ({contractor.contractorShortCode})
            </Typography>
            <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
              {contractor.address}
              <br />
              {contractor.city}, {contractor.province}
              <br />
              {contractor.postalCode}
            </Typography>
          </div>

          <div className="invoice-header-item">
            <div>
              <Typography variant="button">Date:</Typography>
              <Typography>{moment(details.payslipDate).format('MM-DD-YYYY')}</Typography>
            </div>
            <div>
              <Typography variant="button">Payslip ID:</Typography>
              <Typography>{details.payslipID}</Typography>
            </div>
            <div>
              <Typography variant="button">Up to Period:</Typography>
              <Typography>{moment(details.upToPeriod).format('MM-DD-YYYY')}</Typography>
            </div>
          </div>
        </div>

        <div className="invoice-table-container">
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>Order #</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Compl.</TableCell>
                <TableCell>Applicant Name</TableCell>
                <TableCell>Co.</TableCell>
                <TableCell>Service/Expense</TableCell>
                <TableCell>Charge</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableRows}
              <TableRow>
                <TableCell colSpan={5} />
                <TableCell variant="head">Subtotal:</TableCell>
                <TableCell align="right">${roundTo(details.ordersTotal, 2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} />
                <TableCell variant="head">HST:</TableCell>
                <TableCell align="right">${roundTo(details.hstTotal, 2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} />
                <TableCell variant="head">Grand Total:</TableCell>
                <TableCell align="right">${roundTo(details.grandTotal, 2)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
};

export default PayslipPdf;
