import React from "react";
import "./ReportLabel.scss";
import classNames from "classnames";

import Typography from "@material-ui/core/Typography";

const ReportLabel = ({
  variant = "body2",
  color = "initial",
  bold = false,
  display = "inline",
  flexEnd = false,
  className,
  toUpperCase = false,
  minHeight = false,
  ...props
}) => {

  const containerClasses = classNames({
    flexEnd: flexEnd === true,
    minHeight: minHeight === true,
  });

  const textClasses = classNames({
    boldFont: bold === true,
    toUpperCase: toUpperCase === true
  });

  const label = () => {
    return (
      <div className={containerClasses}>
        <Typography variant={variant} color={color} className={textClasses} display={display}>
          {props.children}
        </Typography>
      </div>
    );
  };
  return display === "flex" ? <span className={textClasses}>{label()}</span> : label();
};

export default ReportLabel;
