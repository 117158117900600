import React from "react";
import Typography from "@material-ui/core/Typography";

import "./ProfileSectionHeader.scss";

const ProfileSectionHeader = ({ saveStatus = null, title }) => {
  // const statusClass = classNames({
  //   saved__text: true,
  //   "saved__text--success": saveStatus,
  //   "saved__text--fail": saveStatus === false
  // });

  // let content = saveStatus
  //   ? "Saved Successfully"
  //   : saveStatus === null
  //   ? ""
  //   : "Something went wrong. Please try again.";
  return (
    <div className="header-container">
      <Typography variant="h4" className="title__text" color="primary">
        {title}
      </Typography>
    </div>
  );
};

export default ProfileSectionHeader;
