import React, { useState, useContext, useEffect } from "react";
import TextField from '@material-ui/core/TextField';


const normalizeInput = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;
  
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
  // return value
};



const validateInput = value => {
  let error = ""
  
  if (!value) error = "Required!"
  else if (value.length !== 14) error = "Invalid phone format. ex: (555) 555-5555";
  
  return error;
};
    
 export default class OrderDetailsPhoneBox extends React.Component {
  constructor() {
    super();
    
    this.state = { value: "", error: "", selectionStart: 0, selectionEnd: 0 };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReset = this.handleReset.bind(this);
  }

  input = React.createRef();

  componentDidMount() {
    const { value } = this.props;
    this.input.current.value = normalizeInput(value)
  }

  componentDidUpdate(prevProps, prevState) {
    const { value, selectionStart, selectionEnd } = this.state;
    if (prevState.value !== value) {
      this.input.current.value = normalizeInput(value)

      // make it happen
      this.input.current.selectionStart = selectionStart;
      this.input.current.selectionEnd = selectionEnd;


    }
  }


  // { target: { value } }
  handleChange=(e) => {  
    const { target } = e;

    this.props.onChange(target.value, this.props.name)    

    // save cursor position
    this.setState({
      selectionStart: target.selectionStart,
      selectionEnd: target.selectionEnd
    });

     

    // this.setState(prevState=> ({ value: normalizeInput(value, prevState.value) }));
  };
  
  handleSubmit(e) {
    e.preventDefault();
    const error = validateInput(this.state.value);
    
    this.setState({ error }, () => {
       if(!error) {
         setTimeout(() => {
           alert(JSON.stringify(this.state, null, 4));
         }, 300)
       }
    });
  }

  
  handleReset() {
     this.setState({ value: "", error: "" });
  };
  
  render() {
    return(
      <form className="form" onSubmit={this.handleSubmit}>
        <div className="input-container" style={{width: "9em"}}>
          <TextField
            ref={this.input}
            className="input"
            type="text"
            name="phone"
            placeholder="(xxx) xxx-xxxx"
            value={this.props.initialValue}
            onChange={this.handleChange}
          />
          {this.state.error && <p className="error">{this.state.error}</p>}
        </div>
      </form>
    );
  }
}