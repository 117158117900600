import React, { useState, useEffect, useReducer, createContext } from "react";
import instance from "./components/utils/API";
import { getAllInsuranceCompanies } from "./components/API_calls/insuranceCompanies";
import { getSearchedUsers, getRoles, getMobileServiceTypes } from "./components/API_calls/orders";
import * as detailHelpers from "./components/OrderDetails/orderDetailsHelpers";

/***************************** ORDERS CONTEXT *****************************/

const initialOrderState = {
  orders: [{}]
};

let orderReducer = (state, action) => {
  switch (action.type) {
    case "INITIALIZE":
      action.payload.setOrders();
      return { ...state, orders: action.payload.data };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const OrdersContext = createContext(initialOrderState);

const OrdersProvider = props => {
  const [orderState, orderDispatch] = useReducer(orderReducer, initialOrderState);

  return <OrdersContext.Provider value={{ orderState, orderDispatch }}>{props.children}</OrdersContext.Provider>;
};

/***************************** ORDERS CONTEXT *****************************/
/***************************** ORDER TABS CONTEXT *****************************/

const initialOrderTabState = {
  tabIndexVal: 0
};

let orderTabReducer = (state, action) => {
  switch (action.type) {
    case "SETVAL":
      return { ...state, tabIndexVal: action.payload.data };
    default:
      throw new Error(`Unsupported action type: ${action.type}`);
  }
};

const OrderTabContext = createContext(initialOrderTabState);

const OrderTabProvider = props => {
  const [orderTabState, orderTabDispatch] = useReducer(orderTabReducer, initialOrderTabState);

  return (
    <OrderTabContext.Provider value={{ orderTabState, orderTabDispatch }}>{props.children}</OrderTabContext.Provider>
  );
};

/***************************** ORDER TABS CONTEXT *****************************/
/***************************** APP DATA CONTEXT *****************************/

const AppDataContext = createContext({});

const AppDataProvider = props => {
  const [appDataState, setAppDataState] = useState(null);

  const getAppData = async () => {
    const res = await instance
      .get("/appdata")
      .then(res =>{
        return res.data
      } )
      .catch(error => {
        // throw error.response.data;
        throw error.response;
      });

    return res;
  };

  useEffect(() => {
    getAppData().then(val => setAppDataState(val));
  }, []);

  return <AppDataContext.Provider value={{ appDataState }}>{props.children}</AppDataContext.Provider>;
};

/***************************** APP DATA CONTEXT *****************************/
/***************************** GLOBAL NAMELIST CONTEXT *****************************/

const GlobalNameListContext = createContext({});

const GlobalNameListProvider = props => {
  const [globalNameList, setGlobalNameList] = useState({
    insuranceCompanies: [],
    agents: [],
    contractors: [],
    mobileServiceTypes: [],
    admins: [],
    superAdmins: [],
  });

  useEffect(() => {
    getAllInsuranceCompanies().then(res => {
      let list = detailHelpers.getInsuranceCompaniesList(res);
      setGlobalNameList(prev => {
        return {
          ...prev,
          insuranceCompanies: list
        };
      });
    });

    getRoles().then(res => {
      let agentRole = detailHelpers.getTargetRole(res, "ROLE_AGENCY_USER");
      let contractorRole = detailHelpers.getTargetRole(res, "ROLE_CONTRACTOR");
      let adminRole = detailHelpers.getTargetRole(res, 'ROLE_ADMIN');
      let superAdminRole = detailHelpers.getTargetRole(res, 'ROLE_SUPER_ADMIN');

      let paramsAgent = {
        enabled: true,
        role: agentRole._links.self.href,
        size: 100
      };

      let paramsContractor = {
        enabled: true,
        role: contractorRole._links.self.href,
        size: 100
      };

      let paramsAdmin = {
        enabled: true,
        role: adminRole._links.self.href,
        size: 100
      };

      let paramsSuperAdmin = {
        enabled: true,
        role: superAdminRole._links.self.href,
        size: 100
      };

      getSearchedUsers(paramsAgent).then(res => {
        let list = detailHelpers.getListWithLinks("firstName", res._embedded.users);
        setGlobalNameList(prev => {
          return {
            ...prev,
            agents: list
          };
        });
      });

      getSearchedUsers(paramsContractor).then(res => {
        let contractorList = detailHelpers.getListWithLinks("firstName", res._embedded.users);
        setGlobalNameList(prev => {
          return {
            ...prev,
            contractors: contractorList
          };
        });
      });

      getSearchedUsers(paramsAdmin).then(res => {
        let adminList = detailHelpers.getListWithLinks("firstName", res._embedded.users);
        setGlobalNameList(prev => {
          return {
            ...prev,
            admins: adminList
          };
        });
      });

      getSearchedUsers(paramsSuperAdmin).then(res => {
        let superAdminList = detailHelpers.getListWithLinks("firstName", res._embedded.users);
        setGlobalNameList(prev => {
          return {
            ...prev,
            superAdmins: superAdminList
          };
        });
      });
    });

    getMobileServiceTypes().then(res => {
      let mobileServiceTypeList = detailHelpers.getListWithLinks("shortCode", res._embedded.mobileServiceTypes);

      setGlobalNameList(prev => {
        return {
          ...prev,
          mobileServiceTypes: mobileServiceTypeList
        };
      });
    });
  }, []);

  return <GlobalNameListContext.Provider value={{ globalNameList, setGlobalNameList }}>{props.children}</GlobalNameListContext.Provider>;
};

/***************************** GLOBAL NAMELIST CONTEXT *****************************/

/***************************** INVOICE PAYSLIP DATA CONTEXT *****************************/

const InvoicePayslipDataContext = createContext({});

const InvoicePayslipDataProvider = props => {
  const [invoicePayslipData, setInvoicePayslipData] = useState([]);

  return <InvoicePayslipDataContext.Provider value={{ invoicePayslipData, setInvoicePayslipData }}>{props.children}</InvoicePayslipDataContext.Provider>;
};

/***************************** INVOICE PAYSLIP DATA CONTEXT *****************************/

/***************************** WEB REQUESTS CONTEXT *****************************/

const WebRequestsContext = createContext({});

const WebRequestsProvider = props => {
  const [webRequests, setWebRequests] = useState(0);

  return <WebRequestsContext.Provider value={{ webRequests, setWebRequests }}>{props.children}</WebRequestsContext.Provider>;
};

/***************************** WEB REQUESTS CONTEXT *****************************/

export {
  OrdersContext,
  OrdersProvider,
  OrderTabContext,
  OrderTabProvider,
  AppDataContext,
  AppDataProvider,
  GlobalNameListContext,
  GlobalNameListProvider,
  InvoicePayslipDataContext,
  InvoicePayslipDataProvider,
  WebRequestsContext,
  WebRequestsProvider
};
