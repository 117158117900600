import React from "react";
import ReactDOM from "react-dom";

import App from "./components/App";
import "./index.scss";
import "typeface-roboto";

import {
  // OrdersProvider,
  // OrderTabProvider,
  AppDataProvider,
  // GlobalNameListProvider
} from "./Context";
import { UserProvider } from "./UserContext";

/********************************* DATE PICKER SECTION **********************************/

import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

// axios.defaults.baseURL = process.env.PRODUCTION
//   ? process.env.PRODUCTION_API
//   : "https://dev.api.gigeco.net:8080/api/v1";

ReactDOM.render(
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <UserProvider>
      <AppDataProvider>
        <App />
      </AppDataProvider>
    </UserProvider>
  </MuiPickersUtilsProvider>,
  document.getElementById("root")
);
