// import axios from "axios";
import instance from "../utils/API";

//get global data
const getGlobalData = async () => {
  try {
    const res = await instance.get("/appdata").then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

export { getGlobalData };
