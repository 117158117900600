import React, { useEffect, useState } from "react";
import DefaultTable from "./DefaultTable";

import { mobileExpenseInfoColumns } from "./data/constants";
import {
  createMobileExpenseType,
  updateMobileExpenseType_base,
} from "./API_calls/mobileExpenseTypes";

import PopUp from "./PopUp";


const MobileExpenseCrudTable = ({ data, ...props }) => {
  
  useEffect(() => {
    if (data) {
      const initializeData = async () => {
        try {
          setIsLoading(true);
          setInfo(data);
        } catch (e) {
          console.log("error:", e);
        } finally {
          setIsLoading(false);
        }
      };
      initializeData();
    }
  }, [data]);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);

  //for popup error msg
  const [popUpStatus, setPopUpStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [popUpType, setPopUpType] = useState("");



  const addFunction = async (newData) => {
    setIsLoading(true);
    try {
      const res = await createMobileExpenseType(newData);
      setInfo(prev => [res, ...prev]);
    } catch (e) {

      setErrorMsg('Error: Please fillout all fields')
      setPopUpType('error')

      setPopUpStatus(new Date())

      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const updateFunction = async (newData, oldData) => {
    setIsLoading(true);
    try {
      await updateMobileExpenseType_base(oldData._links.self.href, newData);
      setInfo(prev => prev.map(currData => currData.tableData.id === oldData.tableData.id ? newData : currData));
    } catch (e) {
      setErrorMsg('Error: Please fillout all fields')
      setPopUpType('error')

      setPopUpStatus(new Date())

      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <DefaultTable
      title="Mobile Expense"
      inputColumns={mobileExpenseInfoColumns}
      inputData={info}
      updateFunction={updateFunction}
      addFunction={addFunction}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
      {popUpStatus ? <PopUp type="error" message={errorMsg} status={popUpStatus} /> : null}

    </>
  );
};

export default MobileExpenseCrudTable;
