import React, { useContext } from 'react';
import * as billingHelpers from './BillingHelpers';

import Drawer from '@material-ui/core/Drawer';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Divider from '@material-ui/core/Divider';

import { InvoicePayslipDataContext } from '../../Context';

const BillingDrawer = ({open, setOpen, setDetails, currentId, idSource, ...props }) => {

  const { invoicePayslipData } = useContext(InvoicePayslipDataContext);

  const classes = billingHelpers.useStyles();
  const theme = useTheme();

  const handlePrevButton = () => {
    const currIndex = invoicePayslipData.findIndex(val => val[idSource] === currentId);
    if (currIndex !== 0) {
      setDetails(prev => ({ ...prev, ...invoicePayslipData[currIndex - 1] }));
    } else {
      console.log('This is the first invoice'); // REPLACE THIS WITH CLIENT SIDE ERROR HANDLING
    }
  };

  const handleNextButton = () => {
    const currIndex = invoicePayslipData.findIndex(val => val[idSource] === currentId);
    if (currIndex !== (invoicePayslipData.length - 1)) {
      setDetails(prev => ({ ...prev, ...invoicePayslipData[currIndex + 1] }));
    } else {
      console.log('This is the first invoice'); // REPLACE THIS WITH CLIENT SIDE ERROR HANDLING
    }
  };

  const handleLastButton = () => {
    setDetails(prev => ({ ...prev, ...invoicePayslipData[invoicePayslipData.length - 1] }));
  };

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={open}
      classes={{
        paper: classes.drawerPaper
      }}
    >
      <div className={classes.drawerHeader}>
        <List>
          <ListItem button onClick={() => setOpen(false)}>
            {theme.direction === "rtl" ? (
              <ListItemText primary={"OPEN"} style={{ color: "red" }} />
            ) : (
              <ListItemText primary={"CLOSE"} style={{ color: "red" }} />
            )}
          </ListItem>
          <Divider style={{ border: "solid 1px grey" }} variant="middle" />
        </List>
      </div>

      <List>
        {/* <Link to="/orders" className="text-link">
          <ListItem button>
            <ListItemText primary="Search" />
          </ListItem>
        </Link>
        <Divider /> */}
        <ListItem
          button
          onClick={handlePrevButton}
          style={{ paddingTop: "0px", paddingBottom: "0px", color: "blue" }}
        >
          <ListItemText primary="Prev." />
        </ListItem>
        <ListItem
          button
          onClick={handleNextButton}
          style={{ paddingTop: "0px", paddingBottom: "0px", color: "blue" }}
        >
          <ListItemText primary="Next" />
        </ListItem>
        <ListItem
          button
          onClick={handleLastButton}
          style={{ paddingTop: "0px", paddingBottom: "0px", color: "blue" }}
        >
          <ListItemText primary="Last" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default BillingDrawer;