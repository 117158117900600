import React from "react";
import TextField from "@material-ui/core/TextField";
import "./InputField.scss";
import clsx from 'clsx';

const InputField = ({
  type = "text",
  name,
  label,
  placeholder,
  value,
  changeHandler,
  errorMsg,
  noCase,
  disabled = false
}) => {

  const classes = clsx({
    textField: true,
    noCase: noCase
  })

  return (
    <div className="container">
      <TextField
        autoComplete="off"
        InputProps={{
          readOnly: disabled
        }}
        data-cy={name} 
        type={type}
        name={name}
        error={errorMsg ? true : false}
        className={classes}
        value={value}
        onChange={changeHandler}
        id={name}
        label={label}
        placeholder={placeholder}
        helperText={errorMsg}
        fullWidth
        margin="normal"
        variant="outlined"
        InputLabelProps={{
          shrink: true
        }}
      />
    </div>
  );
};

export default InputField;
