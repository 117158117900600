import React from "react";
import ReactToPrint from "react-to-print";
import Button from "@material-ui/core/Button";

const PrintPDF = ({ refProp, ...props }) => {
  const printDocument = async () => {};

  return (
    <ReactToPrint
      trigger={() => (
        <Button
          style={{ width: "100%", margin: "0", padding: "0" }}
          variant="contained"
          onClick={printDocument}
        >
          PRINT
        </Button>
      )}
      content={() => refProp.current}
    />
  );
};

export default PrintPDF;
