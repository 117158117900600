import React, { useState, useContext } from "react";
import "./RatesAndSchedulesPage.scss";
import { RolesContext } from "../UserContext";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CompanyRates from "./CompanyRates";
import ContractorRates from "./ContractorRates";
import CompanyFeeSchedule from "./CompanyFeeSchedule";
import ContractorFeeSchedule from "./ContractorFeeSchedule";


const RatesAndSchedulesPage = () => {
  const { roles } = useContext(RolesContext);

  const TabPanel = ({ children, value, index, ...other }) => {
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box
          style={{
            padding: "10px",
            overflow: "auto",
            margin: "5px"
          }}
        >
          {children}
        </Box>
      </Typography>
    );
  };

  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar
        position="static"
        color="default"
        style={{ width: "100%", margin: "0", padding: "0" }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
        >
          <Tab label="Contractor Fee Schedule" />
          <Tab label="Contractor Rates" data-cy="contractor-rates"/>
          <Tab label="Company Fee Schedule" />
          <Tab label="Company Rates" data-cy="company-rates" />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <ContractorFeeSchedule
          // contractorData={contractorList}
          // mobileServiceList={mobileServiceList}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <ContractorRates 
          // contractorData={contractorList} 
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <CompanyFeeSchedule 
          // mobileServiceList={mobileServiceList} 
          // companyData={companyList} 
        />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <CompanyRates 
          // companyData={companyList} 
        />
      </TabPanel>
    </>
  );
};

export default RatesAndSchedulesPage;
