import React, { useEffect, useState } from "react";

import DefaultTable from "./DefaultTable";

import { updateLogsInfoColumns } from "./data/constants";
import { updateLog } from "./API_calls/orderLogs";

const UpdateLogsCrudTable = ({ details, ...props }) => {
  
  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);


  useEffect(() => {

    if (details && details._embedded) {
      const updateLogs = details._embedded.orderUpdateLogs 
        ? details._embedded.orderUpdateLogs.map((log, index) => ({...log, id: index, by: `${log.by.lastName}, ${log.by.firstName}`}))
        : [];
      setInfo(updateLogs);
    }
  
  }, [details]);

  // useEffect(() => {
  //   console.log('info:', info)
  
  // }, [info]);

  const updateFunction = async (newData, oldData) => {
    console.log('oldData:', oldData)
    console.log('newData:', newData)
    setIsLoading(true);
    try {
      await updateLog(oldData._links.self.href.split('{')[0], {remarks: newData.remarks});
      setInfo(prev => prev.map((currData) => currData.tableData.id === oldData.tableData.id ? newData : currData));
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <DefaultTable
      title="Update Logs"
      inputColumns={updateLogsInfoColumns}
      inputData={info}
      updateFunction={updateFunction}
      isLoading={isLoading}
      pageSize={pageSize}
      setPageSize={setPageSize}
    />
  );
};

export default UpdateLogsCrudTable;
