import React, { useContext } from 'react';

import * as billingHelpers from "./BillingHelpers";
import clsx from 'clsx';

import AutoCompleteDropDown from '../OrderDetails/AutoCompletes/AutoCompleteDropDown';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { InvoicePayslipDataContext } from '../../Context';
import moment from 'moment';
import { roundTo } from '../utils/helpers';

const PayslipHeader = ({details, beforeCreate, setDetails, upToDate, ...props}) => {

  const classes = billingHelpers.useStyles();

  const { invoicePayslipData } = useContext(InvoicePayslipDataContext);

  return (
    <>
      {details && beforeCreate ? 
        <>
          <Grid item container spacing={1}>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Up To Period:"
                fullWidth
                variant="filled"
                type="date"
                value={upToDate}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <AutoCompleteDropDown
                onChange={(e, newVal) => setDetails(invoicePayslipData.find(data => data._links.self.href === newVal._links.self.href))}
                dropDownData={invoicePayslipData}
                dropDownVal='firstName'
                renderItems={[details]}
                enableDelete={false}
                label='Contractor:'
              />
            </Grid>
          </Grid>
        </> :
        <>
          <Grid item container spacing={1}>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Payslip No.:"
                fullWidth
                variant="filled"
                value={details.payslipID}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Payslip Date:"
                fullWidth
                variant="filled"
                value={details.payslipDate ? moment(details.payslipDate).format('MM-DD-YYYY') : undefined}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                label="Up To Period:"
                fullWidth
                variant="filled"
                value={details.upToPeriod ? moment(details.upToPeriod).format('MM-DD-YYYY') : undefined}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
          </Grid>
          <Grid item container spacing={1}>
            <Grid item xs={12}>
              <TextField
                className={clsx(classes.textField, classes.dense)}
                margin="none"
                fullWidth
                value={details.serviceItems[0] ? `${details.serviceItems[0].user.lastName}, ${details.serviceItems[0].user.firstName}` : ''}
                // error={error.error}
                // helperText={error.msg}
                readOnly
              />
            </Grid>
          </Grid>
        </>
      }
      <Grid container spacing={1} style={{ marginTop: '10px' }}>
        <Grid item lg={3} sm={6} xs={12}>
          <TextField
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            label={`Amount($):`}
            fullWidth
            variant="filled"
            value={details.ordersTotal ? roundTo(details.ordersTotal, 2) : details.ordersTotal}
            // error={error.error}
            // helperText={error.msg}
            readOnly
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <TextField
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            label='HST (%):'
            fullWidth
            variant="filled"
            value={details.hstRate*100}
            // error={error.error}
            // helperText={error.msg}
            readOnly
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <TextField
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            label='HST Amount ($):'
            fullWidth
            variant="filled"
            value={details.hstTotal ? roundTo(details.hstTotal, 2) : details.hstTotal} // this number is not returned from the back end rounded, so we are rounding it on the front end
            // error={error.error}
            // helperText={error.msg}
            readOnly
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <TextField
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            label={`Grand Total ($):`}
            fullWidth
            variant="filled"
            value={details.grandTotal ? roundTo(details.grandTotal, 2) : details.grandTotal}
            // error={error.error}
            // helperText={error.msg}
            readOnly
          />
        </Grid>
      </Grid>

    </>
  );

};

export default PayslipHeader;