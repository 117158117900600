import React from "react";
// import classnames from "classnames";

const BasicCheckbox = ({ value = "", checked, onChange, name, error = false, ...props }) => {
  // const basicTextInputClass = classnames({ "basic-input": true, "error-input": error });

  return (
    <div style={{ display: "flex" }}>
      <input
        // className={basicTextInputClass}
        checked={checked}
        onChange={onChange}
        type="checkbox"
        name={name}
        value={value}
      />
    </div>
  );
};

export default BasicCheckbox;
