import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";
import "./DrawerMenu.scss";
import { removeJWT, removeUserId } from "../utils/JWT";
import { UserContext } from "../../UserContext";
// import { OrderTabContext } from "../../Context";
// import * as detailHelpers from "../OrderDetails/orderDetailsHelpers";
import { getLatestOrder, searchOrders } from "../API_calls/orders";

import { Link } from "react-router-dom";

import clsx from "clsx";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import HomeIcon from "@material-ui/icons/Home";
import DescriptionIcon from "@material-ui/icons/Description";
import PersonIcon from "@material-ui/icons/Person";
// import PersonAddIcon from "@material-ui/icons/PersonAdd";
// import BusinessIcon from "@material-ui/icons/Business";
// import LocationCityIcon from "@material-ui/icons/LocationCity";
import HelpIcon from "@material-ui/icons/Help";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import EmailIcon from "@material-ui/icons/Email";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";
// import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import BarChartIcon from "@material-ui/icons/BarChart";
import ReceiptIcon from "@material-ui/icons/Receipt";
import ListIcon from "@material-ui/icons/List";
import AddBoxIcon from "@material-ui/icons/AddBox";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import ApartmentIcon from "@material-ui/icons/Apartment";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Badge from '@material-ui/core/Badge';
import { WebRequestsContext } from "../../Context";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  menuButton: {
    marginRight: 36
  },
  hide: {
    display: "none"
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap"
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0
    }
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar
  },
  content: {
    justifyContent: "flex-end",
    width: "100%"
  },
  contentShift: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    width: `calc(100% - ${drawerWidth}px)`
  },
}));

const DrawerMenu = ({ location, globalData, ...props }) => {
  // const { pathname } = location;
  const { userState, userDispatch } = useContext(UserContext);
  const { webRequests, setWebRequests } = useContext(WebRequestsContext);
  // const { orderTabState } = useContext(OrderTabContext);
  const [lastOrderId, setLastOrderId] = useState("");

  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(true);

  const [openInvoicePayslips, setOpenInvoicePayslips] = useState(false);

  useEffect(() => {
    if (open) {
      const getPendingOrders = async () => {
        try {
          const res = await searchOrders({ statusList: 'Pending' });
          setWebRequests(res.length);
        } catch (err) {
          console.log(err);
        }
      };
  
      getPendingOrders();
    }
  }, [open, setWebRequests]);

  useEffect(() => {
    if (userState.isLoggedIn) {
      const getLastOrderId = async () => {
        const latestOrder = await getLatestOrder();
        if (latestOrder) {
          const id = latestOrder.orderID;
          setLastOrderId(id);
        }
      };
      getLastOrderId();
    }
  }, [userState.isLoggedIn]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const logoutHandler = () => {
    removeJWT();
    removeUserId();
    userDispatch({ type: "logout" });
    props.history.push("/login");
  };

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h4" noWrap>
            <Link to="/" className="text-link">
              {globalData.companyName}
            </Link>
          </Typography>
          {!userState.isLoggedIn && (
            <div className="buttons-menu">
              <Button color="inherit" onClick={() => props.history.push("/login")}>
                Login
              </Button>
              <Button color="inherit" onClick={() => props.history.push("/register")}>
                Register
              </Button>
            </div>
          )}
          {userState.isLoggedIn && (
            <div className="buttons-menu">
              <Link className="text-link" to={`/profile/${userState.userInfo.id}`}>
                <Typography variant="subtitle1">{userState.userInfo.email}</Typography>
              </Link>
              <Button onClick={logoutHandler} color="inherit">
                Logout
              </Button>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
        open={open}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />

        <List>
          <Link to="/" className="text-link">
            <ListItem button>
              <ListItemIcon>
                <HomeIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link>

          <Divider />

          {/* ***** Insurance Company User Portal ***** */}
          {/* Change conditional once in production */}
          {userState.userInfo.accountType === 'ROLE_INSURANCE_USER' && (
            <>
              <Link to="/insurance-user/orders" className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary='Order List' />
                </ListItem>
              </Link>
              <Divider />
            </>
          )}

          {/* ***** Agent Portal ***** */}
          {/* Change conditional once in production */}
          {userState.userInfo.accountType === 'ROLE_AGENCY_USER' && (
            <>
            <Link to="/new-order/new" className="text-link">
            <ListItem button>
              <ListItemIcon>
                <AddBoxIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Place Order" />
            </ListItem>
          </Link>
            <Link to="/order-requests" className="text-link">
            <ListItem button>
              <ListItemIcon>
                <BarChartIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Pending/Processed Orders" />
            </ListItem>
          </Link> 
              <Link to="/order-list" className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <ListIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Order Search" />
                </ListItem>
              </Link>
              <Divider />
            </>
          )}

          {/* ***** Medifast Admin Portal ***** */}
          {/* Change conditional once in production */}
          {userState.userInfo.accountType === 'ROLE_SUPER_ADMIN' && (
            <>
              <Link to="/orders" className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <Badge badgeContent={webRequests} color='secondary'>
                      <ListIcon fontSize="large" />
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Order List" />
                </ListItem>
              </Link>
              <Link to={lastOrderId ? `/orders/${lastOrderId}` : '/orders/new'} className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <DescriptionIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Order Details" />
                </ListItem>
              </Link>
              <ListItem button onClick={() => setOpenInvoicePayslips(!openInvoicePayslips)}>
                <ListItemIcon>
                  <ReceiptIcon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Invoice & Payslip" />
                {openInvoicePayslips ? <ExpandLess /> : <ExpandMore />}
              </ListItem>
              <Collapse in={openInvoicePayslips} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to="/invoice-payslip/createInvoice" className="text-link">
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Create Invoice" />
                    </ListItem>
                  </Link>
                </List>
                <List component="div" disablePadding>
                  <Link to="/invoice-payslip/searchInvoice" className="text-link">
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Search Invoice" />
                    </ListItem>
                  </Link>
                </List>
                <List component="div" disablePadding>
                  <Link to="/invoice-payslip/createPayslip" className="text-link">
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Create Payslip" />
                    </ListItem>
                  </Link>
                </List>
                <List component="div" disablePadding>
                  <Link to="/invoice-payslip/searchPayslip" className="text-link">
                    <ListItem button className={classes.nested}>
                      <ListItemText primary="Search Payslip" />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
              <Divider />
              <Link to="/companies" className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <ApartmentIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Companies" />
                </ListItem>
              </Link>
              <Link to="/users" className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <PeopleAltIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Users" />
                </ListItem>
              </Link>
              <Link to="/service-expense" className="text-link">
                <ListItem button>
                  <ListItemIcon>
                    <AccountBalanceIcon fontSize="large" />
                  </ListItemIcon>
                  <ListItemText primary="Service & Expense" />
                </ListItem>
              </Link>
              <Link to="/fee-schedule" className="text-link">
              <ListItem button divider>
                <ListItemIcon>
                  <AttachMoneyIcon fontSize="large" />
                </ListItemIcon>
                <ListItemText primary="Fee Schedule" />
              </ListItem>
            </Link>
            </>
          )}

          {/* ***** help section + profile ***** */}
          <Divider />
          <Link to={`/profile/${userState.userInfo.id}`} className="text-link">
            <ListItem button>
              <ListItemIcon>
                <PersonIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItem>
          </Link>
          <Link to="/help" className="text-link">
            <ListItem button>
              <ListItemIcon>
                <HelpIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Help" />
            </ListItem>
          </Link>
          <Link to="/resources" className="text-link">
            <ListItem button>
              <ListItemIcon>
                <MenuBookIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Resources" />
            </ListItem>
          </Link>
          <Link to="/contact" className="text-link">
            <ListItem button>
              <ListItemIcon>
                <EmailIcon fontSize="large" />
              </ListItemIcon>
              <ListItemText primary="Contact" />
            </ListItem>
          </Link>
          <Divider />
          {/* ***** help section + profile ***** */}
        </List>
      </Drawer>
      <main className={clsx(classes.content, {
          [classes.contentShift]: open
        })}
      >
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </>
  );
};

export default withRouter(DrawerMenu);

