import React from 'react';
import SendEmailButton from '../SendEmailButton';
import DownloadButton from '../DownloadButton';
import pathExists from 'object-array-path-exists';
import { downloadAttachment_base } from '../API_calls/orderAttachments';
import moment from 'moment';
import Typography from '@material-ui/core/Typography';
import { roundTo } from '../utils/helpers';
import TextField from '@material-ui/core/TextField';

const normalizePhone2 = (phone) => {
  //normalize string and remove all unnecessary characters
  phone = phone.replace(/[^\d]/g, '');

  //check if number length equals to 10
  //reformat and return phone number
  return phone.replace(/(\d{3})(\d{0,3})(\d{0,4})/, '$1-$2-$3');
};

const inputProps = {
  style: { textTransform: 'lowercase' },
};

const statusTypes = ['Pending', 'Assignment', 'Cancelled', 'Hold', 'Complete'];

const dateRangeTypes = [
  { name: 'Order Date', toDate: 'orderTimeToDate', fromDate: 'orderTimeFromDate' },
  { name: 'Appointment Date', toDate: 'serviceTimeToDate', fromDate: 'serviceTimeFromDate' },
  { name: 'Completion Date', toDate: 'completionToDate', fromDate: 'completionFromDate' },
];

const typeOfAccounts = {
  admin: 'ROLE_ADMIN',
  superAdmin: 'ROLE_SUPER_ADMIN',
  agencyAdmin: 'ROLE_AGENCY_ADMIN',
  agent: 'ROLE_AGENCY_USER',
  contractor: 'ROLE_CONTRACTOR',
  insuranceCompanyUser: 'ROLE_INSURANCE_USER',
};

const agencyList = [
  { id: 1, name: 'agency 1' },
  { id: 2, name: 'agency 2' },
  { id: 3, name: 'agency 3' },
];

const genderOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
  { value: 'Unknown', label: 'Unknown' },
];

const refNoTypeOptions = [
  { value: 'a', label: 'App. No.' },
  { value: 'p', label: 'Policy No.' },
];

// const shortCodes = {
//   P01: "P01",
//   P04: "P04"
// };

// const shortCodeMobileServiceMapping = {
//   P01: "Paramedical test",
//   P04: "Paramedical test v2"
// };

const webRequestServiceOptions = [
  { value: '2D Echocardiogram', label: '2D Echocardiogram' },
  { value: 'Chest X-Ray', label: 'Chest X-Ray' },
  { value: "Doctor's Medical", label: "Doctor's Medical" },
  { value: 'ECG (Resting)', label: 'ECG (Resting)' },
  { value: 'Full Blood Profile', label: 'Full Blood Profile' },
  { value: 'HIV Urine', label: 'HIV Urine' },
  { value: 'Other (Specify)', label: 'Other (Specify)' },
  { value: 'Paramedical', label: 'Paramedical' },
  { value: 'Saliva', label: 'Saliva' },
  { value: 'Stress ECG', label: 'Stress ECG' },
  { value: 'Urine Specimen', label: 'Urine Specimen' },
  { value: 'Vital Statistic', label: 'Vital Statistic' },
];

const specialTestOptions = [
  { value: 'CBC', label: 'CBC' },
  { value: 'HBA1C', label: 'HBA1C' },
  { value: 'HEP SC B', label: 'HEP SC B' },
  { value: 'HEP SC C', label: 'HEP SC C' },
  { value: 'MICRO ALBUMIN', label: 'MICRO ALBUMIN' },
  { value: 'OTHERS', label: 'OTHERS' },
  { value: 'PSA', label: 'PSA' },
];

const languageList = [
  { id: 'Arabic', label: 'Arabic' },
  { id: 'Armenian', label: 'Armenian' },
  { id: 'ASL', label: 'ASL' },
  { id: 'Assamese', label: 'Assamese' },
  { id: 'Assyrian', label: 'Assyrian' },
  { id: 'Azerbaijani', label: 'Azerbaijani' },
  { id: 'Bahdini', label: 'Bahdini' },
  { id: 'Bambara', label: 'Bambara' },
  { id: 'Bashkir', label: 'Bashkir' },
  { id: 'Basque', label: 'Basque' },
  { id: 'Belarusian', label: 'Belarusian' },
  { id: 'Bengali', label: 'Bengali' },
  { id: 'Bosnian', label: 'Bosnian' },
  { id: 'Bravanese', label: 'Bravanese' },
  { id: 'Bulgarian', label: 'Bulgarian' },
  { id: 'Burmese', label: 'Burmese' },
  { id: 'Cambodian', label: 'Cambodian' },
  { id: 'Cantonese', label: 'Cantonese' },
  { id: 'Catalan', label: 'Catalan' },
  { id: 'Cebuano', label: 'Cebuano' },
  { id: 'Chaldean', label: 'Chaldean' },
  { id: 'Chamorro', label: 'Chamorro' },
  { id: 'Chaozhou', label: 'Chaozhou' },
  { id: 'Chavacano', label: 'Chavacano' },
  { id: 'Chin', label: 'Chin' },
  { id: 'Chuukese', label: 'Chuukese' },
  { id: 'Cree', label: 'Cree' },
  { id: 'Croatian', label: 'Croatian' },
  { id: 'Czech', label: 'Czech' },
  { id: 'Dakota', label: 'Dakota' },
  { id: 'Danish', label: 'Danish' },
  { id: 'Dari', label: 'Dari' },
  { id: 'Dinka', label: 'Dinka' },
  { id: 'Dioula', label: 'Dioula' },
  { id: 'Dutch', label: 'Dutch' },
  { id: 'Dzongkha', label: 'Dzongkha' },
  { id: 'English', label: 'English' },
  { id: 'Estonian', label: 'Estonian' },
  { id: 'Ewe', label: 'Ewe' },
  { id: 'Fante', label: 'Fante' },
  { id: 'Faroese', label: 'Faroese' },
  { id: 'Farsi', label: 'Farsi' },
  { id: 'Fijian Hindi', label: 'Fijian Hindi' },
  { id: 'Finnish', label: 'Finnish' },
  { id: 'Flemish', label: 'Flemish' },
  { id: 'French', label: 'French' },
  { id: 'French Canadian', label: 'French Canadian' },
  { id: 'Frisian', label: 'Frisian' },
  { id: 'Fujianese', label: 'Fujianese' },
  { id: 'Fukienese', label: 'Fukienese' },
  { id: 'Fula', label: 'Fula' },
  { id: 'Fulani', label: 'Fulani' },
  { id: 'Fuzhou', label: 'Fuzhou' },
  { id: 'Ga', label: 'Ga' },
  { id: 'Gaelic', label: 'Gaelic' },
  { id: 'Galician', label: 'Galician' },
  { id: 'Ganda', label: 'Ganda' },
  { id: 'Georgian', label: 'Georgian' },
  { id: 'German', label: 'German' },
  { id: 'Gorani', label: 'Gorani' },
  { id: 'Greek', label: 'Greek' },
  { id: 'Gujarati', label: 'Gujarati' },
  { id: 'Haitian Creole', label: 'Haitian Creole' },
  { id: 'Hakka', label: 'Hakka' },
  { id: 'Hassaniyya', label: 'Hassaniyya' },
  { id: 'Hausa', label: 'Hausa' },
  { id: 'Hebrew', label: 'Hebrew' },
  { id: 'Hiligaynon', label: 'Hiligaynon' },
  { id: 'Hindi', label: 'Hindi' },
  { id: 'Hmong', label: 'Hmong' },
  { id: 'Hungarian', label: 'Hungarian' },
  { id: 'Ibanag', label: 'Ibanag' },
  { id: 'Icelandic', label: 'Icelandic' },
  { id: 'Igbo', label: 'Igbo' },
  { id: 'Ilocano', label: 'Ilocano' },
  { id: 'Ilonggo', label: 'Ilonggo' },
  { id: 'Indian', label: 'Indian' },
  { id: 'Indonesian', label: 'Indonesian' },
  { id: 'Inuktitut', label: 'Inuktitut' },
  { id: 'Irish', label: 'Irish' },
  { id: 'Italian', label: 'Italian' },
  { id: 'Jakartanese', label: 'Jakartanese' },
  { id: 'Japanese', label: 'Japanese' },
  { id: 'Javanese', label: 'Javanese' },
  { id: 'Kanjobal', label: 'Kanjobal' },
  { id: 'Kannada', label: 'Kannada' },
  { id: 'Karen', label: 'Karen' },
  { id: 'Kashmiri', label: 'Kashmiri' },
  { id: 'Kazakh', label: 'Kazakh' },
  { id: 'Khalkha', label: 'Khalkha' },
  { id: 'Khmer', label: 'Khmer' },
  { id: 'Kikuyu', label: 'Kikuyu' },
  { id: 'Kinyarwanda', label: 'Kinyarwanda' },
  { id: 'Kirundi', label: 'Kirundi' },
  { id: 'Korean', label: 'Korean' },
  { id: 'Kosovan', label: 'Kosovan' },
  { id: 'Kotokoli', label: 'Kotokoli' },
  { id: 'Krio', label: 'Krio' },
  { id: 'Kurdish', label: 'Kurdish' },
  { id: 'Kurmanji', label: 'Kurmanji' },
  { id: 'Kyrgyz', label: 'Kyrgyz' },
  { id: 'Lakota', label: 'Lakota' },
  { id: 'Laotian', label: 'Laotian' },
  { id: 'Latin', label: 'Latin' },
  { id: 'Latvian', label: 'Latvian' },
  { id: 'Lingala', label: 'Lingala' },
  { id: 'Lithuanian', label: 'Lithuanian' },
  { id: 'Luganda', label: 'Luganda' },
  { id: 'Luo', label: 'Luo' },
  { id: 'Lusoga', label: 'Lusoga' },
  { id: 'Luxembourgeois', label: 'Luxembourgeois' },
  { id: 'Maay', label: 'Maay' },
  { id: 'Macedonian', label: 'Macedonian' },
  { id: 'Malagasy', label: 'Malagasy' },
  { id: 'Malay', label: 'Malay' },
  { id: 'Malayalam', label: 'Malayalam' },
  { id: 'Maldivian', label: 'Maldivian' },
  { id: 'Maltese', label: 'Maltese' },
  { id: 'Mandarin', label: 'Mandarin' },
  { id: 'Mandingo', label: 'Mandingo' },
  { id: 'Mandinka', label: 'Mandinka' },
  { id: 'Maori', label: 'Maori' },
  { id: 'Marathi', label: 'Marathi' },
  { id: 'Marshallese', label: 'Marshallese' },
  { id: 'Mien', label: 'Mien' },
  { id: 'Mirpuri', label: 'Mirpuri' },
  { id: 'Mixteco', label: 'Mixteco' },
  { id: 'Moldovan', label: 'Moldovan' },
  { id: 'Mongolian', label: 'Mongolian' },
  { id: 'Navajo', label: 'Navajo' },
  { id: 'Neapolitan', label: 'Neapolitan' },
  { id: 'Nepali', label: 'Nepali' },
  { id: 'Norwegian', label: 'Norwegian' },
  { id: 'Nuer', label: 'Nuer' },
  { id: 'Nyanja', label: 'Nyanja' },
  { id: 'Ojibaway', label: 'Ojibaway' },
  { id: 'Oriya', label: 'Oriya' },
  { id: 'Oromo', label: 'Oromo' },
  { id: 'Ossetian', label: 'Ossetian' },
  { id: 'Pahari', label: 'Pahari' },
  { id: 'Pampangan', label: 'Pampangan' },
  { id: 'Pashto', label: 'Pashto' },
  { id: 'Patois', label: 'Patois' },
  { id: 'Pidgin English', label: 'Pidgin English' },
  { id: 'Polish', label: 'Polish' },
  { id: 'Portuguese', label: 'Portuguese' },
  { id: 'Pothwari', label: 'Pothwari' },
  { id: 'Pulaar', label: 'Pulaar' },
  { id: 'Punjabi', label: 'Punjabi' },
  { id: 'Putian', label: 'Putian' },
  { id: 'Quanxi', label: 'Quanxi' },
  { id: 'Quechua', label: 'Quechua' },
  { id: 'Romani', label: 'Romani' },
  { id: 'Romanian', label: 'Romanian' },
  { id: 'Romansch', label: 'Romansch' },
  { id: 'Rundi', label: 'Rundi' },
  { id: 'Russian', label: 'Russian' },
  { id: 'Samoan', label: 'Samoan' },
  { id: 'Sango', label: 'Sango' },
  { id: 'Sanskrit', label: 'Sanskrit' },
  { id: 'Serbian', label: 'Serbian' },
  { id: 'Shanghainese', label: 'Shanghainese' },
  { id: 'Shona', label: 'Shona' },
  { id: 'Sichuan', label: 'Sichuan' },
  { id: 'Sicilian', label: 'Sicilian' },
  { id: 'Sindhi', label: 'Sindhi' },
  { id: 'Sinhala', label: 'Sinhala' },
  { id: 'Sinhalese', label: 'Sinhalese' },
  { id: 'Siswati/Swazi', label: 'Siswati/Swazi' },
  { id: 'Slovak', label: 'Slovak' },
  { id: 'Slovene', label: 'Slovene' },
  { id: 'Slovenian', label: 'Slovenian' },
  { id: 'Somali', label: 'Somali' },
  { id: 'Soninke', label: 'Soninke' },
  { id: 'Sorani', label: 'Sorani' },
  { id: 'Sotho', label: 'Sotho' },
  { id: 'Spanish', label: 'Spanish' },
  { id: 'Sundanese', label: 'Sundanese' },
  { id: 'Susu', label: 'Susu' },
  { id: 'Swahili', label: 'Swahili' },
  { id: 'Swedish', label: 'Swedish' },
  { id: 'Sylhetti', label: 'Sylhetti' },
  { id: 'Tagalog', label: 'Tagalog' },
  { id: 'Taiwanese', label: 'Taiwanese' },
  { id: 'Tajik', label: 'Tajik' },
  { id: 'Tamil', label: 'Tamil' },
  { id: 'Telugu', label: 'Telugu' },
  { id: 'Thai', label: 'Thai' },
  { id: 'Tibetan', label: 'Tibetan' },
  { id: 'Tigrinya', label: 'Tigrinya' },
  { id: 'Tongan', label: 'Tongan' },
  { id: 'Tshiluba', label: 'Tshiluba' },
  { id: 'Tsonga', label: 'Tsonga' },
  { id: 'Tswana', label: 'Tswana' },
  { id: 'Turkish', label: 'Turkish' },
  { id: 'Turkmen', label: 'Turkmen' },
  { id: 'Uighur', label: 'Uighur' },
  { id: 'Ukrainian', label: 'Ukrainian' },
  { id: 'Urdu', label: 'Urdu' },
  { id: 'Uzbek', label: 'Uzbek' },
  { id: 'Venda', label: 'Venda' },
  { id: 'Vietnamese', label: 'Vietnamese' },
  { id: 'Visayan', label: 'Visayan' },
  { id: 'Welsh', label: 'Welsh' },
  { id: 'Wolof', label: 'Wolof' },
  { id: 'Xhosa', label: 'Xhosa' },
  { id: 'Yao', label: 'Yao' },
  { id: 'Yiddish', label: 'Yiddish' },
  { id: 'Yoruba', label: 'Yoruba' },
  { id: 'Yupik', label: 'Yupik' },
  { id: 'Zulu', label: 'Zulu' },
];

const contractorFeeColumns = [
  {
    title: 'Short Code',
    field: 'mobileServiceType.shortCode',
    editable: 'never',
  },
  {
    title: 'Mobile Service',
    field: 'mobileServiceLink',
    render: (rowData) => rowData.mobileServiceType.name,
  },
  {
    title: 'Charge',
    field: 'charge',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
];

const contractorRateColumns = [
  {
    title: 'Short Code',
    field: 'shortCode',
    editable: 'never',
  },
  {
    title: 'First Name',
    field: 'firstName',
    editable: 'never',
  },
  {
    title: 'Last Name',
    field: 'lastName',
    editable: 'never',
  },
  {
    title: 'HST Rate (%)',
    field: 'hstRate',
    type: 'numeric',
  },
  {
    title: 'Mileage Rate',
    field: 'mileageRate',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
  {
    title: 'Referral Fee',
    field: 'referralFee',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
];

const companyRateColumns = [
  { title: 'Short Code', field: 'shortCode', editable: 'never' },
  { title: 'Insurance Company', field: 'name', editable: 'never' },
  {
    title: 'HST Rate (%)',
    field: 'hstRate',
    type: 'numeric',
  },
  {
    title: 'Mileage Rate',
    field: 'mileageRate',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
  {
    title: 'Urine Kit',
    field: 'urineKit',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
  {
    title: 'Blood Kit',
    field: 'bloodKit',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
  // { title: "Saliva Kit", field: "salivaKit", type: "numeric" }
];

const companyFeeColumns = [
  { title: 'Short Code', field: 'mobileServiceType.shortCode', editable: 'never' },
  { title: 'Mobile Service', field: 'mobileServiceLink', render: (rowData) => rowData.mobileServiceType.name },
  {
    title: 'Price',
    field: 'price',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
  {
    title: 'Scan/Fax Charge',
    field: 'scanFaxPrice',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
];

const companyInfoColumns = [
  { title: 'Short Code', field: 'shortCode' },
  { title: 'Insurance Company', field: 'name' },
  { title: 'Default Lab.', field: 'defaultLab', cellStyle: { whiteSpace: 'nowrap' } },
  { title: 'Mileage Rate', field: 'mileageRate', type: 'numeric' },
  { title: 'HST Rate (%)', field: 'hstRate', type: 'numeric' },
  {
    title: 'IsLabCode',
    field: 'isLabCode',
    type: 'boolean',
  },
  { title: 'Require App. No.', field: 'requireAppNo', type: 'boolean' },
  { title: 'Require Ins. Amt', field: 'requireInsAmt', type: 'boolean' },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const agencyInfoColumns = [
  { title: 'Short Code', field: 'shortCode' },
  { title: 'Name', field: 'name' },
  { title: 'Billing Attention', field: 'billingAttention' },
  {
    title: 'Tel',
    field: 'tele',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.tele) {
        rowData.tele = normalizePhone2(rowData.tele);

        const firstSet = rowData.tele.slice(0, 3);
        const secondSet = rowData.tele.slice(4, 7);
        const thirdSet = rowData.tele.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Fax',
    field: 'fax',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.fax) {
        rowData.fax = normalizePhone2(rowData.fax);

        const firstSet = rowData.fax.slice(0, 3);
        const secondSet = rowData.fax.slice(4, 7);
        const thirdSet = rowData.fax.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  { title: 'Email', field: 'email', cellStyle: { textTransform: 'lowercase' } },
  {
    title: 'Enabled',
    field: 'enabled',
    type: 'boolean',
    initialEditValue: true,
  },
  {
    title: 'New Order Email',
    field: 'newOrderEmail',
    type: 'boolean',
  },
  { title: 'Appointment Email', field: 'appointmentEmail', type: 'boolean' },
  { title: 'Update Status Email', field: 'updateStatusEmail', type: 'boolean' },
  { title: 'Completion Email', field: 'completionEmail', type: 'boolean' },
];

const agentInfoColumns = [
  { title: 'Username', field: 'username', cellStyle: { textTransform: 'lowercase' } },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Email', field: 'email', cellStyle: { textTransform: 'lowercase' } },
  {
    title: 'Mobile',
    field: 'mobileTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.mobileTel) {
        rowData.mobileTel = normalizePhone2(rowData.mobileTel);
        const firstSet = rowData.mobileTel.slice(0, 3);
        const secondSet = rowData.mobileTel.slice(4, 7);
        const thirdSet = rowData.mobileTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Home',
    field: 'homeTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.homeTel) {
        rowData.homeTel = normalizePhone2(rowData.homeTel);

        const firstSet = rowData.homeTel.slice(0, 3);
        const secondSet = rowData.homeTel.slice(4, 7);
        const thirdSet = rowData.homeTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Business',
    field: 'businessTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.businessTel) {
        rowData.businessTel = normalizePhone2(rowData.businessTel);

        const firstSet = rowData.businessTel.slice(0, 3);
        const secondSet = rowData.businessTel.slice(4, 7);
        const thirdSet = rowData.businessTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Other',
    field: 'otherTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.otherTel) {
        rowData.otherTel = normalizePhone2(rowData.otherTel);

        const firstSet = rowData.otherTel.slice(0, 3);
        const secondSet = rowData.otherTel.slice(4, 7);
        const thirdSet = rowData.otherTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
  { title: 'New Order Email', field: 'newOrderEmail', type: 'boolean' },
  { title: 'Gender', field: 'gender' },
];

const searchAgentOrderInfoColumns = [
  { title: 'Last Name', field: 'lastName' },
  { title: 'First Name', field: 'firstName' },
  { title: 'Email', field: 'email', cellStyle: { textTransform: 'lowercase' } },
  { title: 'Mobile', field: 'mobileTel' },
  { title: 'Home', field: 'homeTel' },
  { title: 'Business', field: 'businessTel' },
  { title: 'Fax', field: 'fax' },
];

const agentRelationsInfoColumns = [
  { title: 'Agency Name', field: '_links.self.href' },
  { title: 'Tel', field: 'tele', editable: 'never' },
  { title: 'Fax', field: 'fax', editable: 'never' },
  { title: 'Email', field: 'email', editable: 'never', cellStyle: { textTransform: 'lowercase' } },
];

const contractorInfoColumns = [
  { title: 'Short Code', field: 'contractorShortCode' },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  {
    title: 'Contact Info.',
    field: 'mobileTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.mobileTel) {
        rowData.mobileTel = normalizePhone2(rowData.mobileTel);

        const firstSet = rowData.mobileTel.slice(0, 3);
        const secondSet = rowData.mobileTel.slice(4, 7);
        const thirdSet = rowData.mobileTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  { title: 'Mileage Rate', field: 'contractorMileageRate', type: 'numeric' },
  { title: 'Referral Fee (%)', field: 'contractorReferralFee', type: 'numeric' },
  { title: 'HST Rate (%)', field: 'contractorHSTRate', type: 'numeric' },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const clientInfoColumns = [
  {
    title: 'First Name',
    field: 'firstName',
  },
  {
    title: 'Last Name',
    field: 'lastName',
  },
  { title: 'Gender', field: 'gender' },
  {
    title: 'DOB',
    render: (rowData) => {
      if (rowData.dateOfBirth) {
        return moment(rowData.dateOfBirth).format('MM-DD-YYYY');
      }
      return '';
    },
    customFilterAndSearch: (term, rowData) => {
      if (rowData.dateOfBirth && rowData.dateOfBirth.includes(term)) {
        return true;
      }
      return false;
    },
  },
  {
    title: 'Age',
    render: (rowData) => {
      if (rowData.dateOfBirth) {
        return moment().diff(rowData.dateOfBirth, 'years', false);
      }
      return '';
    },
    customFilterAndSearch: (term, rowData) => {
      if (rowData.dateOfBirth) {
        if (moment().diff(rowData.dateOfBirth, 'years', false).toString().includes(term)) {
          return true;
        }
        return false;
      }
      return false;
    },
  },
  { title: 'Home', field: 'homeTel' },
  { title: 'Mobile', field: 'mobileTel' },
  { title: 'Other Contact', field: 'otherTel' },
  { title: 'Username', field: 'username' },
];

const mobileServiceInfoColumns = [
  { title: 'Short Code', field: 'shortCode' },
  { title: 'Mobile Service', field: 'name' },
  { title: 'Kit Code', field: 'kitCode' },
  { title: 'Para Service', field: 'paraService', type: 'boolean' },
  { title: 'PARA', field: 'para', type: 'boolean' },
  { title: 'ECG', field: 'ecg', type: 'boolean' },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const webRequestServiceInfoColumns = [
  { title: 'Short Code', field: 'shortCode' },
  { title: 'Web Request Service', field: 'name' },
  { title: 'Web Request Service Type', field: 'serviceType' },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const documentInfoColumns = [
  {
    title: 'Title',
    field: 'fileName',
    editable: 'onUpdate',
  },
  {
    title: 'File Size',
    render: (rowData) => {
      if (rowData.fileSize) {
        return `${Math.round(rowData.fileSize / 1024)}KB`;
      }
      return '';
    },
    customFilterAndSearch: (term, rowData) => {
      if (rowData.fileSize) {
        return Math.round(rowData.fileSize / 1024)
          .toString()
          .includes(term)
          ? true
          : false;
      }
      return false;
    },
    editable: 'never',
  },
  { title: 'File Type', field: 'fileType', editable: 'never' },
  { title: 'Uploaded By', field: 'uploadedBy.username', editable: 'never' },
  {
    title: 'Uploaded Time',
    render: (rowData) => {
      if (rowData.createDateTime) {
        return moment(rowData.createDateTime).format('MM-DD-YYYY hh:mm A');
      }
      return '';
    },
    customFilterAndSearch: (term, rowData) => {
      if (rowData.createDateTime) {
        return moment(rowData.createDateTime).format().includes(term) ? true : false;
      }
      return false;
    },
    editable: 'never',
  },
  {
    title: 'Send Email',
    render: (rowData) => {
      return (
        <SendEmailButton color="default" onClick={() => alert('send email button pressed')}>
          Send Email
        </SendEmailButton>
      );
    },
  },
  {
    title: 'Download',
    render: (rowData) => {
      return (
        <DownloadButton
          color="default"
          onClick={async () => {
            if (pathExists(rowData, '_links.self.href')) {
              const res = await downloadAttachment_base(`${rowData._links.self.href.split('{')[0]}/download`);
              let link = document.createElement('a');
              link.download = rowData.fileName;
              const url = window.URL.createObjectURL(new Blob([res]));
              link.href = url;
              link.click();
            } else {
              alert('No file to download');
            }
          }}
        >
          Download
        </DownloadButton>
      );
    },
  },
];

const webAccessLogsInfoColumns = [
  {
    title: 'Date/Time',
    field: 'dateTime',
    editable: 'never',
    render: (rowData) => (
      <Typography variant="body2">{moment(rowData.dateTime).format('MM-DD-YYYY hh:mm A')}</Typography>
    ),
  },
  { title: 'Updated By', field: 'by', editable: 'never' },
  { title: 'Action Details', field: 'actionDetails', editable: 'never' },
  { title: 'Remarks', field: 'remarks', editable: 'always' },
];

const updateLogsInfoColumns = [
  {
    title: 'Date/Time',
    field: 'dateTime',
    editable: 'never',
    render: (rowData) => (
      <Typography variant="body2">{moment(rowData.dateTime).format('MM-DD-YYYY hh:mm A')}</Typography>
    ),
  },
  { title: 'Updated By', field: 'by', editable: 'never' },
  { title: 'Action Details', field: 'actionDetails', editable: 'never' },
  { title: 'Remarks', field: 'remarks', editable: 'always' },
];

const expensesInfoColumns = [
  {
    title: 'Expense',
    field: 'mobileExpenseType',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.mobileExpenseTypeShortCode} - {rowData.mobileExpenseTypeName}
      </Typography>
    ),
  },
  { title: 'Description', field: 'description' },
  {
    title: 'Contractor',
    field: 'user',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.contractorLastName}, {rowData.contractorFirstName}
      </Typography>
    ),
  },
  {
    title: 'Amount',
    field: 'amount',
    type: 'currency',
    editComponent: (props) => (
      <input type="number" value={props.value} onChange={(e) => props.onChange(e.target.value)} />
    ),
  },
];

const mobileExpenseInfoColumns = [
  { title: 'Short Code', field: 'shortCode' },
  { title: 'Mobile Expense', field: 'name' },
  { title: 'Ref. Charge', field: 'refCharge', type: 'numeric' },
  { title: 'Remarks', field: 'remarks' },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const processedReportInfoColumns = [
  { title: 'Order ID', field: 'medifastID' },
  {
    title: 'Request Date',
    field: 'createDateTime',
    render: (rowData) => moment(rowData.createDateTime).format('MM-DD-YYYY'),
  },
  {
    title: 'Client Name',
    field: 'clientInfo.lastName',
    render: (rowData) => `${rowData.clientInfo.lastName}, ${rowData.clientInfo.firstName}`,
  },
  { title: 'Insurance Company', field: 'insuranceCompanyName' },
  { title: 'Policy No.', field: 'refNum.refNumNumber' },
];

const pendingReportInfoColumns = [
  { title: 'Order ID', field: 'medifastID' },
  {
    title: 'Request Date',
    field: 'createDateTime',
    render: (rowData) => moment(rowData.createDateTime).format('MM-DD-YYYY'),
  },
  {
    title: 'Client Name',
    field: 'clientInfo.lastName',
    render: (rowData) => `${rowData.clientInfo.lastName}, ${rowData.clientInfo.firstName}`,
  },
  { title: 'Insurance Company', field: 'insuranceCompanyName' },
  { title: 'Policy No.', field: 'refNum.refNumNumber' },
];

const dynamicReportInfoColumns = [
  { title: 'Order ID', field: 'medifastID' },
  {
    title: 'Order Date',
    field: 'orderDate',
    render: (rowData) => (rowData.orderDate ? moment(rowData.orderDate).format('MM-DD-YYYY') : null),
  },
  {
    title: 'Appointment Date',
    field: 'serviceTime',
    render: (rowData) => (rowData.serviceTime ? moment(rowData.serviceTime).format('MM-DD-YYYY') : null),
  },
  {
    title: 'Completion Date',
    field: 'completionTime',
    render: (rowData) => (rowData.completionTime ? moment(rowData.completionTime).format('MM-DD-YYYY') : null),
  },
  {
    title: 'Client',
    field: 'clientInfo.lastName',
    render: (rowData) => `${rowData.clientInfo.lastName}, ${rowData.clientInfo.firstName}`,
  },
  { title: 'Policy No.', field: 'refNum.refNumNumber' },
  { title: 'Status', field: 'status' },
];

const emailLogsInfoColumns = [
  { title: 'Sent At', field: 'sentAt' },
  { title: 'Subject', field: 'subject' },
  { title: 'Type', field: 'type' },
  { title: 'From', field: 'emailFrom', cellStyle: { textTransform: 'lowercase' } },
  { title: 'To', field: 'emailTo', cellStyle: { textTransform: 'lowercase' } },
];

const sysAdminInfoColumns = [
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  {
    title: 'Username',
    field: 'username',
    cellStyle: { textTransform: 'lowercase' },
    editComponent: (props) => (
      <TextField
        data-cy="usernameSysAdminField"
        type="text"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        inputProps={inputProps}
      />
    ),
  },
  {
    title: 'Email',
    field: 'email',
    cellStyle: { textTransform: 'lowercase' },
    editComponent: (props) => (
      <TextField
        data-cy="emailSysAdminField"
        type="email"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        inputProps={inputProps}
      />
    ),
  },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const insuranceAgencyUserInfoColumns = [
  { title: 'Login Name', field: 'username', cellStyle: { textTransform: 'lowercase' } },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  {
    title: 'Agency',
    render: (rowData) => <Typography variant="body2">{rowData.managedAgencyName}</Typography>,
    customFilterAndSearch: (term, rowData) => {
      let searchedValue = '';
      if (pathExists(rowData, 'agency.name')) {
        searchedValue = rowData.agency.name.toUpperCase();
      }
      return searchedValue.includes(term.toUpperCase());
    },
  },
  { title: 'Email', field: 'email', cellStyle: { textTransform: 'lowercase' } },
  {
    title: 'Mobile',
    field: 'mobileTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.mobileTel) {
        rowData.mobileTel = normalizePhone2(rowData.mobileTel);

        const firstSet = rowData.mobileTel.slice(0, 3);
        const secondSet = rowData.mobileTel.slice(4, 7);
        const thirdSet = rowData.mobileTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Home',
    field: 'homeTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.homeTel) {
        const firstSet = rowData.homeTel.slice(0, 3);
        const secondSet = rowData.homeTel.slice(4, 7);
        const thirdSet = rowData.homeTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Business',
    field: 'businessTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.businessTel) {
        const firstSet = rowData.businessTel.slice(0, 3);
        const secondSet = rowData.businessTel.slice(4, 7);
        const thirdSet = rowData.businessTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Other',
    field: 'otherTel',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.otherTel) {
        const firstSet = rowData.otherTel.slice(0, 3);
        const secondSet = rowData.otherTel.slice(4, 7);
        const thirdSet = rowData.otherTel.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  {
    title: 'Fax',
    field: 'fax',
    cellStyle: { whiteSpace: 'nowrap' },
    render: (rowData) => {
      if (rowData.fax) {
        const firstSet = rowData.fax.slice(0, 3);
        const secondSet = rowData.fax.slice(4, 7);
        const thirdSet = rowData.fax.slice(8, 12);
        const areaCode = `( ${firstSet} )`;
        const final = `${areaCode} ${secondSet}-${thirdSet}`;
        return final;
      }
    },
  },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
];

const insuranceCompanyUserInfoColumns = [
  {
    title: 'Login Name',
    field: 'username',
    cellStyle: { textTransform: 'lowercase' },
    editComponent: (props) => (
      <TextField
        type="text"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        inputProps={inputProps}
      />
    ),
  },
  { title: 'First Name', field: 'firstName' },
  { title: 'Last Name', field: 'lastName' },
  { title: 'Email', field: 'email', cellStyle: { textTransform: 'lowercase' } },
  { title: 'Enabled', field: 'enabled', type: 'boolean', initialEditValue: true },
  { title: 'PDF ZIP Password', field: 'pdfZipPassword' },
  { title: 'Login Time', field: 'loginTime', type: 'datetime' },
];

const orderColumns = [
  { title: 'Order ID', field: 'medifastID' }, //Sequential ID for users only, refered to as Order ID
  {
    title: 'Created Date',
    field: 'createDateTime',
    render: (rowData) => <Typography variant="body2">{moment(rowData.createDateTime).format('MM-DD-YYYY')}</Typography>,
  },
  {
    title: 'Client Name',
    field: 'clientInfo.lastName',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.clientInfo.lastName}, {rowData.clientInfo.firstName}
      </Typography>
    ),
  },
  {
    title: 'Agent',
    field: 'agentLastName',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.agentLastName}, {rowData.agentFirstName}
      </Typography>
    ),
  },
  { title: 'Insurance Company', field: 'insuranceCompanyName' },
  {
    title: 'Policy No.',
    field: 'refNum.refNumNumber',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.refNum?.refNumType} - {rowData.refNum?.refNumNumber}
      </Typography>
    ),
  },
  { title: 'Status', field: 'status' },
  { title: 'Attachments', field: 'numAttachments' },
  { title: 'Created By', field: 'createdBy' },
];

const createInvoiceColumns = [
  { title: 'Company Name', field: 'name' },
  { title: 'Unbilled Order Amount', field: 'grandTotal', type: 'currency', cellStyle: { textAlign: 'left' } },
  {
    title: 'Last Invoice Date',
    field: 'lastInvoicedDate',
    render: (rowData) => (
      <Typography variant="body2">{moment(rowData.lastInvoicedDate).format('MM-DD-YYYY')}</Typography>
    ),
  },
];

const invoiceDetailColumns = [
  { title: 'Order ID', field: 'medifastID' },
  {
    title: 'Order Date',
    field: 'orderDate',
    render: (rowData) => (
      <Typography variant="body2">
        {moment(rowData.orderDate ? rowData.orderDate : rowData.createDateTime).format('MM-DD-YYYY')}
      </Typography>
    ),
  },
  {
    title: 'Completed Date',
    field: 'completionTime',
    render: (rowData) => <Typography variant="body2">{moment(rowData.completionTime).format('MM-DD-YYYY')}</Typography>,
  },
  {
    title: 'Client Name',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.clientInfo.lastName}, {rowData.clientInfo.firstName}
      </Typography>
    ),
  },
  {
    title: 'Service',
    render: (rowData) => {
      let shortCodes = '';
      if (rowData.serviceItems && rowData.serviceItems.length > 0) {
        for (let item of rowData.serviceItems) {
          shortCodes += `${item.serviceType.shortCode} `;
        }
      }
      return <Typography variant="body2">{shortCodes}</Typography>;
    },
  },
  {
    title: 'Amount',
    render: (rowData) => {
      let amount =
        rowData.serviceItems && rowData.serviceItems.length
          ? `$${roundTo(
              rowData.serviceItems.reduce((acc, curr) => acc + curr.serviceAmount, 0),
              2
            )}`
          : '$0.00';
      const errorMessage = 'Missing Fee Schedule';
      if (rowData.serviceItems[0].serviceAmount === null) {
        return <p style={{ color: 'red' }}>{errorMessage}</p>;
      }
      return amount;
    },
  },
  {
    title: 'Mileage',
    render: (rowData) => {
      let mileage =
        rowData.serviceItems && rowData.serviceItems.length
          ? `$${roundTo(
              rowData.serviceItems.reduce((acc, curr) => acc + curr.mileageAmount, 0),
              2
            )}`
          : '$0.00';
      const errorMessageTwo = 'Missing Fee Schedule';
      if (rowData.serviceItems[0].insuranceMileage && rowData.serviceItems[0].mileageAmount === null) {
        return <p style={{ color: 'red' }}>{errorMessageTwo}</p>;
      }
      return mileage;
    },
  },
  {
    title: 'Total',
    render: (rowData) => {
      let total =
        rowData.serviceItems && rowData.serviceItems.length
          ? `$${roundTo(
              rowData.serviceItems.reduce((acc, curr) => acc + curr.mileageAmount + curr.serviceAmount, 0),
              2
            )}`
          : '$0.00';
      const errorMessage = 'N/A';
      if (!rowData.serviceItems[0].serviceAmount) {
        return <p>{errorMessage}</p>;
      }
      return total;
    },
  },
];

const searchInvoiceColumns = [
  { title: 'Invoice No.', field: 'invoiceID' },
  { title: 'Company Name', field: 'insuranceCompany.name' },
  { title: 'Total Amount', field: 'grandTotal', type: 'currency', cellStyle: { textAlign: 'left' } },
  {
    title: 'Invoice Date',
    field: 'invoiceDate',
    render: (rowData) => <Typography variant="body2">{moment(rowData.invoiceDate).format('MM-DD-YYYY')}</Typography>,
  },
];

const createPayslipColumns = [
  {
    title: 'Contractor Name',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.lastName}, {rowData.firstName}
      </Typography>
    ),
  },
  { title: 'Unpaid Order Amount', field: 'grandTotal', type: 'currency', cellStyle: { textAlign: 'left' } },
  {
    title: 'Last Payslip Date',
    field: 'lastPayslipDate',
    render: (rowData) => (
      <Typography variant="body2">{moment(rowData.lastPayslipDate).format('MM-DD-YYYY')}</Typography>
    ),
  },
];

const payslipDetailColumns = [
  { title: 'Order ID', field: 'order.medifastID' },
  {
    title: 'Order Date',
    render: (rowData) => (
      <Typography variant="body2">
        {moment(rowData.order.orderDate ? rowData.order.orderDate : rowData.createDateTime).format('MM-DD-YYYY')}
      </Typography>
    ),
  },
  {
    title: 'Completed Date',
    render: (rowData) => (
      <Typography variant="body2">{moment(rowData.order.completionTime).format('MM-DD-YYYY')}</Typography>
    ),
  },
  {
    title: 'Client Name',
    render: (rowData) => (
      <Typography variant="body2">
        {rowData.order.clientInfo.lastName}, {rowData.order.clientInfo.firstName}
      </Typography>
    ),
  },
  { title: 'Service/Expense', field: 'serviceExpenseName' },
  {
    title: 'Charge',
    type: 'currency',
    cellStyle: { textAlign: 'left' },
    render: (rowData) => {
      let dollarSign = `$`;
      let errorMessage = 'Missing Fee Schedule';
      if (rowData.serviceExpenseName !== 'Mileage' && !rowData.serviceExpenseCharge) {
        return <p style={{ color: 'red' }}>{errorMessage}</p>;
      } else if (rowData.serviceExpenseName === 'Mileage' && !rowData.serviceExpenseCharge) {
        return dollarSign.concat(`0.00`);
      }
      return dollarSign.concat(rowData.serviceExpenseCharge);
    },
  },
];

const searchPayslipColumns = [
  { title: 'Payslip No.', field: 'payslipID' },
  {
    title: 'Contractor Name',
    render: (rowData) =>
      rowData.serviceItems.length > 0 && (
        <Typography variant="body2">
          {rowData.serviceItems[0].user.lastName}, {rowData.serviceItems[0].user.firstName}
        </Typography>
      ),
  },
  { title: 'Total Amount', field: 'grandTotal', type: 'currency', cellStyle: { textAlign: 'left' } },
  {
    title: 'Payslip Date',
    field: 'payslipDate',
    render: (rowData) => <Typography variant="body2">{moment(rowData.payslipDate).format('MM-DD-YYYY')}</Typography>,
  },
];

const insurancePortalColumns = [
  { title: 'Order ID', field: 'medifastID' },
  {
    title: 'Order Date',
    field: 'orderDate',
    render: (rowData) => (rowData.orderDate ? moment(rowData.orderDate).format('MM-DD-YYYY') : null),
  },
  {
    title: 'Appointment Date',
    field: 'serviceTime',
    render: (rowData) => (rowData.serviceTime ? moment(rowData.serviceTime).format('MM-DD-YYYY') : null),
  },
  {
    title: 'Completion Date',
    field: 'completionTime',
    render: (rowData) => (rowData.completionTime ? moment(rowData.completionTime).format('MM-DD-YYYY') : null),
  },
  {
    title: 'Client',
    field: 'clientInfo.lastName',
    render: (rowData) => `${rowData.clientInfo.lastName}, ${rowData.clientInfo.firstName}`,
  },
  {
    title: 'Client',
    field: 'agentLastName',
    render: (rowData) => `${rowData.agentLastName}, ${rowData.agentFirstName}`,
  },
  { title: 'Policy No.', field: 'refNum.refNumNumber' },
  { title: 'Lab No.', field: 'lab.labNumber' },
  {
    title: 'Has PDF?',
    render: (rowData) =>
      rowData.numAttachments > 0 ? (
        <Typography variant="body2" color="secondary">
          Yes
        </Typography>
      ) : (
        'No'
      ),
  },
  { title: 'Status', field: 'status' },
];

const insuranceFilesColumns = [
  {
    title: 'File Name',
    field: 'fileName',
    editable: 'never',
  },
  {
    title: 'File Size',
    render: (rowData) => {
      if (rowData.fileSize) {
        return `${Math.round(rowData.fileSize / 1024)}KB`;
      }
      return '';
    },
    customFilterAndSearch: (term, rowData) => {
      if (rowData.fileSize) {
        return Math.round(rowData.fileSize / 1024)
          .toString()
          .includes(term)
          ? true
          : false;
      }
      return false;
    },
    editable: 'never',
  },
  {
    title: 'Download Count',
    field: 'currentUserDownloadCount',
  },
  {
    title: 'Download',
    render: (rowData) => {
      return (
        <DownloadButton
          color="default"
          onClick={async () => {
            if (pathExists(rowData, '_links.self.href')) {
              const res = await downloadAttachment_base(`${rowData._links.self.href.split('{')[0]}/download`);
              let link = document.createElement('a');
              link.download = rowData.fileName;
              const url = window.URL.createObjectURL(new Blob([res]));
              link.href = url;
              link.click();
            } else {
              alert('No file to download');
            }
          }}
        >
          Download
        </DownloadButton>
      );
    },
  },
];

const securityLogsColumns = [
  {
    title: 'Date Time',
    field: 'dateTime',
    render: (rowData) => (
      <Typography variant="body2">{moment(rowData.dateTime).format('MM-DD-YYYY hh:mm A')}</Typography>
    ),
  },
  { title: 'Updated By', field: 'by' },
  { title: 'Action Details', field: 'actionDetails' },
  { title: 'Remarks', field: 'remarks' },
];

export {
  statusTypes,
  dateRangeTypes,
  agentRelationsInfoColumns,
  insuranceCompanyUserInfoColumns,
  insuranceAgencyUserInfoColumns,
  sysAdminInfoColumns,
  emailLogsInfoColumns,
  dynamicReportInfoColumns,
  pendingReportInfoColumns,
  processedReportInfoColumns,
  mobileExpenseInfoColumns,
  expensesInfoColumns,
  updateLogsInfoColumns,
  webAccessLogsInfoColumns,
  documentInfoColumns,
  mobileServiceInfoColumns,
  webRequestServiceInfoColumns,
  clientInfoColumns,
  contractorInfoColumns,
  agentInfoColumns,
  searchAgentOrderInfoColumns,
  agencyInfoColumns,
  companyInfoColumns,
  contractorRateColumns,
  companyFeeColumns,
  companyRateColumns,
  contractorFeeColumns,
  languageList,
  typeOfAccounts,
  agencyList,
  genderOptions,
  refNoTypeOptions,
  webRequestServiceOptions,
  specialTestOptions,
  orderColumns,
  createInvoiceColumns,
  createPayslipColumns,
  invoiceDetailColumns,
  searchInvoiceColumns,
  searchPayslipColumns,
  payslipDetailColumns,
  // invoice1Columns,
  insurancePortalColumns,
  insuranceFilesColumns,
  securityLogsColumns,
};
