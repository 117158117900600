import instance from "../utils/API";

const getSpecificRole = async (roleName) => {
  try {
    const res = await instance.get("/roles").then((res) => res.data._embedded.roles);
    const link = res.find((elem) => elem.name === roleName)._links.self.href;
    return link;
  } catch (error) {
    console.log(error);
  }
};

const getAllRoles = async () => {
  try {
    const res = await instance.get("/roles").then((res) => res.data._embedded.roles);
    return res;
  } catch (error) {
    throw error;
  }
};

export { getSpecificRole, getAllRoles };
