import React, { useState, useEffect, useContext } from "react";
import { withRouter } from 'react-router-dom';
import { AppDataContext, GlobalNameListContext } from "../Context";
import { UserContext } from "../UserContext";
import "./NewOrderPage.scss";
import ReportLabel from "../components/ReportLabel";
import InputField from "../components/InputField";
import RadioField from "../components/RadioField";
import DropdownField from "../components/DropdownField";
import Typography from "@material-ui/core/Typography";
// import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import FormHelperText from "@material-ui/core/FormHelperText";
import Checkbox from "@material-ui/core/Checkbox";
import SaveButton from "../components/Profile/SaveButton";
import LoadingSpinner from "../components/LoadingSpinner";

import { genderOptions } from "../components/data/constants";
// import { getAllInsuranceCompanies } from "../components/API_calls/insuranceCompanies";
// import { getAllAgencies } from "../components/API_calls/agencies";
import { getAllWebRequestServiceTypes } from "../components/API_calls/webRequestServiceTypes";
import { createOrder, getOrder_base, updateOrder_base } from "../components/API_calls/orders";

import Grid from "@material-ui/core/Grid/";
import { searchAgency } from "../components/API_calls/agencies";
import { generateKey } from "../components/utils/helpers";
import moment from "moment";
import Required from "../components/basic-components/Required";
import { createOrderAttachment } from "../components/API_calls/orderAttachments";
import NumberFormat from 'react-number-format';


const initialValues = {
  orderLocation: 'Toronto',
  insuranceCompany: "",
  agency: "",
  tempAgency: '',
  serviceTime: "",
  clientInfo: {
    firstName: "",
    lastName: "",
    gender: "",
    age: "",
    dob: "",
    mobile: "",
    home: "",
    business: "",
    fax: "",
    languages: '',
  },
  examLocation: {
    clientLocationAddress: '',
    clientLocationPostalCode: '',
    clientLocationMedifastOffice: false,
  },
  refNum: {
    refNumNumber: '',
    refNumType: ''
  },
  insuranceItems: {
    insuranceAmount: '',
    insuranceType: ''
  },
  orderAttachments: [],
  remarks: ""
};

const NewOrderPage = ({ match, history, location, ...props }) => {

  // ROUTER VARIABLES
  const { params: {orderType} } = match;

  const { appDataState } = useContext(AppDataContext);
  const { userState } = useContext(UserContext);
  const { globalNameList } = useContext(GlobalNameListContext)   
  const [values, setValues] = useState(initialValues);
  const [webRequestServices, setWebRequestServices] = useState([]);
  const [agenciesList, setAgenciesList] = useState([{ id: 'other', name: 'Other' }]);
  const [isLoading, setIsLoading] = useState(false);
  const [webService, setWebService] = useState({});
  const [specialService, setSpecialService] = useState({});

  const [languageList, setLanguageList] = useState([]);
  const [insuranceTypeList, setInsuranceTypeList] = useState([]);
  const [refNoList, setRefNoList] = useState([]);
  const [isWebServerValid, setIsWebServiceValid] = useState(false);

  useEffect(() => {
    console.log('values:', values)
  
  }, [values]);

  useEffect(() => {
    console.log('webService:', webService)
  }, [webService]);

  useEffect(() => {
    console.log('specialService:', specialService)
  }, [specialService]);

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      try {
        const webRequestData = await getAllWebRequestServiceTypes();
        setWebRequestServices(webRequestData);

        if (orderType !== 'new') {
          let order;

          if (location.state.details) {
            order = location.state.details;
          } else {
            order = await getOrder_base(location.state.orderLink);
          }
          setValues(prev => ({
            ...prev,
            orderLocation: 'Toronto',
            insuranceCompany: order._embedded.insuranceCompany ? order._embedded.insuranceCompany._links.self.href.split('{')[0] : '',
            agency: order._embedded.agency ? order._embedded.agency._links.self.href.split('{')[0] : '',
            tempAgency: order.tempAgency,
            serviceTime: order.serviceTime,
            clientInfo: {...order.clientInfo, languages: order.clientInfo.languages[0]},
            examLocation: order.examLocation,
            refNum: order.refNum,
            insuranceItems: order.insuranceItems[0],
            orderAttachments: [],
            remarks: order.remarks
          }));

          if (order._embedded.webRequestServiceTypes) {
            order._embedded.webRequestServiceTypes.forEach(service => setWebService(prev => ({ ...prev, [service._links.self.href.split('{')[0]]: true })));
          }

          if (order.specialTests.length > 0) {
            order.specialTests.forEach(test => setSpecialService(prev => ({ ...prev, [test]: true })));
          }

        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    };
    initialize();
  }, [location.state, orderType]);


  useEffect(() => {
    if (userState && userState.userInfo.link) {
      const getAgenciesList = async () => {
        setIsLoading(true);
        try {
          const list = await searchAgency({ user: userState.userInfo.link });
          const listModified = list._embedded.agencies.map(agency => ({ id: agency._links.self.href, name: agency.name }));
          setAgenciesList([...listModified, { id: 'other', name: 'Other' }]);
        } catch (err) {
          console.log(err);
        } finally {
          setIsLoading(false);
        }
      }

      getAgenciesList();
    }
  
  }, [userState]);

  useEffect(() => {
    if (appDataState && appDataState.languages) {
      const languageListTemp = appDataState.languages.map((language) => {
        return { id: language, label: language };
      });
      setLanguageList(languageListTemp);
    } 
  }, [appDataState]);

  useEffect(() => {
    if (appDataState && appDataState.insuranceTypes) {
      const insuranceTypeListTemp = Object.values(appDataState.insuranceTypes).map((type) => {
        return { id: type, label: type };
      });
      setInsuranceTypeList(insuranceTypeListTemp);
    } 
  }, [appDataState]);

  useEffect(() => {
    if (appDataState && appDataState.refNoTypes) {
      const refNoListTemp = appDataState.refNoTypes.map((refNo) => {
        return { value: refNo, label: refNo };
      });
      setRefNoList(refNoListTemp);
    } 
  }, [appDataState]);

  useEffect(() => {
    if (values.clientInfo.dob) {
      const age = moment().diff(values.clientInfo.dob, 'years');
      setValues(prev => ({ ...prev, clientInfo: { ...prev.clientInfo, age } }))
    }
  }, [values.clientInfo.dob]);

  const changeHandler = (e, subName = null) => {
    console.log('e:', e)
    if (subName) {
      setValues({...values, [subName]: { ...values[subName], [e.target.name]: e.target.value }});
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const changeInsuranceAmount = value => {
    setValues(prev => ({ ...prev, insuranceItems: { ...prev.insuranceItems, insuranceAmount: value }}));
  };
  // const changeOtherAgencyHandler = val => {
  //   setOtherAgency(val);
  // };

  const fileChangeHandler = (e) => {
    console.log('e:', e)
    // console.log("FILE AREA --------", e.target.files);
    setValues({ ...values, [e.target.name]: [...e.target.files] });
  };

  const handleWebServiceChange = (e) => {
    const tempWebService = { ...webService, [e.target.name]: e.target.checked };
    setWebService(tempWebService);
    let isWebServerValid = false;
    for (const property in tempWebService) {
      if (tempWebService[property]) {
        isWebServerValid = true;
        break
      } 
    }
    setIsWebServiceValid(isWebServerValid);
  };

  const handleSpecialServiceChange = (e) => {
    setSpecialService({ ...specialService, [e.target.name]: e.target.checked });
  };

  const submitForm = async (e) => {
    e.preventDefault();

    const pushServices = serviceObj => {
      let array = [];
      for (let key of Object.keys(serviceObj)) {
        if (serviceObj[key]) {
          array.push(key);
        }
      }
      return array;
    };

    const defaultLab = globalNameList.insuranceCompanies.find(company => company.link === values.insuranceCompany).defaultLab;

    const newOrder = {
      ...values,
      serviceTime: values.serviceTime,
      status: 'Pending',
      agency: values.agency === 'other' ? null : values.agency,
      lab: { labName: defaultLab, labNumber: '', labPuroNum: '' },
      agent: userState.userInfo.link,
      insuranceItems: [values.insuranceItems],
      clientInfo: {...values.clientInfo, dob: moment(values.clientInfo.dob), languages: [values.clientInfo.languages]},
      webRequestServiceTypes: pushServices(webService),
      specialTests: pushServices(specialService),
      orderDate: moment(),
      createdBy: userState.userInfo.link,
      orderedBy: 'Online',
    };

    try {
      console.log('newOrder:', newOrder)
      let res;
      if (orderType === 'edit') {
        res = await updateOrder_base(location.state.orderLink, newOrder);
      } else {
        res = await createOrder(newOrder);
      }
      console.log('new order created', res);

      if (values.files && values.files.length > 0) {
        for (let file of values.files) {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("uploadedBy", userState.userInfo.link);
          formData.append("order", res._links.self.href);
          await createOrderAttachment(formData);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      history.push('/order-requests');
    }
  };

  const renderWebRequest = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <FormControl component="fieldset">
          <FormGroup row style={{ justifyContent: "flex-start" }}>
            {webRequestServices.map((webReqService) => {
              return (
                <Grid item container xs={6} className="checkBox" key={generateKey()} >
                  <FormControlLabel
                    style={{ paddingLeft: "10px !important" }}
                    control={
                      <Checkbox
                        name={webReqService._links.self.href}
                        checked={webService[webReqService._links.self.href]}
                        onChange={handleWebServiceChange}
                      />
                    }
                    label={webReqService.name}
                  />
                </Grid>
              );
            })}
          </FormGroup>
        </FormControl>
      </Grid>
    );
  };

  const renderSpecialRequest = () => {
    return (
      <Grid item container xs={12} spacing={2}>
        <FormControl component="fieldset">
          <FormGroup row>
            {appDataState && appDataState.specialTests ? appDataState.specialTests.map((specialTest) => {
              return (
                <Grid item container xs={6} className="checkBox" key={generateKey()} >
                  <FormControlLabel
                    style={{ paddingLeft: "10px !important" }}
                    control={
                      <Checkbox
                        name={specialTest}
                        checked={specialService[specialTest]}
                        onChange={handleSpecialServiceChange}
                      />
                    }
                    label={specialTest}
                  />
                </Grid>
              );
            }) : []}
          </FormGroup>
        </FormControl>
      </Grid>
    );
  };

  return isLoading && appDataState ? (
    <LoadingSpinner />
  ) : (
    <>
      <div className="section">
        <Grid container spacing={2}>
          <Grid item xs={12} className="section-header main-info">
            <Typography variant="h6">Place Order</Typography>
          </Grid>
          <Grid item container xs={12} md={6} className="seperator" alignContent="flex-start">
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Agency:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <DropdownField
                // disabled={true}
                name="agency"
                value={values.agency}
                onChange={e => changeHandler(e)}
                options={agenciesList}
                labelField="name"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} className="sub-section__input">
              {values.agency === 'other' && <InputField 
                name="tempAgency" 
                onChange={e => changeHandler(e)} 
                placeholder='Click here to enter new agency' 
                value={values.tempAgency}
              />}
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Insurance Company:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <DropdownField
                name="insuranceCompany"
                value={values.insuranceCompany}
                onChange={e => changeHandler(e)}
                // options={insuranceCompanies}
                options={globalNameList.insuranceCompanies}
                labelField="name"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Appointment Date:
              </ReportLabel>
            </Grid>
            <Grid item container xs={9}>
              <Grid item xs={12} className="sub-section__input">
                <InputField
                  name="serviceTime"
                  onChange={changeHandler}
                  value={values.serviceTime}
                  type="datetime-local"
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <div className="section">
        <Grid container spacing={2}>
          <Grid item xs={12} className="section-header client-info">
            <Typography variant="h6">Client Information</Typography>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Client Name:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <Grid item container xs={12} direction='column'>
                <InputField 
                  name="firstName" 
                  onChange={e => changeHandler(e, 'clientInfo')} 
                  value={values.clientInfo.firstName} 
                  placeholder='First Name' 
                />
                <InputField 
                  name="lastName" 
                  onChange={e => changeHandler(e, 'clientInfo')} 
                  value={values.clientInfo.lastName} 
                  placeholder='Last Name' 
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Gender:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <RadioField
                value={values.clientInfo.gender}
                onChange={e => changeHandler(e, 'clientInfo')}
                options={genderOptions}
                name="gender"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                DOB:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={5} className="sub-section__input">
              <InputField name="dob" type="date" onChange={e => changeHandler(e, 'clientInfo')} value={values.clientInfo.dob} />
            </Grid>
            <Grid item xs={2} className="sub-section">
              <ReportLabel flexEnd bold>
                Age:
              </ReportLabel>
            </Grid>
            <Grid item xs={2} className="sub-section__input">
              <InputField name="age" type="number" value={values.clientInfo.age} />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Mobile:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <InputField type="tel" name="mobile" onChange={e => changeHandler(e, 'clientInfo')} value={values.clientInfo.mobile} />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Home Tel.:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <InputField name="home" onChange={e => changeHandler(e, 'clientInfo')} value={values.clientInfo.home} />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Business Tel.:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <InputField name="business" onChange={e => changeHandler(e, 'clientInfo')} value={values.clientInfo.business} />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} className="seperator">
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Ref. No. Type:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <RadioField
                value={values.refNum.refNumType}
                onChange={e => changeHandler(e, 'refNum')}
                options={refNoList}
                name="refNumType"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6} className="seperator">
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Ref. No.:
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <InputField name="refNumNumber" onChange={e => changeHandler(e, 'refNum')} value={values.refNum.refNumNumber} />
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Ins. Amt ($):
                <br />
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <div className='MuiInputBase-root MuiInput-root input MuiInputBase-fullWidth MuiInput-fullWidth'>
                <NumberFormat
                  className='MuiInputBase-input MuiInput-input'
                  thousandSeparator={true}
                  name="insuranceAmount"
                  value={values.insuranceItems.insuranceAmount}
                  prefix='$'
                  onValueChange={values => changeInsuranceAmount(values.value)}
                />
              </div>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Ins. Type:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="sub-section__input">
              <DropdownField
                name="insuranceType"
                value={values.insuranceItems.insuranceType}
                onChange={e => changeHandler(e, 'insuranceItems')}
                options={insuranceTypeList}
                labelField="label"
              />
            </Grid>
          </Grid>

          <Grid item container xs={12} md={6}>
          <Grid item xs={3} className="sub-section">
            <ReportLabel flexEnd bold>
              Address:
              <br />
              <Required />
            </ReportLabel>
          </Grid>
          <Grid item xs={5} className="sub-section__input">
            <InputField 
            name="clientLocationAddress" 
            onChange={e => changeHandler(e, 'examLocation')} 
            value={values.examLocation.clientLocationAddress} 
            multiline={true}
            numRows={2}
            />
          </Grid>
          <Grid item xs={2} className="sub-section">
            <ReportLabel flexEnd bold>
              Postal Code:
            </ReportLabel>
              <Required />
          </Grid>
          <Grid item xs={2} className="sub-section__input">
            <InputField 
            name="clientLocationPostalCode" 
            onChange={e => changeHandler(e, 'examLocation')} 
            value={values.examLocation.clientLocationPostalCode} 
            />
          </Grid>
        </Grid>
        </Grid>
      </div>
      <div className="section">
        <Grid container spacing={2}>
          <Grid item xs={12} className="section-header service-info">
            <Typography variant="h6">Web Request Service</Typography>
          </Grid>
          <Grid item xs={12} md={6} className="sub-section-header web-request">
            <Typography variant="subtitle1">Web Request Service</Typography>
          </Grid>
          <Grid item xs={12} md={6} className="sub-section-header special-test">
            <Typography variant="subtitle1">Special Test</Typography>
          </Grid>
          <Grid item container xs={12} md={6} className="web-request-info">
            {renderWebRequest()}
          </Grid>
          <Grid item container xs={12} md={6} className="web-request-info">
            {renderSpecialRequest()}
          </Grid>
        </Grid>
      </div>
      <div className="section">
        <Grid container spacing={2}>
          <Grid item xs={12} className="section-header attachment">
            <Typography variant="h6">Attachment (You can upload your PDF files here)</Typography>
          </Grid>
          <Grid item xs={12}>
            <InputField
              type="file"
              name="files"
              files={values.files}
              onChange={fileChangeHandler}
              accept=".pdf"
            />
          </Grid>
        </Grid>
      </div>
      <div className="section">
        <Grid container spacing={2}>
          <Grid item xs={12} className="section-header examination-details">
            <Typography variant="h6">Examination Details</Typography>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Language:
              </ReportLabel>
            </Grid>
            <Grid item xs={9}>
              <DropdownField
                name="languages"
                onChange={e => changeHandler(e, 'clientInfo')}
                value={values.clientInfo.languages}
                options={languageList}
                labelField="label"
              />
            </Grid>
          </Grid>
          <Grid item container xs={false} md={6}>
            {""}
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={3} className="sub-section">
              <ReportLabel flexEnd bold>
                Remarks/Other Req.:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} container className="sub-section__full">
              <InputField
                name="remarks"
                onChange={e => changeHandler(e)}
                value={values.remarks}
                multiline={true}
                numRows={4}
              />
            </Grid>
          </Grid>
          <Grid item container xs={false} md={6} className="sub-section__full">
            {""}
          </Grid>
        </Grid>
      </div>
      <SaveButton 
        text="submit" 
        submitHandler={submitForm} 
        disabled={!(isWebServerValid && values.agency && values.insuranceCompany && values.clientInfo.firstName && values.clientInfo.lastName && values.clientInfo.dob && values.clientInfo.home && values.clientInfo.mobile && values.examLocation.clientLocationAddress && values.examLocation.clientLocationPostalCode && values.refNum.refNumNumber && values.insuranceItems.insuranceAmount)}
      />
    </>
  );
};

export default withRouter(NewOrderPage);
