import React, { useState, useEffect } from "react";

import DetailHeader from "./DetailHeader";
import DetailFooter from "./DetailFooter";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ReportLabel from "../ReportLabel";
import BasicInput from "../basic-components/BasicInput";
import BasicInputLowerCase from "../basic-components/BasicInputLowerCase";
import TripleInputTele from "../basic-components/TripleInputTele"; 


import BasicTextarea from "../basic-components/BasicTextarea";
import BasicCheckbox from "../basic-components/BasicCheckbox";
import GenderOptions from "../basic-components/GenderOptions";
import Required from '../basic-components/Required';
import "./DetailsGridCSS.scss";

import { getUser_base } from "../API_calls/users";
import validator from "validator";
import { roundTo, normalizePhone2} from "../utils/helpers"

const ContractorDetails = ({
  handleClose,
  handleSave = null,
  handleCreate = null,
  details,
  type,
  footerMsg,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});

  // brought in for triple box
  const [phone3Box, setPhone3Box] = useState({});
  const [formatPhone, setFormatPhone] = useState('');
  //end

  let validMobileTel = values.mobileTel
  ? values.mobileTel.length === 12
  : false;

  let validEmail;
  if (!values.email){
    validEmail =  validator.isEmail("")
  } else {
    validEmail = validator.isEmail(values.email)
  }
  
  useEffect(() => {
    if (details) {
      const initializeData = async () => {
        if (type === "add") {
          setValues({ enabled: true, gender: "Unknown" });
        } else {
          const res = await getUser_base(details._links.self.href);
         console.log("RES FROM AGENT DETAILS:", res);
         res.contractorHSTRate = roundTo(res.contractorHSTRate*=100, 2)
          setValues(res);




          //for triple input
            let mobileSplit;
           let businessSplit;
           let homeSplit;
           let faxSplit;

           if (res.mobileTel){
            res.mobileTel=normalizePhone2(res.mobileTel)
            mobileSplit=res.mobileTel.split("-")          } else mobileSplit = ""



           if (res.businessTel){
            res.businessTel=normalizePhone2(res.businessTel)
             businessSplit = res.businessTel.split("-")
           } else businessSplit = ""
 
           if (res.homeTel){
            res.homeTel=normalizePhone2(res.homeTel)
             homeSplit = res.homeTel.split("-")
           } else homeSplit = ""
 
 
           if (res.fax){
            res.fax=normalizePhone2(res.fax)
             faxSplit = res.fax.split("-")
           } else faxSplit = ""
 
           setPhone3Box({mobileTel1: mobileSplit[0], mobileTel2: mobileSplit[1], mobileTel3: mobileSplit[2],
             businessTel1: businessSplit[0], businessTel2: businessSplit[1], businessTel3: businessSplit[2],
             homeTel1: homeSplit[0], homeTel2: homeSplit[1], homeTel3: homeSplit[2],
             fax1: faxSplit[0], fax2: faxSplit[1], fax3: faxSplit[2],
           })
           //end

        }
      };
      initializeData();
    }
  }, [details, type]);

  const changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };

  const genderOptions = () => {
    return <GenderOptions name="gender" value={values.gender} onChange={changeHandler} />;
  };

  //triple input
  const removeWords =(arr) =>{
    const finalOP=[]
    if (arr.length===0){
      return arr
    }
    if (!arr[0][1] && !arr[1][1] && !arr[2][1]){
      return ""
    }

    if (arr.length>=1){
      finalOP.push(arr[0][1])
    }
    if (arr.length>=2){
      finalOP.push(arr[1][1])
    }
    if (arr.length===3){
      finalOP.push(arr[2][1])
    }
    return finalOP.join("-")
  }

  useEffect(() => {
    const formatPhoneNumber= Object.entries(phone3Box).filter(element =>{
      if (element[0].startsWith(phone3Box.type)){
        return element
      }
    })
    const numbersOnly = removeWords(formatPhoneNumber)
    setFormatPhone({type: phone3Box.type, formattedNum:numbersOnly})
  }, [phone3Box]);

  useEffect(() => { 
      setValues({ ...values, [formatPhone.type]: formatPhone.formattedNum });
  }, [formatPhone]);
 
  


  const changeHandlerPhone = (e) => {

    setPhone3Box({ ...phone3Box, type: e.target.name.slice(0, -1), [e.target.name]: e.target.value.replace(/\D/g, "") });
  };
//end

  return (
    <>
      <DetailHeader title="Contractor details" />
      <DialogContent dividers={true}>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Username:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                name="username"
                value={values.username}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Short Code:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="contractorShortCode"
                value={values.contractorShortCode}
                error={errors.contractorShortCode}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Enabled:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item" alignItems="center">
              <BasicCheckbox name="enabled" checked={values.enabled} onChange={changeHandler} />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Last Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="lastName"
                value={values.lastName}
                error={errors.lastName}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                First Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="firstName"
                value={values.firstName}
                error={errors.firstName}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Gender:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              {genderOptions()}
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                HST Rate (%):
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="contractorHSTRate"
                type="number"
                value={values.contractorHSTRate}
                error={errors.contractorHSTRate}
                onChange={changeHandler}
              />
            </Grid>
            {type !== "add" && (
              <>
                <Grid item xs={3} className="row-section-item">
                  <ReportLabel variant="subtitle1" flexEnd>
                    Join Date:
                  </ReportLabel>
                </Grid>
                <Grid item xs={9} className="row-section-item">
                  <BasicInput
                    type="datetime"
                    name="createDateTime"
                    value={values.createDateTime}
                    readOnly={true}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Remarks:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="remarks"
                value={values.remarks}
                error={errors.remarks}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Home Tel:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
            <TripleInputTele
                name="homeTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg='Not a valid phone #'
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                type="email"
                name="email"
                value={values.email}
                error={values.email ? !validator.isEmail(values.email) : false}
                errorMsg='Not a valid email address'
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Mobile:
                <Required />

              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
            <TripleInputTele
                name="mobileTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg='Not a valid phone #'
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Fax:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
            <TripleInputTele
                name="fax"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg='Not a valid phone #'
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Address:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="address"
                value={values.address}
                error={errors.address}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                City:
                <Required />

              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="city"
                rows={1}
                value={values.city}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Province:
                <Required />

              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="province"
                rows={1}
                value={values.province}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Postal Code:
                <Required />

              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="postalCode"
                rows={1}
                value={values.postalCode}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Mileage Rate:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="contractorMileageRate"
                type="number"
                value={values.contractorMileageRate}
                error={errors.contractorMileageRate}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Referral Fee:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="contractorReferralFee"
                type="number"
                value={values.contractorReferralFee}
                error={errors.contractorReferralFee}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Working Region:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="contractorWorkingRegion"
                value={values.contractorWorkingRegion}
                error={errors.contractorWorkingRegion}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Availability:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="contractorAvailability"
                value={values.contractorAvailability}
                error={errors.contractorAvailability}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DetailFooter
        // type={Object.keys(details).length > 0 ? "update" : "add"}
        type={type}
        handleClose={handleClose}
        handleSave={() => handleSave(values, details)}
        handleCreate={() => handleCreate(values)}
        disabled={!(values.username 
          && values.firstName 
          && values.lastName 
          && values.enabled
          && validMobileTel
          && values.city 
          && values.province 
          && values.postalCode
          && validEmail
          )}
        footerMsg={footerMsg}
      />
    </>
  );
};

export default ContractorDetails;
