////////////////////// TODO //////////////////////////////////

/* Fix reuseability bug */

////////////////////// TODO //////////////////////////////////

import React from "react";
import * as detailHelpers from "../orderDetailsHelpers";
import { generateKey } from '../../utils/helpers';
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import DeleteButton from "../DeleteButton";
import Grid from "@material-ui/core/Grid";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from "@material-ui/core/IconButton";
import FormHelperText from '@material-ui/core/FormHelperText';

import './AutoComplete.scss';

const AutoCompleteDropDown = ({ 
  multiple = false, 
  width = "100%", 
  label = "", 
  variant, 
  margin = "none", 
  disableClearable = false, 
  error = false,
  helperText,
  ...props 
}) => {
  // const defaultProps = {
  //   options: props.dropDownData,
  //   getOptionLabel: option => option
  // };

  const classes = detailHelpers.useStyles();

  const getOptionLabel = option => {
    if (props.dropDownVal) {
      if (props.dropDownVal === 'firstName') {
        return `${option.lastName}, ${option.firstName}`
      } else {
        return option[props.dropDownVal];
      }
    } else {
      return option;
    }
  };

  const dropDownComponent = props.renderItems.map((item, i) => {
    return (
      <Grid item key={generateKey()}>
        <div style={{ display: "flex", flexDirection: "rows" }}>
          <Autocomplete
            style={{ width: width }}
            options={props.dropDownData}
            getOptionLabel={option => getOptionLabel(option)}
            autoSelect
            multiple={multiple}
            renderInput={params => <TextField {...params} label={label} margin={margin} fullWidth variant={variant} />}
            value={item}
            onChange={props.onChange}
            disableClearable={disableClearable}
            classes={{ inputRoot: error ? 'error' : '' }}
          />
          {props.enableDelete ? (
            <DeleteButton
              style={{ cursor: "pointer", display: "flex", justifySelf: "flex-end" }}
              deleteHandler={() => props.deleteHandler(i)}
            />
          ) : null}
          {props.enableSearch ? (
            <IconButton
              onClick={() => props.onClickSearch(i)}
              size="small"
              color="secondary"
              style={{ display: "flex", justifySelf: "flex-end" }}
            >
              <SearchIcon />
            </IconButton>
          ) : null}
        </div>
        {error && <FormHelperText error={true}>{helperText}</FormHelperText>} 
      </Grid>
    );
  });

  return dropDownComponent;
};

export default AutoCompleteDropDown;
