import instance_base from "../utils/API_base";
import instance from "../utils/API";

const addUserAgencyRelation_base = async (url, agencyLink) => {
  try {
    const res = await instance_base
      .post(url, agencyLink, { headers: { "content-type": "text/uri-list" } })
      .then((res) => res.data);
    return res;
  } catch (error) {
    throw error.response.data;
  }
};

const addInsuranceAgencyPair = async data => {
  try {
    const res = await instance.post('/insuranceAgencyPairs', data).then(res => {
      return res.data;
    });
    return res;
  } catch (error) {
    throw error.response.data
  }
};

const deleteInsuranceAgencyPair_base = async url => {
  try {
    const res = await instance_base.delete(url).then((res) => res);
    return res;
  } catch (error) {
    throw error.response.data;
  }
}

export { addUserAgencyRelation_base, addInsuranceAgencyPair, deleteInsuranceAgencyPair_base };
