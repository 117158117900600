import React from "react";
import ContractorReport from "./ContractorReport";
import ReportWrapper from "../components/ReportWrapper";

const ContractorReportViewer = ({ closeDialog, id, emailLogs, mobileExpenses }) => {
  return (
    <div>
      <ReportWrapper closeDialog={closeDialog} title="Contractor Report">
        <ContractorReport id={id} />
      </ReportWrapper>
    </div>
  );
};

export default ContractorReportViewer;
