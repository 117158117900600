import React, { useEffect, useState, useContext } from "react";

import DefaultTable from "./DefaultTable";

import { mobileServiceInfoColumns } from "./data/constants";
import {
  createMobileServiceType,
  updateMobileServiceType_base
} from "./API_calls/mobileServiceTypes";
import { GlobalNameListContext, AppDataContext } from "../Context";

import PopUp from "./PopUp";


const MobileServiceCrudTable = ({ data, ...props }) => {

  const { setGlobalNameList } = useContext(GlobalNameListContext);
  const { appDataState } = useContext(AppDataContext);

  const [info, setInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(50);


  //for popup error msg
  const [popUpStatus, setPopUpStatus] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [popUpType, setPopUpType] = useState("");

    
  useEffect(() => {
    if (data && appDataState) {
      const initializeData = async () => {
        try {
          setIsLoading(true);
          setInfo(data);
          const kitCodes = {};
          for (let name of appDataState.kitNames) {
            kitCodes[name] = name;
          }
          mobileServiceInfoColumns[2].lookup = kitCodes;
        } catch (e) {
          console.log("error:", e);
        } finally {
          setIsLoading(false);
        }
      };
      initializeData();
    }
  }, [data, appDataState]);

  const addFunction = async (newData) => {

    console.log(newData)


    if (newData.kitCode === undefined){
      setErrorMsg('Error: Require Kit Code')
      setPopUpType('error')

      setPopUpStatus(new Date())
    } else {
      setIsLoading(true);
      try {
        let res = await createMobileServiceType(newData);
        setInfo([res, ...info]);
        setGlobalNameList(prev => ({ ...prev, mobileServiceTypes: [...prev.mobileServiceTypes, { name: res.name, link: res._links.self.href }]}));
      } catch (e) {
        setErrorMsg('Error: Please fillout all fields')
        setPopUpType('error')

        setPopUpStatus(new Date())
  
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const updateFunction = async (newData, oldData) => {

    if (newData.kitCode === undefined){
      setErrorMsg('Error: Require Kit Code')
      setPopUpType('error')

      setPopUpStatus(new Date())
    } else {
      setIsLoading(true);
      try {
        await updateMobileServiceType_base(oldData._links.self.href, newData);
        const data = info.map((currData) =>
          currData.tableData.id === oldData.tableData.id ? newData : currData
        );
        setInfo(data);
      } catch (e) {
        setErrorMsg('Error: Please fillout all fields')
        setPopUpType('error')
        setPopUpStatus(new Date())
          console.log(e);
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <DefaultTable
        title="Mobile Service Info"
        inputColumns={mobileServiceInfoColumns}
        inputData={info}
        updateFunction={updateFunction}
        addFunction={addFunction}
        isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
      />

      {popUpStatus ? <PopUp type="error" message={errorMsg} status={popUpStatus} /> : null}

    </>
  );
};

export default MobileServiceCrudTable;
