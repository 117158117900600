import React, { useState, useEffect } from "react";

import DetailHeader from "./DetailHeader";
import DetailFooter from "./DetailFooter";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import ReportLabel from "../ReportLabel";
import BasicInput from "../basic-components/BasicInput";
import BasicInputLowerCase from "../basic-components/BasicInputLowerCase";
import TripleInputTele from "../basic-components/TripleInputTele";

import BasicTextarea from "../basic-components/BasicTextarea";
import BasicCheckbox from "../basic-components/BasicCheckbox";
import DefaultTable from "../DefaultTable";
import Required from "../basic-components/Required";

import GenderOptions from "../basic-components/GenderOptions";

// import DetailsGridCSS from "./DetailsGridCSS.scss";
import { getUser_base } from "../API_calls/users";
import {
  addInsuranceAgencyPair,
  deleteInsuranceAgencyPair_base,
} from "../API_calls/relations";

import { agentRelationsInfoColumns } from "../data/constants";
import { searchAgency } from "../API_calls/agencies";
import validator from "validator";

import {
  formatPhoneNumber,
  normalizePhone,
  normalizePhone2,
} from "../utils/helpers";

const AgentDetails = ({
  handleClose,
  handleSave = null,
  handleCreate = null,
  details,
  companyList,
  agencyList,
  type,
  footerMsg,
  ...props
}) => {
  const [values, setValues] = useState({});
  const [phone3Box, setPhone3Box] = useState({});
  const [formatPhone, setFormatPhone] = useState("");

  const [errors, setErrors] = useState({});

  const [isLoading2, setIsLoading2] = useState(false);
  const [pageSize2, setPageSize2] = useState(10);
  const [info2, setInfo2] = useState([]);

  let validMobileTel = values.mobileTel
    ? values.mobileTel.length === 12
    : false; 

    let validEmail;
  if (!values.email){
    validEmail =  validator.isEmail("")
  } else {
    validEmail = validator.isEmail(values.email)
  }

  useEffect(() => {
    if (details) {
      const initializeData = async () => {
        if (type === "add") {
          setValues({
            enabled: true,
            gender: "Unknown",
            newOrderEmail: true,
            updateStatusEmail: true,
            appointmentEmail: true,
            completionEmail: true,
          });
        } else {
          const res = await getUser_base(details._links.self.href);


          //for triple input
          let mobileSplit;
          let businessSplit;
          let homeSplit;
          let faxSplit;
          let otherSplit;

          if (res.mobileTel) {
            res.mobileTel = normalizePhone2(res.mobileTel);
            mobileSplit = res.mobileTel.split("-");
          } else mobileSplit = "";

          if (res.businessTel) {
            res.businessTel = normalizePhone2(res.businessTel);
            businessSplit = res.businessTel.split("-");
          } else businessSplit = "";

          if (res.homeTel) {
            res.homeTel = normalizePhone2(res.homeTel);
            homeSplit = res.homeTel.split("-");
          } else homeSplit = "";

          if (res.fax) {
            res.fax = normalizePhone2(res.fax);
            faxSplit = res.fax.split("-");
          } else faxSplit = "";

          if (res.otherTel) {
            res.otherTel = normalizePhone2(res.otherTel);
            otherSplit = res.otherTel.split("-");
          } else otherSplit = "";

          setValues(res);
          setPhone3Box({
            mobileTel1: mobileSplit[0],
            mobileTel2: mobileSplit[1],
            mobileTel3: mobileSplit[2],
            businessTel1: businessSplit[0],
            businessTel2: businessSplit[1],
            businessTel3: businessSplit[2],
            homeTel1: homeSplit[0],
            homeTel2: homeSplit[1],
            homeTel3: homeSplit[2],
            fax1: faxSplit[0],
            fax2: faxSplit[1],
            fax3: faxSplit[2],
            otherTel1: otherSplit[0],
            otherTel2: otherSplit[1],
            otherTel3: otherSplit[2],
          });
        }
      };
      initializeData();
    }
  }, [details, type]);

  useEffect(() => {
    const updateInsuranceCompanyDropDown = (options) => {
      const companyLookup = {};
      for (const i in options) {
        companyLookup[options[i].id] = options[i].name;
      }
      // agentRelationsInfoColumns[0].lookup = companyLookup;
    };
    updateInsuranceCompanyDropDown(companyList);
  }, [companyList]);

  useEffect(() => {
    const updateAgencyDropDown = (options) => {
      const agencyLookup = {};
      for (const i in options) {
        agencyLookup[options[i].id] = options[i].name;
      }
      agentRelationsInfoColumns[0].lookup = agencyLookup;
    };
    updateAgencyDropDown(agencyList);
  }, [agencyList]);

  useEffect(() => {
    if (type !== "add" && values._links) {
      const loadAgencyRelations = async (url) => {
        try {
          const res = await searchAgency({ user: values._links.self.href });
          setInfo2(res._embedded.agencies);
        } catch (err) {
          console.log(err);
        }
      };

      loadAgencyRelations();
    }
  }, [values, type]);

  const addFunction2 = async (newData) => {
    // console.log('newData:', newData)
    setIsLoading2(true);
    try {
      const res = await addInsuranceAgencyPair({
        agent: values._links.self.href,
        agency: newData._links.self.href,
      });
      // console.log('new pair:', res)
      setInfo2((prev) => [
        {
          name: res._embedded.agency.name,
          email: res._embedded.agency.email,
          tele: res._embedded.agency.tele,
          fax: res._embedded.agency.fax,
          _links: {
            self: { href: res._embedded.agency._links.self.href.split("{")[0] },
          },
        },
        ...prev,
      ]);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading2(false);
    }
  };

  const deleteFunction2 = async (oldData) => {
    setIsLoading2(true);
    try {
      const oldUrl = values._embedded.insuranceAgencyPairs
        .find((pair) => pair.agency.name === oldData.name)
        ._links.self.href.split("{")[0];
      await deleteInsuranceAgencyPair_base(oldUrl);
      setInfo2((prev) =>
        prev.filter(
          (currData) => currData.tableData.id !== oldData.tableData.id
        )
      );
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading2(false);
    }
  };

  const changeHandler = (e) => {
    if (e.target.type === "checkbox") {
      setValues({ ...values, [e.target.name]: e.target.checked });
    } else {
      setValues({ ...values, [e.target.name]: e.target.value });
    }
  };
  /// for triple input
  const removeWords = (arr) => {
    const finalOP = [];
    if (arr.length === 0) {
      return arr;
    }
    if (!arr[0][1] && !arr[1][1] && !arr[2][1]) {
      return "";
    }

    if (arr.length >= 1) {
      finalOP.push(arr[0][1]);
    }
    if (arr.length >= 2) {
      finalOP.push(arr[1][1]);
    }
    if (arr.length === 3) {
      finalOP.push(arr[2][1]);
    }
    return finalOP.join("-");
  };

  useEffect(() => {
    const formatPhoneNumber = Object.entries(phone3Box).filter((element) => {
      if (element[0].startsWith(phone3Box.type)) {
        return element;
      }
    });
    const numbersOnly = removeWords(formatPhoneNumber);
    setFormatPhone({ type: phone3Box.type, formattedNum: numbersOnly });
  }, [phone3Box]);

  useEffect(() => {
    setValues({ ...values, [formatPhone.type]: formatPhone.formattedNum });
  }, [formatPhone]);
 
  const removeLetters = (value) =>{

    if (value===undefined){
      return ""
    }

    return value.replace(/\D/g, "")
  }

  const changeHandlerPhone = (e) => {

    // if (e.target.name.endsWith('1') || e.target.name.endsWith('2')){
    //   if (e.target.value.length > 3) {
    //     e.target.value = e.target.value.slice(0,3); 
    // }
    // }

    // if (e.target.name.endsWith('3')){
    //   if (e.target.value.length > 4) {
    //     e.target.value = e.target.value.slice(0,4); 
    //   }
    // }

    setPhone3Box({ ...phone3Box, type: e.target.name.slice(0, -1), [e.target.name]: removeLetters(e.target.value) });

  };

  // triple input functions ends here

  const genderOptions = () => {
    return (
      <GenderOptions
        name="gender"
        value={values.gender}
        onChange={changeHandler}
      />
    );
  };

  return (
    <>
      <DetailHeader title="Agent details" />
      <DialogContent dividers={true}>
        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Username:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                name="username"
                value={values.username}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Enabled:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid
              container
              item
              xs={9}
              className="row-section-item"
              alignItems="center"
            >
              <BasicCheckbox
                name="enabled"
                checked={values.enabled}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Last Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="lastName"
                value={values.lastName}
                error={errors.lastName}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                First Name:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInput
                name="firstName"
                value={values.firstName}
                error={errors.firstName}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Gender:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              {genderOptions()}
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Remarks:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="remarks"
                value={values.remarks}
                error={errors.remarks}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Subscribe:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item">
              <BasicCheckbox
                type="checkbox"
                name="newOrderEmail"
                checked={values.newOrderEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">New Order Email</ReportLabel>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item">
              <BasicCheckbox
                type="checkbox"
                name="updateStatusEmail"
                checked={values.updateStatusEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">Update Status Email</ReportLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item">
              <BasicCheckbox
                type="checkbox"
                name="appointmentEmail"
                checked={values.appointmentEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">Appointment Email</ReportLabel>
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                {""}
              </ReportLabel>
            </Grid>
            <Grid container item xs={9} className="row-section-item">
              <BasicCheckbox
                type="checkbox"
                name="completionEmail"
                checked={values.completionEmail}
                onChange={changeHandler}
              />
              <ReportLabel variant="subtitle1">Completion Email</ReportLabel>
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Home Tel:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="homeTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Other:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="otherTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Mobile:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row" style={{ paddingLeft: "2px" }}>
              <TripleInputTele
                name="mobileTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email:
                <Required />
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                type="email"
                name="email"
                value={values.email}
                error={values.email ? !validator.isEmail(values.email) : false}
                errorMsg="Not a valid email address"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Business:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="businessTel"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email 2:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                name="email2"
                value={values.email2}
                error={
                  values.email2 ? !validator.isEmail(values.email2) : false
                }
                errorMsg="Not a valid email address"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Fax:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <TripleInputTele
                name="fax"
                value={values}
                setValues={setValues}
                phone3Box={phone3Box}
                setPhone3Box={setPhone3Box}
                // error={errors.mobileTel}
                // error={values.mobileTel ? !values.mobileTel.match(/^[0-9-+\s()]*$/g) : false}
                errorMsg="Not a valid phone #"
                onChange={changeHandlerPhone}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Email 3:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicInputLowerCase
                name="email3"
                value={values.email3}
                error={
                  values.email3 ? !validator.isEmail(values.email3) : false
                }
                errorMsg="Not a valid email address"
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* <Grid container className="row">
          <Grid container xs={6} item className="row-section">
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Address:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="address"
                value={values.address}
                error={errors.address}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
          <Grid container xs={6} item className="row-section">
          <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                City:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="city"
                rows={1}
                value={values.city}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Province:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="province"
                rows={1}
                value={values.province}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
            <Grid item xs={3} className="row-section-item">
              <ReportLabel variant="subtitle1" flexEnd>
                Postal Code:
              </ReportLabel>
            </Grid>
            <Grid item xs={9} className="row-section-item">
              <BasicTextarea
                name="postalCode"
                rows={1}
                value={values.postalCode}
                // error={errors.address2}
                onChange={changeHandler}
              />
            </Grid>
          </Grid>
        </Grid> */}

        {type !== "add" && (
          <div style={{ width: "100%" }}>
            <DefaultTable
              title="Agent Relations"
              inputColumns={agentRelationsInfoColumns}
              inputData={info2}
              addFunction={addFunction2}
              deleteFunction={deleteFunction2}
              isLoading={isLoading2}
              pageSize={pageSize2}
              setPageSize={setPageSize2}
            />
          </div>
        )}
      </DialogContent>
      <DetailFooter
        type={type}
        handleClose={handleClose}
        handleSave={() => handleSave(values, details)}
        handleCreate={() => handleCreate(values)}
        disabled={
          !(
            values.username &&
            validEmail &&
            values.firstName &&
            values.lastName &&
            values.enabled &&
            validMobileTel
          )
        }
        footerMsg={footerMsg}
      />
    </>
  );
};

export default AgentDetails;
