import React from 'react';

import * as detailHelpers from "./orderDetailsHelpers";
import clsx from 'clsx';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import AutoCompleteDropDown from './AutoCompletes/AutoCompleteDropDown';
import { InputAdornment } from '@material-ui/core';
import DebouncedTextField from '../basic-components/DebouncedTextField'; 
import TextField from "@material-ui/core/TextField";


const InsuranceItem = ({type, changeInsuranceInfo, i, insuranceTypeList, deleteInsuranceType, insuranceInfo, ...props}) => {
  const classes = detailHelpers.useStyles();

  const handleDebounce = (key, val) => {
    changeInsuranceInfo('insuranceItems', key, val, i);
  };

  return (
    <Grid item container xs={12}>
      <Grid item xs={5}>
        <Typography>
          <strong>Ins. Amount:</strong>
        </Typography>
        {/* <TextField
          className={clsx(classes.textField, classes.dense)}
          hiddenLabel
          fullWidth
          value={type.insuranceAmount}
          onChange={e => changeInsuranceInfo("insuranceItems", e.target.value, "insuranceAmount", i)}
          InputProps={{ startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
          error={!type.insuranceAmount}
          helperText={!type.insuranceAmount ? 'This field is required.' : null}
        /> */}
        <DebouncedTextField
          className={clsx(classes.textField, classes.dense)}
          initialValue={type.insuranceAmount}
          onChange={handleDebounce}
          name='insuranceAmount'
          error={!type.insuranceAmount}
          helperText={!type.insuranceAmount ? 'This field is required.' : null}
        />
      </Grid>

      <Grid item xs={5} style={{marginLeft:"1em", paddingTop:"2px"}}>
        <Typography>
          <strong>Ins. Type:</strong>
        </Typography>

        <AutoCompleteDropDown
          onChange={(e, newVal) => changeInsuranceInfo("insuranceItems", newVal, "insuranceType", i)}
          dropDownData={insuranceTypeList}
          deleteHandler={() => deleteInsuranceType(i)}
          renderItems={[type.insuranceType]}
          enableDelete={insuranceInfo.insuranceItems.length > 1}
          error={!type.insuranceType}
          helperText='This field is required.'
        />
      </Grid>
    </Grid>
  );
};

export default InsuranceItem;