import React from "react";
import BackspaceIcon from "@material-ui/icons/Backspace";

const DeleteButton = ({ deleteHandler }) => {
  const hover = {
    cursor: "pointer",
    marginTop: "8px",
    padding: 2
  };
  // const notHover = {
  //   cursor: "pointer",
  //   marginTop: "8px",
  //   padding: 2,
  //   opacity: 0
  // };
  return <BackspaceIcon style={hover} onClick={deleteHandler} color="secondary" size="small" />;
};

export default DeleteButton;
