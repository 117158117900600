import React from "react";
import Button from "@material-ui/core/Button";

const SimpleButton = ({ onClick, text, icon, style }) => {
  return (
    <div>
      <Button onClick={onClick} variant="contained" color="default" size="small" startIcon={icon} style={style}>
        {text}
      </Button>
    </div>
  );
};

export default SimpleButton;
