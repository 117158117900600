import React from "react";
import Button from "@material-ui/core/Button";
import GetAppIcon from "@material-ui/icons/GetApp";

const DownloadButton = ({ href, onClick, color = "primary", size = "small", ...props }) => {
  return (
    <a href={href}>
      <Button
        onClick={onClick}
        variant="contained"
        color={color}
        size={size}
        startIcon={<GetAppIcon />}
      >
        {props.children}
      </Button>
    </a>
  );
};

export default DownloadButton;
