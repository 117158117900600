import instance from "../utils/API";
import instance_base from "../utils/API_base";

//get all agencies
const getAllAgencies = async () => {
  try {
    const res = await instance
      .get("/agencies", {
        params: {
          page: 0,
          size: 1000,
          sort: "createDateTime,desc"
        }
      })
      .then((res) => res.data);
    return res._embedded.agencies;
  } catch (error) {
    throw error;
  }
};

const getAgency_base = async (url) => {
  try {
    const res = await instance_base.get(url).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//create agency
const createAgency = async (info) => {
  console.log(info)
  try {
    const res = await instance.post("/agencies", {...info, email:info.email.trim()}).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

//update agency
const updateAgency_base = async (url, newData) => {
  try {
    const res = await instance_base.patch(url, newData).then((res) => res.data);
    return res;
  } catch (error) {
    throw error;
  }
};

// search agencies
const searchAgency = async data => {
  try {
    const res = await instance
      .get("/agencies/search/multiParam", {
        params: {...data}
      })
      .then((res) => res.data);

    return res;
  } catch (error) {
    throw error;
  }
}

export { getAgency_base, getAllAgencies, createAgency, updateAgency_base, searchAgency };
