import React from "react";
import "./BasicInput.scss";
import classnames from "classnames";
import Typography from "@material-ui/core/Typography";

const BasicInputLowerCase = ({
  value,
  onChange,
  type = "text",
  name,
  error = false,
  readOnly = false,
  disabled = false,
  errorMsg,
  ...props
}) => {
  const basicTextInputClass = classnames({ "basic-input": true, "error-input": error });

  return (
    <div style={{ display: "flex", width: "100%" }}>
      <input
        data-cy={name}
        className={basicTextInputClass}
        value={value}
        onChange={onChange}
        type={type}
        name={name}
        readOnly={readOnly}
        disabled={disabled}
        style={{textTransform: "lowercase"}}
        step={type === "number" ? "0.01" : undefined}
      />
      {error && <Typography variant='overline' color='secondary'>{errorMsg}</Typography>}
    </div>
  );
};

export default BasicInputLowerCase;
