import React, { useState, useEffect, useContext, useCallback } from 'react';
import { OrderTabContext } from '../../Context';
import OrderInfo from './Tab-OrderInfo';
import { getAllOrders, getOrderIDsByPage, getOrder, createOrder, updateOrder_base } from '../API_calls/orders';
import { getAllEmailLogs } from '../API_calls/emailLogs';
import { getMobileExpenses_base } from '../API_calls/mobileExpenses';
import { getAllMobileExpenseTypes } from '../API_calls/mobileExpenseTypes';

import { withRouter, Link } from 'react-router-dom';
import moment from 'moment';

import ContractorReportViewer from '../../pages/ContractorReportViewer';
import AgentReportViewer from '../../pages/AgentReportViewer';
import DocumentCrudTable from '../DocumentCrudTable';
import ExpensesCrudTable from '../ExpensesCrudTable';
import WebAccessLogsCrudTable from '../WebAccessLogsCrudTable';
import UpdateLogsCrudTable from '../UpdateLogsCrudTable';
import CopyOrderDialog from './CopyOrderDialog';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import EmailLogsTable from '../EmailLogsTable';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import LoadingSpinner from '../LoadingSpinner';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TabPanel = ({ children, value, index }) => {
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index}>
      <Box
        style={{
          padding: '10px',
          overflow: 'auto',
          margin: '5px',
          backgroundColor: '#dee0df',
        }}
      >
        {children}
      </Box>
    </Typography>
  );
};

const newOrderInitialData = {
  orderDate: moment(),
  serviceTime: null,
  completionTime: null,
  assignmentTime: null,
  paraOutTime: null,
  paraPuroNum: '',
  adminNote: '',
  insuranceFaxed: false,
  orderLocation: 'Toronto',
  orderID: 'new',
  medifastID: 'new',
  refNum: {
    refNumType: '',
    refNumNumber: '',
  },
  examLocation: {
    clientLocationAddress: '',
    clientLocationPostalCode: '',
    clientLocationMediFastOffice: false,
  },
  lab: {
    labName: '',
    labNumber: '',
    labPuroNum: '',
  },
  prebook: false,
  prebookConfirmed: false,
  status: 'Pending',
  orderedBy: 'Call-In',
  remarks: '',
  insuranceItems: [],
  referredBy: '',
  paidBy: 'COMPANY',
  specialTests: [],
  clientInfo: {
    firstName: '',
    lastName: '',
    gender: '',
    dob: null,
    mobile: '',
    home: '',
    business: '',
    fax: '',
    languages: ['English'],
    email: '',
    age: '',
  },
  _embedded: {
    agent: {
      username: '',
      firstName: '',
      lastName: '',
      fax: '',
      homeTel: '',
      mobileTel: '',
      businessTel: '',
      ext: '',
      agentOrderTel: '',
      agentNote: '',
    },
    orderAttachments: [],
    emailLogs: [],
    // serviceItems: [],
    insuranceCompany: {
      name: '',
      _links: { self: { href: '' } },
    },
  },
};

/********************************************************* INNER DRAWER ******************************************************/

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  tabDirection: {
    float: 'right',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    top: '63px',
    width: '100%',
    height: '48px',
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: 'white',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(7.3),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-start',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: 0,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  spinner: {
    display: 'flex',
    '& > * + *': {
      marginLeft: theme.spacing(2),
    },
  },
}));

/*********************************************************/
const OrderDetail = ({ match, history }) => {
  const classes = useStyles();
  const { orderTabState, orderTabDispatch } = useContext(OrderTabContext);

  const {
    params: { orderId },
  } = match;

  const [orderDetail, setOrderDetail] = useState({});
  const [id, setId] = useState(orderId);
  const [lastID, setlastID] = useState(null); //used to nav to next order when new page is fetched
  const [contractorReportOpen, setContractorReportOpen] = useState(false);
  const [agentReportOpen, setAgentReportOpen] = useState(false);
  const [openCopyDialog, setOpenCopyDialog] = useState(false);
  const [emailLogs, setEmailLogs] = useState([]);
  const [mobileExpenses, setMobileExpenses] = useState([]);
  const [mobileExpenseTypeList, setMobileExpenseTypeList] = useState([]);
  const [currentContractors, setCurrentContractors] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [orderPage, setOrderPage] = useState(0);
  const [isLastPage, setIsLastPage] = useState(false);

  const [save, setSave] = useState(false);
  const [copyState, setCopyState] = useState({
    companyInfo: false,
    agencyInfo: false,
    agentInfo: true,
    clientInfo: 'clientNoFirstName',
  });
  const [copy, setCopy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNavDisabled, setIsNavDisabled] = useState({ prev: true, next: true });

  const [allowSaving, setAllowSaving] = useState(false); // Will be set in Tab Order Info, when conditions are met
  //1. All required fields are filled
  //2. Changes to the form [] Should be refactored to actually compare inital form against a current state rather then flipping flags
  // (that wont account for undoing changes manually)

  // DIRTY FLAGS FOR ALL ELEMENTS
  const [changeFlag, setChangeFlag] = useState({
    /* Drilled as flag/setFlag (oldName)
    Areas thave have changed from the inital state
    These flags are used to control what gets fired/ saved during the save process in Tab-OrderInfo
    Rather than comparing against the original order...
    */
    order: false,
    insurance: false,
    serviceDetails: false,
    serviceItemDelete: false,
    agent: false,
  });

  // Set order list logic
  const getOrderList = useCallback(
    async (page = 0, fetchSize = 5, currentID = '', currData = []) => {
      /* Only run if !Errors, otherwise just give latest option
      Should bring in next/ prev conditions closer to this */
      const cumlativeList = currData;
      let pageToFetch = page;
      try {
        //Fetch Initial Data
        const fetchData = await getOrderIDsByPage(page, fetchSize);
        const totalPages = fetchData.page.totalPages; // Need to store in state, and will be used to avoid extra fetches (end of list)
        // const totalElements = fetchData.page.totalElements;
        const list = fetchData._embedded.orders;
        const currListIds = list.map((order) => order.orderID);
        const newCumulativeList = [...cumlativeList, ...currListIds];
        const isCurrOrderInList = newCumulativeList.includes(currentID);

        const fetchOnlyOne = currentID === '' || currentID === 'new' ? true : false;
        const isEndOfList = pageToFetch + 1 === totalPages;
        setIsLastPage(isEndOfList);
        if (isEndOfList || fetchOnlyOne || isCurrOrderInList) {
          // End loop
          setOrderPage(pageToFetch); //Last Page Fetched prev/next will use if they need to fetch more
          setOrderList(newCumulativeList); //Append and set OrderList for component to use
          if (lastID) {
            setlastID(null);
            let nextID = newCumulativeList.indexOf(id);
            if (nextID) nextID++;
            nextID = newCumulativeList[nextID];
            setId(nextID);
            return history.push(`/orders/${nextID}`);
          }
          return;
        }
        // Fetch next page and check again
        //Add list to cumulative list and fetch the next page
        currListIds.every((id) => cumlativeList.push(id));
        pageToFetch += 1;
        return getOrderList(pageToFetch, fetchSize, currentID, cumlativeList); // Need to take data too?
      } catch (err) {
        console.log(err);
      }
    },
    [lastID]
  );

  // Handle tab changes
  const handleChange = (event, newValue) => {
    orderTabDispatch({
      type: 'SETVAL',
      payload: {
        data: newValue,
      },
    });
    // newValue = defaulted to index of the child (e.target)
  };

  useEffect(() => {
    //Fetches and or sets order details (that builds orderInfo (displayed info))
    // Also fetches data for other tabs (logs and expenses)
    const fetchOrderDetailById = async (inputID) => {
      try {
        let details;
        let emailLogsData;
        let mobileExpensesData;
        let mobileExpenseTypesListData;

        if (inputID === 'new') {
          details = newOrderInitialData;
          emailLogsData = [];
          mobileExpensesData = [];
          mobileExpenseTypesListData = await getAllMobileExpenseTypes();
          setOrderDetail(details);
          setLoading(false);
        } else {
          details = await getOrder(inputID);
          setOrderDetail(details);
          setLoading(false); //Shows what is has ASAP gets the rest in the background
          //In the background==================================================================================================
          const linkForEmailLogs = details._links.emailLogs.href;
          const linkForOrderExpenses = details._links.mobileExpenses.href.replace('{?projection}', '');

          const res = await Promise.all([
            getAllEmailLogs(linkForEmailLogs),
            getMobileExpenses_base(linkForOrderExpenses),
            getAllMobileExpenseTypes(),
          ]);

          emailLogsData = res[0];
          mobileExpensesData = res[1];
          mobileExpenseTypesListData = res[2];

          setEmailLogs(emailLogsData);
          setMobileExpenses(mobileExpensesData);
          setMobileExpenseTypeList(mobileExpenseTypesListData);
        }

        setSave(false); //Why is this here?  Prev Click New Order started save but it didn't do anything...
        setOpenCopyDialog(false);
      } catch (err) {
        setLoading(false);
      }
    };

    if (id) {
      setLoading(true);
      fetchOrderDetailById(id);
      // setId(orderId); // [] Redundant > should be in an else? as it wont change from the initial load... > unneccisay so the id should only change by link (re-renders...)
    }
  }, [id, orderId]);

  useEffect(() => {
    //page = 0, fetchSize = 5, currentID = 'T3100', currData = []
    const FETCH_SIZE = 10;
    getOrderList(orderPage, FETCH_SIZE, id, orderList);
  }, [orderPage]);

  const updateParaTime = (newTime) => {
    setOrderDetail((prev) => {
      return {
        ...prev,
        paraOutTime: newTime,
      };
    });
  };
  /********************************************************* STATE FUNCTION ******************************************************/
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const saveOrder = (detail) => {
    createOrder(detail);
  };

  const updateOrder = (detail, id) => {
    updateOrder_base(detail, id);
  };

  const handleSave = () => {
    setSave(true);
  };
  const orderInfoTools = () => {
    return (
      <>
        {orderTabState.tabIndexVal === 0 && (
          <>
            <ListItem
              data-cy="save-order-btn"
              button
              onClick={handleSave}
              style={{ backgroundColor: allowSaving ? 'red' : 'white' }}
              disabled={!allowSaving}
            >
              <ListItemText primary="Save" />
            </ListItem>
            <Link to="/orders" className="text-link">
              <ListItem button>
                <ListItemText primary="Search" />
              </ListItem>
            </Link>
            <Divider />
            <Link to="/orders/new" className="text-link">
              <ListItem button onClick={() => setId('new')}>
                <ListItemText data-cy="new-order-btn" primary="New Order" />
              </ListItem>
            </Link>
            <ListItem button onClick={() => setOpenCopyDialog(true)}>
              <ListItemText primary="Copy Order" data-cy="copy-order-btn" />
            </ListItem>
            <Divider />
            <ListItem button onClick={() => setContractorReportOpen(true)} disabled={!allowSaving}>
              <ListItemText primary="Order Ticket" />
            </ListItem>
            <ListItem button onClick={() => setAgentReportOpen(true)} disabled={!allowSaving}>
              <ListItemText primary="Status Report" />
            </ListItem>
          </>
        )}
      </>
    );
  };

  const changeOrderId = (id) => {
    setId(id);
  };

  const submitOrderId = (e) => {
    e.preventDefault();
    return history.push(`/orders/${id}`);
  };

  const handlePrevButton = async () => {
    const currIndex = orderList.indexOf(id);
    const prevOrderID = orderList[currIndex - 1];
    if (prevOrderID) {
      setId(prevOrderID); //need to update ID IOT get proper index
      return history.push(`/orders/${prevOrderID}`);
    }
    /* Else isnt requires b/c the page info starts @ 0; and the 'latest' order is controlled by the disable logic */
  };
  const navigateToLatest = async () => {
    const latestOrder = await getAllOrders(0, 1);
    const latestOrderID = latestOrder[0].orderID;
    setId(latestOrderID);
    history.push(`/orders/${latestOrderID}`);
  };
  const handleLastButton = async () => {
    navigateToLatest();
  };

  const handleNextButton = async () => {
    const currIndex = orderList.indexOf(id);
    const nextOrderID = orderList[currIndex + 1];
    /* can Refactor > if lastin index, then fetch the next page in the background */
    if (id === 'new') {
      handleLastButton();
    }
    if (currIndex !== orderList.length - 1) {
      setId(nextOrderID);
      return history.push(`/orders/${nextOrderID}`);
    } else {
      const nextPageToFetch = orderPage + 1;
      setlastID(id); //used as a ref to find next order in useEffect
      setOrderPage(nextPageToFetch);
    }
  };

  const copyOrder = () => {
    setCopy(true);
  };

  useEffect(() => {
    const updateNavButtons = () => {
      const isDisabled = {
        prev: true,
        next: true,
      };
      if (id === 'new') return isDisabled;
      isDisabled.prev = orderList[0] === id ? true : false;
      isDisabled.next = isLastPage && orderList[orderList.length - 1] === id ? true : false;
      return isDisabled;
    };
    const update = updateNavButtons();
    setIsNavDisabled(update);
  }, [id, orderList]);

  /****************************************************** ORDER INFO ******************************************************/

  return (
    <div className={classes.root}>
      <AppBar
        position="static"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
        color="default"
      >
        <Grid container>
          <Grid item xs={open ? 12 : 11}>
            <Tabs
              value={orderTabState.tabIndexVal}
              onChange={handleChange}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Order Info" />
              <Tab label="Email Logs" />
              <Tab label="Other Expenses" />
              <Tab label="Documents" />
              <Tab label="Web Access Logs" />
              <Tab label="Update Logs" />
            </Tabs>
          </Grid>
          {!open && (
            <Grid item container xs={1} justify="flex-end">
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge={false}
                onClick={handleDrawerOpen}
                className={clsx(open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </AppBar>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: open,
        })}
      >
        <TabPanel value={orderTabState.tabIndexVal} index={0}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <OrderInfo
              orderDetail={orderDetail}
              id={id}
              setId={setId}
              changeOrderId={changeOrderId}
              submitOrderId={submitOrderId}
              save={save}
              setSave={setSave}
              saveOrder={saveOrder}
              updateOrder={updateOrder}
              setCurrentContractors={setCurrentContractors}
              copyState={copyState}
              copy={copy}
              setCopy={setCopy}
              history={history}
              changeFlag={changeFlag}
              setChangeFlag={setChangeFlag}
              setAllowSaving={setAllowSaving}
            />
          )}
        </TabPanel>
        <TabPanel value={orderTabState.tabIndexVal} index={1}>
          {orderDetail && <EmailLogsTable emailLogs={emailLogs} />}
        </TabPanel>
        <TabPanel value={orderTabState.tabIndexVal} index={2}>
          {orderDetail && (
            <ExpensesCrudTable
              mobileExpenses={mobileExpenses}
              mobileExpenseTypeList={mobileExpenseTypeList}
              contractorDetails={currentContractors}
              order={orderDetail._links ? orderDetail._links.self.href : ''}
            />
          )}
        </TabPanel>
        <TabPanel value={orderTabState.tabIndexVal} index={3}>
          {orderDetail && (
            <DocumentCrudTable
              orderLink={orderDetail._links ? orderDetail._links.self.href : ''}
              orderAttachmentLink={orderDetail._links ? orderDetail._links.orderAttachments.href : ''}
              updateParaTime={updateParaTime}
            />
          )}
        </TabPanel>
        <TabPanel value={orderTabState.tabIndexVal} index={4}>
          <WebAccessLogsCrudTable details={orderDetail} />
        </TabPanel>
        <TabPanel value={orderTabState.tabIndexVal} index={5}>
          <UpdateLogsCrudTable details={orderDetail} />
        </TabPanel>
      </main>

      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <List>
            <ListItem button onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ListItemText primary={'OPEN'} style={{ color: 'red' }} />
              ) : (
                <ListItemText primary={'CLOSE'} style={{ color: 'red' }} />
              )}
            </ListItem>
            <Divider style={{ border: 'solid 1px grey' }} variant="middle" />
          </List>
        </div>

        <List>
          {orderInfoTools()}
          <Divider />
          <ListItem
            button
            onClick={handlePrevButton}
            style={{ paddingTop: '0px', paddingBottom: '0px', color: 'blue' }}
            // disabled={orderList[0] === id ? true : false}
            disabled={isNavDisabled.prev}
          >
            <ListItemText primary="Prev." />
          </ListItem>
          <ListItem
            button
            onClick={handleNextButton}
            style={{ paddingTop: '0px', paddingBottom: '0px', color: 'blue' }}
            // disabled={isLastPage && orderList[orderList.length - 1] === id}
            disabled={isNavDisabled.next}

            // disabled={orderList[orderList.length - 1] === id ? true : false}
            //Need to check page totals to enable or disable
          >
            <ListItemText primary="Next" />
          </ListItem>
          <ListItem
            button
            onClick={handleLastButton}
            style={{ paddingTop: '0px', paddingBottom: '0px', color: 'blue' }}
            // disabled={orderList[0] === id ? true : false}
          >
            <ListItemText primary="Latest" />
          </ListItem>
        </List>
      </Drawer>
      <Dialog
        // maxWidth="lg"
        fullScreen
        open={contractorReportOpen}
        onClose={() => setContractorReportOpen(false)}
        TransitionComponent={Transition}
      >
        <ContractorReportViewer closeDialog={() => setContractorReportOpen(false)} id={id} />
      </Dialog>
      <Dialog
        fullScreen
        open={agentReportOpen}
        onClose={() => setAgentReportOpen(false)}
        TransitionComponent={Transition}
      >
        <AgentReportViewer closeDialog={() => setAgentReportOpen(false)} id={id} />
      </Dialog>
      <Dialog open={openCopyDialog} onClose={() => setOpenCopyDialog(false)} maxWidth="lg">
        {orderDetail && (
          <CopyOrderDialog
            handleClose={() => setOpenCopyDialog(false)}
            copyState={copyState}
            setCopyState={setCopyState}
            handleOk={copyOrder}
          />
        )}
      </Dialog>
    </div>
  );
};

export default withRouter(OrderDetail);
