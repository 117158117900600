import React from 'react';

import DeleteButton from './DeleteButton';

import * as detailHelpers from "./orderDetailsHelpers";
import AutoCompleteDropDownControlled from "./AutoCompletes/AutoCompleteDropDownControlled";
import clsx from "clsx";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import DebouncedTextField from '../basic-components/DebouncedTextField';
import OrderDetailsPhoneBoxStatic from "../basic-components/OrderDetailsPhoneBoxStatic";


const ServiceItem = ({
  globalNameList, 
  showDelete, 
  serviceDetail, 
  index, 
  changeContractor, 
  changeServiceDetails, 
  delServiceItem, 
  ...props}) => {

  const classes = detailHelpers.useStyles();

  const handleServiceItemChange = (val, name) => {
    changeServiceDetails(name, val, index);
  };


  // console.log(serviceDetail)

  return (
    <Grid item container xs={12} spacing={1}>

      <Grid item lg={3} sm={6} xs={12} className={classes.overflowHidden}>
        <Grid item xs={12}>
          <Typography>
            <strong>Service Type:</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
        <AutoCompleteDropDownControlled 
            source="mobileServices"
            dropDownData={globalNameList.mobileServiceTypes}
            dropDownVal="shortCode"
            renderItem={serviceDetail.serviceType}
            onChange={(e, newVal) => changeServiceDetails("serviceType", newVal, index)}
            // error={!serviceDetail.serviceType.name}
          />
        </Grid>
      </Grid>

      <Grid item lg={3} sm={6} xs={12} className={classes.overflowHidden}>
        <Grid item xs={12}>
          <Typography>
            <strong>Contractor:</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <AutoCompleteDropDownControlled
            source="contractor"
            dropDownData={globalNameList.contractors}
            dropDownVal="firstName"
            renderItem={serviceDetail.contractor.name}
            enableDelete={false}
            multiple={false}
            onChange={(e, newVal) => changeContractor(newVal, index)}
          />
        </Grid>
      </Grid>

      <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
        <Grid item xs={12}>
          <Typography>
            <strong>Tel.:</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <OrderDetailsPhoneBoxStatic
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            hiddenLabel
            fullWidth
            value={serviceDetail.contractor.home}
            inputProps={{ readOnly: true }}
            type='tel'

          />

        </Grid>
      </Grid>

      <Grid item lg={2} sm={3} xs={12} className={classes.overflowHidden}>
        <Grid item xs={12}>
          <Typography>
            <strong>Mobile:</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <OrderDetailsPhoneBoxStatic
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            hiddenLabel
            fullWidth
            value={serviceDetail.contractor.mobile}
            inputProps={{ readOnly: true }}
            type='tel'

          />
        </Grid>
      </Grid>

      <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
        <Grid item xs={12}>
          <Typography>
            <strong>EM:</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {/* <TextField
            className={clsx(classes.textField, classes.dense)}
            margin="none"
            hiddenLabel
            fullWidth
            value={serviceDetail.employeeMileage}
            onChange={e => changeServiceDetails('employeeMileage', e.target.value, index)}
          /> */}
          <DebouncedTextField
            className={clsx(classes.textField, classes.dense)}
            initialValue={serviceDetail.employeeMileage}
            onChange={handleServiceItemChange}
            name='employeeMileage'
            type='number'

            // error={!serviceDetail.employeeMileage}
            // helperText={!serviceDetail.employeeMileage && 'This field is required.'}
          />
        </Grid>
      </Grid>

      <Grid item lg={1} sm={2} xs={12} className={classes.overflowHidden}>
        <Grid item xs={12}>
          <Typography>
            <strong>IM:</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <div style={{ display: "flex", flexDirection: "rows" }}>
            {/* <TextField
              className={clsx(classes.textField, classes.dense)}
              margin="none"
              hiddenLabel
              fullWidth
              value={serviceDetail.insuranceMileage}
              onChange={e => changeServiceDetails("insuranceMileage", e.target.value, index)}
            /> */}
            <DebouncedTextField
              className={clsx(classes.textField, classes.dense)}
              initialValue={serviceDetail.insuranceMileage}
              onChange={handleServiceItemChange}
              name='insuranceMileage'
              type='number'

              // error={!serviceDetail.insuranceMileage}
              // helperText={!serviceDetail.insuranceMileage && 'This field is required.'}
            />
            {showDelete && 
              <DeleteButton
                style={{ cursor: "pointer", display: "flex", justifySelf: "flex-end" }}
                deleteHandler={() => delServiceItem(index, serviceDetail.link)} 
              />
            }
          </div>
        </Grid>
      </Grid>
      
    </Grid>
  );
};

export default ServiceItem;