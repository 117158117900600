import React, { useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DefaultTable from '../components/DefaultTable';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import TextField from '@material-ui/core/TextField';
import AutoCompleteDropDown from '../components/OrderDetails/AutoCompletes/AutoCompleteDropDown';
import { GlobalNameListContext } from '../Context';
import { UserContext } from '../UserContext';
import DateTimePicker from '../components/OrderDetails/DateTimePicker';
import SimpleButton from '../components/OrderDetails/SimpleButton';
import { dynamicReportInfoColumns, statusTypes, dateRangeTypes } from '../components/data/constants';
import { searchOrders } from '../components/API_calls/orders';
import FileCopyIcon from '@material-ui/icons/FileCopy'


const OrderListPage = props => {

  const history = useHistory();

  const { globalNameList } = useContext(GlobalNameListContext);
  const { userState } = useContext(UserContext);

  const [searchField, setSearchField] = useState({});
  const [dateRange, setDateRange] = useState('');

  // TABLE STATES
  const [ordersList, setOrdersList] = useState([]);
  const [pageSize, setPageSize] = useState(50);
  const [orderPage, setOrderPage] = useState(0);

  // DEBUGGING USEEECTS
  useEffect(() => {
    console.log('searchField:', searchField)
  
  }, [searchField]);

  useEffect(() => {
    console.log('dateRange:', dateRange)
  
  }, [dateRange]);

  const getData = async (page = 0, search = true) => {

    const dateFields = dateRange ? {
      [dateRange.toDate]: searchField.toDate ? searchField.toDate : null,
      [dateRange.fromDate]: searchField.fromDate ? searchField.fromDate : null
    } : {};

    const list = await searchOrders({ 
      clientFirstName: searchField.clientFirstName ? searchField.clientFirstName : null,
      clientLastName: searchField.clientLastName ? searchField.clientLastName : null,
      insuranceCompany: searchField.insuranceCompany ? searchField.insuranceCompany.link : null,
      agent: userState.userInfo.link,
      ...dateFields
    }, page);
    console.log('list:', list)

    // if on search submission, replace entire list, otherwise, append new results to existing list
    search ? setOrdersList(list) : setOrdersList(prev => [...prev, ...list]);
  };

  const changeDate = (val, setCurrentTime, source) => {
    setCurrentTime(val);
    setSearchField(prev => ({ ...prev, [source]: val ? val.format() : val }));
  };

  const onRowClick = (event, rowData) => {
    console.log('rowData:', rowData)
    history.push({
      pathname: `/view-order/${rowData.orderID}`,
      state: { orderLink: rowData._links.self.href }
    });
  };

  const actions = [
    rowData => 
    ({
      icon: () => <FileCopyIcon />,
      tooltip: "Copy Order",
      onClick: (event, rowData, togglePanel) => {
        history.push({
          pathname: '/new-order/copy',
          state: { orderLink: rowData._links.self.href }
        });
      },
      hidden: rowData.status === 'Cancelled' || rowData.status === 'Complete' || rowData.status === 'Hold'
    }),
  ];

  const onMoreOrdersClick = () => {
    setOrderPage(prev => prev + 1);
    getData(orderPage + 1, false);
  };

  return (
    <>
      <Grid container spacing={1} style={{ margin: "10px 0"}}>
        <Grid item container lg={12} sm={12} xs={12} spacing={1}>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setSearchField(prev => ({ ...prev, insuranceCompany: newVal }))}
              dropDownData={globalNameList.insuranceCompanies}
              dropDownVal='name'
              renderItems={searchField.insuranceCompany ? [searchField.insuranceCompany] : [null]}
              enableDelete={false}
              label='Insurance Company:'
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setSearchField(prev => ({ ...prev, statusList: newVal }))}
              dropDownData={statusTypes}
              renderItems={searchField.statusList ? [searchField.statusList] : [null]}
              enableDelete={false}
              label='Status:'
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <TextField 
              fullWidth
              onChange={e => setSearchField(prev => ({ ...prev, clientLastName: e.target.value }))}
              value={searchField.clientLastName}
              label='Last Name'
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <TextField 
              fullWidth
              onChange={e => setSearchField(prev => ({ ...prev, clientFirstName: e.target.value }))}
              value={searchField.clientFirstName}
              label='First Name'
            />
          </Grid>
        </Grid>

        <Grid item container lg={11} sm={12} xs={12} spacing={1} style={{ margin: "10px 0"}}>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <AutoCompleteDropDown
              onChange={(e, newVal) => setDateRange(newVal)}
              dropDownData={dateRangeTypes}
              renderItems={dateRange ? [dateRange] : [null]}
              enableDelete={false}
              dropDownVal='name'
              label='Date Range:'
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <DateTimePicker
              source='fromDate'
              fromDate={searchField.fromDate ? searchField.fromDate : null}
              label='From:'
              changeDate={changeDate}
              clearable
            />
          </Grid>
          <Grid item lg={3} sm={6} xs={12} style={{ padding: "0 10px"}}>
            <DateTimePicker
              source='toDate'
              toDate={searchField.toDate ? searchField.toDate : null}
              label='To:'
              changeDate={changeDate}
              clearable
            />
          </Grid>
        </Grid>

        <Grid item container lg={1} sm={12} xs={12} style={{ marginTop: "20px", textAlign: "center" }}>
          <Grid item xs={6}>
            <SimpleButton 
              onClick={event => {
                event.persist(); // to remove from synthetic event pool as per warning, not sure why this doesn't apply to other searches
                getData();
              }}
              text={"SEARCH"} icon={<SearchIcon />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider />

      <DefaultTable
        title=""
        inputColumns={dynamicReportInfoColumns}
        inputData={ordersList}
        // deleteFunction={deleteFunction}
        // isLoading={isLoading}
        pageSize={pageSize}
        setPageSize={setPageSize}
        headerStyle={{ backgroundColor: "#34b7eb", textTransform: 'capitalize' }}
        actions={actions}
        onRowClick={onRowClick}
        loadMoreOrders={onMoreOrdersClick}
      />
    </>
  );
};

export default OrderListPage;