import React from "react";
import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";

const SaveButton = ({ text = "Save", size = "large", submitHandler, disabled }) => {
  return (
      <Button
      onClick={submitHandler}
      variant="contained"
      color="primary"
      size={size}
      startIcon={<SaveIcon />}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default SaveButton;
