import React from "react";
import html2pdf from "html2pdf.js";
import Button from "@material-ui/core/Button";

const CreatePDF = ({ refProp, dimensions, orientation = 'landscape', ...props }) => {
  const worker = html2pdf();
  // useEffect(() => {
  //   worker = html2pdf();
  // });
  const printDocument = async () => {
    const opt = {
      margin: 1,
      filename: "myfile.pdf",
      image: { type: "pdf", quality: 1 },
      jsPDF: { format: "A4", orientation: orientation },
      pagebreak: {
        before: '.invoice-summary',
        mode: ['css', 'legacy', 'avoid-all']
      }
    };

    let pdf = await worker
      .from(refProp.current)
      .set(opt)
      .toPdf()
      .outputPdf()
      .save();
    pdf = btoa(pdf);
    console.log(pdf);
  };

  return (
    <Button
      style={{ width: "100%", margin: "0", padding: "0" }}
      variant="contained"
      onClick={printDocument}
    >
      DOWNLOAD
    </Button>
  );
};

export default CreatePDF;
